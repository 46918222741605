define("plutof/components/annotation/bulk/sections/area", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const __COLOCATED_TEMPLATE__ = Ember.HTMLBars.template(
  /*
    <PlutofPanel::Simple @title={{i18n-t "Sample.samplingArea"}}>
      <Layout::RegularGrid @columns={{2}}>
          <Annotation::Bulk::Field @field={{@fields.country}} as |field|>
              <AutoComplete::Model
                  @model="geography/country"
                  @value={{field.value}}
                  @update={{field.update}}
                  @disabled={{field.disabled}} />
          </Annotation::Bulk::Field>
      </Layout::RegularGrid>
  </PlutofPanel::Simple>
  
  */
  {
    "id": "xfQU0far",
    "block": "{\"symbols\":[\"field\",\"@fields\"],\"statements\":[[8,\"plutof-panel/simple\",[],[[\"@title\"],[[30,[36,0],[\"Sample.samplingArea\"],null]]],[[\"default\"],[{\"statements\":[[2,\"\\n    \"],[8,\"layout/regular-grid\",[],[[\"@columns\"],[2]],[[\"default\"],[{\"statements\":[[2,\"\\n        \"],[8,\"annotation/bulk/field\",[],[[\"@field\"],[[32,2,[\"country\"]]]],[[\"default\"],[{\"statements\":[[2,\"\\n            \"],[8,\"auto-complete/model\",[],[[\"@model\",\"@value\",\"@update\",\"@disabled\"],[\"geography/country\",[32,1,[\"value\"]],[32,1,[\"update\"]],[32,1,[\"disabled\"]]]],null],[2,\"\\n        \"]],\"parameters\":[1]}]]],[2,\"\\n    \"]],\"parameters\":[]}]]],[2,\"\\n\"]],\"parameters\":[]}]]],[2,\"\\n\"]],\"hasEval\":false,\"upvars\":[\"i18n-t\"]}",
    "moduleName": "plutof/components/annotation/bulk/sections/area.hbs"
  });
  var _default = _exports.default = Ember._setComponentTemplate(__COLOCATED_TEMPLATE__, Ember._templateOnlyComponent());
});