define("plutof/components/conservation/red-list/view/permissions", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const __COLOCATED_TEMPLATE__ = Ember.HTMLBars.template(
  /*
    <PlutofPanel::Simple @title={{i18n-t "General.access"}}>
      <Access::ViewRightsBase as |vr|>
          <vr.panel>
              <vr.view.public />
              <vr.edit.selected @groups={{list @workgroup}} />
          </vr.panel>
  
          <vr.panel>
              <vr.history
                  @createdBy={{@redlist.created_by.representation}}
                  @createdAt={{@redlist.created_at}}
                  @updatedBy={{@redlist.modified_by.representation}}
                  @updatedAt={{@redlist.modified_at}} />
          </vr.panel>
      </Access::ViewRightsBase>
  </PlutofPanel::Simple>
  
  */
  {
    "id": "A03hkRyQ",
    "block": "{\"symbols\":[\"vr\",\"@workgroup\",\"@redlist\"],\"statements\":[[8,\"plutof-panel/simple\",[],[[\"@title\"],[[30,[36,0],[\"General.access\"],null]]],[[\"default\"],[{\"statements\":[[2,\"\\n    \"],[8,\"access/view-rights-base\",[],[[],[]],[[\"default\"],[{\"statements\":[[2,\"\\n        \"],[8,[32,1,[\"panel\"]],[],[[],[]],[[\"default\"],[{\"statements\":[[2,\"\\n            \"],[8,[32,1,[\"view\",\"public\"]],[],[[],[]],null],[2,\"\\n            \"],[8,[32,1,[\"edit\",\"selected\"]],[],[[\"@groups\"],[[30,[36,1],[[32,2]],null]]],null],[2,\"\\n        \"]],\"parameters\":[]}]]],[2,\"\\n\\n        \"],[8,[32,1,[\"panel\"]],[],[[],[]],[[\"default\"],[{\"statements\":[[2,\"\\n            \"],[8,[32,1,[\"history\"]],[],[[\"@createdBy\",\"@createdAt\",\"@updatedBy\",\"@updatedAt\"],[[32,3,[\"created_by\",\"representation\"]],[32,3,[\"created_at\"]],[32,3,[\"modified_by\",\"representation\"]],[32,3,[\"modified_at\"]]]],null],[2,\"\\n        \"]],\"parameters\":[]}]]],[2,\"\\n    \"]],\"parameters\":[1]}]]],[2,\"\\n\"]],\"parameters\":[]}]]],[2,\"\\n\"]],\"hasEval\":false,\"upvars\":[\"i18n-t\",\"list\"]}",
    "moduleName": "plutof/components/conservation/red-list/view/permissions.hbs"
  });
  var _default = _exports.default = Ember._setComponentTemplate(__COLOCATED_TEMPLATE__, Ember._templateOnlyComponent());
});