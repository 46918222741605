define("plutof/components/collection/view/repositories", ["exports", "ember-concurrency", "plutof/utils/pagination/builder", "plutof/utils/search", "plutof/utils/search/modules"], function (_exports, _emberConcurrency, _builder, _search, _modules) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _dec, _dec2, _dec3, _dec4, _class, _descriptor, _descriptor2, _descriptor3;
  function _initializerDefineProperty(e, i, r, l) { r && Object.defineProperty(e, i, { enumerable: r.enumerable, configurable: r.configurable, writable: r.writable, value: r.initializer ? r.initializer.call(l) : void 0 }); }
  function _defineProperty(e, r, t) { return (r = _toPropertyKey(r)) in e ? Object.defineProperty(e, r, { value: t, enumerable: !0, configurable: !0, writable: !0 }) : e[r] = t, e; }
  function _toPropertyKey(t) { var i = _toPrimitive(t, "string"); return "symbol" == typeof i ? i : i + ""; }
  function _toPrimitive(t, r) { if ("object" != typeof t || !t) return t; var e = t[Symbol.toPrimitive]; if (void 0 !== e) { var i = e.call(t, r || "default"); if ("object" != typeof i) return i; throw new TypeError("@@toPrimitive must return a primitive value."); } return ("string" === r ? String : Number)(t); }
  function _applyDecoratedDescriptor(i, e, r, n, l) { var a = {}; return Object.keys(n).forEach(function (i) { a[i] = n[i]; }), a.enumerable = !!a.enumerable, a.configurable = !!a.configurable, ("value" in a || a.initializer) && (a.writable = !0), a = r.slice().reverse().reduce(function (r, n) { return n(i, e, r) || r; }, a), l && void 0 !== a.initializer && (a.value = a.initializer ? a.initializer.call(l) : void 0, a.initializer = void 0), void 0 === a.initializer ? (Object.defineProperty(i, e, a), null) : a; }
  function _initializerWarningHelper(r, e) { throw Error("Decorating class property failed. Please ensure that transform-class-properties is enabled and runs after the decorators transform."); }
  const __COLOCATED_TEMPLATE__ = Ember.HTMLBars.template(
  /*
    <RelatedObjects::View
      @title={{i18n-t "collection.repository.repositories"}}
      @pagination={{this.pagination}}
  >
      <RelatedObjects::Content @pagination={{this.pagination}} as |Field repository|>
          <Field @label={{backend-translations/label "repository.name"}}>
              {{repository.name}}
          </Field>
  
          <Field @label={{i18n-t "General.Specimens"}}>
              <a href={{repository.specimens.searchURL}}>
                  {{repository.specimens.count}}
              </a>
          </Field>
  
          <Field @label={{i18n-t "General.LivingC"}}>
              <a href={{repository.livingSpecimens.searchURL}}>
                  {{repository.livingSpecimens.count}}
              </a>
          </Field>
  
          <Field @label={{i18n-t "General.materialSamples"}}>
              <a href={{repository.materialsamples.searchURL}}>
                  {{repository.materialsamples.count}}
              </a>
          </Field>
      </RelatedObjects::Content>
  </RelatedObjects::View>
  
  */
  {
    "id": "ctCsawbH",
    "block": "{\"symbols\":[\"Field\",\"repository\"],\"statements\":[[8,\"related-objects/view\",[],[[\"@title\",\"@pagination\"],[[30,[36,0],[\"collection.repository.repositories\"],null],[32,0,[\"pagination\"]]]],[[\"default\"],[{\"statements\":[[2,\"\\n    \"],[8,\"related-objects/content\",[],[[\"@pagination\"],[[32,0,[\"pagination\"]]]],[[\"default\"],[{\"statements\":[[2,\"\\n        \"],[8,[32,1],[],[[\"@label\"],[[30,[36,1],[\"repository.name\"],null]]],[[\"default\"],[{\"statements\":[[2,\"\\n            \"],[1,[32,2,[\"name\"]]],[2,\"\\n        \"]],\"parameters\":[]}]]],[2,\"\\n\\n        \"],[8,[32,1],[],[[\"@label\"],[[30,[36,0],[\"General.Specimens\"],null]]],[[\"default\"],[{\"statements\":[[2,\"\\n            \"],[10,\"a\"],[15,6,[32,2,[\"specimens\",\"searchURL\"]]],[12],[2,\"\\n                \"],[1,[32,2,[\"specimens\",\"count\"]]],[2,\"\\n            \"],[13],[2,\"\\n        \"]],\"parameters\":[]}]]],[2,\"\\n\\n        \"],[8,[32,1],[],[[\"@label\"],[[30,[36,0],[\"General.LivingC\"],null]]],[[\"default\"],[{\"statements\":[[2,\"\\n            \"],[10,\"a\"],[15,6,[32,2,[\"livingSpecimens\",\"searchURL\"]]],[12],[2,\"\\n                \"],[1,[32,2,[\"livingSpecimens\",\"count\"]]],[2,\"\\n            \"],[13],[2,\"\\n        \"]],\"parameters\":[]}]]],[2,\"\\n\\n        \"],[8,[32,1],[],[[\"@label\"],[[30,[36,0],[\"General.materialSamples\"],null]]],[[\"default\"],[{\"statements\":[[2,\"\\n            \"],[10,\"a\"],[15,6,[32,2,[\"materialsamples\",\"searchURL\"]]],[12],[2,\"\\n                \"],[1,[32,2,[\"materialsamples\",\"count\"]]],[2,\"\\n            \"],[13],[2,\"\\n        \"]],\"parameters\":[]}]]],[2,\"\\n    \"]],\"parameters\":[1,2]}]]],[2,\"\\n\"]],\"parameters\":[]}]]],[2,\"\\n\"]],\"hasEval\":false,\"upvars\":[\"i18n-t\",\"backend-translations/label\"]}",
    "moduleName": "plutof/components/collection/view/repositories.hbs"
  });
  let CollectionViewRepositories = _exports.default = (_dec = Ember.inject.service, _dec2 = Ember.inject.service, _dec3 = Ember.computed('collectionId'), _dec4 = (0, _emberConcurrency.task)({
    restartable: true
  }), _class = class CollectionViewRepositories extends Ember.Component {
    constructor(...args) {
      super(...args);
      _initializerDefineProperty(this, "ajax", _descriptor, this);
      _initializerDefineProperty(this, "router", _descriptor2, this);
      _initializerDefineProperty(this, "updatePagination", _descriptor3, this);
    }
    get queryParams() {
      return {
        parent_organization: this.collectionId
      };
    }
    didReceiveAttrs() {
      super.didReceiveAttrs(...arguments);
      this.updatePagination.perform();
    }
    async getRecordCount(repositoryID, searchModule) {
      const count = await this.ajax.request(`/${searchModule.value}${_search.SEARCH_ENDPOINT}?sub_repository=${repositoryID}&page_size=1`).then(response => response.count);
      return {
        count,
        searchURL: this.router.urlFor('search.index', {
          queryParams: {
            module: searchModule.id,
            deposited_in: this.collectionId,
            sub_repository: repositoryID
          }
        })
      };
    }
  }, _descriptor = _applyDecoratedDescriptor(_class.prototype, "ajax", [_dec], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor2 = _applyDecoratedDescriptor(_class.prototype, "router", [_dec2], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _applyDecoratedDescriptor(_class.prototype, "queryParams", [_dec3], Object.getOwnPropertyDescriptor(_class.prototype, "queryParams"), _class.prototype), _descriptor3 = _applyDecoratedDescriptor(_class.prototype, "updatePagination", [_dec4], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return function* () {
        const [specimens, livingSpecimens, materialsamples] = ['specimen', 'livingculture', 'materialsample'].map(id => _modules.default.findBy('id', id));
        const pagination = yield (0, _builder.default)({
          ajax: this.ajax,
          store: this.store
        }).fromStore('agent/repository').withCount.fromEndpoint().withFilters(this.queryParams).withPreprocess(page => {
          return Ember.RSVP.Promise.all(page.map(repository => {
            return Ember.RSVP.hash({
              id: repository.id,
              name: repository.name,
              specimens: this.getRecordCount(repository.id, specimens),
              livingSpecimens: this.getRecordCount(repository.id, livingSpecimens),
              materialsamples: this.getRecordCount(repository.id, materialsamples)
            });
          }));
        }).build();
        this.set('pagination', pagination);
      };
    }
  }), _class);
  Ember._setComponentTemplate(__COLOCATED_TEMPLATE__, CollectionViewRepositories);
});