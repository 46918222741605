define("plutof/models/filerepository/file", ["exports", "ember-data", "@ember-data/model", "plutof/misc/content_types", "plutof/models/plutof-model", "plutof/utils/display-name-from-value", "plutof/utils/file-serialization", "plutof/utils/filetypes", "plutof/utils/model", "plutof/utils/store", "plutof/misc/fileupload"], function (_exports, _emberData, _model, _content_types, _plutofModel, _displayNameFromValue, _fileSerialization, _filetypes, _model2, _store, _fileupload) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = _exports.FileType = void 0;
  var _dec, _dec2, _dec3, _dec4, _dec5, _dec6, _dec7, _dec8, _dec9, _dec10, _dec11, _dec12, _dec13, _dec14, _dec15, _dec16, _dec17, _dec18, _dec19, _dec20, _dec21, _dec22, _dec23, _dec24, _dec25, _dec26, _dec27, _dec28, _dec29, _dec30, _dec31, _dec32, _dec33, _dec34, _dec35, _dec36, _class, _descriptor, _descriptor2, _descriptor3, _descriptor4, _descriptor5, _descriptor6, _descriptor7, _descriptor8, _descriptor9, _descriptor10, _descriptor11, _descriptor12, _descriptor13, _descriptor14, _descriptor15, _descriptor16, _descriptor17, _descriptor18, _descriptor19, _descriptor20, _descriptor21, _descriptor22, _descriptor23, _descriptor24, _descriptor25, _descriptor26, _descriptor27, _descriptor28, _descriptor29;
  function _initializerDefineProperty(e, i, r, l) { r && Object.defineProperty(e, i, { enumerable: r.enumerable, configurable: r.configurable, writable: r.writable, value: r.initializer ? r.initializer.call(l) : void 0 }); }
  function _defineProperty(e, r, t) { return (r = _toPropertyKey(r)) in e ? Object.defineProperty(e, r, { value: t, enumerable: !0, configurable: !0, writable: !0 }) : e[r] = t, e; }
  function _toPropertyKey(t) { var i = _toPrimitive(t, "string"); return "symbol" == typeof i ? i : i + ""; }
  function _toPrimitive(t, r) { if ("object" != typeof t || !t) return t; var e = t[Symbol.toPrimitive]; if (void 0 !== e) { var i = e.call(t, r || "default"); if ("object" != typeof i) return i; throw new TypeError("@@toPrimitive must return a primitive value."); } return ("string" === r ? String : Number)(t); }
  function _applyDecoratedDescriptor(i, e, r, n, l) { var a = {}; return Object.keys(n).forEach(function (i) { a[i] = n[i]; }), a.enumerable = !!a.enumerable, a.configurable = !!a.configurable, ("value" in a || a.initializer) && (a.writable = !0), a = r.slice().reverse().reduce(function (r, n) { return n(i, e, r) || r; }, a), l && void 0 !== a.initializer && (a.value = a.initializer ? a.initializer.call(l) : void 0, a.initializer = void 0), void 0 === a.initializer ? (Object.defineProperty(i, e, a), null) : a; }
  function _initializerWarningHelper(r, e) { throw Error("Decorating class property failed. Please ensure that transform-class-properties is enabled and runs after the decorators transform."); }
  const EXTENSIONS_3D = ['glb', 'gltf'];
  const FileType = _exports.FileType = {
    Other: 3,
    Dataset: 4,
    Sound: 12,
    Text: 14,
    Application: 15,
    Video: 17,
    Image: 18,
    Model: 19
  };
  const displayName = (0, _displayNameFromValue.displayNames)('filerepository/file');
  let File = (_dec = Ember.inject.service, _dec2 = (0, _model.attr)('string'), _dec3 = (0, _model.attr)('string', {
    defaultValue: ''
  }), _dec4 = (0, _model.attr)('date'), _dec5 = (0, _model.attr)('string'), _dec6 = (0, _model.attr)('number'), _dec7 = (0, _model.attr)('number'), _dec8 = (0, _model.attr)('number'), _dec9 = (0, _model.attr)('number'), _dec10 = (0, _model.attr)('enum'), _dec11 = (0, _model.attr)('enum'), _dec12 = displayName('licence'), _dec13 = Ember.computed('file_name'), _dec14 = Ember.computed('size'), _dec15 = Ember.computed.reads('file_name'), _dec16 = Ember.computed('id'), _dec17 = (0, _model2.hasmany_property)('filerepository/item', {
    file: 'id'
  }), _dec18 = (0, _model2.hasmany_property)('filerepository/creator', {
    file: 'id'
  }), _dec19 = (0, _model.belongsTo)('agent/agent'), _dec20 = (0, _model.attr)('string'), _dec21 = (0, _model.attr)('string'), _dec22 = (0, _model.attr)('string'), _dec23 = (0, _model.attr)('string'), _dec24 = (0, _model.attr)('string'), _dec25 = (0, _model.attr)('string'), _dec26 = (0, _model.attr)('string'), _dec27 = (0, _model.attr)('json'), _dec28 = (0, _model.attr)('json'), _dec29 = (0, _model.belongsTo)('geography/language'), _dec30 = Ember.computed('type'), _dec31 = Ember.computed.equal('internalType', 'sound'), _dec32 = Ember.computed.equal('internalType', 'video'), _dec33 = Ember.computed.equal('internalType', 'image'), _dec34 = Ember.computed('extension'), _dec35 = Ember.computed('image_width', 'image_height'), _dec36 = Ember.computed('isSound', 'isVideo', 'isImage', 'is3dModel'), _class = class File extends _plutofModel.default {
    constructor(...args) {
      super(...args);
      _initializerDefineProperty(this, "ajax", _descriptor, this);
      _initializerDefineProperty(this, "file_name", _descriptor2, this);
      _initializerDefineProperty(this, "capture_device", _descriptor3, this);
      _initializerDefineProperty(this, "digitization_date", _descriptor4, this);
      _initializerDefineProperty(this, "format", _descriptor5, this);
      _initializerDefineProperty(this, "image_width", _descriptor6, this);
      _initializerDefineProperty(this, "image_height", _descriptor7, this);
      _initializerDefineProperty(this, "size", _descriptor8, this);
      _initializerDefineProperty(this, "download_count", _descriptor9, this);
      _initializerDefineProperty(this, "type", _descriptor10, this);
      _initializerDefineProperty(this, "licence", _descriptor11, this);
      _initializerDefineProperty(this, "licenceDisplayName", _descriptor12, this);
      _initializerDefineProperty(this, "representation", _descriptor13, this);
      _initializerDefineProperty(this, "items", _descriptor14, this);
      _initializerDefineProperty(this, "creators", _descriptor15, this);
      _initializerDefineProperty(this, "rights_owner", _descriptor16, this);
      _initializerDefineProperty(this, "creation_technique", _descriptor17, this);
      _initializerDefineProperty(this, "rights_statement", _descriptor18, this);
      _initializerDefineProperty(this, "description", _descriptor19, this);
      _initializerDefineProperty(this, "caption", _descriptor20, this);
      _initializerDefineProperty(this, "source", _descriptor21, this);
      _initializerDefineProperty(this, "credit", _descriptor22, this);
      _initializerDefineProperty(this, "title", _descriptor23, this);
      _initializerDefineProperty(this, "download_links", _descriptor24, this);
      _initializerDefineProperty(this, "relations", _descriptor25, this);
      _initializerDefineProperty(this, "language", _descriptor26, this);
      _initializerDefineProperty(this, "isSound", _descriptor27, this);
      _initializerDefineProperty(this, "isVideo", _descriptor28, this);
      _initializerDefineProperty(this, "isImage", _descriptor29, this);
    }
    get extension() {
      const name = this.file_name || '';
      const dot = name.lastIndexOf('.');
      return dot === -1 ? '' : name.substring(dot + 1).toLowerCase();
    }
    get humanReadableSize() {
      return (0, _fileSerialization.getHumanReadableSize)(this.size);
    }
    get keywords() {
      return _emberData.default.PromiseArray.create({
        promise: (0, _content_types.get_ctype_by_name)(this.store, 'filerepository/file').then(ctype => {
          return (0, _store.query)(this.store, 'glossary/item', {
            content_type: ctype.id,
            object_id: this.id
          });
        })
      });
    }
    get internalType() {
      return _filetypes.type_to_internal[this.type];
    }
    // TODO: This should be type-, not extension-based
    get is3dModel() {
      return EXTENSIONS_3D.includes(this.get('extension'));
    }
    get dimensions() {
      if (this.image_height && this.image_width) {
        return `${this.image_width}x${this.image_height}`;
      } else {
        return '';
      }
    }
    get isDocument() {
      return [this.isSound, this.isVideo, this.isImage, this.is3dModel].every(type => !type);
    }
    async destroyCompletely() {
      await super.destroyCompletely();
      const uploader = await (0, _fileupload.get_uploader)(this.store);
      uploader.remove(this);
    }
  }, _descriptor = _applyDecoratedDescriptor(_class.prototype, "ajax", [_dec], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor2 = _applyDecoratedDescriptor(_class.prototype, "file_name", [_dec2], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor3 = _applyDecoratedDescriptor(_class.prototype, "capture_device", [_dec3], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor4 = _applyDecoratedDescriptor(_class.prototype, "digitization_date", [_dec4], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor5 = _applyDecoratedDescriptor(_class.prototype, "format", [_dec5], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor6 = _applyDecoratedDescriptor(_class.prototype, "image_width", [_dec6], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor7 = _applyDecoratedDescriptor(_class.prototype, "image_height", [_dec7], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor8 = _applyDecoratedDescriptor(_class.prototype, "size", [_dec8], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor9 = _applyDecoratedDescriptor(_class.prototype, "download_count", [_dec9], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor10 = _applyDecoratedDescriptor(_class.prototype, "type", [_dec10], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor11 = _applyDecoratedDescriptor(_class.prototype, "licence", [_dec11], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor12 = _applyDecoratedDescriptor(_class.prototype, "licenceDisplayName", [_dec12], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _applyDecoratedDescriptor(_class.prototype, "extension", [_dec13], Object.getOwnPropertyDescriptor(_class.prototype, "extension"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "humanReadableSize", [_dec14], Object.getOwnPropertyDescriptor(_class.prototype, "humanReadableSize"), _class.prototype), _descriptor13 = _applyDecoratedDescriptor(_class.prototype, "representation", [_dec15], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _applyDecoratedDescriptor(_class.prototype, "keywords", [_dec16], Object.getOwnPropertyDescriptor(_class.prototype, "keywords"), _class.prototype), _descriptor14 = _applyDecoratedDescriptor(_class.prototype, "items", [_dec17], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor15 = _applyDecoratedDescriptor(_class.prototype, "creators", [_dec18], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor16 = _applyDecoratedDescriptor(_class.prototype, "rights_owner", [_dec19], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor17 = _applyDecoratedDescriptor(_class.prototype, "creation_technique", [_dec20], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor18 = _applyDecoratedDescriptor(_class.prototype, "rights_statement", [_dec21], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor19 = _applyDecoratedDescriptor(_class.prototype, "description", [_dec22], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor20 = _applyDecoratedDescriptor(_class.prototype, "caption", [_dec23], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor21 = _applyDecoratedDescriptor(_class.prototype, "source", [_dec24], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor22 = _applyDecoratedDescriptor(_class.prototype, "credit", [_dec25], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor23 = _applyDecoratedDescriptor(_class.prototype, "title", [_dec26], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor24 = _applyDecoratedDescriptor(_class.prototype, "download_links", [_dec27], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor25 = _applyDecoratedDescriptor(_class.prototype, "relations", [_dec28], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor26 = _applyDecoratedDescriptor(_class.prototype, "language", [_dec29], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _applyDecoratedDescriptor(_class.prototype, "internalType", [_dec30], Object.getOwnPropertyDescriptor(_class.prototype, "internalType"), _class.prototype), _descriptor27 = _applyDecoratedDescriptor(_class.prototype, "isSound", [_dec31], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor28 = _applyDecoratedDescriptor(_class.prototype, "isVideo", [_dec32], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor29 = _applyDecoratedDescriptor(_class.prototype, "isImage", [_dec33], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _applyDecoratedDescriptor(_class.prototype, "is3dModel", [_dec34], Object.getOwnPropertyDescriptor(_class.prototype, "is3dModel"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "dimensions", [_dec35], Object.getOwnPropertyDescriptor(_class.prototype, "dimensions"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "isDocument", [_dec36], Object.getOwnPropertyDescriptor(_class.prototype, "isDocument"), _class.prototype), _class);
  var _default = _exports.default = File;
});