define("plutof/templates/components/unite/sequence-guidelines", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = Ember.HTMLBars.template({
    "id": "v09Z6gCE",
    "block": "{\"symbols\":[\"char\"],\"statements\":[[6,[37,5],[[35,4]],null,[[\"default\",\"else\"],[{\"statements\":[[6,[37,3],[[30,[36,2],[[30,[36,2],[[35,1]],null]],null]],[[\"key\"],[\"@index\"]],[[\"default\"],[{\"statements\":[[2,\"        \"],[10,\"td\"],[14,0,\"colorized\"],[12],[1,[32,1]],[13],[2,\"\\n\"]],\"parameters\":[1]}]]]],\"parameters\":[]},{\"statements\":[[2,\"    \"],[10,\"td\"],[14,0,\"non-colorized\"],[12],[1,[34,0]],[13],[2,\"\\n\"]],\"parameters\":[]}]]]],\"hasEval\":false,\"upvars\":[\"guidelineString\",\"guidelineStringChars\",\"-track-array\",\"each\",\"colorize\",\"if\"]}",
    "moduleName": "plutof/templates/components/unite/sequence-guidelines.hbs"
  });
});