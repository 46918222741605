define("plutof/components/search/filters/typification", ["exports", "@glimmer/component", "plutof/utils/store"], function (_exports, _component, _store) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _dec, _dec2, _dec3, _dec4, _dec5, _class, _descriptor, _descriptor2;
  function _initializerDefineProperty(e, i, r, l) { r && Object.defineProperty(e, i, { enumerable: r.enumerable, configurable: r.configurable, writable: r.writable, value: r.initializer ? r.initializer.call(l) : void 0 }); }
  function _defineProperty(e, r, t) { return (r = _toPropertyKey(r)) in e ? Object.defineProperty(e, r, { value: t, enumerable: !0, configurable: !0, writable: !0 }) : e[r] = t, e; }
  function _toPropertyKey(t) { var i = _toPrimitive(t, "string"); return "symbol" == typeof i ? i : i + ""; }
  function _toPrimitive(t, r) { if ("object" != typeof t || !t) return t; var e = t[Symbol.toPrimitive]; if (void 0 !== e) { var i = e.call(t, r || "default"); if ("object" != typeof i) return i; throw new TypeError("@@toPrimitive must return a primitive value."); } return ("string" === r ? String : Number)(t); }
  function _applyDecoratedDescriptor(i, e, r, n, l) { var a = {}; return Object.keys(n).forEach(function (i) { a[i] = n[i]; }), a.enumerable = !!a.enumerable, a.configurable = !!a.configurable, ("value" in a || a.initializer) && (a.writable = !0), a = r.slice().reverse().reduce(function (r, n) { return n(i, e, r) || r; }, a), l && void 0 !== a.initializer && (a.value = a.initializer ? a.initializer.call(l) : void 0, a.initializer = void 0), void 0 === a.initializer ? (Object.defineProperty(i, e, a), null) : a; }
  function _initializerWarningHelper(r, e) { throw Error("Decorating class property failed. Please ensure that transform-class-properties is enabled and runs after the decorators transform."); }
  const __COLOCATED_TEMPLATE__ = Ember.HTMLBars.template(
  /*
    <div class="filter-input" data-input={{@filter.config.label}}>
      <Common::Ui::ControlLabel>
          <:label>
              <TooltipWrapper as |t|>
                  <div class="plutof-label">
                      {{@filter.config.label}}
                  </div>
  
                  <span id="{{t.tid}}" class="wrapper-tooltip">
                      {{#if @filter.config.help_text}}
                          {{@filter.config.help_text}}
                      {{else}}
                          {{@filter.config.label}}
                      {{/if}}
                  </span>
              </TooltipWrapper>
          </:label>
      </Common::Ui::ControlLabel>
  
      {{#resolve-promise this.typifications as |typifications|}}
          <PlutofSelect::Object
              @content={{typifications}}
              @oneWay={{true}}
              @value={{this.dummy}}
              @selectionChanged={{this.add}} />
  
          <Common::RecordList
              @records={{@filter.value}}
              @remove={{this.remove}} />
      {{/resolve-promise}}
  </div>
  
  */
  {
    "id": "3BN77zNm",
    "block": "{\"symbols\":[\"typifications\",\"__arg0\",\"t\",\"@filter\"],\"statements\":[[10,\"div\"],[14,0,\"filter-input\"],[15,\"data-input\",[32,4,[\"config\",\"label\"]]],[12],[2,\"\\n    \"],[8,\"common/ui/control-label\",[],[[\"@namedBlocksInfo\"],[[30,[36,1],null,[[\"label\"],[0]]]]],[[\"default\"],[{\"statements\":[[6,[37,0],[[30,[36,2],[[32,2],\"label\"],null]],null,[[\"default\"],[{\"statements\":[[2,\"\\n            \"],[8,\"tooltip-wrapper\",[],[[],[]],[[\"default\"],[{\"statements\":[[2,\"\\n                \"],[10,\"div\"],[14,0,\"plutof-label\"],[12],[2,\"\\n                    \"],[1,[32,4,[\"config\",\"label\"]]],[2,\"\\n                \"],[13],[2,\"\\n\\n                \"],[10,\"span\"],[15,1,[31,[[32,3,[\"tid\"]]]]],[14,0,\"wrapper-tooltip\"],[12],[2,\"\\n\"],[6,[37,0],[[32,4,[\"config\",\"help_text\"]]],null,[[\"default\",\"else\"],[{\"statements\":[[2,\"                        \"],[1,[32,4,[\"config\",\"help_text\"]]],[2,\"\\n\"]],\"parameters\":[]},{\"statements\":[[2,\"                        \"],[1,[32,4,[\"config\",\"label\"]]],[2,\"\\n\"]],\"parameters\":[]}]]],[2,\"                \"],[13],[2,\"\\n            \"]],\"parameters\":[3]}]]],[2,\"\\n        \"]],\"parameters\":[]}]]]],\"parameters\":[2]}]]],[2,\"\\n\\n\"],[6,[37,3],[[32,0,[\"typifications\"]]],null,[[\"default\"],[{\"statements\":[[2,\"        \"],[8,\"plutof-select/object\",[],[[\"@content\",\"@oneWay\",\"@value\",\"@selectionChanged\"],[[32,1],true,[32,0,[\"dummy\"]],[32,0,[\"add\"]]]],null],[2,\"\\n\\n        \"],[8,\"common/record-list\",[],[[\"@records\",\"@remove\"],[[32,4,[\"value\"]],[32,0,[\"remove\"]]]],null],[2,\"\\n\"]],\"parameters\":[1]}]]],[13],[2,\"\\n\"]],\"hasEval\":false,\"upvars\":[\"if\",\"hash\",\"-is-named-block-invocation\",\"resolve-promise\"]}",
    "moduleName": "plutof/components/search/filters/typification.hbs"
  });
  let TypificationSearchFilter = _exports.default = (_dec = Ember.inject.service, _dec2 = Ember.computed, _dec3 = Ember._tracked, _dec4 = Ember._action, _dec5 = Ember._action, _class = class TypificationSearchFilter extends _component.default {
    constructor(...args) {
      super(...args);
      _initializerDefineProperty(this, "store", _descriptor, this);
      _initializerDefineProperty(this, "dummy", _descriptor2, this);
    }
    get typifications() {
      return (0, _store.findAll)(this.store, 'determination/typification');
    }
    add(value) {
      if (value) {
        this.args.filter.value.pushObject(value);
        this.dummy = null;
      }
    }
    remove(value) {
      this.args.filter.value.removeObject(value);
    }
  }, _descriptor = _applyDecoratedDescriptor(_class.prototype, "store", [_dec], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _applyDecoratedDescriptor(_class.prototype, "typifications", [_dec2], Object.getOwnPropertyDescriptor(_class.prototype, "typifications"), _class.prototype), _descriptor2 = _applyDecoratedDescriptor(_class.prototype, "dummy", [_dec3], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return null;
    }
  }), _applyDecoratedDescriptor(_class.prototype, "add", [_dec4], Object.getOwnPropertyDescriptor(_class.prototype, "add"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "remove", [_dec5], Object.getOwnPropertyDescriptor(_class.prototype, "remove"), _class.prototype), _class);
  Ember._setComponentTemplate(__COLOCATED_TEMPLATE__, TypificationSearchFilter);
});