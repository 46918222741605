define("plutof/templates/components/filerepository/image-area/-selector", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = Ember.HTMLBars.template({
    "id": "RUbkPrtr",
    "block": "{\"symbols\":[\"@source\"],\"statements\":[[10,\"div\"],[14,0,\"image-preview\"],[12],[2,\"\\n    \"],[10,\"img\"],[15,\"src\",[32,1]],[15,1,[31,[[32,0,[\"elementId\"]],\"-image\"]]],[14,0,\"image-preview__image\"],[12],[13],[2,\"\\n\\n    \"],[10,\"div\"],[15,1,[31,[[32,0,[\"elementId\"]],\"-selection\"]]],[14,0,\"image-preview__selection\"],[12],[2,\"\\n    \"],[13],[2,\"\\n\"],[13],[2,\"\\n\"]],\"hasEval\":false,\"upvars\":[]}",
    "moduleName": "plutof/templates/components/filerepository/image-area/-selector.hbs"
  });
});