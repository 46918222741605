define("plutof/templates/components/experiment/dna-extraction/edit-form", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = Ember.HTMLBars.template({
    "id": "2braUK5A",
    "block": "{\"symbols\":[\"@experiment\",\"@dnas\",\"@linkedItemsData\",\"@validationContext\",\"@save\",\"@cancel\"],\"statements\":[[8,\"plutof-panel/simple\",[],[[\"@title\"],[[30,[36,0],[\"General.generalData\"],null]]],[[\"default\"],[{\"statements\":[[2,\"\\n    \"],[1,[30,[36,3],null,[[\"experiment\",\"materialIsInvalid\",\"validationContext\"],[[32,1],[35,2],[35,1,[\"metadata\"]]]]]],[2,\"\\n\"]],\"parameters\":[]}]]],[2,\"\\n\\n\"],[1,[30,[36,6],null,[[\"experiment\",\"dnas\",\"addLabware\",\"validationContext\"],[[32,1],[32,2],[30,[36,5],[[32,0],[35,4]],null],[35,1,[\"material\"]]]]]],[2,\"\\n\\n\"],[1,[30,[36,7],null,[[\"experiment\",\"materials\",\"materialType\"],[[32,1],[32,2],\"dna\"]]]],[2,\"\\n\\n\"],[1,[30,[36,8],null,[[\"data\",\"validationContext\"],[[32,3],[35,1,[\"associatedData\"]]]]]],[2,\"\\n\\n\"],[8,\"sticky-bottom-wrapper\",[],[[],[]],[[\"default\"],[{\"statements\":[[2,\"\\n    \"],[8,\"invalid-panels\",[],[[\"@validationContext\"],[[32,4]]],null],[2,\"\\n\\n    \"],[10,\"div\"],[14,0,\"action-buttons\"],[12],[2,\"\\n        \"],[8,\"common/save-button\",[],[[\"@save\",\"@disabled\"],[[30,[36,5],[[32,0],[32,5]],null],[34,1,[\"isInvalid\"]]]],null],[2,\"\\n\\n        \"],[8,\"common/cancel-button\",[],[[\"@cancel\"],[[30,[36,5],[[32,0],[32,6]],null]]],null],[2,\"\\n    \"],[13],[2,\"\\n\"]],\"parameters\":[]}]]],[2,\"\\n\"]],\"hasEval\":false,\"upvars\":[\"i18n-t\",\"validationContext\",\"materialIsInvalid\",\"experiment/dna-extraction/experiment-metadata\",\"addLabware\",\"action\",\"experiment/dna-extraction/experiment-material\",\"experiment/common/labware-visualization\",\"linked-items/edit\"]}",
    "moduleName": "plutof/templates/components/experiment/dna-extraction/edit-form.hbs"
  });
});