define("plutof/templates/transaction/add", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = Ember.HTMLBars.template({
    "id": "eMgjIuvg",
    "block": "{\"symbols\":[],\"statements\":[[8,\"test/route-marker\",[],[[\"@route\"],[\"transaction.add\"]],null],[2,\"\\n\\n\"],[8,\"navbar/edit\",[],[[\"@icon\",\"@title\",\"@record\",\"@reset\",\"@infoContent\"],[[30,[36,1],[\"transaction\"],null],[30,[36,0],[\"transactions.newTitle\"],null],[32,0,[\"model\",\"transaction\"]],[30,[36,2],[\"triggerReset\"],null],\"information/content/collection/transaction\"]],null],[2,\"\\n\\n\"],[8,\"transaction/edit\",[],[[\"@model\",\"@validationContext\",\"@forceExpand\"],[[32,0,[\"model\",\"transaction\"]],[32,0,[\"validationContext\"]],[32,0,[\"forceExpand\"]]]],null],[2,\"\\n\\n\"],[8,\"sticky-bottom-wrapper\",[],[[],[]],[[\"default\"],[{\"statements\":[[2,\"\\n    \"],[8,\"invalid-panels\",[],[[\"@validationContext\"],[[32,0,[\"validationContext\"]]]],null],[2,\"\\n\\n\"],[6,[37,4],[[30,[36,3],[[32,0,[\"model\",\"transaction\",\"hasRequiredRoles\"]]],null]],null,[[\"default\"],[{\"statements\":[[2,\"        \"],[10,\"p\"],[12],[2,\"\\n            \"],[10,\"strong\"],[14,0,\"invalid-panel\"],[12],[2,\"\\n                \"],[1,[30,[36,0],[\"transactions.invalidRoles\"],null]],[2,\"\\n            \"],[13],[2,\"\\n        \"],[13],[2,\"\\n\"]],\"parameters\":[]}]]],[2,\"\\n    \"],[10,\"div\"],[14,0,\"action-buttons\"],[12],[2,\"\\n        \"],[8,\"common/save-button\",[],[[\"@save\",\"@disabled\"],[[32,0,[\"save\"]],[32,0,[\"validationContext\",\"isInvalid\"]]]],null],[2,\"\\n\\n        \"],[8,\"common/cancel-button\",[],[[\"@cancel\"],[[30,[36,5],[[32,0],\"cancel\"],null]]],null],[2,\"\\n    \"],[13],[2,\"\\n\"]],\"parameters\":[]}]]],[2,\"\\n\"]],\"hasEval\":false,\"upvars\":[\"i18n-t\",\"icon\",\"route-action\",\"not\",\"if\",\"action\"]}",
    "moduleName": "plutof/templates/transaction/add.hbs"
  });
});