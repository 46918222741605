define("plutof/components/linked-items/-edit/-files/uploaded", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const __COLOCATED_TEMPLATE__ = Ember.HTMLBars.template(
  /*
    <tr>
      <td>
          {{#link-to "filerepository.view" @file.id}}
              {{@file.representation}}
          {{/link-to}}
      </td>
  
      <td>
          {{@file.licenceDisplayName}}
      </td>
  
      <td>
          {{@file.rights_owner.representation}}
      </td>
  
      <td>
          <Common::RecordListView @records={{@file.creators}} as |creator|>
              {{creator.representation}}
          </Common::RecordListView>
      </td>
  
      <td class="align-right">
          <button {{on "click" @remove}} class="btn btn-link">
              <span class={{icon "remove"}}></span>
          </button>
      </td>
  </tr>
  
  */
  {
    "id": "k/12FLRj",
    "block": "{\"symbols\":[\"creator\",\"@file\",\"@remove\"],\"statements\":[[10,\"tr\"],[12],[2,\"\\n    \"],[10,\"td\"],[12],[2,\"\\n\"],[6,[37,0],null,[[\"route\",\"model\"],[\"filerepository.view\",[32,2,[\"id\"]]]],[[\"default\"],[{\"statements\":[[2,\"            \"],[1,[32,2,[\"representation\"]]],[2,\"\\n\"]],\"parameters\":[]}]]],[2,\"    \"],[13],[2,\"\\n\\n    \"],[10,\"td\"],[12],[2,\"\\n        \"],[1,[32,2,[\"licenceDisplayName\"]]],[2,\"\\n    \"],[13],[2,\"\\n\\n    \"],[10,\"td\"],[12],[2,\"\\n        \"],[1,[32,2,[\"rights_owner\",\"representation\"]]],[2,\"\\n    \"],[13],[2,\"\\n\\n    \"],[10,\"td\"],[12],[2,\"\\n        \"],[8,\"common/record-list-view\",[],[[\"@records\"],[[32,2,[\"creators\"]]]],[[\"default\"],[{\"statements\":[[2,\"\\n            \"],[1,[32,1,[\"representation\"]]],[2,\"\\n        \"]],\"parameters\":[1]}]]],[2,\"\\n    \"],[13],[2,\"\\n\\n    \"],[10,\"td\"],[14,0,\"align-right\"],[12],[2,\"\\n        \"],[11,\"button\"],[24,0,\"btn btn-link\"],[4,[38,1],[\"click\",[32,3]],null],[12],[2,\"\\n            \"],[10,\"span\"],[15,0,[30,[36,2],[\"remove\"],null]],[12],[13],[2,\"\\n        \"],[13],[2,\"\\n    \"],[13],[2,\"\\n\"],[13],[2,\"\\n\"]],\"hasEval\":false,\"upvars\":[\"link-to\",\"on\",\"icon\"]}",
    "moduleName": "plutof/components/linked-items/-edit/-files/uploaded.hbs"
  });
  var _default = _exports.default = Ember._setComponentTemplate(__COLOCATED_TEMPLATE__, Ember._templateOnlyComponent());
});