define("plutof/templates/components/import/relation-auto-complete", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = Ember.HTMLBars.template({
    "id": "DwConD8i",
    "block": "{\"symbols\":[\"@cell\"],\"statements\":[[6,[37,0],[[32,1,[\"field\",\"multiple\"]]],null,[[\"default\",\"else\"],[{\"statements\":[[2,\"    \"],[8,\"auto-complete/generic\",[],[[\"@model\",\"@filters\",\"@update\"],[[32,0,[\"localModel\"]],[32,1,[\"field\",\"filters\"]],[32,0,[\"addMultipleInput\"]]]],null],[2,\"\\n\\n    \"],[8,\"common/record-list\",[],[[\"@records\",\"@remove\"],[[32,1,[\"relation\"]],[32,0,[\"removeMultipleInput\"]]]],null],[2,\"\\n\"]],\"parameters\":[]},{\"statements\":[[2,\"    \"],[8,\"auto-complete/generic\",[],[[\"@model\",\"@filters\",\"@value\",\"@update\"],[[32,0,[\"localModel\"]],[32,1,[\"field\",\"filters\"]],[32,1,[\"relation\"]],[32,0,[\"updateInput\"]]]],null],[2,\"\\n\"]],\"parameters\":[]}]]]],\"hasEval\":false,\"upvars\":[\"if\"]}",
    "moduleName": "plutof/templates/components/import/relation-auto-complete.hbs"
  });
});