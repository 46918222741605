define("plutof/templates/series/edit", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = Ember.HTMLBars.template({
    "id": "eIsJF+OO",
    "block": "{\"symbols\":[],\"statements\":[[8,\"test/route-marker\",[],[[\"@route\"],[\"series.edit\"]],null],[2,\"\\n\\n\"],[8,\"navbar/edit\",[],[[\"@icon\",\"@title\",\"@record\"],[[30,[36,0],[\"reference\"],null],[30,[36,1],[\"References.editRefSeries\"],null],[32,0,[\"model\",\"series\"]]]],null],[2,\"\\n\\n\"],[8,\"series/edit\",[],[[\"@series\",\"@accessRights\",\"@validationContext\",\"@save\"],[[32,0,[\"model\",\"series\"]],[32,0,[\"model\",\"accessRights\"]],[32,0,[\"validationContext\"]],[32,0,[\"save\"]]]],null],[2,\"\\n\"]],\"hasEval\":false,\"upvars\":[\"icon\",\"i18n-t\"]}",
    "moduleName": "plutof/templates/series/edit.hbs"
  });
});