define("plutof/templates/components/menu/side-bar", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = Ember.HTMLBars.template({
    "id": "TFvCPCt9",
    "block": "{\"symbols\":[\"@collapseMenu\"],\"statements\":[[10,\"div\"],[15,0,[31,[[30,[36,0],[[32,1],\"sidebar collapsed\",\"sidebar\"],null]]]],[12],[2,\"\\n\"],[2,\"    \"],[10,\"div\"],[14,0,\"sidebar__brand visible-xs\"],[14,\"tabindex\",\"1\"],[12],[2,\"\\n\"],[6,[37,1],null,[[\"route\"],[\"index\"]],[[\"default\"],[{\"statements\":[[2,\"            \"],[10,\"img\"],[14,0,\"sidebar__brand__logo\"],[14,\"src\",\"/assets/images/PlutoF-assets/plutof-logo-green-cropped.svg\"],[12],[13],[2,\"\\n\"]],\"parameters\":[]}]]],[2,\"    \"],[13],[2,\"\\n\\n\"],[2,\"    \"],[11,\"button\"],[24,0,\"sidebar__toggle-btn visible-xs\"],[4,[38,2],[\"click\",[32,0,[\"toggleMenuCollapse\"]]],null],[12],[2,\"\\n        \"],[10,\"span\"],[14,0,\"fas fa-bars fa-2x\"],[12],[13],[2,\"\\n    \"],[13],[2,\"\\n\\n\"],[2,\"    \"],[11,\"div\"],[24,0,\"visible-xs\"],[4,[38,3],[[32,0,[\"burgerMenuOpen\"]]],null],[12],[2,\"\\n        \"],[8,\"menu/menu-tree\",[],[[\"@collapseMenu\",\"@logOut\",\"@changeLanguage\",\"@menuItemClicked\"],[[32,1],[32,0,[\"_logOut\"]],[32,0,[\"_changeLanguage\"]],[32,0,[\"collapseBurgerMenu\"]]]],null],[2,\"\\n    \"],[13],[2,\"\\n\\n\"],[2,\"    \"],[10,\"div\"],[14,0,\"hidden-xs\"],[12],[2,\"\\n        \"],[8,\"menu/menu-tree\",[],[[\"@collapseMenu\",\"@logOut\",\"@changeLanguage\",\"@menuItemClicked\"],[[32,1],[32,0,[\"_logOut\"]],[32,0,[\"_changeLanguage\"]],[32,0,[\"collapseBurgerMenu\"]]]],null],[2,\"\\n    \"],[13],[2,\"\\n\\n\"],[2,\"    \"],[11,\"button\"],[16,0,[31,[\"sidebar__toggle-btn hidden-xs \",[30,[36,0],[[32,1],\"collapsed\"],null]]]],[4,[38,2],[\"click\",[32,0,[\"toggleSidebar\"]]],null],[12],[2,\"\\n        \"],[10,\"span\"],[14,0,\"fas fa-bars fa-2x\"],[12],[13],[2,\"\\n    \"],[13],[2,\"\\n\"],[13],[2,\"\\n\"]],\"hasEval\":false,\"upvars\":[\"if\",\"link-to\",\"on\",\"collapse\"]}",
    "moduleName": "plutof/templates/components/menu/side-bar.hbs"
  });
});