define("plutof/templates/components/experiment/common/-labware-visualization/row-header", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = Ember.HTMLBars.template({
    "id": "S3IJm84E",
    "block": "{\"symbols\":[\"@row\",\"@materialContainer\",\"@enableTubeSelection\"],\"statements\":[[10,\"td\"],[14,0,\"labware-table-header\"],[12],[2,\"\\n\"],[6,[37,1],[[32,3]],null,[[\"default\",\"else\"],[{\"statements\":[[2,\"        \"],[11,\"button\"],[24,0,\"labware-table-header__button\"],[4,[38,0],[[32,0],[32,2,[\"toggleMultiple\"]],[32,1,[\"materials\"]]],null],[12],[2,\"\\n            \"],[1,[32,1,[\"label\"]]],[2,\"\\n        \"],[13],[2,\"\\n\"]],\"parameters\":[]},{\"statements\":[[2,\"        \"],[10,\"button\"],[14,0,\"labware-table-header__button\"],[12],[2,\"\\n            \"],[1,[32,1,[\"label\"]]],[2,\"\\n        \"],[13],[2,\"\\n\"]],\"parameters\":[]}]]],[13],[2,\"\\n\"]],\"hasEval\":false,\"upvars\":[\"action\",\"if\"]}",
    "moduleName": "plutof/templates/components/experiment/common/-labware-visualization/row-header.hbs"
  });
});