define("plutof/components/tables/bulk-update/update-button", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const __COLOCATED_TEMPLATE__ = Ember.HTMLBars.template(
  /*
    <button type="button" class="btn btn-default plutof-btn-green form-control" {{on "click" @update}}>
      <span class="fas fa-check"></span>
      {{i18n-t "General.tableBulkUpdate.update"}}
  </button>
  
  */
  {
    "id": "vwtrZfAB",
    "block": "{\"symbols\":[\"@update\"],\"statements\":[[11,\"button\"],[24,0,\"btn btn-default plutof-btn-green form-control\"],[24,4,\"button\"],[4,[38,0],[\"click\",[32,1]],null],[12],[2,\"\\n    \"],[10,\"span\"],[14,0,\"fas fa-check\"],[12],[13],[2,\"\\n    \"],[1,[30,[36,1],[\"General.tableBulkUpdate.update\"],null]],[2,\"\\n\"],[13],[2,\"\\n\"]],\"hasEval\":false,\"upvars\":[\"on\",\"i18n-t\"]}",
    "moduleName": "plutof/components/tables/bulk-update/update-button.hbs"
  });
  var _default = _exports.default = Ember._setComponentTemplate(__COLOCATED_TEMPLATE__, Ember._templateOnlyComponent());
});