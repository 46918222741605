define("plutof/templates/components/analysis/matching-source-item", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = Ember.HTMLBars.template({
    "id": "UBLSnF8F",
    "block": "{\"symbols\":[\"@remove\",\"@item\",\"@canRemove\"],\"statements\":[[10,\"td\"],[14,0,\"analysis-matching-source__accno-cell\"],[12],[2,\"\\n    \"],[10,\"div\"],[15,0,[31,[[30,[36,3],[[30,[36,2],[[35,1]],null],\"has-error\"],null],\" control-group\"]]],[12],[2,\"\\n        \"],[8,\"auto-complete/bound-model\",[],[[\"@model\",\"@value\",\"@filters\",\"@params\"],[\"taxonoccurrence/sequence/sequence\",[32,2,[\"sequence\"]],[30,[36,4],null,[[\"access\"],[\"edit\"]]],[30,[36,4],null,[[\"mini\"],[true]]]]],null],[2,\"\\n    \"],[13],[2,\"\\n\"],[13],[2,\"\\n\\n\"],[10,\"td\"],[14,0,\"analysis-matching-source__sequence-cell\"],[12],[2,\"\\n    \"],[10,\"div\"],[14,0,\"mini-input item-table__read-only-value\"],[12],[2,\"\\n        \"],[1,[35,5,[\"sequence\",\"sequence\"]]],[2,\" \\n    \"],[13],[2,\"\\n\"],[13],[2,\"\\n\\n\"],[10,\"td\"],[14,0,\"align-right\"],[12],[2,\"\\n\"],[6,[37,3],[[32,3]],null,[[\"default\"],[{\"statements\":[[2,\"        \"],[11,\"button\"],[24,0,\"btn btn-link no-padding\"],[24,\"tabindex\",\"-1\"],[4,[38,0],[\"click\",[32,1]],null],[12],[2,\"\\n            \"],[10,\"span\"],[14,0,\"icon-remove--red\"],[12],[13],[2,\"\\n        \"],[13],[2,\"\\n\"]],\"parameters\":[]}]]],[13],[2,\"\\n\"]],\"hasEval\":false,\"upvars\":[\"on\",\"accnoValid\",\"not\",\"if\",\"hash\",\"item\"]}",
    "moduleName": "plutof/templates/components/analysis/matching-source-item.hbs"
  });
});