define("plutof/templates/components/clipboard/bulk-update-multi-select-list", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = Ember.HTMLBars.template({
    "id": "jRsMvzAH",
    "block": "{\"symbols\":[\"selectedVal\",\"type\"],\"statements\":[[10,\"span\"],[12],[2,\" \"],[2,\" \"],[13],[2,\"\\n\"],[6,[37,7],[[30,[36,6],[[30,[36,6],[[35,0]],null]],null]],null,[[\"default\"],[{\"statements\":[[6,[37,7],[[30,[36,6],[[30,[36,6],[[35,5]],null]],null]],null,[[\"default\"],[{\"statements\":[[6,[37,4],[[30,[36,3],[[32,2,[\"value\"]],[32,1]],null]],null,[[\"default\"],[{\"statements\":[[6,[37,2],null,[[\"tagName\",\"fade-class\"],[\"span\",\"inline-block\"]],[[\"default\"],[{\"statements\":[[2,\"                \"],[10,\"span\"],[14,0,\"plutof-fade-in\"],[12],[2,\"\\n                    \"],[1,[32,2,[\"display_name\"]]],[2,\"\\n                    \"],[11,\"span\"],[24,0,\"icon-remove--red clickable\"],[4,[38,1],[[32,0],\"remove\",[35,0],[32,1]],null],[12],[13],[2,\"\\n                \"],[13],[2,\"\\n\"]],\"parameters\":[]}]]]],\"parameters\":[]}]]]],\"parameters\":[2]}]]]],\"parameters\":[1]}]]]],\"hasEval\":false,\"upvars\":[\"realValues\",\"action\",\"fade-element\",\"is-equal\",\"if\",\"optionList\",\"-track-array\",\"each\"]}",
    "moduleName": "plutof/templates/components/clipboard/bulk-update-multi-select-list.hbs"
  });
});