define("plutof/templates/components/profile/groups/group-link", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = Ember.HTMLBars.template({
    "id": "ozfjuGww",
    "block": "{\"symbols\":[\"@group\"],\"statements\":[[6,[37,0],[[32,0,[\"open\",\"isRunning\"]]],null,[[\"default\"],[{\"statements\":[[2,\"    \"],[10,\"span\"],[14,0,\"loading-icon\"],[12],[13],[2,\"\\n\"]],\"parameters\":[]}]]],[2,\"\\n\"],[1,[32,1,[\"representation\"]]],[2,\"\\n\"]],\"hasEval\":false,\"upvars\":[\"if\"]}",
    "moduleName": "plutof/templates/components/profile/groups/group-link.hbs"
  });
});