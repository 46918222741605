define("plutof/templates/components/my-menu/tool-selector", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = Ember.HTMLBars.template({
    "id": "6H3uDfiL",
    "block": "{\"symbols\":[\"tool\",\"&default\"],\"statements\":[[10,\"ul\"],[14,0,\"list-inline\"],[12],[2,\"\\n\"],[6,[37,3],[[30,[36,2],[[30,[36,2],[[32,0,[\"tools\"]]],null]],null]],null,[[\"default\"],[{\"statements\":[[2,\"        \"],[10,\"li\"],[12],[2,\"\\n\"],[2,\"            \"],[11,\"a\"],[16,0,[31,[\"drag-widget-menu \",[30,[36,0],[[32,1,[\"isActive\"]],\"active-item\"],null]]]],[4,[38,1],[[32,0],\"switch\",[32,1]],null],[12],[2,\"\\n                \"],[10,\"span\"],[15,0,[31,[[32,1,[\"icon\"]]]]],[12],[13],[2,\"\\n                \"],[1,[32,1,[\"title\"]]],[2,\"\\n            \"],[13],[2,\"\\n        \"],[13],[2,\"\\n\"]],\"parameters\":[1]}]]],[13],[2,\"\\n\\n\"],[18,2,[[30,[36,5],null,[[\"tool\"],[[30,[36,4],[\"my-menu/-tool\"],[[\"selector\"],[[32,0]]]]]]]]],[2,\"\\n\"]],\"hasEval\":false,\"upvars\":[\"if\",\"action\",\"-track-array\",\"each\",\"component\",\"hash\"]}",
    "moduleName": "plutof/templates/components/my-menu/tool-selector.hbs"
  });
});