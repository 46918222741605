define("plutof/templates/components/resolve-promise", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = Ember.HTMLBars.template({
    "id": "nbQnNonD",
    "block": "{\"symbols\":[\"&default\",\"@wait\"],\"statements\":[[6,[37,2],[[30,[36,1],[[30,[36,0],[[32,2]],null],[32,0,[\"resolve\",\"last\",\"isSuccessful\"]]],null]],null,[[\"default\"],[{\"statements\":[[2,\"    \"],[18,1,[[32,0,[\"resolve\",\"last\",\"value\"]],[32,0,[\"resolve\",\"last\",\"isSuccessful\"]],[32,0,[\"resolve\",\"isRunning\"]]]],[2,\"\\n\"]],\"parameters\":[]}]]]],\"hasEval\":false,\"upvars\":[\"not\",\"or\",\"if\"]}",
    "moduleName": "plutof/templates/components/resolve-promise.hbs"
  });
});