define("plutof/components/gallery/footer/-right-control", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const __COLOCATED_TEMPLATE__ = Ember.HTMLBars.template(
  /*
    <Gallery::Footer::-RightItem>
      <ToolTip @tooltip={{@label}}>
          <button
              {{on "click" @clicked}}
              class="gallery-control-btn"
          >
              <span class={{@icon}}></span>
          </button>
      </ToolTip>
  </Gallery::Footer::-RightItem>
  
  */
  {
    "id": "AElYv7Fd",
    "block": "{\"symbols\":[\"@label\",\"@clicked\",\"@icon\"],\"statements\":[[8,\"gallery/footer/-right-item\",[],[[],[]],[[\"default\"],[{\"statements\":[[2,\"\\n    \"],[8,\"tool-tip\",[],[[\"@tooltip\"],[[32,1]]],[[\"default\"],[{\"statements\":[[2,\"\\n        \"],[11,\"button\"],[24,0,\"gallery-control-btn\"],[4,[38,0],[\"click\",[32,2]],null],[12],[2,\"\\n            \"],[10,\"span\"],[15,0,[32,3]],[12],[13],[2,\"\\n        \"],[13],[2,\"\\n    \"]],\"parameters\":[]}]]],[2,\"\\n\"]],\"parameters\":[]}]]],[2,\"\\n\"]],\"hasEval\":false,\"upvars\":[\"on\"]}",
    "moduleName": "plutof/components/gallery/footer/-right-control.hbs"
  });
  var _default = _exports.default = Ember._setComponentTemplate(__COLOCATED_TEMPLATE__, Ember._templateOnlyComponent());
});