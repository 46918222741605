define("plutof/components/search/clipboard-controls", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const __COLOCATED_TEMPLATE__ = Ember.HTMLBars.template(
  /*
    <ToolTip @tooltip={{i18n-t "Search.clipboard.sendPageToClipboard"}}>
      <PromiseButton
          @clicked={{@sendPage}}
          @restIcon="panel-header-btn__icon {{icon 'clipboard'}}"
          @disabled={{@disabled}}
          class="panel-header-btn" />
  </ToolTip>
  
  <ToolTip @tooltip={{i18n-t "Search.clipboard.sendAllToClipboard"}}>
      <PromiseButton
          @clicked={{@sendAll}}
          @restIcon="panel-header-btn__icon {{icon 'clipboard-add-all'}}"
          @disabled={{@disabled}}
          class="panel-header-btn" />
  </ToolTip>
  
  */
  {
    "id": "Rohdct1c",
    "block": "{\"symbols\":[\"@sendPage\",\"@disabled\",\"@sendAll\"],\"statements\":[[8,\"tool-tip\",[],[[\"@tooltip\"],[[30,[36,0],[\"Search.clipboard.sendPageToClipboard\"],null]]],[[\"default\"],[{\"statements\":[[2,\"\\n    \"],[8,\"promise-button\",[[24,0,\"panel-header-btn\"]],[[\"@clicked\",\"@restIcon\",\"@disabled\"],[[32,1],[31,[\"panel-header-btn__icon \",[30,[36,1],[\"clipboard\"],null]]],[32,2]]],null],[2,\"\\n\"]],\"parameters\":[]}]]],[2,\"\\n\\n\"],[8,\"tool-tip\",[],[[\"@tooltip\"],[[30,[36,0],[\"Search.clipboard.sendAllToClipboard\"],null]]],[[\"default\"],[{\"statements\":[[2,\"\\n    \"],[8,\"promise-button\",[[24,0,\"panel-header-btn\"]],[[\"@clicked\",\"@restIcon\",\"@disabled\"],[[32,3],[31,[\"panel-header-btn__icon \",[30,[36,1],[\"clipboard-add-all\"],null]]],[32,2]]],null],[2,\"\\n\"]],\"parameters\":[]}]]],[2,\"\\n\"]],\"hasEval\":false,\"upvars\":[\"i18n-t\",\"icon\"]}",
    "moduleName": "plutof/components/search/clipboard-controls.hbs"
  });
  var _default = _exports.default = Ember._setComponentTemplate(__COLOCATED_TEMPLATE__, Ember._templateOnlyComponent());
});