define("plutof/components/collection/repository", ["exports", "ember-cp-validations", "@ember-decorators/component", "plutof/mixins/component-validations", "plutof/utils/validations"], function (_exports, _emberCpValidations, _component, _componentValidations, _validations) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _dec, _class;
  const __COLOCATED_TEMPLATE__ = Ember.HTMLBars.template(
  /*
    <td class={{has-error this "repository.name"}}>
      <Input
          @value={{@repository.name}}
          @type="text"
          class="mini-input" />
  </td>
  
  <td class="align-right">
      <button
          {{on "click" @remove}}
          class="btn btn-xs btn-link"
      >
          <span class="icon-remove icon-remove--red"></span>
      </button>
  </td>
  
  */
  {
    "id": "q7JMLb3Z",
    "block": "{\"symbols\":[\"@repository\",\"@remove\"],\"statements\":[[10,\"td\"],[15,0,[30,[36,0],[[32,0],\"repository.name\"],null]],[12],[2,\"\\n    \"],[8,\"input\",[[24,0,\"mini-input\"]],[[\"@value\",\"@type\"],[[32,1,[\"name\"]],\"text\"]],null],[2,\"\\n\"],[13],[2,\"\\n\\n\"],[10,\"td\"],[14,0,\"align-right\"],[12],[2,\"\\n    \"],[11,\"button\"],[24,0,\"btn btn-xs btn-link\"],[4,[38,1],[\"click\",[32,2]],null],[12],[2,\"\\n        \"],[10,\"span\"],[14,0,\"icon-remove icon-remove--red\"],[12],[13],[2,\"\\n    \"],[13],[2,\"\\n\"],[13],[2,\"\\n\"]],\"hasEval\":false,\"upvars\":[\"has-error\",\"on\"]}",
    "moduleName": "plutof/components/collection/repository.hbs"
  });
  const Validations = (0, _emberCpValidations.buildValidations)({
    'repository.name': (0, _validations.notEmptySpace)(256)
  });
  let CollectionRepository = (_dec = (0, _component.tagName)('tr'), _dec(_class = class CollectionRepository extends Ember.Component.extend(Validations, _componentValidations.default) {}) || _class);
  var _default = _exports.default = Ember._setComponentTemplate(__COLOCATED_TEMPLATE__, CollectionRepository);
});