define("plutof/templates/components/photobank/agents-form", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = Ember.HTMLBars.template({
    "id": "kgLPc4FC",
    "block": "{\"symbols\":[\"item\",\"value\",\"update\",\"@items\"],\"statements\":[[8,\"plutof-panel/simple\",[[24,\"data-test\",\"Photobank::AgentsForm\"]],[[\"@title\"],[[30,[36,1],[\"Photobank.agents\"],null]]],[[\"default\"],[{\"statements\":[[2,\"\\n    \"],[8,\"layout/regular-grid\",[],[[\"@columns\"],[2]],[[\"default\"],[{\"statements\":[[2,\"\\n        \"],[10,\"div\"],[14,0,\"add-component\"],[12],[2,\"\\n            \"],[10,\"div\"],[14,0,\"component-part\"],[12],[2,\"\\n                \"],[8,\"agent-label-tooltip\",[],[[\"@path\"],[\"agent.item.agent\"]],null],[2,\"\\n\\n\"],[6,[37,2],[[32,0,[\"newAgent\"]]],null,[[\"default\"],[{\"statements\":[[2,\"                    \"],[8,\"agent/auto-complete\",[[24,\"data-test\",\"agent\"]],[[\"@value\",\"@update\",\"@params\"],[[32,2],[32,3],[30,[36,0],null,[[\"inputClasses\"],[\"form-control control\"]]]]],null],[2,\"\\n\"]],\"parameters\":[2,3]}]]],[2,\"            \"],[13],[2,\"\\n\\n\"],[2,\"            \"],[10,\"div\"],[14,0,\"component-part component-part--button\"],[12],[2,\"\\n                \"],[11,\"button\"],[16,\"disabled\",[30,[36,3],[[32,0,[\"newAgent\"]]],null]],[24,0,\"btn btn-default control\"],[24,\"data-test\",\"add\"],[4,[38,4],[\"click\",[32,0,[\"addItem\"]]],null],[12],[2,\"\\n                    \"],[10,\"span\"],[15,0,[30,[36,5],[\"ok\"],null]],[12],[13],[2,\"\\n                    \"],[1,[30,[36,1],[\"General.Add\"],null]],[2,\"\\n                \"],[13],[2,\"\\n            \"],[13],[2,\"\\n        \"],[13],[2,\"\\n    \"]],\"parameters\":[]}]]],[2,\"\\n\\n    \"],[8,\"common/record-list\",[],[[\"@records\",\"@remove\"],[[32,4],[32,0,[\"remove\"]]]],[[\"default\"],[{\"statements\":[[2,\"\\n        \"],[1,[32,1,[\"representation\"]]],[2,\"\\n    \"]],\"parameters\":[1]}]]],[2,\"\\n\"]],\"parameters\":[]}]]],[2,\"\\n\"]],\"hasEval\":false,\"upvars\":[\"hash\",\"i18n-t\",\"async/bind-relation\",\"not\",\"on\",\"icon\"]}",
    "moduleName": "plutof/templates/components/photobank/agents-form.hbs"
  });
});