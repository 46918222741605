define("plutof/routes/livingspecimen/add", ["exports", "plutof/components/livingspecimen/edit", "plutof/misc/abstract", "plutof/misc/config", "plutof/misc/content_types", "plutof/misc/profile_settings", "plutof/utils/cloning", "plutof/utils/model", "plutof/utils/routes", "plutof/components/layer/occurrence-areas"], function (_exports, _edit, _abstract, _config, _content_types, _profile_settings, _cloning, _model, _routes, _occurrenceAreas) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _dec, _dec2, _dec3, _class, _descriptor, _descriptor2;
  function _initializerDefineProperty(e, i, r, l) { r && Object.defineProperty(e, i, { enumerable: r.enumerable, configurable: r.configurable, writable: r.writable, value: r.initializer ? r.initializer.call(l) : void 0 }); }
  function _defineProperty(e, r, t) { return (r = _toPropertyKey(r)) in e ? Object.defineProperty(e, r, { value: t, enumerable: !0, configurable: !0, writable: !0 }) : e[r] = t, e; }
  function _toPropertyKey(t) { var i = _toPrimitive(t, "string"); return "symbol" == typeof i ? i : i + ""; }
  function _toPrimitive(t, r) { if ("object" != typeof t || !t) return t; var e = t[Symbol.toPrimitive]; if (void 0 !== e) { var i = e.call(t, r || "default"); if ("object" != typeof i) return i; throw new TypeError("@@toPrimitive must return a primitive value."); } return ("string" === r ? String : Number)(t); }
  function _applyDecoratedDescriptor(i, e, r, n, l) { var a = {}; return Object.keys(n).forEach(function (i) { a[i] = n[i]; }), a.enumerable = !!a.enumerable, a.configurable = !!a.configurable, ("value" in a || a.initializer) && (a.writable = !0), a = r.slice().reverse().reduce(function (r, n) { return n(i, e, r) || r; }, a), l && void 0 !== a.initializer && (a.value = a.initializer ? a.initializer.call(l) : void 0, a.initializer = void 0), void 0 === a.initializer ? (Object.defineProperty(i, e, a), null) : a; }
  function _initializerWarningHelper(r, e) { throw Error("Decorating class property failed. Please ensure that transform-class-properties is enabled and runs after the decorators transform."); }
  async function getMainform(params, store) {
    if (Ember.isNone(params.mainform_id)) {
      const settings = await (0, _profile_settings.get_personal_settings)(store);
      const form = await settings.strain_form;
      return [form, Boolean(form)];
    } else {
      const form = await store.findRecord('measurement/mainform', params.mainform_id);
      return [form, false];
    }
  }
  function getParentCtype(params, store) {
    return params.content_type ? store.findRecord('contenttype', params.content_type) : _abstract.EMPTY_PROMISE;
  }
  function getTarget(params, store) {
    return params.content_type ? getParentCtype(params, store).then(function (ctype) {
      return (0, _content_types.resolve_generic_link)(store, ctype, params.object_id);
    }) : _abstract.EMPTY_PROMISE;
  }
  let LivingspecimenAddRoute = (_dec = Ember.inject.service, _dec2 = Ember.inject.service, _dec3 = Ember._action, _class = class LivingspecimenAddRoute extends _routes.AddRoute {
    constructor(...args) {
      super(...args);
      _initializerDefineProperty(this, "ajax", _descriptor, this);
      _initializerDefineProperty(this, "settings", _descriptor2, this);
      _defineProperty(this, "defaultReturnRoute", 'livingspecimen.index');
      _defineProperty(this, "title", 'LivingCultures.newLivingC');
    }
    async createStrainData(params) {
      if (!Ember.isNone(params.template_id)) {
        const template = await this.store.findRecord('taxonoccurrence/livingculture/strain', params.template_id);
        const clonedFields = await Ember.RSVP.hash(template.getProperties(['content_type', 'object_id', 'strain_idprim', 'subcode', 'deposited_in', 'subrepository', 'mainform']));
        const clone = this.store.createRecord('taxonoccurrence/livingculture/strain', clonedFields);
        const templateSample = await (0, _model.getOccurrenceSamplingData)(this.store, template);
        const cloneSample = await (0, _cloning.clone_sampling_data)(this.store, templateSample.formData, params.clone_area === 'true', params.clone_event === 'true');
        let parent;
        if (clonedFields.content_type) {
          parent = await (0, _content_types.resolve_generic_link)(this.store, clonedFields.content_type, clonedFields.object_id);
        }
        return {
          livingSpecimen: clone,
          sample: cloneSample,
          layers: _occurrenceAreas.LinkedLayersModel.clone({
            ajax: this.ajax,
            templateOccurrence: template
          }),
          parent,
          usingDefaultForm: true
        };
      }
      const livingSpecimen = this.store.createRecord('taxonoccurrence/livingculture/strain');
      const [[mainform, usingDefaultForm], targetCType, {
        formData
      }, collection, project] = await Ember.RSVP.all([getMainform(params, this.store), getParentCtype(params, this.store),
      // createSamplingData(params, this.store),
      (0, _model.getSamplingDataForParams)(this.store, params), Ember.isNone(params.collection_id) ? this.settings.wait().then(settings => settings.default_living_specimen_collection) : this.store.findRecord('agent/collection', params.collection_id), Ember.isNone(params.project_id) ? null : this.store.findRecord('study/study', params.project_id)]);
      livingSpecimen.setProperties({
        mainform,
        project: project || formData.project,
        deposited_in: collection
      });
      const {
        POSSIBLE_TARGETS
      } = _config.default.Livingculture;
      let target;
      if (!Ember.isNone(targetCType) && POSSIBLE_TARGETS.indexOf(Ember.get(targetCType, 'model')) !== -1) {
        target = await getTarget(params, this.store);
        livingSpecimen.setProperties({
          target,
          // XXX: Should not be needed after the refactor
          object_id: target.id,
          content_type: targetCType
        });
      }
      return {
        livingSpecimen,
        sample: formData,
        layers: _occurrenceAreas.LinkedLayersModel.create(this.ajax),
        parent: target,
        usingDefaultForm
      };
    }
    async model(params) {
      if (this.get('controller.routeHasBeenLoaded')) {
        return undefined;
      }
      const {
        livingSpecimen,
        sample,
        layers,
        parent,
        usingDefaultForm
      } = await this.createStrainData(params);
      const requireIdentification = !parent || parent.constructor.modelName !== 'taxonoccurrence/specimen/specimen';
      const livingSpecimenModel = await (0, _edit.createLivingSpecimenModel)(this.store, livingSpecimen, parent, sample, layers, {
        requireIdentification
      });
      if (parent) {
        await livingSpecimenModel.setParentToOccurrence(parent);
      }
      return {
        livingSpecimenModel,
        usingDefaultForm
      };
    }
    setupController(controller, model) {
      super.setupController(controller, model);
      if (!Ember.isNone(model)) {
        controller.setProperties({
          routeHasBeenLoaded: true
        });
      }
    }
    doRefresh() {
      this.set('controller.routeHasBeenLoaded', false);
      this.refresh();
    }
  }, _descriptor = _applyDecoratedDescriptor(_class.prototype, "ajax", [_dec], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor2 = _applyDecoratedDescriptor(_class.prototype, "settings", [_dec2], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _applyDecoratedDescriptor(_class.prototype, "doRefresh", [_dec3], Object.getOwnPropertyDescriptor(_class.prototype, "doRefresh"), _class.prototype), _class);
  var _default = _exports.default = LivingspecimenAddRoute;
});