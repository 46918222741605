define("plutof/components/common/ui/buttons/link", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const __COLOCATED_TEMPLATE__ = Ember.HTMLBars.template(
  /*
    <button
      {{on "click" @clicked}}
      class="btn-fake-link"
  >
      {{yield}}
  </button>
  
  */
  {
    "id": "QuB77Tz1",
    "block": "{\"symbols\":[\"@clicked\",\"&default\"],\"statements\":[[11,\"button\"],[24,0,\"btn-fake-link\"],[4,[38,0],[\"click\",[32,1]],null],[12],[2,\"\\n    \"],[18,2,null],[2,\"\\n\"],[13],[2,\"\\n\"]],\"hasEval\":false,\"upvars\":[\"on\"]}",
    "moduleName": "plutof/components/common/ui/buttons/link.hbs"
  });
  var _default = _exports.default = Ember._setComponentTemplate(__COLOCATED_TEMPLATE__, Ember._templateOnlyComponent());
});