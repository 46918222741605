define("plutof/templates/components/common/confirmation-dialog", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = Ember.HTMLBars.template({
    "id": "H1AF+W8M",
    "block": "{\"symbols\":[\"__arg0\",\"dialogOption\"],\"statements\":[[8,\"ui/modal/dialog\",[[24,\"data-test\",\"Common::ConfirmationDialog\"]],[[\"@open\",\"@close\",\"@namedBlocksInfo\"],[[32,0,[\"confirmationDialog\",\"visible\"]],[32,0,[\"cancel\"]],[30,[36,6],null,[[\"header\",\"body\",\"footer\"],[0,0,0]]]]],[[\"default\"],[{\"statements\":[[6,[37,5],[[30,[36,4],[[32,1],\"header\"],null]],null,[[\"default\",\"else\"],[{\"statements\":[[2,\"\\n        \"],[1,[32,0,[\"confirmationDialog\",\"dialog\",\"title\"]]],[2,\"\\n    \"]],\"parameters\":[]},{\"statements\":[[6,[37,5],[[30,[36,4],[[32,1],\"body\"],null]],null,[[\"default\",\"else\"],[{\"statements\":[[2,\"\\n        \"],[1,[32,0,[\"confirmationDialog\",\"dialog\",\"content\"]]],[2,\"\\n    \"]],\"parameters\":[]},{\"statements\":[[6,[37,5],[[30,[36,4],[[32,1],\"footer\"],null]],null,[[\"default\"],[{\"statements\":[[2,\"\\n\"],[6,[37,3],[[30,[36,2],[[30,[36,2],[[32,0,[\"confirmationDialog\",\"dialog\",\"options\"]]],null]],null]],null,[[\"default\"],[{\"statements\":[[2,\"            \"],[11,\"button\"],[24,0,\"btn btn-default\"],[4,[38,1],[\"click\",[30,[36,0],[[32,0,[\"choose\"]],[32,2]],null]],null],[12],[2,\"\\n                \"],[1,[32,2,[\"label\"]]],[2,\"\\n            \"],[13],[2,\"\\n\"]],\"parameters\":[2]}]]],[2,\"    \"]],\"parameters\":[]}]]]],\"parameters\":[]}]]]],\"parameters\":[]}]]]],\"parameters\":[1]}]]],[2,\"\\n\"]],\"hasEval\":false,\"upvars\":[\"fn\",\"on\",\"-track-array\",\"each\",\"-is-named-block-invocation\",\"if\",\"hash\"]}",
    "moduleName": "plutof/templates/components/common/confirmation-dialog.hbs"
  });
});