define("plutof/templates/components/experiment/pcr/material-bulk-update", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = Ember.HTMLBars.template({
    "id": "lj73KWuU",
    "block": "{\"symbols\":[\"values\",\"updateButton\",\"@update\"],\"statements\":[[6,[37,4],null,[[\"update\"],[[32,3]]],[[\"default\"],[{\"statements\":[[2,\"    \"],[10,\"div\"],[12],[2,\"\\n        \"],[1,[30,[36,0],null,[[\"path\"],[\"pcr-product.forward_primer\"]]]],[2,\"\\n\\n        \"],[8,\"auto-complete/bound-model\",[],[[\"@model\",\"@value\",\"@filters\"],[\"dna-lab/primer\",[32,1,[\"forward_primer\"]],[30,[36,1],null,[[\"type\"],[1]]]]],null],[2,\"\\n    \"],[13],[2,\"\\n\\n    \"],[10,\"div\"],[12],[2,\"\\n        \"],[1,[30,[36,0],null,[[\"path\"],[\"pcr-product.reverse_primer\"]]]],[2,\"\\n\\n        \"],[8,\"auto-complete/bound-model\",[],[[\"@model\",\"@value\",\"@filters\"],[\"dna-lab/primer\",[32,1,[\"reverse_primer\"]],[30,[36,1],null,[[\"type\"],[2]]]]],null],[2,\"\\n    \"],[13],[2,\"\\n\\n    \"],[10,\"div\"],[12],[2,\"\\n        \"],[1,[30,[36,0],null,[[\"path\"],[\"pcr-product.purification\"]]]],[2,\"\\n\\n        \"],[1,[30,[36,2],null,[[\"content\",\"value\",\"skipDefault\"],[[32,0,[\"purifications\"]],[32,1,[\"purification\"]],true]]]],[2,\"\\n    \"],[13],[2,\"\\n\\n    \"],[10,\"div\"],[12],[2,\"\\n        \"],[1,[30,[36,0],null,[[\"path\"],[\"pcr-product.remarks\"]]]],[2,\"\\n\\n        \"],[1,[30,[36,3],null,[[\"type\",\"value\",\"class\"],[\"text\",[32,1,[\"remarks\"]],\"form-control\"]]]],[2,\"\\n    \"],[13],[2,\"\\n\\n    \"],[10,\"div\"],[14,0,\"spacer-large\"],[12],[13],[2,\"\\n\\n    \"],[1,[32,2]],[2,\"\\n\"]],\"parameters\":[1,2]}]]]],\"hasEval\":false,\"upvars\":[\"plutof-labelc\",\"hash\",\"plutof-select/value\",\"input\",\"tables/bulk-fields-update\"]}",
    "moduleName": "plutof/templates/components/experiment/pcr/material-bulk-update.hbs"
  });
});