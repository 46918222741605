define("plutof/templates/components/analysis/matching-source-fasta-item", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = Ember.HTMLBars.template({
    "id": "l6BOEIad",
    "block": "{\"symbols\":[\"@file\"],\"statements\":[[10,\"td\"],[14,\"colspan\",\"3\"],[14,0,\"analysis-matching-source__fasta-cell mini-input item-table__read-only-value\"],[12],[2,\"\\n    \"],[1,[30,[36,0],[\"Analysis.fastaSequences\"],[[\"filename\"],[[32,1,[\"name\"]]]]]],[2,\"\\n\"],[13],[2,\"\\n\"]],\"hasEval\":false,\"upvars\":[\"i18n-t\"]}",
    "moduleName": "plutof/templates/components/analysis/matching-source-fasta-item.hbs"
  });
});