define("plutof/components/photobank/taxa", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const __COLOCATED_TEMPLATE__ = Ember.HTMLBars.template(
  /*
    <PlutofPanel::ItemList
      @collapse={{and (not @taxa.length) (not @open)}}
      ...attributes
      as |panel|
  >
      <panel.header
          @title={{i18n-t "Photobank.taxa"}}
          @count={{@taxa.length}} />
  
      <panel.content>
          {{yield}}
      </panel.content>
  </PlutofPanel::ItemList>
  
  */
  {
    "id": "XFKtmYMd",
    "block": "{\"symbols\":[\"panel\",\"@open\",\"@taxa\",\"&attrs\",\"&default\"],\"statements\":[[8,\"plutof-panel/item-list\",[[17,4]],[[\"@collapse\"],[[30,[36,1],[[30,[36,0],[[32,3,[\"length\"]]],null],[30,[36,0],[[32,2]],null]],null]]],[[\"default\"],[{\"statements\":[[2,\"\\n    \"],[8,[32,1,[\"header\"]],[],[[\"@title\",\"@count\"],[[30,[36,2],[\"Photobank.taxa\"],null],[32,3,[\"length\"]]]],null],[2,\"\\n\\n    \"],[8,[32,1,[\"content\"]],[],[[],[]],[[\"default\"],[{\"statements\":[[2,\"\\n        \"],[18,5,null],[2,\"\\n    \"]],\"parameters\":[]}]]],[2,\"\\n\"]],\"parameters\":[1]}]]],[2,\"\\n\"]],\"hasEval\":false,\"upvars\":[\"not\",\"and\",\"i18n-t\"]}",
    "moduleName": "plutof/components/photobank/taxa.hbs"
  });
  var _default = _exports.default = Ember._setComponentTemplate(__COLOCATED_TEMPLATE__, Ember._templateOnlyComponent());
});