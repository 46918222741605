define("plutof/templates/components/experiment/common/material-selector", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = Ember.HTMLBars.template({
    "id": "wA0tp/pV",
    "block": "{\"symbols\":[\"&default\"],\"statements\":[[18,1,[[30,[36,0],null,[[\"disable\",\"selected\",\"select\"],[[32,0,[\"disable\"]],[32,0,[\"selected\"]],[32,0,[\"select\"]]]]]]],[2,\"\\n\"]],\"hasEval\":false,\"upvars\":[\"hash\"]}",
    "moduleName": "plutof/templates/components/experiment/common/material-selector.hbs"
  });
});