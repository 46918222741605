define("plutof/templates/dmp/edit", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = Ember.HTMLBars.template({
    "id": "r5lw9I7J",
    "block": "{\"symbols\":[],\"statements\":[[8,\"navbar/edit\",[],[[\"@icon\",\"@title\",\"@record\",\"@infoTitle\",\"@infoContent\"],[[30,[36,0],[\"dmp\"],null],[30,[36,1],[\"dmp.routeTitles.edit\"],null],[32,0,[\"model\",\"dmp\"]],[30,[36,1],[\"dmp.general.dmp\"],null],\"information/content/dmp/general\"]],null],[2,\"\\n\\n\"],[8,\"dmp/edit\",[],[[\"@data\",\"@cancel\",\"@save\",\"@accessRights\",\"@validationContext\"],[[32,0,[\"model\",\"dmp\"]],[30,[36,2],[[32,0],\"cancel\"],null],[30,[36,2],[[32,0],\"saveDMP\"],null],[32,0,[\"model\",\"accessRights\"]],[32,0,[\"validationContext\"]]]],null],[2,\"\\n\"]],\"hasEval\":false,\"upvars\":[\"icon\",\"i18n-t\",\"action\"]}",
    "moduleName": "plutof/templates/dmp/edit.hbs"
  });
});