define("plutof/components/collection-lab/permit/view-permissions", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const __COLOCATED_TEMPLATE__ = Ember.HTMLBars.template(
  /*
    <PlutofPanel::Simple @title={{i18n-t "General.access"}}>
      <Access::ViewRightsBase as |vr|>
          <vr.panel>
              <vr.view.public />
              <vr.edit.private />
          </vr.panel>
  
          <vr.panel>
              <vr.history
                  @createdBy={{@permit.created_by.representation}}
                  @createdAt={{@permit.created_at}}
                  @updatedBy={{@permit.updated_by.representation}}
                  @updatedAt={{@permit.updated_at}} />
          </vr.panel>
      </Access::ViewRightsBase>
  </PlutofPanel::Simple>
  
  */
  {
    "id": "joOp7fD8",
    "block": "{\"symbols\":[\"vr\",\"@permit\"],\"statements\":[[8,\"plutof-panel/simple\",[],[[\"@title\"],[[30,[36,0],[\"General.access\"],null]]],[[\"default\"],[{\"statements\":[[2,\"\\n    \"],[8,\"access/view-rights-base\",[],[[],[]],[[\"default\"],[{\"statements\":[[2,\"\\n        \"],[8,[32,1,[\"panel\"]],[],[[],[]],[[\"default\"],[{\"statements\":[[2,\"\\n            \"],[8,[32,1,[\"view\",\"public\"]],[],[[],[]],null],[2,\"\\n            \"],[8,[32,1,[\"edit\",\"private\"]],[],[[],[]],null],[2,\"\\n        \"]],\"parameters\":[]}]]],[2,\"\\n\\n        \"],[8,[32,1,[\"panel\"]],[],[[],[]],[[\"default\"],[{\"statements\":[[2,\"\\n            \"],[8,[32,1,[\"history\"]],[],[[\"@createdBy\",\"@createdAt\",\"@updatedBy\",\"@updatedAt\"],[[32,2,[\"created_by\",\"representation\"]],[32,2,[\"created_at\"]],[32,2,[\"updated_by\",\"representation\"]],[32,2,[\"updated_at\"]]]],null],[2,\"\\n        \"]],\"parameters\":[]}]]],[2,\"\\n    \"]],\"parameters\":[1]}]]],[2,\"\\n\"]],\"parameters\":[]}]]],[2,\"\\n\"]],\"hasEval\":false,\"upvars\":[\"i18n-t\"]}",
    "moduleName": "plutof/components/collection-lab/permit/view-permissions.hbs"
  });
  var _default = _exports.default = Ember._setComponentTemplate(__COLOCATED_TEMPLATE__, Ember._templateOnlyComponent());
});