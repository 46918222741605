define("plutof/components/taxonomy/taxon/view/external-links-panel", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const __COLOCATED_TEMPLATE__ = Ember.HTMLBars.template(
  /*
    <PlutofPanel::ItemList as |panel|>
      <panel.header @title={{i18n-t "Taxonomy.externalLinksTitle"}} />
  
      <panel.content>
          <GenericViews::Record @record={{@taxon}} as |view|>
              <Layout::RegularGrid @columns={{4}}>
                  <Taxonomy::Taxon::View::ExternalLinksView
                      @view={{view}}
                      @taxon={{@taxon}} />
              </Layout::RegularGrid>
          </GenericViews::Record>
      </panel.content>
  </PlutofPanel::ItemList>
  
  */
  {
    "id": "qu5kU0lT",
    "block": "{\"symbols\":[\"panel\",\"view\",\"@taxon\"],\"statements\":[[8,\"plutof-panel/item-list\",[],[[],[]],[[\"default\"],[{\"statements\":[[2,\"\\n    \"],[8,[32,1,[\"header\"]],[],[[\"@title\"],[[30,[36,0],[\"Taxonomy.externalLinksTitle\"],null]]],null],[2,\"\\n\\n    \"],[8,[32,1,[\"content\"]],[],[[],[]],[[\"default\"],[{\"statements\":[[2,\"\\n        \"],[8,\"generic-views/record\",[],[[\"@record\"],[[32,3]]],[[\"default\"],[{\"statements\":[[2,\"\\n            \"],[8,\"layout/regular-grid\",[],[[\"@columns\"],[4]],[[\"default\"],[{\"statements\":[[2,\"\\n                \"],[8,\"taxonomy/taxon/view/external-links-view\",[],[[\"@view\",\"@taxon\"],[[32,2],[32,3]]],null],[2,\"\\n            \"]],\"parameters\":[]}]]],[2,\"\\n        \"]],\"parameters\":[2]}]]],[2,\"\\n    \"]],\"parameters\":[]}]]],[2,\"\\n\"]],\"parameters\":[1]}]]],[2,\"\\n\"]],\"hasEval\":false,\"upvars\":[\"i18n-t\"]}",
    "moduleName": "plutof/components/taxonomy/taxon/view/external-links-panel.hbs"
  });
  var _default = _exports.default = Ember._setComponentTemplate(__COLOCATED_TEMPLATE__, Ember._templateOnlyComponent());
});