define("plutof/templates/profile/rights", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = Ember.HTMLBars.template({
    "id": "M1fLxOcK",
    "block": "{\"symbols\":[\"grid\",\"value\",\"update\",\"t\"],\"statements\":[[8,\"test/route-marker\",[],[[\"@route\"],[\"profile.rights\"]],null],[2,\"\\n\\n\"],[8,\"layout/regular-grid\",[],[[\"@columns\"],[3]],[[\"default\"],[{\"statements\":[[2,\"\\n\"],[6,[37,5],[[32,1,[\"span\"]],2],null,[[\"default\"],[{\"statements\":[[2,\"        \"],[1,[30,[36,4],null,[[\"rights\",\"defaultOwner\",\"validationContext\"],[[35,3,[\"profileRights\"]],[35,3,[\"profile\",\"default_owner\"]],[35,2,[\"access\"]]]]]],[2,\"\\n\"]],\"parameters\":[]}]]],[2,\"\\n    \"],[10,\"div\"],[12],[2,\"\\n\"],[6,[37,6],null,null,[[\"default\"],[{\"statements\":[[2,\"            \"],[1,[30,[36,0],null,[[\"path\",\"hideTooltip\"],[\"personalsetting.default_owner\",true]]]],[2,\"\\n\\n            \"],[10,\"span\"],[15,1,[31,[[32,4,[\"tid\"]]]]],[14,0,\"wrapper-tooltip\"],[12],[2,\"\\n                \"],[1,[30,[36,1],[\"Profile.defaultRightsTooltip\"],null]],[2,\"\\n                \"],[10,\"br\"],[12],[13],[2,\"\\n                \"],[1,[30,[36,1],[\"Agents.personTooltip\"],null]],[2,\"\\n            \"],[13],[2,\"\\n\"]],\"parameters\":[4]}]]],[2,\"\\n\"],[6,[37,7],[[32,0,[\"model\",\"profile\",\"default_owner\"]]],null,[[\"default\"],[{\"statements\":[[2,\"            \"],[8,\"agent/user/auto-complete\",[],[[\"@value\",\"@update\",\"@noDefault\"],[[32,2],[32,3],true]],null],[2,\"\\n\"]],\"parameters\":[2,3]}]]],[2,\"    \"],[13],[2,\"\\n\"]],\"parameters\":[1]}]]],[2,\"\\n\\n\"],[10,\"div\"],[14,0,\"plutof-label\"],[12],[2,\" \"],[13],[2,\"\\n\\n\"],[1,[30,[36,9],null,[[\"save\",\"disabled\"],[[30,[36,8],[[32,0],\"save\"],null],[35,2,[\"isInvalid\"]]]]]],[2,\"\\n\"]],\"hasEval\":false,\"upvars\":[\"plutof-labelc\",\"i18n-t\",\"validationContext\",\"model\",\"access/rights-form\",\"component\",\"tooltip-wrapper\",\"async/bind-relation\",\"action\",\"common/save-button\"]}",
    "moduleName": "plutof/templates/profile/rights.hbs"
  });
});