define("plutof/templates/components/observation/taxon-sheet/taimeatlas", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = Ember.HTMLBars.template({
    "id": "feHi3ONH",
    "block": "{\"symbols\":[\"column\",\"entry\",\"@toggle\"],\"statements\":[[6,[37,5],[[32,0,[\"sheet\",\"items\",\"length\"]]],null,[[\"default\"],[{\"statements\":[[2,\"    \"],[10,\"span\"],[14,0,\"fas fa-sync-alt spinicon\"],[12],[13],[2,\"\\n     \\n    \"],[1,[30,[36,4],[\"General.loading\"],null]],[2,\"\\n\"]],\"parameters\":[]}]]],[2,\"\\n\"],[6,[37,3],[[30,[36,2],[[30,[36,2],[[32,0,[\"columns\"]]],null]],null]],null,[[\"default\"],[{\"statements\":[[2,\"    \"],[10,\"div\"],[14,0,\"taimeatlas-acronyms__column\"],[12],[2,\"\\n\"],[6,[37,3],[[30,[36,2],[[30,[36,2],[[32,1]],null]],null]],null,[[\"default\"],[{\"statements\":[[2,\"            \"],[1,[30,[36,1],null,[[\"item\",\"selected\",\"toggle\"],[[32,2,[\"item\"]],[32,2,[\"selected\"]],[30,[36,0],[[32,0],[32,3],[32,2]],null]]]]],[2,\"\\n\"]],\"parameters\":[2]}]]],[2,\"    \"],[13],[2,\"\\n\"]],\"parameters\":[1]}]]]],\"hasEval\":false,\"upvars\":[\"action\",\"observation/taxon-sheet/item\",\"-track-array\",\"each\",\"i18n-t\",\"unless\"]}",
    "moduleName": "plutof/templates/components/observation/taxon-sheet/taimeatlas.hbs"
  });
});