define("plutof/components/search/filters/-range-input", ["exports", "@glimmer/component", "plutof/utils/search/filters"], function (_exports, _component, _filters) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _dec, _class;
  function _defineProperty(e, r, t) { return (r = _toPropertyKey(r)) in e ? Object.defineProperty(e, r, { value: t, enumerable: !0, configurable: !0, writable: !0 }) : e[r] = t, e; }
  function _toPropertyKey(t) { var i = _toPrimitive(t, "string"); return "symbol" == typeof i ? i : i + ""; }
  function _toPrimitive(t, r) { if ("object" != typeof t || !t) return t; var e = t[Symbol.toPrimitive]; if (void 0 !== e) { var i = e.call(t, r || "default"); if ("object" != typeof i) return i; throw new TypeError("@@toPrimitive must return a primitive value."); } return ("string" === r ? String : Number)(t); }
  function _applyDecoratedDescriptor(i, e, r, n, l) { var a = {}; return Object.keys(n).forEach(function (i) { a[i] = n[i]; }), a.enumerable = !!a.enumerable, a.configurable = !!a.configurable, ("value" in a || a.initializer) && (a.writable = !0), a = r.slice().reverse().reduce(function (r, n) { return n(i, e, r) || r; }, a), l && void 0 !== a.initializer && (a.value = a.initializer ? a.initializer.call(l) : void 0, a.initializer = void 0), void 0 === a.initializer ? (Object.defineProperty(i, e, a), null) : a; }
  const __COLOCATED_TEMPLATE__ = Ember.HTMLBars.template(
  /*
    <div class="{{this.styleClasses}}">
      <div class="plutof-label">
          {{@filter.label}}
      </div>
  
      <div class="input-group {{@inputGroupClass}}">
          {{yield to="lower"}}
  
          <span class="input-group-addon {{@inputGroupClass}}__addon">
              <PlutofSelect::Object
                  @content={{this.operators}}
                  @oneWay={{true}}
                  @selection={{@filter.rangeOperator}}
                  @selectionChanged={{this.updateOperator}}
                  @optionValuePath="id"
                  @optionLabelPath="id"
                  @prompt={{null}}
                  @selectClass="{{@inputGroupClass}}__addon__select clickable" />
          </span>
  
          {{yield this.disableUpperBound to="upper"}}
      </div>
  </div>
  
  */
  {
    "id": "aE9UUb59",
    "block": "{\"symbols\":[\"@filter\",\"@inputGroupClass\",\"&default\"],\"statements\":[[10,\"div\"],[15,0,[31,[[32,0,[\"styleClasses\"]]]]],[12],[2,\"\\n    \"],[10,\"div\"],[14,0,\"plutof-label\"],[12],[2,\"\\n        \"],[1,[32,1,[\"label\"]]],[2,\"\\n    \"],[13],[2,\"\\n\\n    \"],[10,\"div\"],[15,0,[31,[\"input-group \",[32,2]]]],[12],[2,\"\\n        \"],[18,3,[[30,[36,0],[\"lower\"],null]]],[2,\"\\n\\n        \"],[10,\"span\"],[15,0,[31,[\"input-group-addon \",[32,2],\"__addon\"]]],[12],[2,\"\\n            \"],[8,\"plutof-select/object\",[],[[\"@content\",\"@oneWay\",\"@selection\",\"@selectionChanged\",\"@optionValuePath\",\"@optionLabelPath\",\"@prompt\",\"@selectClass\"],[[32,0,[\"operators\"]],true,[32,1,[\"rangeOperator\"]],[32,0,[\"updateOperator\"]],\"id\",\"id\",null,[31,[[32,2],\"__addon__select clickable\"]]]],null],[2,\"\\n        \"],[13],[2,\"\\n\\n        \"],[18,3,[[30,[36,0],[\"upper\"],null],[32,0,[\"disableUpperBound\"]]]],[2,\"\\n    \"],[13],[2,\"\\n\"],[13],[2,\"\\n\"]],\"hasEval\":false,\"upvars\":[\"-named-block-invocation\"]}",
    "moduleName": "plutof/components/search/filters/-range-input.hbs"
  });
  let SearchGenericRangeInput = (_dec = Ember._action, _class = class SearchGenericRangeInput extends _component.default {
    constructor(...args) {
      super(...args);
      _defineProperty(this, "operators", [_filters.RangeOperator.Equal, _filters.RangeOperator.Inclusive, _filters.RangeOperator.Exclusive]);
    }
    get disableUpperBound() {
      return this.args.filter.rangeOperator === _filters.RangeOperator.Equal;
    }
    updateOperator(operator) {
      this.args.filter.rangeOperator = operator;
      if (operator === _filters.RangeOperator.Equal) {
        const {
          lowerBound,
          upperBound
        } = this.args.filter;
        if (Ember.isEmpty(lowerBound) && !Ember.isEmpty(upperBound)) {
          this.args.filter.lowerBound = upperBound;
        }
        this.args.filter.upperBound = '';
      }
    }
  }, _applyDecoratedDescriptor(_class.prototype, "updateOperator", [_dec], Object.getOwnPropertyDescriptor(_class.prototype, "updateOperator"), _class.prototype), _class);
  var _default = _exports.default = Ember._setComponentTemplate(__COLOCATED_TEMPLATE__, SearchGenericRangeInput);
});