define("plutof/templates/annotations/bulk/view", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = Ember.HTMLBars.template({
    "id": "Ofn8TQjf",
    "block": "{\"symbols\":[],\"statements\":[[8,\"annotation/navbar/view\",[],[[\"@icon\",\"@title\",\"@annotation\",\"@permissions\"],[[30,[36,0],[\"annotation\"],null],[30,[36,1],[\"annotation.routes.bulk.view.title\"],null],[32,0,[\"model\",\"annotation\"]],[32,0,[\"model\",\"permissions\"]]]],null],[2,\"\\n\\n\"],[8,\"annotation/bulk/summary\",[],[[\"@annotation\"],[[32,0,[\"model\",\"annotation\"]]]],null],[2,\"\\n\\n\"],[8,\"annotation/bulk/records\",[],[[\"@contentType\",\"@records\"],[[32,0,[\"model\",\"annotation\",\"content_type\",\"full_name\"]],[32,0,[\"model\",\"records\"]]]],null],[2,\"\\n\"]],\"hasEval\":false,\"upvars\":[\"icon\",\"i18n-t\"]}",
    "moduleName": "plutof/templates/annotations/bulk/view.hbs"
  });
});