define("plutof/components/info-link", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const __COLOCATED_TEMPLATE__ = Ember.HTMLBars.template(
  /*
    <a href="{{@infoLink}}" target="_blank">
      <span class="icon-info"></span>
  </a>
  
  */
  {
    "id": "DGVzHmxd",
    "block": "{\"symbols\":[\"@infoLink\"],\"statements\":[[10,\"a\"],[15,6,[31,[[32,1]]]],[14,\"target\",\"_blank\"],[12],[2,\"\\n    \"],[10,\"span\"],[14,0,\"icon-info\"],[12],[13],[2,\"\\n\"],[13],[2,\"\\n\"]],\"hasEval\":false,\"upvars\":[]}",
    "moduleName": "plutof/components/info-link.hbs"
  });
  var _default = _exports.default = Ember._setComponentTemplate(__COLOCATED_TEMPLATE__, Ember._templateOnlyComponent());
});