define("plutof/templates/components/occurrences-table/-responsive-row/selector", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = Ember.HTMLBars.template({
    "id": "UGa08q49",
    "block": "{\"symbols\":[\"@selector\"],\"statements\":[[6,[37,0],null,null,[[\"default\"],[{\"statements\":[[2,\"    \"],[10,\"div\"],[14,0,\"plutof-label occ-table-responsive-row__cell-label\"],[12],[2,\" \"],[13],[2,\"\\n\\n    \"],[8,[32,1],[],[[],[]],null],[2,\"\\n\"]],\"parameters\":[]}]]]],\"hasEval\":false,\"upvars\":[\"occurrences-table/-responsive-row/cell\"]}",
    "moduleName": "plutof/templates/components/occurrences-table/-responsive-row/selector.hbs"
  });
});