define("plutof/templates/components/menu/profile-name", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = Ember.HTMLBars.template({
    "id": "93SgG7Ra",
    "block": "{\"symbols\":[],\"statements\":[[6,[37,2],[[32,0,[\"username\"]]],null,[[\"default\",\"else\"],[{\"statements\":[[6,[37,2],[[32,0,[\"settings\",\"profile\",\"default_owner\",\"id\"]]],null,[[\"default\",\"else\"],[{\"statements\":[[2,\"        \"],[1,[34,1]],[2,\"\\n\\n        \"],[10,\"span\"],[12],[2,\" | \"],[13],[2,\"\\n\\n        \"],[10,\"span\"],[14,0,\"default-owner-name\"],[12],[2,\"\\n            \"],[1,[32,0,[\"settings\",\"profile\",\"default_owner\",\"username\"]]],[2,\"\\n        \"],[13],[2,\"\\n\"]],\"parameters\":[]},{\"statements\":[[2,\"        \"],[1,[34,1]],[2,\"\\n\"]],\"parameters\":[]}]]]],\"parameters\":[]},{\"statements\":[[2,\"    \"],[1,[30,[36,0],[\"General.loggingIn\"],null]],[2,\"\\n\"]],\"parameters\":[]}]]]],\"hasEval\":false,\"upvars\":[\"i18n-t\",\"username\",\"if\"]}",
    "moduleName": "plutof/templates/components/menu/profile-name.hbs"
  });
});