define("plutof/templates/components/pagination/load-more", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = Ember.HTMLBars.template({
    "id": "rFZYO75Z",
    "block": "{\"symbols\":[\"@pagination\",\"@showLoadAll\"],\"statements\":[[6,[37,2],[[32,1,[\"isLoading\"]]],null,[[\"default\",\"else\"],[{\"statements\":[[6,[37,2],[[35,4]],null,[[\"default\"],[{\"statements\":[[2,\"        \"],[10,\"span\"],[14,0,\"loading-icon\"],[12],[13],[2,\"\\n\"]],\"parameters\":[]}]]]],\"parameters\":[]},{\"statements\":[[6,[37,2],[[32,0,[\"showControls\"]]],null,[[\"default\"],[{\"statements\":[[6,[37,3],[[32,1,[\"onLastPage\"]]],null,[[\"default\"],[{\"statements\":[[2,\"            \"],[11,\"button\"],[16,\"disabled\",[32,1,[\"isLoading\"]]],[24,0,\"btn btn-xs btn-link\"],[4,[38,0],[\"click\",[32,0,[\"more\"]]],null],[12],[2,\"\\n                \"],[1,[30,[36,1],[\"General.loadMore\"],null]],[2,\"\\n            \"],[13],[2,\"\\n\\n\"],[6,[37,2],[[32,2]],null,[[\"default\"],[{\"statements\":[[2,\"                \"],[11,\"button\"],[16,\"disabled\",[32,1,[\"isLoading\"]]],[24,0,\"btn btn-xs btn-link\"],[4,[38,0],[\"click\",[32,0,[\"all\"]]],null],[12],[2,\"\\n                    \"],[1,[30,[36,1],[\"General.loadAll\"],null]],[2,\"\\n\\n\"],[6,[37,2],[[32,1,[\"objectCount\"]]],null,[[\"default\"],[{\"statements\":[[2,\"                        (\"],[1,[32,1,[\"objectCount\"]]],[2,\")\\n\"]],\"parameters\":[]}]]],[2,\"                \"],[13],[2,\"\\n\"]],\"parameters\":[]}]]]],\"parameters\":[]}]]]],\"parameters\":[]}]]]],\"parameters\":[]}]]]],\"hasEval\":false,\"upvars\":[\"on\",\"i18n-t\",\"if\",\"unless\",\"showInterface\"]}",
    "moduleName": "plutof/templates/components/pagination/load-more.hbs"
  });
});