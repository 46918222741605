define("plutof/templates/components/observation/table/header-buttons", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = Ember.HTMLBars.template({
    "id": "mQ3gIn8X",
    "block": "{\"symbols\":[\"@button\",\"@showSheet\",\"@sheetset\",\"@bulkUpdate\",\"@externalMeasurements\",\"@disabled\",\"@includeTestMarker\"],\"statements\":[[8,[32,1],[],[[\"@clicked\",\"@disabled\"],[[32,4,[\"togglePanel\"]],[32,4,[\"isEmpty\"]]]],[[\"default\"],[{\"statements\":[[2,\"\\n    \"],[1,[30,[36,0],[\"General.tableBulkUpdate.toggle\"],null]],[2,\"\\n\"]],\"parameters\":[]}]]],[2,\"\\n\\n\"],[8,[32,1],[],[[\"@clicked\",\"@disabled\"],[[32,5,[\"request\"]],[32,5,[\"disabled\"]]]],[[\"default\"],[{\"statements\":[[2,\"\\n    \"],[1,[30,[36,0],[\"General.addExternalMeasurements\"],null]],[2,\"\\n\"]],\"parameters\":[]}]]],[2,\"\\n\\n\"],[8,[32,1],[[16,\"data-test\",[30,[36,2],[[32,7],\"add-observation\"],null]]],[[\"@clicked\",\"@disabled\"],[[30,[36,1],[[32,0,[\"add\"]],1],null],[32,6]]],[[\"default\"],[{\"statements\":[[2,\"\\n    \"],[1,[30,[36,0],[\"General.Add\"],null]],[2,\" 1\\n\"]],\"parameters\":[]}]]],[2,\"\\n\\n\"],[8,[32,1],[],[[\"@clicked\",\"@disabled\"],[[30,[36,1],[[32,0,[\"add\"]],10],null],[32,6]]],[[\"default\"],[{\"statements\":[[2,\"\\n    \"],[1,[30,[36,0],[\"General.Add\"],null]],[2,\" 10\\n\"]],\"parameters\":[]}]]],[2,\"\\n\\n\"],[6,[37,2],[[32,3]],null,[[\"default\"],[{\"statements\":[[2,\"    \"],[8,[32,1],[],[[\"@clicked\"],[[32,2]]],[[\"default\"],[{\"statements\":[[2,\"\\n        \"],[1,[30,[36,0],[[32,3,[\"title\"]]],null]],[2,\"\\n    \"]],\"parameters\":[]}]]],[2,\"\\n\"]],\"parameters\":[]}]]]],\"hasEval\":false,\"upvars\":[\"i18n-t\",\"fn\",\"if\"]}",
    "moduleName": "plutof/templates/components/observation/table/header-buttons.hbs"
  });
});