define("plutof/components/search/filter-panels/area-fields", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const __COLOCATED_TEMPLATE__ = Ember.HTMLBars.template(
  /*
    <PlutofPanel::Simple
      @title={{i18n-t "Search.panelProperties"}}
      @collapse={{true}}
  >
      <Layout::RegularGrid @columns={{2}}>
          <div>
              <Search::Filters::Location @filter={{@filterInputs.geom}} />
          </div>
  
          <div>
              <Search::FilterInput @filter={{@filterInputs.study}} />
              <Search::FilterInput @filter={{@filterInputs.country}} />
              <Search::FilterInput @filter={{@filterInputs.state}} />
              <Search::FilterInput @filter={{@filterInputs.district}} />
              <Search::FilterInput @filter={{@filterInputs.commune}} />
              <Search::FilterInput @filter={{@filterInputs.locality_text}} />
              <Search::FilterInput @filter={{@filterInputs.parish}} />
              <Search::FilterInput @filter={{@filterInputs.is_site}} />
          </div>
      </Layout::RegularGrid>
  </PlutofPanel::Simple>
  
  */
  {
    "id": "I+FSSoiP",
    "block": "{\"symbols\":[\"@filterInputs\"],\"statements\":[[8,\"plutof-panel/simple\",[],[[\"@title\",\"@collapse\"],[[30,[36,0],[\"Search.panelProperties\"],null],true]],[[\"default\"],[{\"statements\":[[2,\"\\n    \"],[8,\"layout/regular-grid\",[],[[\"@columns\"],[2]],[[\"default\"],[{\"statements\":[[2,\"\\n        \"],[10,\"div\"],[12],[2,\"\\n            \"],[8,\"search/filters/location\",[],[[\"@filter\"],[[32,1,[\"geom\"]]]],null],[2,\"\\n        \"],[13],[2,\"\\n\\n        \"],[10,\"div\"],[12],[2,\"\\n            \"],[8,\"search/filter-input\",[],[[\"@filter\"],[[32,1,[\"study\"]]]],null],[2,\"\\n            \"],[8,\"search/filter-input\",[],[[\"@filter\"],[[32,1,[\"country\"]]]],null],[2,\"\\n            \"],[8,\"search/filter-input\",[],[[\"@filter\"],[[32,1,[\"state\"]]]],null],[2,\"\\n            \"],[8,\"search/filter-input\",[],[[\"@filter\"],[[32,1,[\"district\"]]]],null],[2,\"\\n            \"],[8,\"search/filter-input\",[],[[\"@filter\"],[[32,1,[\"commune\"]]]],null],[2,\"\\n            \"],[8,\"search/filter-input\",[],[[\"@filter\"],[[32,1,[\"locality_text\"]]]],null],[2,\"\\n            \"],[8,\"search/filter-input\",[],[[\"@filter\"],[[32,1,[\"parish\"]]]],null],[2,\"\\n            \"],[8,\"search/filter-input\",[],[[\"@filter\"],[[32,1,[\"is_site\"]]]],null],[2,\"\\n        \"],[13],[2,\"\\n    \"]],\"parameters\":[]}]]],[2,\"\\n\"]],\"parameters\":[]}]]],[2,\"\\n\"]],\"hasEval\":false,\"upvars\":[\"i18n-t\"]}",
    "moduleName": "plutof/components/search/filter-panels/area-fields.hbs"
  });
  var _default = _exports.default = Ember._setComponentTemplate(__COLOCATED_TEMPLATE__, Ember._templateOnlyComponent());
});