define("plutof/components/add-multiple-form/-column", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const __COLOCATED_TEMPLATE__ = Ember.HTMLBars.template(
  /*
    <GuidedForm @path={{@path}} class="component-part">
      {{yield}}
  </GuidedForm>
  
  */
  {
    "id": "gcBU6sop",
    "block": "{\"symbols\":[\"@path\",\"&default\"],\"statements\":[[8,\"guided-form\",[[24,0,\"component-part\"]],[[\"@path\"],[[32,1]]],[[\"default\"],[{\"statements\":[[2,\"\\n    \"],[18,2,null],[2,\"\\n\"]],\"parameters\":[]}]]],[2,\"\\n\"]],\"hasEval\":false,\"upvars\":[]}",
    "moduleName": "plutof/components/add-multiple-form/-column.hbs"
  });
  var _default = _exports.default = Ember._setComponentTemplate(__COLOCATED_TEMPLATE__, Ember._templateOnlyComponent());
});