define("plutof/templates/taxonomy/edittree", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = Ember.HTMLBars.template({
    "id": "6iP8PG3f",
    "block": "{\"symbols\":[],\"statements\":[[10,\"div\"],[14,0,\"route-marker-taxonomy-edittree\"],[12],[13],[2,\"\\n\\n\"],[8,\"navbar/edit\",[],[[\"@icon\",\"@title\",\"@record\"],[[30,[36,0],[\"taxonomy\"],null],[30,[36,1],[\"Taxonomy.editTree\"],null],[32,0,[\"model\",\"tree\"]]]],null],[2,\"\\n\\n\"],[8,\"access/edit-rights\",[],[[\"@rights\",\"@validationContext\"],[[32,0,[\"model\",\"accessRights\"]],[32,0,[\"validationContext\",\"access\"]]]],null],[2,\"\\n\\n\"],[8,\"sticky-bottom-wrapper\",[],[[],[]],[[\"default\"],[{\"statements\":[[2,\"\\n    \"],[8,\"invalid-panels\",[],[[\"@validationContext\"],[[32,0,[\"validationContext\"]]]],null],[2,\"\\n\\n    \"],[10,\"div\"],[14,0,\"action-buttons\"],[12],[2,\"\\n        \"],[11,\"button\"],[16,\"disabled\",[34,2]],[24,0,\"btn btn-default\"],[4,[38,3],[[32,0],\"update\"],null],[12],[2,\"\\n            \"],[10,\"span\"],[15,0,[31,[[30,[36,5],[[35,4],\"loading-icon\",[30,[36,0],[\"ok\"],null]],null]]]],[12],[13],[2,\"\\n            \"],[1,[30,[36,1],[\"General.Save\"],null]],[2,\"\\n        \"],[13],[2,\"\\n\\n        \"],[8,\"common/cancel-button\",[],[[\"@cancel\"],[[30,[36,3],[[32,0],\"cancel\"],null]]],null],[2,\"\\n    \"],[13],[2,\"\\n\"]],\"parameters\":[]}]]],[2,\"\\n\"]],\"hasEval\":false,\"upvars\":[\"icon\",\"i18n-t\",\"disableSave\",\"action\",\"saving\",\"if\"]}",
    "moduleName": "plutof/templates/taxonomy/edittree.hbs"
  });
});