define("plutof/components/tables/-list-view/order-group", ["exports", "@glimmer/component"], function (_exports, _component) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _dec, _class;
  function _applyDecoratedDescriptor(i, e, r, n, l) { var a = {}; return Object.keys(n).forEach(function (i) { a[i] = n[i]; }), a.enumerable = !!a.enumerable, a.configurable = !!a.configurable, ("value" in a || a.initializer) && (a.writable = !0), a = r.slice().reverse().reduce(function (r, n) { return n(i, e, r) || r; }, a), l && void 0 !== a.initializer && (a.value = a.initializer ? a.initializer.call(l) : void 0, a.initializer = void 0), void 0 === a.initializer ? (Object.defineProperty(i, e, a), null) : a; }
  const __COLOCATED_TEMPLATE__ = Ember.HTMLBars.template(
  /*
    {{yield (component "tables/-list-view/order-group-field"
      field=@field
      orderedBy=@orderedBy
      ascending=@ascending
      disabled=@pagination.isLoading
      sortBy=this.sortBy
  )}}
  
  */
  {
    "id": "18xoydJz",
    "block": "{\"symbols\":[\"@pagination\",\"@ascending\",\"@orderedBy\",\"@field\",\"&default\"],\"statements\":[[18,5,[[30,[36,0],[\"tables/-list-view/order-group-field\"],[[\"field\",\"orderedBy\",\"ascending\",\"disabled\",\"sortBy\"],[[32,4],[32,3],[32,2],[32,1,[\"isLoading\"]],[32,0,[\"sortBy\"]]]]]]],[2,\"\\n\"]],\"hasEval\":false,\"upvars\":[\"component\"]}",
    "moduleName": "plutof/components/tables/-list-view/order-group.hbs"
  });
  let ListRouteTableOrderGroup = (_dec = Ember._action, _class = class ListRouteTableOrderGroup extends _component.default {
    sortBy(key) {
      let ascending = this.args.ascending;
      if (key === this.args.orderedBy) {
        ascending = !ascending;
      }
      this.args.update(key, ascending);
    }
  }, _applyDecoratedDescriptor(_class.prototype, "sortBy", [_dec], Object.getOwnPropertyDescriptor(_class.prototype, "sortBy"), _class.prototype), _class);
  var _default = _exports.default = Ember._setComponentTemplate(__COLOCATED_TEMPLATE__, ListRouteTableOrderGroup);
});