define("plutof/components/dmp/view/choice", ["exports", "ember-data", "plutof/misc/options-getter"], function (_exports, _emberData, _optionsGetter) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _dec, _dec2, _dec3, _class, _descriptor;
  function _initializerDefineProperty(e, i, r, l) { r && Object.defineProperty(e, i, { enumerable: r.enumerable, configurable: r.configurable, writable: r.writable, value: r.initializer ? r.initializer.call(l) : void 0 }); }
  function _defineProperty(e, r, t) { return (r = _toPropertyKey(r)) in e ? Object.defineProperty(e, r, { value: t, enumerable: !0, configurable: !0, writable: !0 }) : e[r] = t, e; }
  function _toPropertyKey(t) { var i = _toPrimitive(t, "string"); return "symbol" == typeof i ? i : i + ""; }
  function _toPrimitive(t, r) { if ("object" != typeof t || !t) return t; var e = t[Symbol.toPrimitive]; if (void 0 !== e) { var i = e.call(t, r || "default"); if ("object" != typeof i) return i; throw new TypeError("@@toPrimitive must return a primitive value."); } return ("string" === r ? String : Number)(t); }
  function _applyDecoratedDescriptor(i, e, r, n, l) { var a = {}; return Object.keys(n).forEach(function (i) { a[i] = n[i]; }), a.enumerable = !!a.enumerable, a.configurable = !!a.configurable, ("value" in a || a.initializer) && (a.writable = !0), a = r.slice().reverse().reduce(function (r, n) { return n(i, e, r) || r; }, a), l && void 0 !== a.initializer && (a.value = a.initializer ? a.initializer.call(l) : void 0, a.initializer = void 0), void 0 === a.initializer ? (Object.defineProperty(i, e, a), null) : a; }
  function _initializerWarningHelper(r, e) { throw Error("Decorating class property failed. Please ensure that transform-class-properties is enabled and runs after the decorators transform."); }
  const __COLOCATED_TEMPLATE__ = Ember.HTMLBars.template(
  /*
    {{this.displayName}}
  
  */
  {
    "id": "74v6NFHY",
    "block": "{\"symbols\":[],\"statements\":[[1,[32,0,[\"displayName\"]]],[2,\"\\n\"]],\"hasEval\":false,\"upvars\":[]}",
    "moduleName": "plutof/components/dmp/view/choice.hbs"
  });
  const OPTIONS = {
    jsonAPI: true,
    preserveEmpty: true
  };
  let DMPChoiceView = (_dec = Ember.inject.service, _dec2 = Ember.computed('property'), _dec3 = Ember.computed('choices.[]', 'value'), _class = class DMPChoiceView extends Ember.Component {
    constructor(...args) {
      super(...args);
      _initializerDefineProperty(this, "ajax", _descriptor, this);
    }
    get choices() {
      return _emberData.default.PromiseArray.create({
        promise: (0, _optionsGetter.getChoices)(this.ajax, '/study/dmps/', `dmp.properties.${this.property}`, OPTIONS)
      });
    }
    get displayName() {
      const choice = this.choices.findBy('value', this.value);
      return choice ? choice['display_name'] : null;
    }
  }, _descriptor = _applyDecoratedDescriptor(_class.prototype, "ajax", [_dec], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _applyDecoratedDescriptor(_class.prototype, "choices", [_dec2], Object.getOwnPropertyDescriptor(_class.prototype, "choices"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "displayName", [_dec3], Object.getOwnPropertyDescriptor(_class.prototype, "displayName"), _class.prototype), _class);
  var _default = _exports.default = Ember._setComponentTemplate(__COLOCATED_TEMPLATE__, DMPChoiceView);
});