define("plutof/components/collection-lab/permit/items/view", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const __COLOCATED_TEMPLATE__ = Ember.HTMLBars.template(
  /*
    <Tables::List
      @records={{@items}}
      as |Field item|
  >
      <Field @label={{i18n-t "permit.source"}}>
          <GenericViews::PojoRecordLink
              @contentType={{item.content_type}}
              @id={{item.object_id}}
              @name={{item.content_object}} />
      </Field>
  
      <Field @label={{backend-translations/label "permit.permit.name"}}>
          {{#link-to "permit.view" item.permit.id}}
              {{item.permit.name}}
          {{/link-to}}
      </Field>
  
      <Field @label={{backend-translations/label "permit.permit.status"}}>
          {{item.permit.statusDisplayName}}
      </Field>
  
      <Field @label={{backend-translations/label "permit.permit.permit_type"}}>
          {{item.permit.permitTypeDisplayName}}
      </Field>
  </Tables::List>
  
  */
  {
    "id": "9vJ88EMA",
    "block": "{\"symbols\":[\"Field\",\"item\",\"@items\"],\"statements\":[[8,\"tables/list\",[],[[\"@records\"],[[32,3]]],[[\"default\"],[{\"statements\":[[2,\"\\n    \"],[8,[32,1],[],[[\"@label\"],[[30,[36,0],[\"permit.source\"],null]]],[[\"default\"],[{\"statements\":[[2,\"\\n        \"],[8,\"generic-views/pojo-record-link\",[],[[\"@contentType\",\"@id\",\"@name\"],[[32,2,[\"content_type\"]],[32,2,[\"object_id\"]],[32,2,[\"content_object\"]]]],null],[2,\"\\n    \"]],\"parameters\":[]}]]],[2,\"\\n\\n    \"],[8,[32,1],[],[[\"@label\"],[[30,[36,1],[\"permit.permit.name\"],null]]],[[\"default\"],[{\"statements\":[[2,\"\\n\"],[6,[37,2],null,[[\"route\",\"model\"],[\"permit.view\",[32,2,[\"permit\",\"id\"]]]],[[\"default\"],[{\"statements\":[[2,\"            \"],[1,[32,2,[\"permit\",\"name\"]]],[2,\"\\n\"]],\"parameters\":[]}]]],[2,\"    \"]],\"parameters\":[]}]]],[2,\"\\n\\n    \"],[8,[32,1],[],[[\"@label\"],[[30,[36,1],[\"permit.permit.status\"],null]]],[[\"default\"],[{\"statements\":[[2,\"\\n        \"],[1,[32,2,[\"permit\",\"statusDisplayName\"]]],[2,\"\\n    \"]],\"parameters\":[]}]]],[2,\"\\n\\n    \"],[8,[32,1],[],[[\"@label\"],[[30,[36,1],[\"permit.permit.permit_type\"],null]]],[[\"default\"],[{\"statements\":[[2,\"\\n        \"],[1,[32,2,[\"permit\",\"permitTypeDisplayName\"]]],[2,\"\\n    \"]],\"parameters\":[]}]]],[2,\"\\n\"]],\"parameters\":[1,2]}]]],[2,\"\\n\"]],\"hasEval\":false,\"upvars\":[\"i18n-t\",\"backend-translations/label\",\"link-to\"]}",
    "moduleName": "plutof/components/collection-lab/permit/items/view.hbs"
  });
  var _default = _exports.default = Ember._setComponentTemplate(__COLOCATED_TEMPLATE__, Ember._templateOnlyComponent());
});