define("plutof/templates/trait-lab", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = Ember.HTMLBars.template({
    "id": "zG5rU2fM",
    "block": "{\"symbols\":[\"hub\"],\"statements\":[[8,\"layout/module-hub\",[],[[\"@icon\",\"@title\"],[[30,[36,0],[\"trait\"],null],[30,[36,1],[\"Forms.traitsLabel\"],null]]],[[\"default\"],[{\"statements\":[[2,\"\\n    \"],[8,\"layout/regular-grid\",[],[[\"@columns\"],[3]],[[\"default\"],[{\"statements\":[[2,\"\\n        \"],[8,[32,1,[\"link\"]],[],[[\"@title\",\"@route\",\"@icon\"],[[30,[36,1],[\"Forms.traitsLabel\"],null],\"trait.index\",[30,[36,0],[\"trait\"],null]]],null],[2,\"\\n\\n        \"],[8,[32,1,[\"link\"]],[],[[\"@title\",\"@route\",\"@icon\"],[[30,[36,1],[\"Forms.title\"],null],\"forms.index\",[30,[36,0],[\"form\"],null]]],null],[2,\"\\n    \"]],\"parameters\":[]}]]],[2,\"\\n\"]],\"parameters\":[1]}]]],[2,\"\\n\"]],\"hasEval\":false,\"upvars\":[\"icon\",\"i18n-t\"]}",
    "moduleName": "plutof/templates/trait-lab.hbs"
  });
});