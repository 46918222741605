define("plutof/components/agent/view/organization-hierarchy", ["exports", "@glimmer/component"], function (_exports, _component) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _dec, _class;
  function _applyDecoratedDescriptor(i, e, r, n, l) { var a = {}; return Object.keys(n).forEach(function (i) { a[i] = n[i]; }), a.enumerable = !!a.enumerable, a.configurable = !!a.configurable, ("value" in a || a.initializer) && (a.writable = !0), a = r.slice().reverse().reduce(function (r, n) { return n(i, e, r) || r; }, a), l && void 0 !== a.initializer && (a.value = a.initializer ? a.initializer.call(l) : void 0, a.initializer = void 0), void 0 === a.initializer ? (Object.defineProperty(i, e, a), null) : a; }
  const __COLOCATED_TEMPLATE__ = Ember.HTMLBars.template(
  /*
    <p data-test="Agent::View::OrganizationHierarchy">
      {{#each this.organizations as |organization|}}
          <span class={{icon "organization"}}></span>
  
          {{#link-to "organization.view" organization.id}}
              <span data-test="parent-organization">
                  {{organization.name}}
              </span>
          {{/link-to}}
  
          &#47;
      {{/each}}
  
      <span class={{icon @icon}}></span> {{@organization.name}}
  </p>
  
  */
  {
    "id": "stUBdoPq",
    "block": "{\"symbols\":[\"organization\",\"@icon\",\"@organization\"],\"statements\":[[10,\"p\"],[14,\"data-test\",\"Agent::View::OrganizationHierarchy\"],[12],[2,\"\\n\"],[6,[37,3],[[30,[36,2],[[30,[36,2],[[32,0,[\"organizations\"]]],null]],null]],null,[[\"default\"],[{\"statements\":[[2,\"        \"],[10,\"span\"],[15,0,[30,[36,0],[\"organization\"],null]],[12],[13],[2,\"\\n\\n\"],[6,[37,1],null,[[\"route\",\"model\"],[\"organization.view\",[32,1,[\"id\"]]]],[[\"default\"],[{\"statements\":[[2,\"            \"],[10,\"span\"],[14,\"data-test\",\"parent-organization\"],[12],[2,\"\\n                \"],[1,[32,1,[\"name\"]]],[2,\"\\n            \"],[13],[2,\"\\n\"]],\"parameters\":[]}]]],[2,\"\\n        /\\n\"]],\"parameters\":[1]}]]],[2,\"\\n    \"],[10,\"span\"],[15,0,[30,[36,0],[[32,2]],null]],[12],[13],[2,\" \"],[1,[32,3,[\"name\"]]],[2,\"\\n\"],[13],[2,\"\\n\"]],\"hasEval\":false,\"upvars\":[\"icon\",\"link-to\",\"-track-array\",\"each\"]}",
    "moduleName": "plutof/components/agent/view/organization-hierarchy.hbs"
  });
  let OrganizationHierarchy = _exports.default = (_dec = Ember.computed('args.organization.organization_hierarchy.[]'), _class = class OrganizationHierarchy extends _component.default {
    get organizations() {
      const hierarchy = this.args.organization.organization_hierarchy ?? [];
      return [...hierarchy].reverse();
    }
  }, _applyDecoratedDescriptor(_class.prototype, "organizations", [_dec], Object.getOwnPropertyDescriptor(_class.prototype, "organizations"), _class.prototype), _class);
  Ember._setComponentTemplate(__COLOCATED_TEMPLATE__, OrganizationHierarchy);
});