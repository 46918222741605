define("plutof/templates/components/study/area-popup", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = Ember.HTMLBars.template({
    "id": "nB+sBDQV",
    "block": "{\"symbols\":[\"@area\",\"@viewRouteArgument\"],\"statements\":[[10,\"table\"],[14,0,\"map-area-tooltip\"],[12],[2,\"\\n    \"],[10,\"tbody\"],[12],[2,\"\\n        \"],[10,\"tr\"],[12],[2,\"\\n            \"],[10,\"td\"],[12],[1,[35,1,[\"name\",\"label\"]]],[2,\":\"],[13],[2,\"\\n            \"],[10,\"td\"],[12],[2,\"\\n\"],[6,[37,3],null,[[\"route\",\"model\"],[\"area.view\",[30,[36,2],[[32,2],[32,1,[\"id\"]]],null]]],[[\"default\"],[{\"statements\":[[2,\"                    \"],[1,[35,0,[\"name\"]]],[2,\"\\n\"]],\"parameters\":[]}]]],[2,\"            \"],[13],[2,\"\\n        \"],[13],[2,\"\\n\\n        \"],[10,\"tr\"],[12],[2,\"\\n            \"],[10,\"td\"],[12],[1,[35,1,[\"latitude\",\"label\"]]],[2,\": \"],[13],[2,\"\\n            \"],[10,\"td\"],[12],[1,[32,0,[\"coords\",\"lat\"]]],[13],[2,\"\\n        \"],[13],[2,\"\\n\\n        \"],[10,\"tr\"],[12],[2,\"\\n            \"],[10,\"td\"],[12],[1,[35,1,[\"longitude\",\"label\"]]],[2,\": \"],[13],[2,\"\\n            \"],[10,\"td\"],[12],[1,[32,0,[\"coords\",\"lon\"]]],[13],[2,\"\\n        \"],[13],[2,\"\\n\\n        \"],[10,\"tr\"],[12],[2,\"\\n            \"],[10,\"td\"],[12],[1,[35,1,[\"district\",\"label\"]]],[2,\": \"],[13],[2,\"\\n            \"],[10,\"td\"],[12],[1,[32,1,[\"district\"]]],[13],[2,\"\\n        \"],[13],[2,\"\\n\\n        \"],[10,\"tr\"],[12],[2,\"\\n            \"],[10,\"td\"],[12],[1,[35,1,[\"commune\",\"label\"]]],[2,\": \"],[13],[2,\"\\n            \"],[10,\"td\"],[12],[1,[32,1,[\"commune\"]]],[13],[2,\"\\n        \"],[13],[2,\"\\n\\n        \"],[10,\"tr\"],[12],[2,\"\\n            \"],[10,\"td\"],[12],[1,[35,1,[\"locality_text\",\"label\"]]],[2,\": \"],[13],[2,\"\\n            \"],[10,\"td\"],[12],[1,[32,1,[\"locality_text\"]]],[13],[2,\"\\n        \"],[13],[2,\"\\n    \"],[13],[2,\"\\n\"],[13],[2,\"\\n\"]],\"hasEval\":false,\"upvars\":[\"area\",\"areaTexts\",\"or\",\"link-to\"]}",
    "moduleName": "plutof/templates/components/study/area-popup.hbs"
  });
});