define("plutof/templates/components/navbar/-navbar-base/upload", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = Ember.HTMLBars.template({
    "id": "05VXVfb2",
    "block": "{\"symbols\":[\"&default\"],\"statements\":[[8,\"navbar/-navbar-base/button\",[],[[\"@clicked\",\"@pending\",\"@icon\"],[[32,0,[\"upload\"]],[32,0,[\"isUploading\"]],[30,[36,1],[\"add-new\"],null]]],[[\"default\"],[{\"statements\":[[2,\"\\n\"],[6,[37,2],[[32,0,[\"pendingUploads\"]]],null,[[\"default\",\"else\"],[{\"statements\":[[2,\"        \"],[1,[30,[36,0],[\"FileRepository.upload.progress\"],[[\"progress\"],[[32,0,[\"uploadProgress\"]]]]]],[2,\"\\n\"]],\"parameters\":[]},{\"statements\":[[2,\"        \"],[18,1,null],[2,\"\\n\"]],\"parameters\":[]}]]]],\"parameters\":[]}]]],[2,\"\\n\"]],\"hasEval\":false,\"upvars\":[\"i18n-t\",\"icon\",\"if\"]}",
    "moduleName": "plutof/templates/components/navbar/-navbar-base/upload.hbs"
  });
});