define("plutof/components/access/-view-rights/owner", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const __COLOCATED_TEMPLATE__ = Ember.HTMLBars.template(
  /*
    {{#access/-view-rights/column}}
      <div class="plutof-tooltip-hover">
          <div class="plutof-label">
              {{i18n-t "General.owner"}}
          </div>
  
          <div class="plutof-tooltip">
              {{i18n-t "General.ownerTooltip"}}
          </div>
      </div>
  
      <p class="view-rights__object-owner">
          {{@owner}}
      </p>
  {{/access/-view-rights/column}}
  
  */
  {
    "id": "wfJYqZug",
    "block": "{\"symbols\":[\"@owner\"],\"statements\":[[6,[37,1],null,null,[[\"default\"],[{\"statements\":[[2,\"    \"],[10,\"div\"],[14,0,\"plutof-tooltip-hover\"],[12],[2,\"\\n        \"],[10,\"div\"],[14,0,\"plutof-label\"],[12],[2,\"\\n            \"],[1,[30,[36,0],[\"General.owner\"],null]],[2,\"\\n        \"],[13],[2,\"\\n\\n        \"],[10,\"div\"],[14,0,\"plutof-tooltip\"],[12],[2,\"\\n            \"],[1,[30,[36,0],[\"General.ownerTooltip\"],null]],[2,\"\\n        \"],[13],[2,\"\\n    \"],[13],[2,\"\\n\\n    \"],[10,\"p\"],[14,0,\"view-rights__object-owner\"],[12],[2,\"\\n        \"],[1,[32,1]],[2,\"\\n    \"],[13],[2,\"\\n\"]],\"parameters\":[]}]]]],\"hasEval\":false,\"upvars\":[\"i18n-t\",\"access/-view-rights/column\"]}",
    "moduleName": "plutof/components/access/-view-rights/owner.hbs"
  });
  var _default = _exports.default = Ember._setComponentTemplate(__COLOCATED_TEMPLATE__, Ember._templateOnlyComponent());
});