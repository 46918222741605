define("plutof/templates/components/observation/taxon-sheet/form", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = Ember.HTMLBars.template({
    "id": "pWMR9ISx",
    "block": "{\"symbols\":[\"entry\",\"@toggle\",\"@entries\"],\"statements\":[[6,[37,3],[[32,0,[\"sheet\",\"items\",\"length\"]]],null,[[\"default\"],[{\"statements\":[[2,\"    \"],[10,\"span\"],[14,0,\"fas fa-sync-alt spinicon\"],[12],[13],[2,\"\\n     \\n    \"],[1,[30,[36,2],[\"General.loading\"],null]],[2,\"\\n\"]],\"parameters\":[]}]]],[2,\"\\n\"],[6,[37,5],[[30,[36,4],[[30,[36,4],[[32,3]],null]],null]],null,[[\"default\"],[{\"statements\":[[2,\"    \"],[1,[30,[36,1],null,[[\"item\",\"selected\",\"toggle\"],[[32,1,[\"item\"]],[32,1,[\"selected\"]],[30,[36,0],[[32,0],[32,2],[32,1]],null]]]]],[2,\"\\n\"]],\"parameters\":[1]}]]]],\"hasEval\":false,\"upvars\":[\"action\",\"observation/taxon-sheet/item\",\"i18n-t\",\"unless\",\"-track-array\",\"each\"]}",
    "moduleName": "plutof/templates/components/observation/taxon-sheet/form.hbs"
  });
});