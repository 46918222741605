define("plutof/components/measurements/measurement-view/panel", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const __COLOCATED_TEMPLATE__ = Ember.HTMLBars.template(
  /*
    <Measurements::MeasurementView::Wrapper
      @mainform={{@mainform}}
      @object={{@object}}
      as |measurements empty expand|
  >
      <PlutofPanel::Simple
          @title={{i18n-t "General.measurements"}}
          @collapse={{empty}}
          ...attributes
      >
          <Measurements::MeasurementView::Columns
              @measurements={{measurements}}
              @columnWidth={{or @columnWidth 2}}
              @expand={{expand}} />
      </PlutofPanel::Simple>
  </Measurements::MeasurementView::Wrapper>
  
  */
  {
    "id": "c3NTQDlY",
    "block": "{\"symbols\":[\"measurements\",\"empty\",\"expand\",\"@mainform\",\"@object\",\"&attrs\",\"@columnWidth\"],\"statements\":[[8,\"measurements/measurement-view/wrapper\",[],[[\"@mainform\",\"@object\"],[[32,4],[32,5]]],[[\"default\"],[{\"statements\":[[2,\"\\n    \"],[8,\"plutof-panel/simple\",[[17,6]],[[\"@title\",\"@collapse\"],[[30,[36,0],[\"General.measurements\"],null],[32,2]]],[[\"default\"],[{\"statements\":[[2,\"\\n        \"],[8,\"measurements/measurement-view/columns\",[],[[\"@measurements\",\"@columnWidth\",\"@expand\"],[[32,1],[30,[36,1],[[32,7],2],null],[32,3]]],null],[2,\"\\n    \"]],\"parameters\":[]}]]],[2,\"\\n\"]],\"parameters\":[1,2,3]}]]],[2,\"\\n\"]],\"hasEval\":false,\"upvars\":[\"i18n-t\",\"or\"]}",
    "moduleName": "plutof/components/measurements/measurement-view/panel.hbs"
  });
  var _default = _exports.default = Ember._setComponentTemplate(__COLOCATED_TEMPLATE__, Ember._templateOnlyComponent());
});