define("plutof/components/annotation/list/icon", ["exports", "@glimmer/component", "plutof/utils/annotation/format"], function (_exports, _component, _format) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const __COLOCATED_TEMPLATE__ = Ember.HTMLBars.template(
  /*
    {{#if this.isElixirAnnotation}}
      <ToolTip @tooltip="European Nucleotide Archive">
          <span class={{icon "ena"}}></span>
      </ToolTip>
  {{/if}}
  
  */
  {
    "id": "7X0G2Jap",
    "block": "{\"symbols\":[],\"statements\":[[6,[37,1],[[32,0,[\"isElixirAnnotation\"]]],null,[[\"default\"],[{\"statements\":[[2,\"    \"],[8,\"tool-tip\",[],[[\"@tooltip\"],[\"European Nucleotide Archive\"]],[[\"default\"],[{\"statements\":[[2,\"\\n        \"],[10,\"span\"],[15,0,[30,[36,0],[\"ena\"],null]],[12],[13],[2,\"\\n    \"]],\"parameters\":[]}]]],[2,\"\\n\"]],\"parameters\":[]}]]]],\"hasEval\":false,\"upvars\":[\"icon\",\"if\"]}",
    "moduleName": "plutof/components/annotation/list/icon.hbs"
  });
  class AnnotationIcon extends _component.default {
    get isElixirAnnotation() {
      return this.args.annotation.annotation.format === _format.AnnotationFormats.Elixir || this.args.annotation.annotation.intended_for_publication;
    }
  }
  var _default = _exports.default = Ember._setComponentTemplate(__COLOCATED_TEMPLATE__, AnnotationIcon);
});