define("plutof/templates/glossary/collection/add", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = Ember.HTMLBars.template({
    "id": "n/spi760",
    "block": "{\"symbols\":[],\"statements\":[[8,\"test/route-marker\",[],[[\"@route\"],[\"glossary.collection.add\"]],null],[2,\"\\n\\n\"],[8,\"navbar/edit\",[],[[\"@icon\",\"@title\",\"@record\",\"@reset\",\"@infoTitle\",\"@infoContent\"],[[30,[36,0],[\"glossary\"],null],[30,[36,1],[\"GlossaryCollection.newCollection\"],null],[32,0,[\"model\",\"collection\"]],[30,[36,2],[\"triggerReset\"],null],[30,[36,1],[\"GlossaryCollection.infoTitle\"],null],\"information/content/general/info-empty\"]],null],[2,\"\\n\\n\"],[8,\"glossary/collection/edit\",[],[[\"@collection\",\"@accessRights\",\"@validationContext\",\"@save\"],[[32,0,[\"model\",\"collection\"]],[32,0,[\"model\",\"accessRights\"]],[32,0,[\"validationContext\"]],[32,0,[\"save\"]]]],null],[2,\"\\n\"]],\"hasEval\":false,\"upvars\":[\"icon\",\"i18n-t\",\"route-action\"]}",
    "moduleName": "plutof/templates/glossary/collection/add.hbs"
  });
});