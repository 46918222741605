define("plutof/templates/components/experiment/common/time-input", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = Ember.HTMLBars.template({
    "id": "sHcbiZjN",
    "block": "{\"symbols\":[\"@disabled\"],\"statements\":[[8,\"one-way-input\",[[16,0,[32,0,[\"inputClasses\"]]]],[[\"@value\",\"@update\",\"@disabled\"],[[32,0,[\"minutes\"]],[32,0,[\"updateMinutes\"]],[32,1]]],null],[2,\"\\n\\n\"],[10,\"span\"],[15,0,[31,[[32,0,[\"separatorClasses\"]]]]],[12],[2,\"\\n    :\\n\"],[13],[2,\"\\n\\n\"],[8,\"one-way-input\",[[16,0,[32,0,[\"inputClasses\"]]]],[[\"@value\",\"@update\",\"@disabled\"],[[32,0,[\"seconds\"]],[32,0,[\"updateSeconds\"]],[32,1]]],null],[2,\"\\n\"]],\"hasEval\":false,\"upvars\":[]}",
    "moduleName": "plutof/templates/components/experiment/common/time-input.hbs"
  });
});