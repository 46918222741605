define("plutof/components/agent/-auto-complete-item", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const __COLOCATED_TEMPLATE__ = Ember.HTMLBars.template(
  /*
    <span class="person-autocomplete">
      {{#if @result.full.valid_name}}
          <i>
              {{!shrinkText name}}
              {{hl-subs @result.value @context.query}}
          </i>
          ({{@result.full.valid_name}})
      {{else}}
          {{!shrinkText name}}
          {{hl-subs @result.value @context.query}}
      {{/if}}
  
      <i>
          {{#if @result.full.user_username}}
              {{#if @result.full.is_ambiguous}}
                  <span class="icon-person"></span>
              {{else}}
                  <span class="icon-user"></span>
              {{/if}}
              <span class="username-highlight">{{@result.full.user_username}}</span>
          {{else}}
              {{#if @result.full.is_collection}}
                  <span class="fas fa-archive"></span>
              {{/if}}
  
              {{#unless (is-equal @result.full.owner_username 'plutof')}}
                  <span class="username-highlight">{{@result.full.owner_username}}</span>
              {{/unless}}
          {{/if}}
      </i>
  </span>
  
  */
  {
    "id": "HE9MXqSP",
    "block": "{\"symbols\":[\"@result\",\"@context\"],\"statements\":[[10,\"span\"],[14,0,\"person-autocomplete\"],[12],[2,\"\\n\"],[6,[37,0],[[32,1,[\"full\",\"valid_name\"]]],null,[[\"default\",\"else\"],[{\"statements\":[[2,\"        \"],[10,\"i\"],[12],[2,\"\\n\"],[2,\"            \"],[1,[30,[36,3],[[32,1,[\"value\"]],[32,2,[\"query\"]]],null]],[2,\"\\n        \"],[13],[2,\"\\n        (\"],[1,[32,1,[\"full\",\"valid_name\"]]],[2,\")\\n\"]],\"parameters\":[]},{\"statements\":[[2,\"        \"],[1,[30,[36,3],[[32,1,[\"value\"]],[32,2,[\"query\"]]],null]],[2,\"\\n\"]],\"parameters\":[]}]]],[2,\"\\n    \"],[10,\"i\"],[12],[2,\"\\n\"],[6,[37,0],[[32,1,[\"full\",\"user_username\"]]],null,[[\"default\",\"else\"],[{\"statements\":[[6,[37,0],[[32,1,[\"full\",\"is_ambiguous\"]]],null,[[\"default\",\"else\"],[{\"statements\":[[2,\"                \"],[10,\"span\"],[14,0,\"icon-person\"],[12],[13],[2,\"\\n\"]],\"parameters\":[]},{\"statements\":[[2,\"                \"],[10,\"span\"],[14,0,\"icon-user\"],[12],[13],[2,\"\\n\"]],\"parameters\":[]}]]],[2,\"            \"],[10,\"span\"],[14,0,\"username-highlight\"],[12],[1,[32,1,[\"full\",\"user_username\"]]],[13],[2,\"\\n\"]],\"parameters\":[]},{\"statements\":[[6,[37,0],[[32,1,[\"full\",\"is_collection\"]]],null,[[\"default\"],[{\"statements\":[[2,\"                \"],[10,\"span\"],[14,0,\"fas fa-archive\"],[12],[13],[2,\"\\n\"]],\"parameters\":[]}]]],[2,\"\\n\"],[6,[37,2],[[30,[36,1],[[32,1,[\"full\",\"owner_username\"]],\"plutof\"],null]],null,[[\"default\"],[{\"statements\":[[2,\"                \"],[10,\"span\"],[14,0,\"username-highlight\"],[12],[1,[32,1,[\"full\",\"owner_username\"]]],[13],[2,\"\\n\"]],\"parameters\":[]}]]]],\"parameters\":[]}]]],[2,\"    \"],[13],[2,\"\\n\"],[13],[2,\"\\n\"]],\"hasEval\":false,\"upvars\":[\"if\",\"is-equal\",\"unless\",\"hl-subs\"]}",
    "moduleName": "plutof/components/agent/-auto-complete-item.hbs"
  });
  var _default = _exports.default = Ember._setComponentTemplate(__COLOCATED_TEMPLATE__, Ember._templateOnlyComponent());
});