define("plutof/components/layout/-module-hub/link", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const __COLOCATED_TEMPLATE__ = Ember.HTMLBars.template(
  /*
    <div class="hub-column">
      {{! Unstyled divs for flex grouping purposes }}
      <div>
          <h4>{{@title}}</h4>
  
          <p class="hub-column__description">
              {{@description}}
          </p>
      </div>
  
      <div>
          {{#if (has-block)}}
              {{yield}}
          {{else}}
              <Layout::-ModuleHub::LinkButton
                  @route={{@route}}
                  @icon={{@icon}}
                  @title={{default-to @buttonTitle @title}} />
          {{/if}}
      </div>
  </div>
  
  */
  {
    "id": "w3UFSc+N",
    "block": "{\"symbols\":[\"@route\",\"@icon\",\"@title\",\"@buttonTitle\",\"&default\",\"@description\",\"@namedBlocksInfo\"],\"statements\":[[10,\"div\"],[14,0,\"hub-column\"],[12],[2,\"\\n\"],[2,\"    \"],[10,\"div\"],[12],[2,\"\\n        \"],[10,\"h4\"],[12],[1,[32,3]],[13],[2,\"\\n\\n        \"],[10,\"p\"],[14,0,\"hub-column__description\"],[12],[2,\"\\n            \"],[1,[32,6]],[2,\"\\n        \"],[13],[2,\"\\n    \"],[13],[2,\"\\n\\n    \"],[10,\"div\"],[12],[2,\"\\n\"],[6,[37,2],[[30,[36,1],[[32,7],\"default\",[27,[32,5]]],null]],null,[[\"default\",\"else\"],[{\"statements\":[[2,\"            \"],[18,5,null],[2,\"\\n\"]],\"parameters\":[]},{\"statements\":[[2,\"            \"],[8,\"layout/-module-hub/link-button\",[],[[\"@route\",\"@icon\",\"@title\"],[[32,1],[32,2],[30,[36,0],[[32,4],[32,3]],null]]],null],[2,\"\\n\"]],\"parameters\":[]}]]],[2,\"    \"],[13],[2,\"\\n\"],[13],[2,\"\\n\"]],\"hasEval\":false,\"upvars\":[\"default-to\",\"-has-block\",\"if\"]}",
    "moduleName": "plutof/components/layout/-module-hub/link.hbs"
  });
  var _default = _exports.default = Ember._setComponentTemplate(__COLOCATED_TEMPLATE__, Ember._templateOnlyComponent());
});