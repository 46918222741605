define("plutof/components/forms/taxon-sheet", ["exports", "@ember-decorators/component", "plutof/utils/has-many"], function (_exports, _component, _hasMany) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.loadTaxonSheetData = _exports.default = _exports.createTaxonSheetData = void 0;
  var _dec, _class, _descriptor, _dec2, _dec3, _dec4, _class2, _class3;
  function _initializerDefineProperty(e, i, r, l) { r && Object.defineProperty(e, i, { enumerable: r.enumerable, configurable: r.configurable, writable: r.writable, value: r.initializer ? r.initializer.call(l) : void 0 }); }
  function _defineProperty(e, r, t) { return (r = _toPropertyKey(r)) in e ? Object.defineProperty(e, r, { value: t, enumerable: !0, configurable: !0, writable: !0 }) : e[r] = t, e; }
  function _toPropertyKey(t) { var i = _toPrimitive(t, "string"); return "symbol" == typeof i ? i : i + ""; }
  function _toPrimitive(t, r) { if ("object" != typeof t || !t) return t; var e = t[Symbol.toPrimitive]; if (void 0 !== e) { var i = e.call(t, r || "default"); if ("object" != typeof i) return i; throw new TypeError("@@toPrimitive must return a primitive value."); } return ("string" === r ? String : Number)(t); }
  function _applyDecoratedDescriptor(i, e, r, n, l) { var a = {}; return Object.keys(n).forEach(function (i) { a[i] = n[i]; }), a.enumerable = !!a.enumerable, a.configurable = !!a.configurable, ("value" in a || a.initializer) && (a.writable = !0), a = r.slice().reverse().reduce(function (r, n) { return n(i, e, r) || r; }, a), l && void 0 !== a.initializer && (a.value = a.initializer ? a.initializer.call(l) : void 0, a.initializer = void 0), void 0 === a.initializer ? (Object.defineProperty(i, e, a), null) : a; }
  function _initializerWarningHelper(r, e) { throw Error("Decorating class property failed. Please ensure that transform-class-properties is enabled and runs after the decorators transform."); }
  const __COLOCATED_TEMPLATE__ = Ember.HTMLBars.template(
  /*
    <PlutofPanel::Simple @title={{i18n-t "Forms.taxonSheet.title"}}>
      <Common::LocalLabel @label="Taxonomy.taxonName" />
  
      <Taxonomy::Taxon::AutoComplete
          @update={{this.add}}
          class="taxon-sheet-edit__selector" />
  
      <ol class="taxon-sheet-edit__taxa">
          {{#each @data.taxa as |sheetTaxon|}}
              <li>
                  {{sheetTaxon.taxon_name}}
  
                  <button {{action this.remove sheetTaxon}} class="btn-fake-link" tabindex="-1">
                      <span class="icon-remove icon-remove--red"></span>
                  </button>
              </li>
          {{/each}}
      </ol>
  
      <Pagination::LoadMore @pagination={{@data.pagination}} />
  </PlutofPanel::Simple>
  
  */
  {
    "id": "AV02GApi",
    "block": "{\"symbols\":[\"sheetTaxon\",\"@data\"],\"statements\":[[8,\"plutof-panel/simple\",[],[[\"@title\"],[[30,[36,1],[\"Forms.taxonSheet.title\"],null]]],[[\"default\"],[{\"statements\":[[2,\"\\n    \"],[8,\"common/local-label\",[],[[\"@label\"],[\"Taxonomy.taxonName\"]],null],[2,\"\\n\\n    \"],[8,\"taxonomy/taxon/auto-complete\",[[24,0,\"taxon-sheet-edit__selector\"]],[[\"@update\"],[[32,0,[\"add\"]]]],null],[2,\"\\n\\n    \"],[10,\"ol\"],[14,0,\"taxon-sheet-edit__taxa\"],[12],[2,\"\\n\"],[6,[37,3],[[30,[36,2],[[30,[36,2],[[32,2,[\"taxa\"]]],null]],null]],null,[[\"default\"],[{\"statements\":[[2,\"            \"],[10,\"li\"],[12],[2,\"\\n                \"],[1,[32,1,[\"taxon_name\"]]],[2,\"\\n\\n                \"],[11,\"button\"],[24,0,\"btn-fake-link\"],[24,\"tabindex\",\"-1\"],[4,[38,0],[[32,0],[32,0,[\"remove\"]],[32,1]],null],[12],[2,\"\\n                    \"],[10,\"span\"],[14,0,\"icon-remove icon-remove--red\"],[12],[13],[2,\"\\n                \"],[13],[2,\"\\n            \"],[13],[2,\"\\n\"]],\"parameters\":[1]}]]],[2,\"    \"],[13],[2,\"\\n\\n    \"],[8,\"pagination/load-more\",[],[[\"@pagination\"],[[32,2,[\"pagination\"]]]],null],[2,\"\\n\"]],\"parameters\":[]}]]],[2,\"\\n\"]],\"hasEval\":false,\"upvars\":[\"action\",\"i18n-t\",\"-track-array\",\"each\"]}",
    "moduleName": "plutof/components/forms/taxon-sheet.hbs"
  });
  let TaxonSheetData = (_dec = Ember.computed.alias('pagination.objects'), _class = class TaxonSheetData extends Ember.Object.extend(_hasMany.HasManyDataMixin) {
    constructor(...args) {
      super(...args);
      _initializerDefineProperty(this, "taxa", _descriptor, this);
    }
    _createEmpty() {
      return this.store.createRecord('measurement/sheet-taxon', {
        mainform: this.mainform
      });
    }
    save() {
      return super.save().then(() => {
        return Ember.RSVP.all(this.taxa.invoke('save'));
      });
    }
  }, _descriptor = _applyDecoratedDescriptor(_class.prototype, "taxa", [_dec], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _class);
  const {
    create: createTaxonSheetData,
    load: loadTaxonSheetData
  } = (0, _hasMany.hasManyDataConstructors)('measurement/sheet-taxon', TaxonSheetData, {
    parentField: 'mainform'
  });
  _exports.loadTaxonSheetData = loadTaxonSheetData;
  _exports.createTaxonSheetData = createTaxonSheetData;
  let TaxonSheetEdit = (_dec2 = (0, _component.classNames)('taxon-sheet-edit'), _dec3 = Ember._action, _dec4 = Ember._action, _dec2(_class2 = (_class3 = class TaxonSheetEdit extends Ember.Component {
    add(taxon) {
      if (!taxon) {
        return;
      }
      if (this.data.taxa.every(t => t.taxon_id !== taxon.id)) {
        const [sheetTaxon] = this.data.add(1);

        // Setting inlined fields so that we can use then instead of resolving full taxon on edit route
        sheetTaxon.setProperties({
          taxon,
          taxon_id: taxon.id,
          taxon_name: taxon.taxon_name
        });
      }
      const autocompleteInput = this.element.querySelector('.taxon-sheet-edit__selector input');
      autocompleteInput.focus();
    }
    remove(item) {
      this.data.remove(item);
    }
  }, _applyDecoratedDescriptor(_class3.prototype, "add", [_dec3], Object.getOwnPropertyDescriptor(_class3.prototype, "add"), _class3.prototype), _applyDecoratedDescriptor(_class3.prototype, "remove", [_dec4], Object.getOwnPropertyDescriptor(_class3.prototype, "remove"), _class3.prototype), _class3)) || _class2);
  var _default = _exports.default = Ember._setComponentTemplate(__COLOCATED_TEMPLATE__, TaxonSheetEdit);
});