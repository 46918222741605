define("plutof/components/tables/row-selector/index", ["exports", "@glimmer/component"], function (_exports, _component) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const __COLOCATED_TEMPLATE__ = Ember.HTMLBars.template(
  /*
    {{yield (hash
      selector=(component "tables/row-selector/selector" selection=@selection equality=(default-to @equality this.defaultEquality))
  )}}
  
  */
  {
    "id": "NuqZc1K+",
    "block": "{\"symbols\":[\"@equality\",\"@selection\",\"&default\"],\"statements\":[[18,3,[[30,[36,2],null,[[\"selector\"],[[30,[36,1],[\"tables/row-selector/selector\"],[[\"selection\",\"equality\"],[[32,2],[30,[36,0],[[32,1],[32,0,[\"defaultEquality\"]]],null]]]]]]]]],[2,\"\\n\"]],\"hasEval\":false,\"upvars\":[\"default-to\",\"component\",\"hash\"]}",
    "moduleName": "plutof/components/tables/row-selector/index.hbs"
  });
  class TableRowSelector extends _component.default {
    defaultEquality(x, y) {
      return x === y;
    }
  }
  var _default = _exports.default = Ember._setComponentTemplate(__COLOCATED_TEMPLATE__, TableRowSelector);
});