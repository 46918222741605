define("plutof/components/study/view/area-tree/node", ["exports", "ember-concurrency", "@ember-decorators/component", "plutof/utils/pagination"], function (_exports, _emberConcurrency, _component, _pagination) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _dec, _dec2, _dec3, _dec4, _dec5, _class, _class2, _descriptor, _descriptor2;
  function _initializerDefineProperty(e, i, r, l) { r && Object.defineProperty(e, i, { enumerable: r.enumerable, configurable: r.configurable, writable: r.writable, value: r.initializer ? r.initializer.call(l) : void 0 }); }
  function _defineProperty(e, r, t) { return (r = _toPropertyKey(r)) in e ? Object.defineProperty(e, r, { value: t, enumerable: !0, configurable: !0, writable: !0 }) : e[r] = t, e; }
  function _toPropertyKey(t) { var i = _toPrimitive(t, "string"); return "symbol" == typeof i ? i : i + ""; }
  function _toPrimitive(t, r) { if ("object" != typeof t || !t) return t; var e = t[Symbol.toPrimitive]; if (void 0 !== e) { var i = e.call(t, r || "default"); if ("object" != typeof i) return i; throw new TypeError("@@toPrimitive must return a primitive value."); } return ("string" === r ? String : Number)(t); }
  function _applyDecoratedDescriptor(i, e, r, n, l) { var a = {}; return Object.keys(n).forEach(function (i) { a[i] = n[i]; }), a.enumerable = !!a.enumerable, a.configurable = !!a.configurable, ("value" in a || a.initializer) && (a.writable = !0), a = r.slice().reverse().reduce(function (r, n) { return n(i, e, r) || r; }, a), l && void 0 !== a.initializer && (a.value = a.initializer ? a.initializer.call(l) : void 0, a.initializer = void 0), void 0 === a.initializer ? (Object.defineProperty(i, e, a), null) : a; }
  function _initializerWarningHelper(r, e) { throw Error("Decorating class property failed. Please ensure that transform-class-properties is enabled and runs after the decorators transform."); }
  const __COLOCATED_TEMPLATE__ = Ember.HTMLBars.template(
  /*
    <div class="area-tree__vertical-notch"></div>
  <div class="area-tree__horizontal-notch"></div>
  
  {{#if this.hasChildren}}
      <button
          {{on "click" this.toggleExpanded}}
          class="btn-reset area-tree__inner-node-marker {{if this.expanded 'area-tree__inner-node-marker--expanded'}}"
      >
          {{!--
          <div class="area-tree__inner-node-marker-horizontal-notch"></div>
  
          {{#unless this.expanded}}
              <div class="area-tree__inner-node-marker-vertical-notch"></div>
          {{/unless}}
          --}}
      </button>
  {{/if}}
  
  <div class="area-tree__node">
      {{#link-to "area.view" @area.id}}
          <span class="area-tree__area-name {{if this.notInProject 'area-tree__area-name--not-in-project'}}">
              {{@area.representation}}
          </span>
      {{/link-to}}
  
      {{#if this.notInProject}}
          ({{i18n-t "Projects.areaHierarchy.notInProject"}})
      {{/if}}
  </div>
  
  {{#if this.expanded}}
      <ol class="area-tree__children">
          {{#each this.loadChildren.last.value.objects as |child|}}
              <Study::View::AreaTree::Node
                  @area={{child}}
                  @project={{@project}} />
          {{/each}}
  
          <Study::View::AreaTree::Pagination @pagination={{this.loadChildren.last.value}} />
      </ol>
  {{/if}}
  
  */
  {
    "id": "okyuNUwD",
    "block": "{\"symbols\":[\"child\",\"@project\",\"@area\"],\"statements\":[[10,\"div\"],[14,0,\"area-tree__vertical-notch\"],[12],[13],[2,\"\\n\"],[10,\"div\"],[14,0,\"area-tree__horizontal-notch\"],[12],[13],[2,\"\\n\\n\"],[6,[37,3],[[32,0,[\"hasChildren\"]]],null,[[\"default\"],[{\"statements\":[[2,\"    \"],[11,\"button\"],[16,0,[31,[\"btn-reset area-tree__inner-node-marker \",[30,[36,3],[[32,0,[\"expanded\"]],\"area-tree__inner-node-marker--expanded\"],null]]]],[4,[38,4],[\"click\",[32,0,[\"toggleExpanded\"]]],null],[12],[2,\"\\n\"],[2,\"    \"],[13],[2,\"\\n\"]],\"parameters\":[]}]]],[2,\"\\n\"],[10,\"div\"],[14,0,\"area-tree__node\"],[12],[2,\"\\n\"],[6,[37,5],null,[[\"route\",\"model\"],[\"area.view\",[32,3,[\"id\"]]]],[[\"default\"],[{\"statements\":[[2,\"        \"],[10,\"span\"],[15,0,[31,[\"area-tree__area-name \",[30,[36,3],[[32,0,[\"notInProject\"]],\"area-tree__area-name--not-in-project\"],null]]]],[12],[2,\"\\n            \"],[1,[32,3,[\"representation\"]]],[2,\"\\n        \"],[13],[2,\"\\n\"]],\"parameters\":[]}]]],[2,\"\\n\"],[6,[37,3],[[32,0,[\"notInProject\"]]],null,[[\"default\"],[{\"statements\":[[2,\"        (\"],[1,[30,[36,2],[\"Projects.areaHierarchy.notInProject\"],null]],[2,\")\\n\"]],\"parameters\":[]}]]],[13],[2,\"\\n\\n\"],[6,[37,3],[[32,0,[\"expanded\"]]],null,[[\"default\"],[{\"statements\":[[2,\"    \"],[10,\"ol\"],[14,0,\"area-tree__children\"],[12],[2,\"\\n\"],[6,[37,1],[[30,[36,0],[[30,[36,0],[[32,0,[\"loadChildren\",\"last\",\"value\",\"objects\"]]],null]],null]],null,[[\"default\"],[{\"statements\":[[2,\"            \"],[8,\"study/view/area-tree/node\",[],[[\"@area\",\"@project\"],[[32,1],[32,2]]],null],[2,\"\\n\"]],\"parameters\":[1]}]]],[2,\"\\n        \"],[8,\"study/view/area-tree/pagination\",[],[[\"@pagination\"],[[32,0,[\"loadChildren\",\"last\",\"value\"]]]],null],[2,\"\\n    \"],[13],[2,\"\\n\"]],\"parameters\":[]}]]]],\"hasEval\":false,\"upvars\":[\"-track-array\",\"each\",\"i18n-t\",\"if\",\"on\",\"link-to\"]}",
    "moduleName": "plutof/components/study/view/area-tree/node.hbs"
  });
  let AreaTreeNode = (_dec = (0, _component.tagName)('li'), _dec2 = (0, _component.classNames)('area-tree__child'), _dec3 = Ember.computed.gt('area.child_count', 0), _dec4 = Ember.computed('area.study.id', 'project.id'), _dec5 = Ember._action, _dec(_class = _dec2(_class = (_class2 = class AreaTreeNode extends Ember.Component {
    constructor(...args) {
      super(...args);
      _defineProperty(this, "expanded", false);
      _initializerDefineProperty(this, "hasChildren", _descriptor, this);
      _initializerDefineProperty(this, "loadChildren", _descriptor2, this);
    }
    get notInProject() {
      return this.area.get('study.id') !== this.project.id;
    }
    expand() {
      if (!this.pagination && this.loadChildren.isIdle) {
        this.loadChildren.perform();
      }
      this.set('expanded', true);
    }
    toggleExpanded() {
      if (this.expanded) {
        this.set('expanded', false);
      } else {
        this.expand();
      }
    }
  }, _descriptor = _applyDecoratedDescriptor(_class2.prototype, "hasChildren", [_dec3], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _applyDecoratedDescriptor(_class2.prototype, "notInProject", [_dec4], Object.getOwnPropertyDescriptor(_class2.prototype, "notInProject"), _class2.prototype), _descriptor2 = _applyDecoratedDescriptor(_class2.prototype, "loadChildren", [_emberConcurrency.task], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return function* () {
        // Query doesn't filter by project, because we want all children on @project areas here
        const query = {
          parent_samplingarea: this.area.id
        };
        const pagination = yield (0, _pagination.paginateModel)(this.store, 'sample/samplingarea', query, [_pagination.AccumulatingPaginationMixin]);
        return pagination;
      };
    }
  }), _applyDecoratedDescriptor(_class2.prototype, "toggleExpanded", [_dec5], Object.getOwnPropertyDescriptor(_class2.prototype, "toggleExpanded"), _class2.prototype), _class2)) || _class) || _class);
  var _default = _exports.default = Ember._setComponentTemplate(__COLOCATED_TEMPLATE__, AreaTreeNode);
});