define("plutof/components/plutof-panel/inner-form", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const __COLOCATED_TEMPLATE__ = Ember.HTMLBars.template(
  /*
    <div class="panel plutof-panel plutof-fade-in" ...attributes>
      <div class="plutof-panel__inner-panel-close-btn">
          {{#if @remove}}
              <button {{on "click" @remove}} class="plutof-close">
                  <span class={{icon "remove"}}></span>
              </button>
          {{/if}}
      </div>
  
      <div class="panel-body plutof-panel-body">
          {{yield}}
      </div>
  </div>
  
  */
  {
    "id": "Fp3tBxU0",
    "block": "{\"symbols\":[\"@remove\",\"&attrs\",\"&default\"],\"statements\":[[11,\"div\"],[24,0,\"panel plutof-panel plutof-fade-in\"],[17,2],[12],[2,\"\\n    \"],[10,\"div\"],[14,0,\"plutof-panel__inner-panel-close-btn\"],[12],[2,\"\\n\"],[6,[37,2],[[32,1]],null,[[\"default\"],[{\"statements\":[[2,\"            \"],[11,\"button\"],[24,0,\"plutof-close\"],[4,[38,0],[\"click\",[32,1]],null],[12],[2,\"\\n                \"],[10,\"span\"],[15,0,[30,[36,1],[\"remove\"],null]],[12],[13],[2,\"\\n            \"],[13],[2,\"\\n\"]],\"parameters\":[]}]]],[2,\"    \"],[13],[2,\"\\n\\n    \"],[10,\"div\"],[14,0,\"panel-body plutof-panel-body\"],[12],[2,\"\\n        \"],[18,3,null],[2,\"\\n    \"],[13],[2,\"\\n\"],[13],[2,\"\\n\"]],\"hasEval\":false,\"upvars\":[\"on\",\"icon\",\"if\"]}",
    "moduleName": "plutof/components/plutof-panel/inner-form.hbs"
  });
  var _default = _exports.default = Ember._setComponentTemplate(__COLOCATED_TEMPLATE__, Ember._templateOnlyComponent());
});