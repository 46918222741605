define("plutof/components/collection/view/annotations/record", ["exports", "@glimmer/component", "plutof/utils/modules"], function (_exports, _component, _modules) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const __COLOCATED_TEMPLATE__ = Ember.HTMLBars.template(
  /*
    {{#link-to this.route @record.id}}
      {{@record.name}}
  {{/link-to}}
  
  */
  {
    "id": "wchoa8Oc",
    "block": "{\"symbols\":[\"@record\"],\"statements\":[[6,[37,0],null,[[\"route\",\"model\"],[[32,0,[\"route\"]],[32,1,[\"id\"]]]],[[\"default\"],[{\"statements\":[[2,\"    \"],[1,[32,1,[\"name\"]]],[2,\"\\n\"]],\"parameters\":[]}]]]],\"hasEval\":false,\"upvars\":[\"link-to\"]}",
    "moduleName": "plutof/components/collection/view/annotations/record.hbs"
  });
  class CollectionAnnotationsRecordField extends _component.default {
    get route() {
      return _modules.default[this.args.record.content_type].routes.view;
    }
  }
  var _default = _exports.default = Ember._setComponentTemplate(__COLOCATED_TEMPLATE__, CollectionAnnotationsRecordField);
});