define("plutof/templates/specimen/add", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = Ember.HTMLBars.template({
    "id": "+HeGVK9y",
    "block": "{\"symbols\":[\"mainform\",\"updateMainform\"],\"statements\":[[8,\"test/route-marker\",[],[[\"@route\"],[\"specimen.add\"]],null],[2,\"\\n\\n\"],[8,\"navbar/edit\",[],[[\"@title\",\"@icon\",\"@infoContent\",\"@reset\"],[[30,[36,0],[\"Specimen.newS\"],null],[30,[36,1],[\"specimen\"],null],\"information/content/specimen/add\",[30,[36,2],[\"triggerReset\"],null]]],null],[2,\"\\n\\n\"],[6,[37,3],[[32,0,[\"model\",\"specimenData\",\"specimen\",\"mainform\"]]],null,[[\"default\"],[{\"statements\":[[2,\"    \"],[8,\"measurements/form-selector\",[],[[\"@type\",\"@mainform\",\"@project\",\"@update\",\"@quickChoices\",\"@validationContext\"],[\"specimen\",[32,1],[32,0,[\"model\",\"specimenData\",\"sampleData\",\"project\"]],[32,2],[32,0,[\"plutofMainforms\"]],[32,0,[\"validationContext\",\"form\"]]]],null],[2,\"\\n\"]],\"parameters\":[1,2]}]]],[2,\"\\n\"],[8,\"specimen/edit\",[],[[\"@data\",\"@closePanels\",\"@canModifyDeterminations\",\"@showAreaEditBtn\",\"@canResetArea\",\"@preserveZoom\",\"@canModifyOwner\",\"@validationContext\"],[[32,0,[\"model\",\"specimenData\"]],[32,0,[\"closePanels\"]],true,false,true,[32,0,[\"preserveZoom\"]],false,[32,0,[\"validationContext\"]]]],null],[2,\"\\n\\n\"],[8,\"sticky-bottom-wrapper\",[],[[],[]],[[\"default\"],[{\"statements\":[[2,\"\\n    \"],[8,\"invalid-panels\",[],[[\"@validationContext\"],[[32,0,[\"validationContext\"]]]],null],[2,\"\\n\\n    \"],[8,\"save-panel\",[],[[\"@save\",\"@cancel\",\"@clone\",\"@ctrl\"],[[32,0,[\"save\"]],[30,[36,4],[[32,0],\"cancel\"],null],[30,[36,4],[[32,0],\"clone\"],null],[32,0]]],null],[2,\"\\n\"]],\"parameters\":[]}]]],[2,\"\\n\"]],\"hasEval\":false,\"upvars\":[\"i18n-t\",\"icon\",\"route-action\",\"async/bind-relation\",\"action\"]}",
    "moduleName": "plutof/templates/specimen/add.hbs"
  });
});