define("plutof/templates/unite/view", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = Ember.HTMLBars.template({
    "id": "XYQi08yS",
    "block": "{\"symbols\":[\"navbar\",\"slots\"],\"statements\":[[10,\"div\"],[14,0,\"route-marker-unite-view\"],[12],[13],[2,\"\\n\\n\"],[8,\"navbar/view\",[],[[\"@icon\",\"@title\",\"@record\",\"@permissions\",\"@infoContent\"],[[30,[36,0],[\"unite-sh-cluster\"],null],[30,[36,1],[\"UniteSH.shView.title\"],null],[32,0,[\"model\",\"dshcluster\"]],[32,0,[\"permissions\"]],\"information/content/unite/view\"]],[[\"default\"],[{\"statements\":[[2,\"\\n    \"],[8,[32,2,[\"secondaryControls\"]],[],[[],[]],[[\"default\"],[{\"statements\":[[2,\"\\n        \"],[8,[32,1,[\"button\"]],[],[[\"@clicked\",\"@icon\"],[[32,0,[\"downloadFASTA\"]],[30,[36,0],[\"download\"],null]]],[[\"default\"],[{\"statements\":[[2,\"\\n            \"],[1,[30,[36,1],[\"UniteSH.downloadFASTA\"],null]],[2,\"\\n        \"]],\"parameters\":[]}]]],[2,\"\\n    \"]],\"parameters\":[]}]]],[2,\"\\n\"]],\"parameters\":[1,2]}]]],[2,\"\\n\\n\"],[8,\"unite-table\",[],[[\"@dshcluster\",\"@currentRefSeq\",\"@modified\",\"@canModifyRefseqs\",\"@canModifyExclusions\",\"@treeTaxon\",\"@thLineage\",\"@dois\",\"@mapData\",\"@collapseLevels\",\"@cancel\"],[[32,0,[\"model\",\"dshcluster\"]],[32,0,[\"refSeq\"]],[32,0,[\"modified\"]],[32,0,[\"canModifyRefseqs\"]],[32,0,[\"canModifyExclusions\"]],[32,0,[\"_loadTaxon\",\"last\",\"value\"]],[32,0,[\"model\",\"thLineage\"]],[32,0,[\"model\",\"dois\"]],[32,0,[\"model\",\"mapData\"]],[32,0,[\"model\",\"collapseLevels\"]],[30,[36,2],[\"goBack\"],null]]],null],[2,\"\\n\"]],\"hasEval\":false,\"upvars\":[\"icon\",\"i18n-t\",\"route-action\"]}",
    "moduleName": "plutof/templates/unite/view.hbs"
  });
});