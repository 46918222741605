define("plutof/templates/components/interaction/edit-multiple", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = Ember.HTMLBars.template({
    "id": "eEaDw8tf",
    "block": "{\"symbols\":[\"panel\",\"entry\",\"context\",\"@data\",\"@validationContext\"],\"statements\":[[8,\"plutof-panel/item-list\",[],[[],[]],[[\"default\"],[{\"statements\":[[2,\"\\n    \"],[8,[32,1,[\"header\"]],[],[[\"@title\",\"@count\"],[[30,[36,1],[\"General.interactions\"],null],[32,4,[\"entries\",\"length\"]]]],[[\"default\"],[{\"statements\":[[2,\"\\n        \"],[8,[32,1,[\"button\"]],[],[[\"@clicked\",\"@class\"],[[32,4,[\"add\"]],\"test-marker-btn-add\"]],[[\"default\"],[{\"statements\":[[2,\"\\n            \"],[1,[30,[36,1],[\"General.Add\"],null]],[2,\"\\n        \"]],\"parameters\":[]}]]],[2,\"\\n    \"]],\"parameters\":[]}]]],[2,\"\\n\\n    \"],[8,[32,1,[\"innerPanels\"]],[],[[],[]],[[\"default\"],[{\"statements\":[[2,\"\\n        \"],[8,\"validation/split-context\",[],[[\"@context\",\"@items\",\"@loopKey\"],[[32,5],[32,4,[\"entries\"]],\"fadeId\"]],[[\"default\"],[{\"statements\":[[2,\"\\n\"],[6,[37,2],null,[[\"class\"],[\"edit-interactions__interaction\"]],[[\"default\"],[{\"statements\":[[2,\"                \"],[8,\"plutof-panel/inner\",[],[[],[]],[[\"default\"],[{\"statements\":[[2,\"\\n                    \"],[8,\"interaction/form\",[],[[\"@data\",\"@validationContext\",\"@remove\"],[[32,2,[\"interaction\"]],[32,3],[30,[36,0],[[32,4,[\"remove\"]],[32,2]],null]]],null],[2,\"\\n                \"]],\"parameters\":[]}]]],[2,\"\\n\"]],\"parameters\":[]}]]],[2,\"        \"]],\"parameters\":[2,3]}]]],[2,\"\\n    \"]],\"parameters\":[]}]]],[2,\"\\n\"]],\"parameters\":[1]}]]],[2,\"\\n\"]],\"hasEval\":false,\"upvars\":[\"fn\",\"i18n-t\",\"fade-element\"]}",
    "moduleName": "plutof/templates/components/interaction/edit-multiple.hbs"
  });
});