define("plutof/components/annotation/fields", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const __COLOCATED_TEMPLATE__ = Ember.HTMLBars.template(
  /*
    {{yield (hash
      icon=(component "annotation/-fields/icon" field=@field annotation=@annotation)
      link=(component "annotation/-fields/link" field=@field annotation=@annotation)
      author=(component "annotation/-fields/author" field=@field annotation=@annotation)
      comment=(component "annotation/-fields/comment" field=@field annotation=@annotation)
      annotatedAt=(component "annotation/-fields/annotatedAt" field=@field annotation=@annotation)
      status=(component "annotation/-fields/status" field=@field annotation=@annotation translations=@translations)
      moderator=(component "annotation/-fields/moderator" field=@field annotation=@annotation)
  )}}
  
  */
  {
    "id": "7GbWZWMH",
    "block": "{\"symbols\":[\"@annotation\",\"@field\",\"@translations\",\"&default\"],\"statements\":[[18,4,[[30,[36,1],null,[[\"icon\",\"link\",\"author\",\"comment\",\"annotatedAt\",\"status\",\"moderator\"],[[30,[36,0],[\"annotation/-fields/icon\"],[[\"field\",\"annotation\"],[[32,2],[32,1]]]],[30,[36,0],[\"annotation/-fields/link\"],[[\"field\",\"annotation\"],[[32,2],[32,1]]]],[30,[36,0],[\"annotation/-fields/author\"],[[\"field\",\"annotation\"],[[32,2],[32,1]]]],[30,[36,0],[\"annotation/-fields/comment\"],[[\"field\",\"annotation\"],[[32,2],[32,1]]]],[30,[36,0],[\"annotation/-fields/annotatedAt\"],[[\"field\",\"annotation\"],[[32,2],[32,1]]]],[30,[36,0],[\"annotation/-fields/status\"],[[\"field\",\"annotation\",\"translations\"],[[32,2],[32,1],[32,3]]]],[30,[36,0],[\"annotation/-fields/moderator\"],[[\"field\",\"annotation\"],[[32,2],[32,1]]]]]]]]],[2,\"\\n\"]],\"hasEval\":false,\"upvars\":[\"component\",\"hash\"]}",
    "moduleName": "plutof/components/annotation/fields.hbs"
  });
  var _default = _exports.default = Ember._setComponentTemplate(__COLOCATED_TEMPLATE__, Ember._templateOnlyComponent());
});