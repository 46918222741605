define("plutof/components/history/record", ["exports", "@glimmer/component", "plutof/utils/modules", "plutof/helpers/icon"], function (_exports, _component, _modules, _icon) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const __COLOCATED_TEMPLATE__ = Ember.HTMLBars.template(
  /*
    <ToolTip @tooltip={{@change.record_content_type}}>
      <span class="{{this.icon}} history__record-type-icon"></span>
      {{@change.record}}
  </ToolTip>
  
  */
  {
    "id": "ABaJmHyl",
    "block": "{\"symbols\":[\"@change\"],\"statements\":[[8,\"tool-tip\",[],[[\"@tooltip\"],[[32,1,[\"record_content_type\"]]]],[[\"default\"],[{\"statements\":[[2,\"\\n    \"],[10,\"span\"],[15,0,[31,[[32,0,[\"icon\"]],\" history__record-type-icon\"]]],[12],[13],[2,\"\\n    \"],[1,[32,1,[\"record\"]]],[2,\"\\n\"]],\"parameters\":[]}]]],[2,\"\\n\"]],\"hasEval\":false,\"upvars\":[]}",
    "moduleName": "plutof/components/history/record.hbs"
  });
  class HistoryRecordLabel extends _component.default {
    get icon() {
      const module = _modules.default[this.args.change.record_content_type];
      if (!module || !module.icon) {
        return (0, _icon.icon)('trait');
      }
      return module.icon;
    }
  }
  var _default = _exports.default = Ember._setComponentTemplate(__COLOCATED_TEMPLATE__, HistoryRecordLabel);
});