define("plutof/components/search/filter-panels/all-occurrences-taxon-checkboxes", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const __COLOCATED_TEMPLATE__ = Ember.HTMLBars.template(
  /*
    <Layout::RegularGrid @columns={{6}}>
      <Search::FilterInput @filter={{@filterInputs.interacting_lineage}} />
      <Search::FilterInput @filter={{@filterInputs.is_synonym}} />
      <Search::FilterInput @filter={{@filterInputs.phenological}} />
      <Search::FilterInput @filter={{@filterInputs.is_restricted}} />
      <Search::FilterInput @filter={{@filterInputs.sequence_parent_taxon}} />
      <Search::FilterInput @filter={{@filterInputs.has_conflict}} />
      <Search::FilterInput @filter={{@filterInputs.has_cluster}} />
  </Layout::RegularGrid>
  
  */
  {
    "id": "DHVNCbrx",
    "block": "{\"symbols\":[\"@filterInputs\"],\"statements\":[[8,\"layout/regular-grid\",[],[[\"@columns\"],[6]],[[\"default\"],[{\"statements\":[[2,\"\\n    \"],[8,\"search/filter-input\",[],[[\"@filter\"],[[32,1,[\"interacting_lineage\"]]]],null],[2,\"\\n    \"],[8,\"search/filter-input\",[],[[\"@filter\"],[[32,1,[\"is_synonym\"]]]],null],[2,\"\\n    \"],[8,\"search/filter-input\",[],[[\"@filter\"],[[32,1,[\"phenological\"]]]],null],[2,\"\\n    \"],[8,\"search/filter-input\",[],[[\"@filter\"],[[32,1,[\"is_restricted\"]]]],null],[2,\"\\n    \"],[8,\"search/filter-input\",[],[[\"@filter\"],[[32,1,[\"sequence_parent_taxon\"]]]],null],[2,\"\\n    \"],[8,\"search/filter-input\",[],[[\"@filter\"],[[32,1,[\"has_conflict\"]]]],null],[2,\"\\n    \"],[8,\"search/filter-input\",[],[[\"@filter\"],[[32,1,[\"has_cluster\"]]]],null],[2,\"\\n\"]],\"parameters\":[]}]]],[2,\"\\n\"]],\"hasEval\":false,\"upvars\":[]}",
    "moduleName": "plutof/components/search/filter-panels/all-occurrences-taxon-checkboxes.hbs"
  });
  var _default = _exports.default = Ember._setComponentTemplate(__COLOCATED_TEMPLATE__, Ember._templateOnlyComponent());
});