define("plutof/components/information/content/unite/view", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const __COLOCATED_TEMPLATE__ = Ember.HTMLBars.template(
  /*
    <h4>Collapsible views</h4>
  
  <Information::Unite::Collapse />
  
  */
  {
    "id": "PrRz7mI1",
    "block": "{\"symbols\":[],\"statements\":[[10,\"h4\"],[12],[2,\"Collapsible views\"],[13],[2,\"\\n\\n\"],[8,\"information/unite/collapse\",[],[[],[]],null],[2,\"\\n\"]],\"hasEval\":false,\"upvars\":[]}",
    "moduleName": "plutof/components/information/content/unite/view.hbs"
  });
  var _default = _exports.default = Ember._setComponentTemplate(__COLOCATED_TEMPLATE__, Ember._templateOnlyComponent());
});