define("plutof/templates/components/tab-group/header", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = Ember.HTMLBars.template({
    "id": "2pkQ3vt6",
    "block": "{\"symbols\":[\"&default\"],\"statements\":[[6,[37,4],[[35,3,[\"headersContainer\"]]],[[\"guid\",\"insertBefore\"],[\"%cursor:0%\",null]],[[\"default\"],[{\"statements\":[[2,\"    \"],[10,\"li\"],[15,0,[31,[\"tab-group__tab \",[30,[36,1],[[35,0,[\"selected\"]],\"tab-group__tab--active\"],null]]]],[15,\"data-tab-name\",[31,[[34,0,[\"name\"]]]]],[15,\"data-test\",[31,[[34,0,[\"name\"]]]]],[12],[2,\"\\n        \"],[11,\"a\"],[4,[38,2],[\"click\",[32,0,[\"switchTab\"]]],null],[12],[2,\"\\n            \"],[18,1,null],[2,\"\\n        \"],[13],[2,\"\\n    \"],[13],[2,\"\\n\"]],\"parameters\":[]}]]]],\"hasEval\":false,\"upvars\":[\"tab\",\"if\",\"on\",\"group\",\"in-element\"]}",
    "moduleName": "plutof/templates/components/tab-group/header.hbs"
  });
});