define("plutof/components/tables/column-control/-pin", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const __COLOCATED_TEMPLATE__ = Ember.HTMLBars.template(
  /*
    <button
      {{on "click" @pinColumn}}
      class="column-control-btn {{if @isPinned 'column-control-btn--active'}}"
      ...attributes
  >
      <ToolTip @tooltip={{i18n-t "Table.actions.pinColumnTooltip"}}>
          <span class="fas fa-thumbtack"></span>
      </ToolTip>
  </button>
  
  */
  {
    "id": "b3mmNIPs",
    "block": "{\"symbols\":[\"@isPinned\",\"&attrs\",\"@pinColumn\"],\"statements\":[[11,\"button\"],[16,0,[31,[\"column-control-btn \",[30,[36,0],[[32,1],\"column-control-btn--active\"],null]]]],[17,2],[4,[38,1],[\"click\",[32,3]],null],[12],[2,\"\\n    \"],[8,\"tool-tip\",[],[[\"@tooltip\"],[[30,[36,2],[\"Table.actions.pinColumnTooltip\"],null]]],[[\"default\"],[{\"statements\":[[2,\"\\n        \"],[10,\"span\"],[14,0,\"fas fa-thumbtack\"],[12],[13],[2,\"\\n    \"]],\"parameters\":[]}]]],[2,\"\\n\"],[13],[2,\"\\n\"]],\"hasEval\":false,\"upvars\":[\"if\",\"on\",\"i18n-t\"]}",
    "moduleName": "plutof/components/tables/column-control/-pin.hbs"
  });
  var _default = _exports.default = Ember._setComponentTemplate(__COLOCATED_TEMPLATE__, Ember._templateOnlyComponent());
});