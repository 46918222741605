define("plutof/components/project/view/record-project-fields", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const __COLOCATED_TEMPLATE__ = Ember.HTMLBars.template(
  /*
    <GenericViews::Record
      @record={{@project}}
      as |view|
  >
      <Layout::RegularGrid
          @columns={{2}}
          @cellGap={{true}}
      >
          {{#view.attr "name"}}
              {{#link-to "study.view" @project.id class=(if @annotated "annotated")}}
                  {{@project.representation}}
              {{/link-to}}
          {{/view.attr}}
  
          {{#view.attr "remarks"}}
              <LongText
                  @text={{@project.remarks}}
                  @text-height="height-1"
                  @tagName="span" />
          {{/view.attr}}
  
          {{view.attr "start_date"}}
          {{view.attr "end_date"}}
      </Layout::RegularGrid>
  </GenericViews::Record>
  
  */
  {
    "id": "SdI+n2xn",
    "block": "{\"symbols\":[\"view\",\"@project\",\"@annotated\"],\"statements\":[[8,\"generic-views/record\",[],[[\"@record\"],[[32,2]]],[[\"default\"],[{\"statements\":[[2,\"\\n    \"],[8,\"layout/regular-grid\",[],[[\"@columns\",\"@cellGap\"],[2,true]],[[\"default\"],[{\"statements\":[[2,\"\\n\"],[6,[37,2],[[32,1,[\"attr\"]],\"name\"],null,[[\"default\"],[{\"statements\":[[6,[37,1],null,[[\"class\",\"route\",\"model\"],[[30,[36,0],[[32,3],\"annotated\"],null],\"study.view\",[32,2,[\"id\"]]]],[[\"default\"],[{\"statements\":[[2,\"                \"],[1,[32,2,[\"representation\"]]],[2,\"\\n\"]],\"parameters\":[]}]]]],\"parameters\":[]}]]],[2,\"\\n\"],[6,[37,2],[[32,1,[\"attr\"]],\"remarks\"],null,[[\"default\"],[{\"statements\":[[2,\"            \"],[8,\"long-text\",[],[[\"@text\",\"@text-height\",\"@tagName\"],[[32,2,[\"remarks\"]],\"height-1\",\"span\"]],null],[2,\"\\n\"]],\"parameters\":[]}]]],[2,\"\\n        \"],[1,[30,[36,2],[[32,1,[\"attr\"]],\"start_date\"],null]],[2,\"\\n        \"],[1,[30,[36,2],[[32,1,[\"attr\"]],\"end_date\"],null]],[2,\"\\n    \"]],\"parameters\":[]}]]],[2,\"\\n\"]],\"parameters\":[1]}]]],[2,\"\\n\"]],\"hasEval\":false,\"upvars\":[\"if\",\"link-to\",\"component\"]}",
    "moduleName": "plutof/components/project/view/record-project-fields.hbs"
  });
  var _default = _exports.default = Ember._setComponentTemplate(__COLOCATED_TEMPLATE__, Ember._templateOnlyComponent());
});