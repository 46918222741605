define("plutof/templates/experiment/dna-extraction/view", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = Ember.HTMLBars.template({
    "id": "hnT5otM8",
    "block": "{\"symbols\":[\"materialContainer\"],\"statements\":[[10,\"div\"],[14,0,\"route-marker-dna-extraction-view\"],[12],[13],[2,\"\\n\\n\"],[8,\"navbar/view\",[],[[\"@icon\",\"@title\",\"@record\",\"@module\",\"@permissions\",\"@editRoute\",\"@deletable\",\"@infoTitle\",\"@infoContent\"],[[30,[36,0],[\"experiment\"],null],[30,[36,1],[\"experiment.extraction.routeTitles.view\"],null],[32,0,[\"model\",\"experiment\"]],\"dnalab/dnaextraction\",[32,0,[\"model\",\"permissions\"]],\"experiment.dna-extraction.edit\",true,[30,[36,1],[\"experiment.general.experiments\"],null],\"information/content/dna-lab/general\"]],null],[2,\"\\n\\n\"],[8,\"experiment/dna-extraction/view/general-data\",[],[[\"@experiment\"],[[32,0,[\"model\",\"experiment\"]]]],null],[2,\"\\n\\n\"],[8,\"experiment/common/material-container\",[],[[\"@labID\",\"@materials\",\"@materialType\"],[[32,0,[\"model\",\"experiment\",\"laboratory\",\"id\"]],[32,0,[\"model\",\"dnas\"]],\"dna\"]],[[\"default\"],[{\"statements\":[[2,\"\\n    \"],[8,\"experiment/dna-extraction/view/experiment-material-view\",[],[[\"@dnas\",\"@experiment\",\"@enableTubeSelection\",\"@materialContainer\"],[[32,0,[\"model\",\"dnas\"]],[32,0,[\"model\",\"experiment\"]],true,[32,1]]],null],[2,\"\\n\\n    \"],[8,\"experiment/common/labware-visualization\",[],[[\"@experiment\",\"@materials\",\"@materialType\",\"@enableTubeSelection\",\"@materialContainer\"],[[32,0,[\"model\",\"experiment\"]],[32,0,[\"model\",\"dnas\"]],\"dna\",true,[32,1]]],null],[2,\"\\n\"]],\"parameters\":[1]}]]],[2,\"\\n\\n\"],[8,\"linked-items/view\",[],[[\"@object\"],[[32,0,[\"model\",\"experiment\"]]]],null],[2,\"\\n\"],[8,\"experiment/common/record-metadata\",[],[[\"@record\"],[[32,0,[\"model\",\"experiment\"]]]],null],[2,\"\\n\"]],\"hasEval\":false,\"upvars\":[\"icon\",\"i18n-t\"]}",
    "moduleName": "plutof/templates/experiment/dna-extraction/view.hbs"
  });
});