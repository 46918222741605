define("plutof/templates/profile/bookmarks", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = Ember.HTMLBars.template({
    "id": "iI/BCPtz",
    "block": "{\"symbols\":[],\"statements\":[[8,\"test/route-marker\",[],[[\"@route\"],[\"profile.bookmarks\"]],null],[2,\"\\n\\n\"],[1,[30,[36,2],null,[[\"bookmarks\",\"isLoading\",\"remove\"],[[35,1,[\"bookmarks\"]],false,[30,[36,0],[[32,0],\"removeBookmark\"],null]]]]],[2,\"\\n\"]],\"hasEval\":false,\"upvars\":[\"action\",\"model\",\"profile/bookmark-list\"]}",
    "moduleName": "plutof/templates/profile/bookmarks.hbs"
  });
});