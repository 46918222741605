define("plutof/components/information/content/organization/index", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const __COLOCATED_TEMPLATE__ = Ember.HTMLBars.template(
  /*
    <Information::MarkdownReader @path="general/list-view" @locale={{@locale}} />
  <Information::MarkdownReader @path="organization" @locale={{@locale}} />
  
  */
  {
    "id": "qf3V8W9Y",
    "block": "{\"symbols\":[\"@locale\"],\"statements\":[[8,\"information/markdown-reader\",[],[[\"@path\",\"@locale\"],[\"general/list-view\",[32,1]]],null],[2,\"\\n\"],[8,\"information/markdown-reader\",[],[[\"@path\",\"@locale\"],[\"organization\",[32,1]]],null],[2,\"\\n\"]],\"hasEval\":false,\"upvars\":[]}",
    "moduleName": "plutof/components/information/content/organization/index.hbs"
  });
  var _default = _exports.default = Ember._setComponentTemplate(__COLOCATED_TEMPLATE__, Ember._templateOnlyComponent());
});