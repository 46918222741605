define("plutof/components/collection/edit", ["exports", "plutof/mixins/component-validations"], function (_exports, _componentValidations) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  function _defineProperty(e, r, t) { return (r = _toPropertyKey(r)) in e ? Object.defineProperty(e, r, { value: t, enumerable: !0, configurable: !0, writable: !0 }) : e[r] = t, e; }
  function _toPropertyKey(t) { var i = _toPrimitive(t, "string"); return "symbol" == typeof i ? i : i + ""; }
  function _toPrimitive(t, r) { if ("object" != typeof t || !t) return t; var e = t[Symbol.toPrimitive]; if (void 0 !== e) { var i = e.call(t, r || "default"); if ("object" != typeof i) return i; throw new TypeError("@@toPrimitive must return a primitive value."); } return ("string" === r ? String : Number)(t); }
  const __COLOCATED_TEMPLATE__ = Ember.HTMLBars.template(
  /*
    <Agent::CollectionForm
      @collection={{@collection}}
      @setLogo={{@setLogo}}
      @validationContext={{this.validationContext.collection}} />
  
  <Agent::OrganizationAdditionalData
      @organization={{@collection}}
      @validationContext={{this.validationContext.additionalData}} />
  
  {{#if @workgroupData}}
      <Agent::OrganizationWorkgroup
          @workgroupData={{@workgroupData}}
          @validationContext={{this.validationContext.managingGroup}} />
  {{/if}}
  
  <Collection::Repositories
      @repositories={{@repositories}}
      @objectsToDelete={{@objectsToDelete}}
      @validationContext={{this.validationContext.repositories}} />
  
  {{#if @identifiers}}
      <Identifiers::Edit
          @model={{@identifiers}}
          @workgroupData={{@workgroupData}} />
  {{/if}}
  
  <LinkedItems::Edit
      @data={{@linkedItems}}
      @validationContext={{@validationContext.linkedItems}} />
  
  <StickyBottomWrapper>
      <InvalidPanels @validationContext={{this.validationContext}} />
  
      <div class="action-buttons">
          <Common::SaveButton @save={{@save}} @disabled={{this.validationContext.isInvalid}} />
          <Common::CancelButton @cancel={{@cancel}} />
      </div>
  </StickyBottomWrapper>
  
  */
  {
    "id": "a0mMXgkR",
    "block": "{\"symbols\":[\"@identifiers\",\"@workgroupData\",\"@collection\",\"@setLogo\",\"@repositories\",\"@objectsToDelete\",\"@linkedItems\",\"@validationContext\",\"@save\",\"@cancel\"],\"statements\":[[8,\"agent/collection-form\",[],[[\"@collection\",\"@setLogo\",\"@validationContext\"],[[32,3],[32,4],[32,0,[\"validationContext\",\"collection\"]]]],null],[2,\"\\n\\n\"],[8,\"agent/organization-additional-data\",[],[[\"@organization\",\"@validationContext\"],[[32,3],[32,0,[\"validationContext\",\"additionalData\"]]]],null],[2,\"\\n\\n\"],[6,[37,0],[[32,2]],null,[[\"default\"],[{\"statements\":[[2,\"    \"],[8,\"agent/organization-workgroup\",[],[[\"@workgroupData\",\"@validationContext\"],[[32,2],[32,0,[\"validationContext\",\"managingGroup\"]]]],null],[2,\"\\n\"]],\"parameters\":[]}]]],[2,\"\\n\"],[8,\"collection/repositories\",[],[[\"@repositories\",\"@objectsToDelete\",\"@validationContext\"],[[32,5],[32,6],[32,0,[\"validationContext\",\"repositories\"]]]],null],[2,\"\\n\\n\"],[6,[37,0],[[32,1]],null,[[\"default\"],[{\"statements\":[[2,\"    \"],[8,\"identifiers/edit\",[],[[\"@model\",\"@workgroupData\"],[[32,1],[32,2]]],null],[2,\"\\n\"]],\"parameters\":[]}]]],[2,\"\\n\"],[8,\"linked-items/edit\",[],[[\"@data\",\"@validationContext\"],[[32,7],[32,8,[\"linkedItems\"]]]],null],[2,\"\\n\\n\"],[8,\"sticky-bottom-wrapper\",[],[[],[]],[[\"default\"],[{\"statements\":[[2,\"\\n    \"],[8,\"invalid-panels\",[],[[\"@validationContext\"],[[32,0,[\"validationContext\"]]]],null],[2,\"\\n\\n    \"],[10,\"div\"],[14,0,\"action-buttons\"],[12],[2,\"\\n        \"],[8,\"common/save-button\",[],[[\"@save\",\"@disabled\"],[[32,9],[32,0,[\"validationContext\",\"isInvalid\"]]]],null],[2,\"\\n        \"],[8,\"common/cancel-button\",[],[[\"@cancel\"],[[32,10]]],null],[2,\"\\n    \"],[13],[2,\"\\n\"]],\"parameters\":[]}]]],[2,\"\\n\"]],\"hasEval\":false,\"upvars\":[\"if\"]}",
    "moduleName": "plutof/components/collection/edit.hbs"
  });
  class CollectionEdit extends Ember.Component.extend(_componentValidations.default) {
    constructor(...args) {
      super(...args);
      _defineProperty(this, "validationChildren", [{
        name: 'collection',
        label: 'collection.collection'
      }, {
        name: 'additionalData',
        label: 'Agents.additionalData'
      }, {
        name: 'repositories',
        label: 'collection.repository.repositories'
      }, {
        name: 'managingGroup',
        label: 'group.panels.workgroup'
      }]);
    }
  }
  var _default = _exports.default = Ember._setComponentTemplate(__COLOCATED_TEMPLATE__, CollectionEdit);
});