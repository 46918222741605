define("plutof/components/access/edit/public-or-private", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const __COLOCATED_TEMPLATE__ = Ember.HTMLBars.template(
  /*
    <Common::ButtonGroup as |buttonGroup|>
      <buttonGroup.button
          {{on "click" (fn @update true)}}
          @isActive={{@value}}
          @disabled={{@disabled}}
      >
          <span class={{icon "public"}}></span>
          {{i18n-t "Access.public"}}
      </buttonGroup.button>
  
      <buttonGroup.button
          {{on "click" (fn @update false)}}
          @isActive={{not @value}}
          @disabled={{@disabled}}
      >
          <span class={{icon "private"}}></span>
          {{i18n-t "Access.private"}}
      </buttonGroup.button>
  </Common::ButtonGroup>
  
  */
  {
    "id": "2To3bBXN",
    "block": "{\"symbols\":[\"buttonGroup\",\"@value\",\"@disabled\",\"@update\"],\"statements\":[[8,\"common/button-group\",[],[[],[]],[[\"default\"],[{\"statements\":[[2,\"\\n    \"],[8,[32,1,[\"button\"]],[[4,[38,1],[\"click\",[30,[36,0],[[32,4],true],null]],null]],[[\"@isActive\",\"@disabled\"],[[32,2],[32,3]]],[[\"default\"],[{\"statements\":[[2,\"\\n        \"],[10,\"span\"],[15,0,[30,[36,2],[\"public\"],null]],[12],[13],[2,\"\\n        \"],[1,[30,[36,3],[\"Access.public\"],null]],[2,\"\\n    \"]],\"parameters\":[]}]]],[2,\"\\n\\n    \"],[8,[32,1,[\"button\"]],[[4,[38,1],[\"click\",[30,[36,0],[[32,4],false],null]],null]],[[\"@isActive\",\"@disabled\"],[[30,[36,4],[[32,2]],null],[32,3]]],[[\"default\"],[{\"statements\":[[2,\"\\n        \"],[10,\"span\"],[15,0,[30,[36,2],[\"private\"],null]],[12],[13],[2,\"\\n        \"],[1,[30,[36,3],[\"Access.private\"],null]],[2,\"\\n    \"]],\"parameters\":[]}]]],[2,\"\\n\"]],\"parameters\":[1]}]]],[2,\"\\n\"]],\"hasEval\":false,\"upvars\":[\"fn\",\"on\",\"icon\",\"i18n-t\",\"not\"]}",
    "moduleName": "plutof/components/access/edit/public-or-private.hbs"
  });
  var _default = _exports.default = Ember._setComponentTemplate(__COLOCATED_TEMPLATE__, Ember._templateOnlyComponent());
});