define("plutof/templates/components/import/navbar-button", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = Ember.HTMLBars.template({
    "id": "fOD22Enp",
    "block": "{\"symbols\":[\"@navbar\"],\"statements\":[[8,[32,1,[\"button\"]],[],[[\"@icon\",\"@clicked\"],[[30,[36,0],[\"import\"],null],[32,0,[\"goToImport\"]]]],[[\"default\"],[{\"statements\":[[2,\"\\n    \"],[1,[30,[36,1],[\"Import.import\"],null]],[2,\"\\n\"]],\"parameters\":[]}]]],[2,\"\\n\"]],\"hasEval\":false,\"upvars\":[\"icon\",\"i18n-t\"]}",
    "moduleName": "plutof/templates/components/import/navbar-button.hbs"
  });
});