define("plutof/templates/components/profile/groups/user-groups", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = Ember.HTMLBars.template({
    "id": "TVmiXqsC",
    "block": "{\"symbols\":[\"membership\",\"@memberships\"],\"statements\":[[10,\"div\"],[14,0,\"user-groups__controls\"],[12],[2,\"\\n    \"],[8,\"pagination/page-controls\",[],[[\"@pagination\"],[[32,2]]],null],[2,\"\\n\\n    \"],[10,\"div\"],[12],[2,\"\\n        \"],[11,\"button\"],[24,0,\"btn btn-default btn-xs\"],[4,[38,1],[\"click\",[32,0,[\"createGroup\"]]],null],[12],[2,\"\\n            \"],[1,[30,[36,2],[\"Settings.groups.create\"],null]],[2,\"\\n        \"],[13],[2,\"\\n\\n        \"],[11,\"button\"],[24,0,\"btn btn-default btn-xs\"],[4,[38,1],[\"click\",[32,0,[\"joinGroup\"]]],null],[12],[2,\"\\n            \"],[1,[30,[36,2],[\"Settings.groups.join\"],null]],[2,\"\\n        \"],[13],[2,\"\\n    \"],[13],[2,\"\\n\"],[13],[2,\"\\n\\n\"],[10,\"div\"],[14,0,\"spacer-large-below\"],[12],[13],[2,\"\\n\\n\"],[10,\"table\"],[14,0,\"plutof-table plutof-table__multi-line table-hover\"],[12],[2,\"\\n    \"],[10,\"thead\"],[12],[2,\"\\n        \"],[10,\"tr\"],[12],[2,\"\\n            \"],[10,\"th\"],[12],[1,[30,[36,2],[\"Settings.groups.fields.group\"],null]],[13],[2,\"\\n            \"],[10,\"th\"],[12],[1,[30,[36,2],[\"Settings.groups.fields.rights\"],null]],[13],[2,\"\\n            \"],[10,\"th\"],[12],[1,[30,[36,2],[\"Settings.groups.fields.status\"],null]],[13],[2,\"\\n        \"],[13],[2,\"\\n    \"],[13],[2,\"\\n\\n    \"],[10,\"tbody\"],[12],[2,\"\\n\"],[6,[37,4],[[30,[36,3],[[30,[36,3],[[32,2,[\"objects\"]]],null]],null]],null,[[\"default\"],[{\"statements\":[[2,\"            \"],[1,[30,[36,0],null,[[\"membership\"],[[32,1]]]]],[2,\"\\n\"]],\"parameters\":[1]}]]],[2,\"    \"],[13],[2,\"\\n\"],[13],[2,\"\\n\"]],\"hasEval\":false,\"upvars\":[\"profile/groups/-user-groups-row\",\"on\",\"i18n-t\",\"-track-array\",\"each\"]}",
    "moduleName": "plutof/templates/components/profile/groups/user-groups.hbs"
  });
});