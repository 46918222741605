define("plutof/components/measurements/add-extra/link", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const __COLOCATED_TEMPLATE__ = Ember.HTMLBars.template(
  /*
    <Measurements::AddExtra::Wrap
      @measurements={{@measurements}}
      @module={{@module}}
      as |extraMeasurements|
  >
      <div class="label-control">
          <button {{on "click" extraMeasurements.request}} class="label-control__btn">
              <span class="icon-add-new"></span>
  
              {{i18n-t "General.addExternalMeasurements"}}
          </button>
      </div>
  </Measurements::AddExtra::Wrap>
  
  */
  {
    "id": "d4uuTa2h",
    "block": "{\"symbols\":[\"extraMeasurements\",\"@measurements\",\"@module\"],\"statements\":[[8,\"measurements/add-extra/wrap\",[],[[\"@measurements\",\"@module\"],[[32,2],[32,3]]],[[\"default\"],[{\"statements\":[[2,\"\\n    \"],[10,\"div\"],[14,0,\"label-control\"],[12],[2,\"\\n        \"],[11,\"button\"],[24,0,\"label-control__btn\"],[4,[38,0],[\"click\",[32,1,[\"request\"]]],null],[12],[2,\"\\n            \"],[10,\"span\"],[14,0,\"icon-add-new\"],[12],[13],[2,\"\\n\\n            \"],[1,[30,[36,1],[\"General.addExternalMeasurements\"],null]],[2,\"\\n        \"],[13],[2,\"\\n    \"],[13],[2,\"\\n\"]],\"parameters\":[1]}]]],[2,\"\\n\"]],\"hasEval\":false,\"upvars\":[\"on\",\"i18n-t\"]}",
    "moduleName": "plutof/components/measurements/add-extra/link.hbs"
  });
  var _default = _exports.default = Ember._setComponentTemplate(__COLOCATED_TEMPLATE__, Ember._templateOnlyComponent());
});