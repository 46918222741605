define("plutof/components/search/filter-panels/occurrence-properties", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const __COLOCATED_TEMPLATE__ = Ember.HTMLBars.template(
  /*
    <PlutofPanel::Simple
      @title={{i18n-t "Search.panelProperties"}}
      @collapse={{true}}
      data-panel-name="default-properties"
  >
      <Search::FilterPanels::CommonProperties::Agents @filterInputs={{@filterInputs}} />
  
      <Layout::RegularGrid @columns={{6}}>
          <Search::FilterInput @filter={{@filterInputs.study}} @autocompletePreset="study/study" />
          <Search::FilterInput @filter={{@filterInputs.mainform}} />
          <Search::FilterInput @filter={{@filterInputs.references}} />
          <Search::FilterInput @filter={{@filterInputs.living_specimen}} @filters={{@filterInputs}} />
          <Search::FilterInput @filter={{@filterInputs.keywords}} />
  
          {{! Range input }}
          <Search::FilterInput @filter={{@filterInputs.individual_count}} @filters={{@filterInputs}} />
      </Layout::RegularGrid>
  
      <Layout::RegularGrid @columns={{6}}>
          <Search::FilterPanels::CommonProperties::Checkboxes @filterInputs={{@filterInputs}} />
      </Layout::RegularGrid>
  </PlutofPanel::Simple>
  
  */
  {
    "id": "4Pc72ySd",
    "block": "{\"symbols\":[\"@filterInputs\"],\"statements\":[[8,\"plutof-panel/simple\",[[24,\"data-panel-name\",\"default-properties\"]],[[\"@title\",\"@collapse\"],[[30,[36,0],[\"Search.panelProperties\"],null],true]],[[\"default\"],[{\"statements\":[[2,\"\\n    \"],[8,\"search/filter-panels/common-properties/agents\",[],[[\"@filterInputs\"],[[32,1]]],null],[2,\"\\n\\n    \"],[8,\"layout/regular-grid\",[],[[\"@columns\"],[6]],[[\"default\"],[{\"statements\":[[2,\"\\n        \"],[8,\"search/filter-input\",[],[[\"@filter\",\"@autocompletePreset\"],[[32,1,[\"study\"]],\"study/study\"]],null],[2,\"\\n        \"],[8,\"search/filter-input\",[],[[\"@filter\"],[[32,1,[\"mainform\"]]]],null],[2,\"\\n        \"],[8,\"search/filter-input\",[],[[\"@filter\"],[[32,1,[\"references\"]]]],null],[2,\"\\n        \"],[8,\"search/filter-input\",[],[[\"@filter\",\"@filters\"],[[32,1,[\"living_specimen\"]],[32,1]]],null],[2,\"\\n        \"],[8,\"search/filter-input\",[],[[\"@filter\"],[[32,1,[\"keywords\"]]]],null],[2,\"\\n\\n\"],[2,\"        \"],[8,\"search/filter-input\",[],[[\"@filter\",\"@filters\"],[[32,1,[\"individual_count\"]],[32,1]]],null],[2,\"\\n    \"]],\"parameters\":[]}]]],[2,\"\\n\\n    \"],[8,\"layout/regular-grid\",[],[[\"@columns\"],[6]],[[\"default\"],[{\"statements\":[[2,\"\\n        \"],[8,\"search/filter-panels/common-properties/checkboxes\",[],[[\"@filterInputs\"],[[32,1]]],null],[2,\"\\n    \"]],\"parameters\":[]}]]],[2,\"\\n\"]],\"parameters\":[]}]]],[2,\"\\n\"]],\"hasEval\":false,\"upvars\":[\"i18n-t\"]}",
    "moduleName": "plutof/components/search/filter-panels/occurrence-properties.hbs"
  });
  var _default = _exports.default = Ember._setComponentTemplate(__COLOCATED_TEMPLATE__, Ember._templateOnlyComponent());
});