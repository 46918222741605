define("plutof/templates/components/experiment/pcr/edit-form", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = Ember.HTMLBars.template({
    "id": "rpCPZxSc",
    "block": "{\"symbols\":[\"@materials\",\"@experiment\",\"@linkedItemsData\",\"@validationContext\",\"@save\",\"@cancel\"],\"statements\":[[8,\"plutof-panel/simple\",[],[[\"@title\"],[[30,[36,4],[\"General.generalData\"],null]]],[[\"default\"],[{\"statements\":[[2,\"\\n    \"],[1,[30,[36,6],null,[[\"experiment\",\"materialIsInvalid\",\"validationContext\"],[[32,2],[35,5],[35,0,[\"metadata\"]]]]]],[2,\"\\n\"]],\"parameters\":[]}]]],[2,\"\\n\\n\"],[6,[37,8],[[35,7]],null,[[\"default\"],[{\"statements\":[[2,\"    \"],[1,[30,[36,3],null,[[\"experiment\",\"products\",\"addLabware\",\"validationContext\"],[[32,2],[32,1],[30,[36,2],[[32,0],[35,1]],null],[35,0,[\"material\"]]]]]],[2,\"\\n\\n    \"],[8,\"experiment/common/labware-visualization\",[],[[\"@experiment\",\"@materials\",\"@materialType\"],[[32,2],[32,1],\"pcr\"]],null],[2,\"\\n\"]],\"parameters\":[]}]]],[2,\"\\n\"],[1,[30,[36,9],null,[[\"data\",\"validationContext\"],[[32,3],[35,0,[\"associatedData\"]]]]]],[2,\"\\n\\n\"],[8,\"sticky-bottom-wrapper\",[],[[],[]],[[\"default\"],[{\"statements\":[[2,\"\\n    \"],[8,\"invalid-panels\",[],[[\"@validationContext\"],[[32,4]]],null],[2,\"\\n\\n    \"],[10,\"div\"],[14,0,\"action-buttons\"],[12],[2,\"\\n        \"],[8,\"common/save-button\",[],[[\"@save\",\"@disabled\"],[[30,[36,2],[[32,0],[32,5]],null],[32,4,[\"isInvalid\"]]]],null],[2,\"\\n\\n        \"],[8,\"common/cancel-button\",[],[[\"@cancel\"],[[30,[36,2],[[32,0],[32,6]],null]]],null],[2,\"\\n    \"],[13],[2,\"\\n\"]],\"parameters\":[]}]]],[2,\"\\n\"]],\"hasEval\":false,\"upvars\":[\"validationContext\",\"addLabware\",\"action\",\"experiment/pcr/experiment-material\",\"i18n-t\",\"materialIsInvalid\",\"experiment/pcr/experiment-metadata\",\"enableMaterialPanel\",\"if\",\"linked-items/edit\"]}",
    "moduleName": "plutof/templates/components/experiment/pcr/edit-form.hbs"
  });
});