define("plutof/components/study/view/project-areas", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const __COLOCATED_TEMPLATE__ = Ember.HTMLBars.template(
  /*
    <PlutofPanel::ItemList
      @collapse={{not @projectAreas.length}}
      data-test="Project::View::ProjectAreas"
      as |panel|
  >
      <panel.header @title="{{i18n-t "Projects.predefinedAreas"}}" @count="{{@projectAreas.length}}" />
  
      <panel.content>
          {{study/-project-areas-table projectAreas=@projectAreas}}
      </panel.content>
  </PlutofPanel::ItemList>
  
  */
  {
    "id": "s+zYq/I/",
    "block": "{\"symbols\":[\"panel\",\"@projectAreas\"],\"statements\":[[8,\"plutof-panel/item-list\",[[24,\"data-test\",\"Project::View::ProjectAreas\"]],[[\"@collapse\"],[[30,[36,0],[[32,2,[\"length\"]]],null]]],[[\"default\"],[{\"statements\":[[2,\"\\n    \"],[8,[32,1,[\"header\"]],[],[[\"@title\",\"@count\"],[[31,[[30,[36,1],[\"Projects.predefinedAreas\"],null]]],[31,[[32,2,[\"length\"]]]]]],null],[2,\"\\n\\n    \"],[8,[32,1,[\"content\"]],[],[[],[]],[[\"default\"],[{\"statements\":[[2,\"\\n        \"],[1,[30,[36,2],null,[[\"projectAreas\"],[[32,2]]]]],[2,\"\\n    \"]],\"parameters\":[]}]]],[2,\"\\n\"]],\"parameters\":[1]}]]],[2,\"\\n\"]],\"hasEval\":false,\"upvars\":[\"not\",\"i18n-t\",\"study/-project-areas-table\"]}",
    "moduleName": "plutof/components/study/view/project-areas.hbs"
  });
  class ProjectAreas extends Ember.Component {}
  var _default = _exports.default = Ember._setComponentTemplate(__COLOCATED_TEMPLATE__, ProjectAreas);
});