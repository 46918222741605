define("plutof/components/access/-view-rights/edit-selected", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const __COLOCATED_TEMPLATE__ = Ember.HTMLBars.template(
  /*
    {{#access/-view-rights/column}}
      {{plutof-labelc path="Access.canEdit" source="local"}}
  
      <p>
          <span class="icon-access-rights"></span>
  
          {{access/-view-rights/agent-list
              usernames=@usernames
              groups=@groups}}
      </p>
  {{/access/-view-rights/column}}
  
  */
  {
    "id": "Fb08EhvU",
    "block": "{\"symbols\":[\"@groups\",\"@usernames\"],\"statements\":[[6,[37,2],null,null,[[\"default\"],[{\"statements\":[[2,\"    \"],[1,[30,[36,0],null,[[\"path\",\"source\"],[\"Access.canEdit\",\"local\"]]]],[2,\"\\n\\n    \"],[10,\"p\"],[12],[2,\"\\n        \"],[10,\"span\"],[14,0,\"icon-access-rights\"],[12],[13],[2,\"\\n\\n        \"],[1,[30,[36,1],null,[[\"usernames\",\"groups\"],[[32,2],[32,1]]]]],[2,\"\\n    \"],[13],[2,\"\\n\"]],\"parameters\":[]}]]]],\"hasEval\":false,\"upvars\":[\"plutof-labelc\",\"access/-view-rights/agent-list\",\"access/-view-rights/column\"]}",
    "moduleName": "plutof/components/access/-view-rights/edit-selected.hbs"
  });
  var _default = _exports.default = Ember._setComponentTemplate(__COLOCATED_TEMPLATE__, Ember._templateOnlyComponent());
});