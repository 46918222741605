define("plutof/templates/livingspecimen/annotation/view", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = Ember.HTMLBars.template({
    "id": "V7iYPweP",
    "block": "{\"symbols\":[],\"statements\":[[8,\"annotation/navbar/view\",[],[[\"@icon\",\"@title\",\"@annotation\",\"@permissions\",\"@parentRoute\"],[[30,[36,0],[\"livingspecimen\"],null],[30,[36,1],[\"LivingCultures.annotation.view\"],null],[32,0,[\"model\",\"annotation\"]],[32,0,[\"model\",\"permissions\"]],\"livingspecimen\"]],null],[2,\"\\n\\n\"],[8,\"annotation/summary\",[],[[\"@annotation\",\"@permissions\"],[[32,0,[\"model\",\"annotation\"]],[32,0,[\"model\",\"permissions\"]]]],null],[2,\"\\n\\n\"],[8,\"livingspecimen/view\",[],[[\"@livingspecimen\",\"@parent\",\"@additionalIdentifiers\",\"@accessRights\",\"@sequences\"],[[32,0,[\"model\",\"strain\"]],[32,0,[\"model\",\"parent\"]],[32,0,[\"model\",\"additionalIdentifiers\"]],[32,0,[\"model\",\"accessRights\"]],[32,0,[\"model\",\"sequences\"]]]],null],[2,\"\\n\"]],\"hasEval\":false,\"upvars\":[\"icon\",\"i18n-t\"]}",
    "moduleName": "plutof/templates/livingspecimen/annotation/view.hbs"
  });
});