define("plutof/components/gis-lab/import/areas", ["exports", "@glimmer/component"], function (_exports, _component) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _dec, _dec2, _dec3, _class, _descriptor, _descriptor2;
  function _initializerDefineProperty(e, i, r, l) { r && Object.defineProperty(e, i, { enumerable: r.enumerable, configurable: r.configurable, writable: r.writable, value: r.initializer ? r.initializer.call(l) : void 0 }); }
  function _defineProperty(e, r, t) { return (r = _toPropertyKey(r)) in e ? Object.defineProperty(e, r, { value: t, enumerable: !0, configurable: !0, writable: !0 }) : e[r] = t, e; }
  function _toPropertyKey(t) { var i = _toPrimitive(t, "string"); return "symbol" == typeof i ? i : i + ""; }
  function _toPrimitive(t, r) { if ("object" != typeof t || !t) return t; var e = t[Symbol.toPrimitive]; if (void 0 !== e) { var i = e.call(t, r || "default"); if ("object" != typeof i) return i; throw new TypeError("@@toPrimitive must return a primitive value."); } return ("string" === r ? String : Number)(t); }
  function _applyDecoratedDescriptor(i, e, r, n, l) { var a = {}; return Object.keys(n).forEach(function (i) { a[i] = n[i]; }), a.enumerable = !!a.enumerable, a.configurable = !!a.configurable, ("value" in a || a.initializer) && (a.writable = !0), a = r.slice().reverse().reduce(function (r, n) { return n(i, e, r) || r; }, a), l && void 0 !== a.initializer && (a.value = a.initializer ? a.initializer.call(l) : void 0, a.initializer = void 0), void 0 === a.initializer ? (Object.defineProperty(i, e, a), null) : a; }
  function _initializerWarningHelper(r, e) { throw Error("Decorating class property failed. Please ensure that transform-class-properties is enabled and runs after the decorators transform."); }
  const __COLOCATED_TEMPLATE__ = Ember.HTMLBars.template(
  /*
    <TabGroup
      @selected={{this.selectedTab}}
      @tabSwitched={{action (mut this.selectedTab)}}
  >
      <:tabs as |group|>
          <group.tab @name="list">
              <group.header @name="list">
                  {{i18n-t "GisLab.import.tabs.list"}}
              </group.header>
  
              <div>
                  <GisLab::Import::Areas::List
                      @areasData={{@areasData}}
                      @showOnMap={{fn this.showOnMap group.group}} />
              </div>
          </group.tab>
  
          <group.tab @name="map">
              <group.header @name="map">
                  {{i18n-t "GisLab.import.tabs.map"}}
              </group.header>
  
              <div>
                  <GisLab::Import::Areas::Map
                      @areasData={{@areasData}}
                      @zoomToGeometry={{this.zoomToGeometry}} />
              </div>
          </group.tab>
      </:tabs>
  </TabGroup>
  
  */
  {
    "id": "XLiF3xpl",
    "block": "{\"symbols\":[\"__arg0\",\"__arg1\",\"group\",\"@areasData\"],\"statements\":[[8,\"tab-group\",[],[[\"@selected\",\"@tabSwitched\",\"@namedBlocksInfo\"],[[32,0,[\"selectedTab\"]],[30,[36,4],[[32,0],[30,[36,3],[[32,0,[\"selectedTab\"]]],null]],null],[30,[36,5],null,[[\"tabs\"],[1]]]]],[[\"default\"],[{\"statements\":[[6,[37,7],[[30,[36,6],[[32,1],\"tabs\"],null]],null,[[\"default\"],[{\"statements\":[[6,[37,2],[[32,2]],null,[[\"default\"],[{\"statements\":[[2,\"\\n        \"],[8,[32,3,[\"tab\"]],[],[[\"@name\"],[\"list\"]],[[\"default\"],[{\"statements\":[[2,\"\\n            \"],[8,[32,3,[\"header\"]],[],[[\"@name\"],[\"list\"]],[[\"default\"],[{\"statements\":[[2,\"\\n                \"],[1,[30,[36,0],[\"GisLab.import.tabs.list\"],null]],[2,\"\\n            \"]],\"parameters\":[]}]]],[2,\"\\n\\n            \"],[10,\"div\"],[12],[2,\"\\n                \"],[8,\"gis-lab/import/areas/list\",[],[[\"@areasData\",\"@showOnMap\"],[[32,4],[30,[36,1],[[32,0,[\"showOnMap\"]],[32,3,[\"group\"]]],null]]],null],[2,\"\\n            \"],[13],[2,\"\\n        \"]],\"parameters\":[]}]]],[2,\"\\n\\n        \"],[8,[32,3,[\"tab\"]],[],[[\"@name\"],[\"map\"]],[[\"default\"],[{\"statements\":[[2,\"\\n            \"],[8,[32,3,[\"header\"]],[],[[\"@name\"],[\"map\"]],[[\"default\"],[{\"statements\":[[2,\"\\n                \"],[1,[30,[36,0],[\"GisLab.import.tabs.map\"],null]],[2,\"\\n            \"]],\"parameters\":[]}]]],[2,\"\\n\\n            \"],[10,\"div\"],[12],[2,\"\\n                \"],[8,\"gis-lab/import/areas/map\",[],[[\"@areasData\",\"@zoomToGeometry\"],[[32,4],[32,0,[\"zoomToGeometry\"]]]],null],[2,\"\\n            \"],[13],[2,\"\\n        \"]],\"parameters\":[]}]]],[2,\"\\n    \"]],\"parameters\":[3]}]]]],\"parameters\":[]}]]]],\"parameters\":[1,2]}]]],[2,\"\\n\"]],\"hasEval\":false,\"upvars\":[\"i18n-t\",\"fn\",\"let\",\"mut\",\"action\",\"hash\",\"-is-named-block-invocation\",\"if\"]}",
    "moduleName": "plutof/components/gis-lab/import/areas.hbs"
  });
  let GisLabImportAreas = (_dec = Ember._tracked, _dec2 = Ember._tracked, _dec3 = Ember._action, _class = class GisLabImportAreas extends _component.default {
    constructor(...args) {
      super(...args);
      _initializerDefineProperty(this, "selectedTab", _descriptor, this);
      _initializerDefineProperty(this, "zoomToGeometry", _descriptor2, this);
    }
    showOnMap(tabGroup, area) {
      tabGroup.switchTab(tabGroup.getTab('map'));
      this.zoomToGeometry = area.geom;
    }
  }, _descriptor = _applyDecoratedDescriptor(_class.prototype, "selectedTab", [_dec], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return 'list';
    }
  }), _descriptor2 = _applyDecoratedDescriptor(_class.prototype, "zoomToGeometry", [_dec2], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return null;
    }
  }), _applyDecoratedDescriptor(_class.prototype, "showOnMap", [_dec3], Object.getOwnPropertyDescriptor(_class.prototype, "showOnMap"), _class.prototype), _class);
  var _default = _exports.default = Ember._setComponentTemplate(__COLOCATED_TEMPLATE__, GisLabImportAreas);
});