define("plutof/components/filerepository/view/items", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const __COLOCATED_TEMPLATE__ = Ember.HTMLBars.template(
  /*
    <RelatedObjects::View
      @pagination={{@items}}
      @title={{i18n-t "FileRepository.linkedObjects"}}
  >
      <RelatedObjects::Content @pagination={{@items}} as |Field item|>
          <Field @label={{i18n-t "FileRepository.type"}}>
              {{item.content_type_name}}
          </Field>
  
          <Field @label={{i18n-t "FileRepository.object"}}>
              <GenericViews::PojoRecordLink
                  @contentType={{item.new_content_type}}
                  @id={{item.object_id}}
                  @name={{item.object_name}} />
          </Field>
      </RelatedObjects::Content>
  </RelatedObjects::View>
  
  */
  {
    "id": "rwi/TtEh",
    "block": "{\"symbols\":[\"Field\",\"item\",\"@items\"],\"statements\":[[8,\"related-objects/view\",[],[[\"@pagination\",\"@title\"],[[32,3],[30,[36,0],[\"FileRepository.linkedObjects\"],null]]],[[\"default\"],[{\"statements\":[[2,\"\\n    \"],[8,\"related-objects/content\",[],[[\"@pagination\"],[[32,3]]],[[\"default\"],[{\"statements\":[[2,\"\\n        \"],[8,[32,1],[],[[\"@label\"],[[30,[36,0],[\"FileRepository.type\"],null]]],[[\"default\"],[{\"statements\":[[2,\"\\n            \"],[1,[32,2,[\"content_type_name\"]]],[2,\"\\n        \"]],\"parameters\":[]}]]],[2,\"\\n\\n        \"],[8,[32,1],[],[[\"@label\"],[[30,[36,0],[\"FileRepository.object\"],null]]],[[\"default\"],[{\"statements\":[[2,\"\\n            \"],[8,\"generic-views/pojo-record-link\",[],[[\"@contentType\",\"@id\",\"@name\"],[[32,2,[\"new_content_type\"]],[32,2,[\"object_id\"]],[32,2,[\"object_name\"]]]],null],[2,\"\\n        \"]],\"parameters\":[]}]]],[2,\"\\n    \"]],\"parameters\":[1,2]}]]],[2,\"\\n\"]],\"parameters\":[]}]]],[2,\"\\n\"]],\"hasEval\":false,\"upvars\":[\"i18n-t\"]}",
    "moduleName": "plutof/components/filerepository/view/items.hbs"
  });
  var _default = _exports.default = Ember._setComponentTemplate(__COLOCATED_TEMPLATE__, Ember._templateOnlyComponent());
});