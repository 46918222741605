define("plutof/components/search/filter-panels/material-sample-taxon", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const __COLOCATED_TEMPLATE__ = Ember.HTMLBars.template(
  /*
    <PlutofPanel::Simple
      @title={{i18n-t "Search.panelTaxon"}}
      @collapse={{true}}
  >
      <div>
          <Layout::RegularGrid @columns={{6}}>
              <Search::FilterInput @filter={{@filterInputs.typification}} />
              <Search::FilterInput @filter={{@filterInputs.interacting_taxa}} />
          </Layout::RegularGrid>
  
          <Layout::RegularGrid @columns={{6}}>
              <Search::FilterInput @filter={{@filterInputs.has_interactions}} />
              <Search::FilterInput @filter={{@filterInputs.interacting_lineage}} />
          </Layout::RegularGrid>
      </div>
  </PlutofPanel::Simple>
  
  */
  {
    "id": "7lxudekw",
    "block": "{\"symbols\":[\"@filterInputs\"],\"statements\":[[8,\"plutof-panel/simple\",[],[[\"@title\",\"@collapse\"],[[30,[36,0],[\"Search.panelTaxon\"],null],true]],[[\"default\"],[{\"statements\":[[2,\"\\n    \"],[10,\"div\"],[12],[2,\"\\n        \"],[8,\"layout/regular-grid\",[],[[\"@columns\"],[6]],[[\"default\"],[{\"statements\":[[2,\"\\n            \"],[8,\"search/filter-input\",[],[[\"@filter\"],[[32,1,[\"typification\"]]]],null],[2,\"\\n            \"],[8,\"search/filter-input\",[],[[\"@filter\"],[[32,1,[\"interacting_taxa\"]]]],null],[2,\"\\n        \"]],\"parameters\":[]}]]],[2,\"\\n\\n        \"],[8,\"layout/regular-grid\",[],[[\"@columns\"],[6]],[[\"default\"],[{\"statements\":[[2,\"\\n            \"],[8,\"search/filter-input\",[],[[\"@filter\"],[[32,1,[\"has_interactions\"]]]],null],[2,\"\\n            \"],[8,\"search/filter-input\",[],[[\"@filter\"],[[32,1,[\"interacting_lineage\"]]]],null],[2,\"\\n        \"]],\"parameters\":[]}]]],[2,\"\\n    \"],[13],[2,\"\\n\"]],\"parameters\":[]}]]],[2,\"\\n\"]],\"hasEval\":false,\"upvars\":[\"i18n-t\"]}",
    "moduleName": "plutof/components/search/filter-panels/material-sample-taxon.hbs"
  });
  var _default = _exports.default = Ember._setComponentTemplate(__COLOCATED_TEMPLATE__, Ember._templateOnlyComponent());
});