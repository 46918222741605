define("plutof/components/annotation/-summary/value", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const __COLOCATED_TEMPLATE__ = Ember.HTMLBars.template(
  /*
    {{#if (not @value)}}
      ∅
  {{else if (is-equal @kind "attribute")}}
      {{@value}}
  {{else if (is-equal @kind "belongsTo")}}
      {{@value.representation}}
  {{else}}
      <Common::RecordListView @records={{@value}} as |related|>
          {{related.representation}}
      </Common::RecordListView>
  {{/if}}
  
  */
  {
    "id": "i0HKykdI",
    "block": "{\"symbols\":[\"related\",\"@value\",\"@kind\"],\"statements\":[[6,[37,1],[[30,[36,2],[[32,2]],null]],null,[[\"default\",\"else\"],[{\"statements\":[[2,\"    ∅\\n\"]],\"parameters\":[]},{\"statements\":[[6,[37,1],[[30,[36,0],[[32,3],\"attribute\"],null]],null,[[\"default\",\"else\"],[{\"statements\":[[2,\"    \"],[1,[32,2]],[2,\"\\n\"]],\"parameters\":[]},{\"statements\":[[6,[37,1],[[30,[36,0],[[32,3],\"belongsTo\"],null]],null,[[\"default\",\"else\"],[{\"statements\":[[2,\"    \"],[1,[32,2,[\"representation\"]]],[2,\"\\n\"]],\"parameters\":[]},{\"statements\":[[2,\"    \"],[8,\"common/record-list-view\",[],[[\"@records\"],[[32,2]]],[[\"default\"],[{\"statements\":[[2,\"\\n        \"],[1,[32,1,[\"representation\"]]],[2,\"\\n    \"]],\"parameters\":[1]}]]],[2,\"\\n\"]],\"parameters\":[]}]]]],\"parameters\":[]}]]]],\"parameters\":[]}]]]],\"hasEval\":false,\"upvars\":[\"is-equal\",\"if\",\"not\"]}",
    "moduleName": "plutof/components/annotation/-summary/value.hbs"
  });
  var _default = _exports.default = Ember._setComponentTemplate(__COLOCATED_TEMPLATE__, Ember._templateOnlyComponent());
});