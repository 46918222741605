define("plutof/templates/components/occurrences-table/-responsive-row/expand-label", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = Ember.HTMLBars.template({
    "id": "tOAvOdQH",
    "block": "{\"symbols\":[\"@toggle\"],\"statements\":[[10,\"div\"],[14,0,\"occ-table-responsive-row__expand-label-label\"],[12],[2,\"\\n    \"],[1,[30,[36,0],null,[[\"path\"],[\"referencebased.occurrence.taxon_node\"]]]],[2,\"\\n\"],[13],[2,\"\\n\\n\"],[11,\"button\"],[24,0,\"btn-fake-link occ-table-responsive-row__expand-label-button\"],[4,[38,1],[[32,0],[32,1]],null],[12],[2,\"\\n    \"],[10,\"span\"],[14,0,\"fas fa-ellipsis-h\"],[12],[13],[2,\"\\n\"],[13],[2,\"\\n\"]],\"hasEval\":false,\"upvars\":[\"plutof-labelc\",\"action\"]}",
    "moduleName": "plutof/templates/components/occurrences-table/-responsive-row/expand-label.hbs"
  });
});