define("plutof/components/agent/user/auto-complete", ["exports", "@glimmer/component", "ember-concurrency", "plutof/components/auto-complete/backends"], function (_exports, _component, _emberConcurrency, _backends) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _dec, _dec2, _dec3, _dec4, _class, _descriptor, _descriptor2, _descriptor3, _descriptor4;
  function _initializerDefineProperty(e, i, r, l) { r && Object.defineProperty(e, i, { enumerable: r.enumerable, configurable: r.configurable, writable: r.writable, value: r.initializer ? r.initializer.call(l) : void 0 }); }
  function _defineProperty(e, r, t) { return (r = _toPropertyKey(r)) in e ? Object.defineProperty(e, r, { value: t, enumerable: !0, configurable: !0, writable: !0 }) : e[r] = t, e; }
  function _toPropertyKey(t) { var i = _toPrimitive(t, "string"); return "symbol" == typeof i ? i : i + ""; }
  function _toPrimitive(t, r) { if ("object" != typeof t || !t) return t; var e = t[Symbol.toPrimitive]; if (void 0 !== e) { var i = e.call(t, r || "default"); if ("object" != typeof i) return i; throw new TypeError("@@toPrimitive must return a primitive value."); } return ("string" === r ? String : Number)(t); }
  function _applyDecoratedDescriptor(i, e, r, n, l) { var a = {}; return Object.keys(n).forEach(function (i) { a[i] = n[i]; }), a.enumerable = !!a.enumerable, a.configurable = !!a.configurable, ("value" in a || a.initializer) && (a.writable = !0), a = r.slice().reverse().reduce(function (r, n) { return n(i, e, r) || r; }, a), l && void 0 !== a.initializer && (a.value = a.initializer ? a.initializer.call(l) : void 0, a.initializer = void 0), void 0 === a.initializer ? (Object.defineProperty(i, e, a), null) : a; }
  function _initializerWarningHelper(r, e) { throw Error("Decorating class property failed. Please ensure that transform-class-properties is enabled and runs after the decorators transform."); }
  const __COLOCATED_TEMPLATE__ = Ember.HTMLBars.template(
  /*
    <AutoComplete::Base
      @backend={{this.backend}}
      @value={{@value}}
      @update={{@update}}
      @params={{merge-params @params (hash
          sideAction=(unless @noDefault (perform this.selectDefault))
          sideActionIcon=(icon "user")
      )}}
      @disabled={{@disabled}}
      ...attributes
  >
      <:result as |result context|>
          <Agent::-AutoCompleteItem
              @result={{result}}
              @context={{context}} />
      </:result>
  </AutoComplete::Base>
  
  */
  {
    "id": "5RirggGc",
    "block": "{\"symbols\":[\"__arg0\",\"__arg1\",\"__arg2\",\"result\",\"context\",\"@value\",\"@update\",\"@noDefault\",\"@params\",\"@disabled\",\"&attrs\"],\"statements\":[[8,\"auto-complete/base\",[[17,11]],[[\"@backend\",\"@value\",\"@update\",\"@params\",\"@disabled\",\"@namedBlocksInfo\"],[[32,0,[\"backend\"]],[32,6],[32,7],[30,[36,5],[[32,9],[30,[36,4],null,[[\"sideAction\",\"sideActionIcon\"],[[30,[36,3],[[32,8],[30,[36,2],[[32,0,[\"selectDefault\"]]],null]],null],[30,[36,1],[\"user\"],null]]]]],null],[32,10],[30,[36,4],null,[[\"result\"],[2]]]]],[[\"default\"],[{\"statements\":[[6,[37,7],[[30,[36,6],[[32,1],\"result\"],null]],null,[[\"default\"],[{\"statements\":[[6,[37,0],[[32,2],[32,3]],null,[[\"default\"],[{\"statements\":[[2,\"\\n        \"],[8,\"agent/-auto-complete-item\",[],[[\"@result\",\"@context\"],[[32,4],[32,5]]],null],[2,\"\\n    \"]],\"parameters\":[4,5]}]]]],\"parameters\":[]}]]]],\"parameters\":[1,2,3]}]]],[2,\"\\n\"]],\"hasEval\":false,\"upvars\":[\"let\",\"icon\",\"perform\",\"unless\",\"hash\",\"merge-params\",\"-is-named-block-invocation\",\"if\"]}",
    "moduleName": "plutof/components/agent/user/auto-complete.hbs"
  });
  let UserAutoComplete = _exports.default = (_dec = Ember.inject.service, _dec2 = Ember.inject.service, _dec3 = Ember.inject.service, _dec4 = (0, _emberConcurrency.task)({
    restartable: true
  }), _class = class UserAutoComplete extends _component.default {
    constructor(...args) {
      super(...args);
      _initializerDefineProperty(this, "ajax", _descriptor, this);
      _initializerDefineProperty(this, "settings", _descriptor2, this);
      _initializerDefineProperty(this, "store", _descriptor3, this);
      _defineProperty(this, "backend", new _backends.ModelAutocomplete({
        ajax: this.ajax,
        store: this.store,
        modelName: 'users/user'
      }));
      _initializerDefineProperty(this, "selectDefault", _descriptor4, this);
    }
  }, _descriptor = _applyDecoratedDescriptor(_class.prototype, "ajax", [_dec], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor2 = _applyDecoratedDescriptor(_class.prototype, "settings", [_dec2], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor3 = _applyDecoratedDescriptor(_class.prototype, "store", [_dec3], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor4 = _applyDecoratedDescriptor(_class.prototype, "selectDefault", [_dec4], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return function* (update) {
        const person = yield this.settings.getUser();
        this.args.update(person);
      };
    }
  }), _class);
  Ember._setComponentTemplate(__COLOCATED_TEMPLATE__, UserAutoComplete);
});