define("plutof/components/unite/determinations/detail-field", ["exports", "@glimmer/component", "plutof/utils/validations", "plutof/utils/formatters"], function (_exports, _component, _validations, _formatters) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _dec, _dec2, _dec3, _class, _descriptor, _descriptor2;
  function _initializerDefineProperty(e, i, r, l) { r && Object.defineProperty(e, i, { enumerable: r.enumerable, configurable: r.configurable, writable: r.writable, value: r.initializer ? r.initializer.call(l) : void 0 }); }
  function _defineProperty(e, r, t) { return (r = _toPropertyKey(r)) in e ? Object.defineProperty(e, r, { value: t, enumerable: !0, configurable: !0, writable: !0 }) : e[r] = t, e; }
  function _toPropertyKey(t) { var i = _toPrimitive(t, "string"); return "symbol" == typeof i ? i : i + ""; }
  function _toPrimitive(t, r) { if ("object" != typeof t || !t) return t; var e = t[Symbol.toPrimitive]; if (void 0 !== e) { var i = e.call(t, r || "default"); if ("object" != typeof i) return i; throw new TypeError("@@toPrimitive must return a primitive value."); } return ("string" === r ? String : Number)(t); }
  function _initializerWarningHelper(r, e) { throw Error("Decorating class property failed. Please ensure that transform-class-properties is enabled and runs after the decorators transform."); }
  function _applyDecoratedDescriptor(i, e, r, n, l) { var a = {}; return Object.keys(n).forEach(function (i) { a[i] = n[i]; }), a.enumerable = !!a.enumerable, a.configurable = !!a.configurable, ("value" in a || a.initializer) && (a.writable = !0), a = r.slice().reverse().reduce(function (r, n) { return n(i, e, r) || r; }, a), l && void 0 !== a.initializer && (a.value = a.initializer ? a.initializer.call(l) : void 0, a.initializer = void 0), void 0 === a.initializer ? (Object.defineProperty(i, e, a), null) : a; }
  const __COLOCATED_TEMPLATE__ = Ember.HTMLBars.template(
  /*
    <div class={{unless this.isValid "has-error"}}>
      {{common/local-label @field.label}}
  
      <OneWayInput
          @type="text"
          @value={{get @details @field.field}}
          @update={{this.update}}
          @class="form-control" />
  </div>
  
  */
  {
    "id": "Ix8YKj4E",
    "block": "{\"symbols\":[\"@field\",\"@details\"],\"statements\":[[10,\"div\"],[15,0,[30,[36,0],[[32,0,[\"isValid\"]],\"has-error\"],null]],[12],[2,\"\\n    \"],[1,[30,[36,1],[[32,1,[\"label\"]]],null]],[2,\"\\n\\n    \"],[8,\"one-way-input\",[],[[\"@type\",\"@value\",\"@update\",\"@class\"],[\"text\",[30,[36,2],[[32,2],[32,1,[\"field\"]]],null],[32,0,[\"update\"]],\"form-control\"]],null],[2,\"\\n\"],[13],[2,\"\\n\"]],\"hasEval\":false,\"upvars\":[\"unless\",\"common/local-label\",\"get\"]}",
    "moduleName": "plutof/components/unite/determinations/detail-field.hbs"
  });
  // XXX: Only numbers for now
  let UniteIdentificationDetailField = (_dec = Ember._action, _dec2 = Ember._tracked, _dec3 = Ember.computed.reads('valid'), _class = class UniteIdentificationDetailField extends _component.default {
    constructor(...args) {
      super(...args);
      _initializerDefineProperty(this, "valid", _descriptor, this);
      _initializerDefineProperty(this, "isValid", _descriptor2, this);
    }
    update(value) {
      Ember.set(this.args.details, this.args.field.field, value);
      this.valid = Ember.isEmpty(value) || _formatters.DECIMAL_REGEX.test(value);
    }
  }, _applyDecoratedDescriptor(_class.prototype, "update", [_dec], Object.getOwnPropertyDescriptor(_class.prototype, "update"), _class.prototype), _descriptor = _applyDecoratedDescriptor(_class.prototype, "valid", [_dec2], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return true;
    }
  }), _descriptor2 = _applyDecoratedDescriptor(_class.prototype, "isValid", [_validations.validator, _dec3], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _class);
  var _default = _exports.default = Ember._setComponentTemplate(__COLOCATED_TEMPLATE__, UniteIdentificationDetailField);
});