define("plutof/templates/components/study/occurrence-statistics", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = Ember.HTMLBars.template({
    "id": "X6TTzADx",
    "block": "{\"symbols\":[\"panel\"],\"statements\":[[8,\"plutof-panel/item-list\",[[24,\"data-test\",\"Project::OccurrenceStatistics\"]],[[\"@collapse\"],[[30,[36,0],[[32,0,[\"open\"]]],null]]],[[\"default\"],[{\"statements\":[[2,\"\\n    \"],[8,[32,1,[\"header\"]],[],[[\"@title\"],[[30,[36,1],[\"General.Statistics\"],null]]],[[\"default\"],[{\"statements\":[[2,\"\\n        \"],[8,[32,1,[\"button\"]],[],[[\"@clicked\"],[[30,[36,3],[[32,0],[35,2]],null]]],[[\"default\"],[{\"statements\":[[2,\"\\n            \"],[10,\"span\"],[14,0,\"icon-reset\"],[12],[13],[2,\"\\n\\n            \"],[1,[30,[36,1],[\"General.Reset\"],null]],[2,\"\\n        \"]],\"parameters\":[]}]]],[2,\"\\n    \"]],\"parameters\":[]}]]],[2,\"\\n\\n    \"],[8,[32,1,[\"content\"]],[],[[],[]],[[\"default\"],[{\"statements\":[[2,\"\\n        \"],[8,\"layout/regular-grid\",[],[[\"@columns\"],[2]],[[\"default\"],[{\"statements\":[[2,\"\\n            \"],[10,\"div\"],[14,0,\"chart-container\"],[12],[2,\"\\n                \"],[1,[30,[36,5],null,[[\"config\",\"resetChart\"],[[35,4],[30,[36,3],[[32,0],\"resetChart\"],null]]]]],[2,\"\\n            \"],[13],[2,\"\\n\\n            \"],[10,\"div\"],[14,0,\"chart-container\"],[12],[2,\"\\n                \"],[1,[30,[36,5],null,[[\"config\"],[[35,6]]]]],[2,\"\\n            \"],[13],[2,\"\\n        \"]],\"parameters\":[]}]]],[2,\"\\n    \"]],\"parameters\":[]}]]],[2,\"\\n\"]],\"parameters\":[1]}]]],[2,\"\\n\"]],\"hasEval\":false,\"upvars\":[\"not\",\"i18n-t\",\"resetChart\",\"action\",\"timespanBeginConfig\",\"chart/histogram-chart\",\"createdAtConfig\"]}",
    "moduleName": "plutof/templates/components/study/occurrence-statistics.hbs"
  });
});