define("plutof/components/forms/access", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const __COLOCATED_TEMPLATE__ = Ember.HTMLBars.template(
  /*
    <PlutofPanel::Simple @title={{i18n-t "General.access"}}>
      {{common/local-label "Access.publicLinkingLabel" "Access.publicLinkingHint"}}
  
      <Access::Edit::PublicOrPrivate
          @value={{@mainform.public_linking}}
          @update={{action (mut @mainform.public_linking)}} />
  </PlutofPanel::Simple>
  
  */
  {
    "id": "ZxFJqsXh",
    "block": "{\"symbols\":[\"@mainform\"],\"statements\":[[8,\"plutof-panel/simple\",[],[[\"@title\"],[[30,[36,0],[\"General.access\"],null]]],[[\"default\"],[{\"statements\":[[2,\"\\n    \"],[1,[30,[36,1],[\"Access.publicLinkingLabel\",\"Access.publicLinkingHint\"],null]],[2,\"\\n\\n    \"],[8,\"access/edit/public-or-private\",[],[[\"@value\",\"@update\"],[[32,1,[\"public_linking\"]],[30,[36,3],[[32,0],[30,[36,2],[[32,1,[\"public_linking\"]]],null]],null]]],null],[2,\"\\n\"]],\"parameters\":[]}]]],[2,\"\\n\"]],\"hasEval\":false,\"upvars\":[\"i18n-t\",\"common/local-label\",\"mut\",\"action\"]}",
    "moduleName": "plutof/components/forms/access.hbs"
  });
  var _default = _exports.default = Ember._setComponentTemplate(__COLOCATED_TEMPLATE__, Ember._templateOnlyComponent());
});