define("plutof/templates/components/referencebased/bio-status", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = Ember.HTMLBars.template({
    "id": "36mD4x1V",
    "block": "{\"symbols\":[\"field\",\"@biostatus\",\"&default\"],\"statements\":[[6,[37,5],[[30,[36,4],[[30,[36,4],[[32,0,[\"fields\"]]],null]],null]],null,[[\"default\"],[{\"statements\":[[2,\"    \"],[18,3,[[30,[36,3],null,[[\"name\",\"label\",\"select\"],[[32,1,[\"name\"]],[30,[36,2],[\"plutof-labelc\"],[[\"path\"],[[32,1,[\"labelPath\"]]]]],[30,[36,2],[\"plutof-select/value\"],[[\"content\",\"oneWay\",\"value\",\"selectionChanged\"],[[32,1,[\"choices\"]],true,[30,[36,1],[[32,2],[32,1,[\"name\"]]],null],[30,[36,0],[[32,0,[\"setField\"]],[32,1]],null]]]]]]]]],[2,\"\\n\"]],\"parameters\":[1]}]]]],\"hasEval\":false,\"upvars\":[\"fn\",\"get\",\"component\",\"hash\",\"-track-array\",\"each\"]}",
    "moduleName": "plutof/templates/components/referencebased/bio-status.hbs"
  });
});