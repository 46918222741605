define("plutof/templates/components/search/pagination-controls", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = Ember.HTMLBars.template({
    "id": "gcSwSccd",
    "block": "{\"symbols\":[\"t\",\"@disabled\",\"@maxPageSize\",\"@pagination\",\"@removeSpacing\",\"@maxPages\"],\"statements\":[[10,\"div\"],[15,0,[31,[[30,[36,1],[[32,0,[\"pageSizeIsInvalid\"]],\"has-error\"],null]]]],[12],[2,\"\\n    \"],[10,\"div\"],[14,0,\"table-control__pagination__page-size\"],[12],[2,\"\\n\"],[6,[37,2],null,null,[[\"default\"],[{\"statements\":[[2,\"            \"],[8,\"input\",[[24,0,\"input-xs table-control__pagination__page-size__input\"]],[[\"@enter\",\"@value\",\"@placeholder\",\"@disabled\"],[[32,0,[\"_updatePageSize\"]],[32,0,[\"pageSize\"]],[30,[36,0],[\"Search.resultsPerPage\"],null],[32,2]]],null],[2,\"\\n\\n\"],[2,\"            \"],[10,\"span\"],[15,1,[31,[[32,1,[\"tid\"]]]]],[14,0,\"wrapper-tooltip\"],[12],[2,\"\\n                \"],[1,[30,[36,0],[\"Search.pageSizeToolip\"],null]],[2,\"\\n\\n                (<=\"],[1,[32,3]],[2,\")\\n            \"],[13],[2,\"\\n\"]],\"parameters\":[1]}]]],[2,\"\\n        \"],[10,\"div\"],[12],[2,\"\\n            \"],[11,\"button\"],[16,\"disabled\",[30,[36,3],[[32,0,[\"pageSizeIsInvalid\"]],[32,2]],null]],[24,0,\"btn btn-xs btn-default plutof-btn-green\"],[4,[38,4],[\"click\",[32,0,[\"_updatePageSize\"]]],null],[12],[2,\"\\n                \"],[10,\"span\"],[15,0,[30,[36,1],[[32,4,[\"isLoading\"]],\"loading-icon\",\"icon-search\"],null]],[12],[13],[2,\"\\n            \"],[13],[2,\"\\n        \"],[13],[2,\"\\n    \"],[13],[2,\"\\n\"],[13],[2,\"\\n\\n\"],[8,\"pagination/page-controls\",[],[[\"@pagination\",\"@removeSpacing\",\"@alwaysShowInterface\",\"@maxPages\"],[[32,4],[32,5],true,[32,6]]],null],[2,\"\\n\"]],\"hasEval\":false,\"upvars\":[\"i18n-t\",\"if\",\"tooltip-wrapper\",\"or\",\"on\"]}",
    "moduleName": "plutof/templates/components/search/pagination-controls.hbs"
  });
});