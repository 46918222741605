define("plutof/templates/components/profile/groups/-user-groups-row", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = Ember.HTMLBars.template({
    "id": "N1vTP7as",
    "block": "{\"symbols\":[\"@membership\"],\"statements\":[[10,\"td\"],[12],[2,\"\\n\"],[6,[37,1],[[32,0,[\"isUnverified\"]]],null,[[\"default\",\"else\"],[{\"statements\":[[2,\"        \"],[1,[32,1,[\"group\",\"representation\"]]],[2,\"\\n\"]],\"parameters\":[]},{\"statements\":[[2,\"        \"],[6,[37,2],null,[[\"route\",\"model\"],[\"profile.groups.group\",[32,0,[\"groupID\"]]]],[[\"default\"],[{\"statements\":[[1,[32,1,[\"group_name\"]]]],\"parameters\":[]}]]],[2,\"\\n\\n\"],[6,[37,1],[[32,0,[\"canModerate\"]]],null,[[\"default\"],[{\"statements\":[[6,[37,1],[[32,1,[\"group\",\"users_waiting_approval\"]]],null,[[\"default\"],[{\"statements\":[[2,\"                \"],[10,\"span\"],[14,0,\"user-pending-approval\"],[12],[2,\"\\n                    \"],[10,\"span\"],[14,0,\"fas fa-exclamation-triangle\"],[12],[13],[2,\"\\n\\n                    \"],[1,[30,[36,0],[\"Settings.groups.requestsPending\"],[[\"count\"],[[32,1,[\"group\",\"users_waiting_approval\"]]]]]],[2,\"\\n                \"],[13],[2,\"\\n\"]],\"parameters\":[]}]]]],\"parameters\":[]}]]]],\"parameters\":[]}]]],[13],[2,\"\\n\\n\"],[10,\"td\"],[12],[2,\"\\n    \"],[1,[32,1,[\"rightsDisplayName\"]]],[2,\"\\n\"],[13],[2,\"\\n\\n\"],[10,\"td\"],[12],[2,\"\\n    \"],[1,[32,1,[\"statusDisplayName\"]]],[2,\"\\n\"],[13],[2,\"\\n\"]],\"hasEval\":false,\"upvars\":[\"i18n-t\",\"if\",\"link-to\"]}",
    "moduleName": "plutof/templates/components/profile/groups/-user-groups-row.hbs"
  });
});