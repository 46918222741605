define("plutof/components/generic-views/-record/link", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  function _defineProperty(e, r, t) { return (r = _toPropertyKey(r)) in e ? Object.defineProperty(e, r, { value: t, enumerable: !0, configurable: !0, writable: !0 }) : e[r] = t, e; }
  function _toPropertyKey(t) { var i = _toPrimitive(t, "string"); return "symbol" == typeof i ? i : i + ""; }
  function _toPrimitive(t, r) { if ("object" != typeof t || !t) return t; var e = t[Symbol.toPrimitive]; if (void 0 !== e) { var i = e.call(t, r || "default"); if ("object" != typeof i) return i; throw new TypeError("@@toPrimitive must return a primitive value."); } return ("string" === r ? String : Number)(t); }
  const __COLOCATED_TEMPLATE__ = Ember.HTMLBars.template(
  /*
    {{#generic-views/-record/attr this.field context=@context label=@label}}
      {{#let (get @context.record this.field) as |value|}}
          {{#if value.id}}
              {{#link-to @route value.id}}
                  {{value.representation}}
              {{/link-to}}
          {{/if}}
      {{/let}}
  {{/generic-views/-record/attr}}
  
  */
  {
    "id": "139WcdNH",
    "block": "{\"symbols\":[\"value\",\"@route\",\"@context\",\"@label\"],\"statements\":[[6,[37,4],[[32,0,[\"field\"]]],[[\"context\",\"label\"],[[32,3],[32,4]]],[[\"default\"],[{\"statements\":[[6,[37,3],[[30,[36,2],[[32,3,[\"record\"]],[32,0,[\"field\"]]],null]],null,[[\"default\"],[{\"statements\":[[6,[37,1],[[32,1,[\"id\"]]],null,[[\"default\"],[{\"statements\":[[6,[37,0],null,[[\"route\",\"model\"],[[32,2],[32,1,[\"id\"]]]],[[\"default\"],[{\"statements\":[[2,\"                \"],[1,[32,1,[\"representation\"]]],[2,\"\\n\"]],\"parameters\":[]}]]]],\"parameters\":[]}]]]],\"parameters\":[1]}]]]],\"parameters\":[]}]]]],\"hasEval\":false,\"upvars\":[\"link-to\",\"if\",\"get\",\"let\",\"generic-views/-record/attr\"]}",
    "moduleName": "plutof/components/generic-views/-record/link.hbs"
  });
  // TODO: Ideally, we'd want to derive route from value, but atm we key modules by
  // content type
  class GenericRecordViewLink extends Ember.Component {}
  _exports.default = GenericRecordViewLink;
  _defineProperty(GenericRecordViewLink, "positionalParams", ['field']);
  Ember._setComponentTemplate(__COLOCATED_TEMPLATE__, GenericRecordViewLink);
});