define("plutof/components/access/-view-rights/view-public", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const __COLOCATED_TEMPLATE__ = Ember.HTMLBars.template(
  /*
    {{#access/-view-rights/column}}
      {{#if @linkingEnabled}}
          <div class="plutof-tooltip-hover">
              <div class="plutof-label">
                  {{i18n-t "Access.canView"}}&#47;{{i18n-t "Access.publicLinkingLabel"}}
              </div>
  
              <div class="plutof-tooltip">
                  {{i18n-t "Access.publicLinkingHint"}}
              </div>
          </div>
      {{else}}
          <div class="plutof-label">
              {{i18n-t "Access.canView"}}
          </div>
      {{/if}}
  
      <p>
          <span class="icon-access-public"></span>
          {{i18n-t "Access.public"}}
  
          {{! template-lint-disable block-indentation }}
          {{#if @linkingEnabled}}
              &#47;
  
              {{#if @linkingAllowed}}
                  <span class={{icon "public"}}></span>
                  {{i18n-t "Access.public"}}
              {{else}}
                  <span class={{icon "prviate"}}></span>
                  {{i18n-t "Access.private"}}
              {{/if}}
          {{/if}}
      </p>
  {{/access/-view-rights/column}}
  
  */
  {
    "id": "zGNq9JGA",
    "block": "{\"symbols\":[\"@linkingAllowed\",\"@linkingEnabled\"],\"statements\":[[6,[37,3],null,null,[[\"default\"],[{\"statements\":[[6,[37,2],[[32,2]],null,[[\"default\",\"else\"],[{\"statements\":[[2,\"        \"],[10,\"div\"],[14,0,\"plutof-tooltip-hover\"],[12],[2,\"\\n            \"],[10,\"div\"],[14,0,\"plutof-label\"],[12],[2,\"\\n                \"],[1,[30,[36,1],[\"Access.canView\"],null]],[2,\"/\"],[1,[30,[36,1],[\"Access.publicLinkingLabel\"],null]],[2,\"\\n            \"],[13],[2,\"\\n\\n            \"],[10,\"div\"],[14,0,\"plutof-tooltip\"],[12],[2,\"\\n                \"],[1,[30,[36,1],[\"Access.publicLinkingHint\"],null]],[2,\"\\n            \"],[13],[2,\"\\n        \"],[13],[2,\"\\n\"]],\"parameters\":[]},{\"statements\":[[2,\"        \"],[10,\"div\"],[14,0,\"plutof-label\"],[12],[2,\"\\n            \"],[1,[30,[36,1],[\"Access.canView\"],null]],[2,\"\\n        \"],[13],[2,\"\\n\"]],\"parameters\":[]}]]],[2,\"\\n    \"],[10,\"p\"],[12],[2,\"\\n        \"],[10,\"span\"],[14,0,\"icon-access-public\"],[12],[13],[2,\"\\n        \"],[1,[30,[36,1],[\"Access.public\"],null]],[2,\"\\n\\n\"],[6,[37,2],[[32,2]],null,[[\"default\"],[{\"statements\":[[2,\"            /\\n\\n\"],[6,[37,2],[[32,1]],null,[[\"default\",\"else\"],[{\"statements\":[[2,\"                \"],[10,\"span\"],[15,0,[30,[36,0],[\"public\"],null]],[12],[13],[2,\"\\n                \"],[1,[30,[36,1],[\"Access.public\"],null]],[2,\"\\n\"]],\"parameters\":[]},{\"statements\":[[2,\"                \"],[10,\"span\"],[15,0,[30,[36,0],[\"prviate\"],null]],[12],[13],[2,\"\\n                \"],[1,[30,[36,1],[\"Access.private\"],null]],[2,\"\\n\"]],\"parameters\":[]}]]]],\"parameters\":[]}]]],[2,\"    \"],[13],[2,\"\\n\"]],\"parameters\":[]}]]]],\"hasEval\":false,\"upvars\":[\"icon\",\"i18n-t\",\"if\",\"access/-view-rights/column\"]}",
    "moduleName": "plutof/components/access/-view-rights/view-public.hbs"
  });
  var _default = _exports.default = Ember._setComponentTemplate(__COLOCATED_TEMPLATE__, Ember._templateOnlyComponent());
});