define("plutof/components/dmp/dataset-identifiers", ["exports", "plutof/misc/options-getter"], function (_exports, _optionsGetter) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _dec, _dec2, _dec3, _dec4, _class, _descriptor, _descriptor2;
  function _initializerDefineProperty(e, i, r, l) { r && Object.defineProperty(e, i, { enumerable: r.enumerable, configurable: r.configurable, writable: r.writable, value: r.initializer ? r.initializer.call(l) : void 0 }); }
  function _defineProperty(e, r, t) { return (r = _toPropertyKey(r)) in e ? Object.defineProperty(e, r, { value: t, enumerable: !0, configurable: !0, writable: !0 }) : e[r] = t, e; }
  function _toPropertyKey(t) { var i = _toPrimitive(t, "string"); return "symbol" == typeof i ? i : i + ""; }
  function _toPrimitive(t, r) { if ("object" != typeof t || !t) return t; var e = t[Symbol.toPrimitive]; if (void 0 !== e) { var i = e.call(t, r || "default"); if ("object" != typeof i) return i; throw new TypeError("@@toPrimitive must return a primitive value."); } return ("string" === r ? String : Number)(t); }
  function _applyDecoratedDescriptor(i, e, r, n, l) { var a = {}; return Object.keys(n).forEach(function (i) { a[i] = n[i]; }), a.enumerable = !!a.enumerable, a.configurable = !!a.configurable, ("value" in a || a.initializer) && (a.writable = !0), a = r.slice().reverse().reduce(function (r, n) { return n(i, e, r) || r; }, a), l && void 0 !== a.initializer && (a.value = a.initializer ? a.initializer.call(l) : void 0, a.initializer = void 0), void 0 === a.initializer ? (Object.defineProperty(i, e, a), null) : a; }
  function _initializerWarningHelper(r, e) { throw Error("Decorating class property failed. Please ensure that transform-class-properties is enabled and runs after the decorators transform."); }
  const __COLOCATED_TEMPLATE__ = Ember.HTMLBars.template(
  /*
    <AddMultipleForm @objects={{@dataset.ids}} as |adder|>
      <div class="add-component">
          <adder.column @path="dmp.datasetID.identifier">
              <Input
                  @type="text"
                  @value={{this.newIdentifier}}
                  @enter={{this.add}}
                  @class="form-control control" />
          </adder.column>
  
          <adder.column @path="dmp.datasetID.type">
              <PlutofSelect::Value
                  @content={{this.idTypes}}
                  @value={{this.newType}}
                  @selectClass="form-control control" />
          </adder.column>
  
          <adder.addButton @add={{this.add}} @disable={{this.disableAddButton}} />
      </div>
  
      <Common::RecordList @records={{@dataset.ids}} @remove={{adder.remove}} as |record|>
          {{record.identifier}} ({{record.type}})
      </Common::RecordList>
  </AddMultipleForm>
  
  */
  {
    "id": "axYg+Hlu",
    "block": "{\"symbols\":[\"adder\",\"record\",\"@dataset\"],\"statements\":[[8,\"add-multiple-form\",[],[[\"@objects\"],[[32,3,[\"ids\"]]]],[[\"default\"],[{\"statements\":[[2,\"\\n    \"],[10,\"div\"],[14,0,\"add-component\"],[12],[2,\"\\n        \"],[8,[32,1,[\"column\"]],[],[[\"@path\"],[\"dmp.datasetID.identifier\"]],[[\"default\"],[{\"statements\":[[2,\"\\n            \"],[8,\"input\",[],[[\"@type\",\"@value\",\"@enter\",\"@class\"],[\"text\",[32,0,[\"newIdentifier\"]],[32,0,[\"add\"]],\"form-control control\"]],null],[2,\"\\n        \"]],\"parameters\":[]}]]],[2,\"\\n\\n        \"],[8,[32,1,[\"column\"]],[],[[\"@path\"],[\"dmp.datasetID.type\"]],[[\"default\"],[{\"statements\":[[2,\"\\n            \"],[8,\"plutof-select/value\",[],[[\"@content\",\"@value\",\"@selectClass\"],[[32,0,[\"idTypes\"]],[32,0,[\"newType\"]],\"form-control control\"]],null],[2,\"\\n        \"]],\"parameters\":[]}]]],[2,\"\\n\\n        \"],[8,[32,1,[\"addButton\"]],[],[[\"@add\",\"@disable\"],[[32,0,[\"add\"]],[32,0,[\"disableAddButton\"]]]],null],[2,\"\\n    \"],[13],[2,\"\\n\\n    \"],[8,\"common/record-list\",[],[[\"@records\",\"@remove\"],[[32,3,[\"ids\"]],[32,1,[\"remove\"]]]],[[\"default\"],[{\"statements\":[[2,\"\\n        \"],[1,[32,2,[\"identifier\"]]],[2,\" (\"],[1,[32,2,[\"type\"]]],[2,\")\\n    \"]],\"parameters\":[2]}]]],[2,\"\\n\"]],\"parameters\":[1]}]]],[2,\"\\n\"]],\"hasEval\":false,\"upvars\":[]}",
    "moduleName": "plutof/components/dmp/dataset-identifiers.hbs"
  });
  let DMPDatasetIdentifiers = (_dec = Ember.inject.service, _dec2 = (0, _optionsGetter.getDMPChoices)('dataset.properties.ids.items.properties.type'), _dec3 = Ember.computed('newIdentifier', 'newType'), _dec4 = Ember._action, _class = class DMPDatasetIdentifiers extends Ember.Component {
    constructor(...args) {
      super(...args);
      _initializerDefineProperty(this, "ajax", _descriptor, this);
      _defineProperty(this, "newIdentifier", '');
      _defineProperty(this, "newType", null);
      _initializerDefineProperty(this, "idTypes", _descriptor2, this);
    }
    get disableAddButton() {
      return Ember.isEmpty(this.newIdentifier.trim()) || Ember.isEmpty(this.newType);
    }
    add() {
      this.dataset.ids.pushObject({
        identifier: this.newIdentifier,
        type: this.newType
      });
      this.setProperties({
        newIdentifier: ''
      });
    }
  }, _descriptor = _applyDecoratedDescriptor(_class.prototype, "ajax", [_dec], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor2 = _applyDecoratedDescriptor(_class.prototype, "idTypes", [_dec2], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _applyDecoratedDescriptor(_class.prototype, "disableAddButton", [_dec3], Object.getOwnPropertyDescriptor(_class.prototype, "disableAddButton"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "add", [_dec4], Object.getOwnPropertyDescriptor(_class.prototype, "add"), _class.prototype), _class);
  var _default = _exports.default = Ember._setComponentTemplate(__COLOCATED_TEMPLATE__, DMPDatasetIdentifiers);
});