define("plutof/components/conservation/red-list-assessment/view-permissions", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const __COLOCATED_TEMPLATE__ = Ember.HTMLBars.template(
  /*
    <PlutofPanel::Simple @title={{i18n-t "General.access"}}>
      <Access::ViewRightsBase as |vr|>
          <vr.panel>
              {{#resolve-promise @assessment.red_list as |redlist|}}
                  {{#if redlist}}
                      <vr.derivedFrom @parent={{hash
                          type="redbook/red-list"
                          id=redlist.id
                          name=redlist.representation
                      }} />
  
                      <vr.view.public />
  
                      {{#resolve-promise redlist.workgroup as |workgroup|}}
                          <vr.edit.selected @groups={{list workgroup}} />
                      {{/resolve-promise}}
                  {{else}}
                      <vr.view.public />
                  {{/if}}
              {{/resolve-promise}}
          </vr.panel>
  
          <vr.panel>
              <vr.history
                  @createdBy="plutof"
                  @createdAt={{@assessment.created}}
                  @updatedBy={{@assessment.modified_by.representation}}
                  @updatedAt={{@assessment.modified}} />
          </vr.panel>
      </Access::ViewRightsBase>
  </PlutofPanel::Simple>
  
  */
  {
    "id": "zfzZJQM8",
    "block": "{\"symbols\":[\"vr\",\"redlist\",\"workgroup\",\"@assessment\"],\"statements\":[[8,\"plutof-panel/simple\",[],[[\"@title\"],[[30,[36,4],[\"General.access\"],null]]],[[\"default\"],[{\"statements\":[[2,\"\\n    \"],[8,\"access/view-rights-base\",[],[[],[]],[[\"default\"],[{\"statements\":[[2,\"\\n        \"],[8,[32,1,[\"panel\"]],[],[[],[]],[[\"default\"],[{\"statements\":[[2,\"\\n\"],[6,[37,2],[[32,4,[\"red_list\"]]],null,[[\"default\"],[{\"statements\":[[6,[37,3],[[32,2]],null,[[\"default\",\"else\"],[{\"statements\":[[2,\"                    \"],[8,[32,1,[\"derivedFrom\"]],[],[[\"@parent\"],[[30,[36,1],null,[[\"type\",\"id\",\"name\"],[\"redbook/red-list\",[32,2,[\"id\"]],[32,2,[\"representation\"]]]]]]],null],[2,\"\\n\\n                    \"],[8,[32,1,[\"view\",\"public\"]],[],[[],[]],null],[2,\"\\n\\n\"],[6,[37,2],[[32,2,[\"workgroup\"]]],null,[[\"default\"],[{\"statements\":[[2,\"                        \"],[8,[32,1,[\"edit\",\"selected\"]],[],[[\"@groups\"],[[30,[36,0],[[32,3]],null]]],null],[2,\"\\n\"]],\"parameters\":[3]}]]]],\"parameters\":[]},{\"statements\":[[2,\"                    \"],[8,[32,1,[\"view\",\"public\"]],[],[[],[]],null],[2,\"\\n\"]],\"parameters\":[]}]]]],\"parameters\":[2]}]]],[2,\"        \"]],\"parameters\":[]}]]],[2,\"\\n\\n        \"],[8,[32,1,[\"panel\"]],[],[[],[]],[[\"default\"],[{\"statements\":[[2,\"\\n            \"],[8,[32,1,[\"history\"]],[],[[\"@createdBy\",\"@createdAt\",\"@updatedBy\",\"@updatedAt\"],[\"plutof\",[32,4,[\"created\"]],[32,4,[\"modified_by\",\"representation\"]],[32,4,[\"modified\"]]]],null],[2,\"\\n        \"]],\"parameters\":[]}]]],[2,\"\\n    \"]],\"parameters\":[1]}]]],[2,\"\\n\"]],\"parameters\":[]}]]],[2,\"\\n\"]],\"hasEval\":false,\"upvars\":[\"list\",\"hash\",\"resolve-promise\",\"if\",\"i18n-t\"]}",
    "moduleName": "plutof/components/conservation/red-list-assessment/view-permissions.hbs"
  });
  var _default = _exports.default = Ember._setComponentTemplate(__COLOCATED_TEMPLATE__, Ember._templateOnlyComponent());
});