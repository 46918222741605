define("plutof/templates/components/import/process-list/button", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = Ember.HTMLBars.template({
    "id": "0+7wPePY",
    "block": "{\"symbols\":[\"@tooltip\",\"@disabled\",\"@highlight\",\"@icon\"],\"statements\":[[8,\"tool-tip\",[],[[\"@tooltip\"],[[32,1]]],[[\"default\"],[{\"statements\":[[2,\"\\n    \"],[11,\"button\"],[16,\"disabled\",[30,[36,0],[[32,2],[32,0,[\"handler\",\"isRunning\"]]],null]],[16,0,[31,[\"\\n            btn-link btn-list-view import-process-list__action\\n            \",[30,[36,1],[[32,3],\"import-process-list__action--highlight\"],null],\"\\n        \"]]],[4,[38,2],[\"click\",[32,0,[\"perform\"]]],null],[12],[2,\"\\n        \"],[10,\"span\"],[15,0,[31,[[30,[36,1],[[32,0,[\"handler\",\"isRunning\"]],\"loading-icon\",[32,4]],null]]]],[12],[13],[2,\"\\n    \"],[13],[2,\"\\n\"]],\"parameters\":[]}]]],[2,\"\\n\"]],\"hasEval\":false,\"upvars\":[\"or\",\"if\",\"on\"]}",
    "moduleName": "plutof/templates/components/import/process-list/button.hbs"
  });
});