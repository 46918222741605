define("plutof/templates/components/datacite/related-identifier-row-form", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = Ember.HTMLBars.template({
    "id": "g5Lj0kF5",
    "block": "{\"symbols\":[\"@identifier\",\"@remove\"],\"statements\":[[10,\"td\"],[12],[2,\"\\n    \"],[8,\"auto-complete/bound-model\",[[24,\"data-test\",\"related_dataset\"]],[[\"@model\",\"@value\",\"@params\"],[\"datacite/metadata\",[32,1,[\"related_dataset\"]],[30,[36,0],null,[[\"mini\"],[true]]]]],null],[2,\"\\n\"],[13],[2,\"\\n\\n\"],[10,\"td\"],[15,0,[31,[\"data-table-short-cell \",[30,[36,1],[[32,0],\"identifier.relation_type\"],null]]]],[12],[2,\"\\n    \"],[1,[30,[36,3],null,[[\"content\",\"value\",\"selectClass\"],[[35,2],[32,1,[\"relation_type\"]],\"mini-input\"]]]],[2,\"\\n\"],[13],[2,\"\\n\\n\"],[10,\"td\"],[15,0,[31,[\"data-table-short-cell \",[30,[36,1],[[32,0],\"identifierValidity\"],null]]]],[12],[2,\"\\n    \"],[1,[30,[36,5],null,[[\"type\",\"value\",\"disabled\",\"class\"],[\"text\",[32,1,[\"related_identifier\"]],[35,4],\"mini-input\"]]]],[2,\"\\n\"],[13],[2,\"\\n\\n\"],[10,\"td\"],[15,0,[31,[\"data-table-short-cell \",[30,[36,1],[[32,0],\"identifierTypeValidity\"],null]]]],[12],[2,\"\\n    \"],[1,[30,[36,3],null,[[\"content\",\"value\",\"disabled\",\"selectClass\"],[[35,6],[32,1,[\"related_identifier_type\"]],[35,4],\"mini-input\"]]]],[2,\"\\n\"],[13],[2,\"\\n\\n\"],[10,\"td\"],[15,0,[31,[\"data-table-short-cell \",[30,[36,1],[[32,0],\"resourceTypeValidity\"],null]]]],[12],[2,\"\\n    \"],[1,[30,[36,3],null,[[\"content\",\"value\",\"disabled\",\"selectClass\"],[[35,7],[32,1,[\"resource_type_general\"]],[35,4],\"mini-input\"]]]],[2,\"\\n\"],[13],[2,\"\\n\\n\"],[10,\"td\"],[14,0,\"data-table-icon-cell align-right\"],[12],[2,\"\\n    \"],[11,\"button\"],[24,0,\"btn btn-link data-table-btn\"],[4,[38,8],[\"click\",[32,2]],null],[12],[2,\"\\n        \"],[10,\"span\"],[14,0,\"icon-remove--red\"],[12],[13],[2,\"\\n    \"],[13],[2,\"\\n\"],[13],[2,\"\\n\"]],\"hasEval\":false,\"upvars\":[\"hash\",\"has-error\",\"relationTypes\",\"plutof-select/value\",\"isExisting\",\"input\",\"relatedIdentifierTypes\",\"resourceTypes\",\"on\"]}",
    "moduleName": "plutof/templates/components/datacite/related-identifier-row-form.hbs"
  });
});