define("plutof/components/transaction/view/objects/row/view-controls", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const __COLOCATED_TEMPLATE__ = Ember.HTMLBars.template(
  /*
    <button
      {{on "click" @edit}}
      disabled={{@disable}}
      class="btn btn-link observation-table__control"
  >
      <ToolTip @tooltip={{i18n-t "transactions.objects.actions.edit.tooltip"}}>
          <span class={{icon "edit"}}></span>
      </ToolTip>
  </button>
  
  <button
      {{on "click" @delete}}
      disabled={{@disable}}
      class="btn btn-link observation-table__control"
  >
      <ToolTip @tooltip={{i18n-t "transactions.objects.actions.delete.tooltip"}}>
          <span class={{icon "delete"}}></span>
      </ToolTip>
  </button>
  
  */
  {
    "id": "pEdg8FKF",
    "block": "{\"symbols\":[\"@disable\",\"@edit\",\"@delete\"],\"statements\":[[11,\"button\"],[16,\"disabled\",[32,1]],[24,0,\"btn btn-link observation-table__control\"],[4,[38,0],[\"click\",[32,2]],null],[12],[2,\"\\n    \"],[8,\"tool-tip\",[],[[\"@tooltip\"],[[30,[36,1],[\"transactions.objects.actions.edit.tooltip\"],null]]],[[\"default\"],[{\"statements\":[[2,\"\\n        \"],[10,\"span\"],[15,0,[30,[36,2],[\"edit\"],null]],[12],[13],[2,\"\\n    \"]],\"parameters\":[]}]]],[2,\"\\n\"],[13],[2,\"\\n\\n\"],[11,\"button\"],[16,\"disabled\",[32,1]],[24,0,\"btn btn-link observation-table__control\"],[4,[38,0],[\"click\",[32,3]],null],[12],[2,\"\\n    \"],[8,\"tool-tip\",[],[[\"@tooltip\"],[[30,[36,1],[\"transactions.objects.actions.delete.tooltip\"],null]]],[[\"default\"],[{\"statements\":[[2,\"\\n        \"],[10,\"span\"],[15,0,[30,[36,2],[\"delete\"],null]],[12],[13],[2,\"\\n    \"]],\"parameters\":[]}]]],[2,\"\\n\"],[13],[2,\"\\n\"]],\"hasEval\":false,\"upvars\":[\"on\",\"i18n-t\",\"icon\"]}",
    "moduleName": "plutof/components/transaction/view/objects/row/view-controls.hbs"
  });
  var _default = _exports.default = Ember._setComponentTemplate(__COLOCATED_TEMPLATE__, Ember._templateOnlyComponent());
});