define("plutof/components/measurements/measurement-view/columns", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const __COLOCATED_TEMPLATE__ = Ember.HTMLBars.template(
  /*
    <Layout::RegularGrid @columns={{@columnWidth}}>
      {{#each @measurements as |om|}}
          <div>
              <Measurements::MeasurementView::Single
                  @measurement={{om}}
                  @expand={{unless om.valueIsEmpty (fn @expand om)}} />
          </div>
      {{/each}}
  </Layout::RegularGrid>
  
  */
  {
    "id": "S6g3A7tN",
    "block": "{\"symbols\":[\"om\",\"@expand\",\"@columnWidth\",\"@measurements\"],\"statements\":[[8,\"layout/regular-grid\",[],[[\"@columns\"],[[32,3]]],[[\"default\"],[{\"statements\":[[2,\"\\n\"],[6,[37,3],[[30,[36,2],[[30,[36,2],[[32,4]],null]],null]],null,[[\"default\"],[{\"statements\":[[2,\"        \"],[10,\"div\"],[12],[2,\"\\n            \"],[8,\"measurements/measurement-view/single\",[],[[\"@measurement\",\"@expand\"],[[32,1],[30,[36,1],[[32,1,[\"valueIsEmpty\"]],[30,[36,0],[[32,2],[32,1]],null]],null]]],null],[2,\"\\n        \"],[13],[2,\"\\n\"]],\"parameters\":[1]}]]]],\"parameters\":[]}]]],[2,\"\\n\"]],\"hasEval\":false,\"upvars\":[\"fn\",\"unless\",\"-track-array\",\"each\"]}",
    "moduleName": "plutof/components/measurements/measurement-view/columns.hbs"
  });
  var _default = _exports.default = Ember._setComponentTemplate(__COLOCATED_TEMPLATE__, Ember._templateOnlyComponent());
});