define("plutof/components/search/filters/sampling-area", ["exports", "@glimmer/component", "plutof/components/auto-complete/backends"], function (_exports, _component, _backends) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _dec, _dec2, _dec3, _dec4, _dec5, _class, _descriptor, _descriptor2, _descriptor3;
  function _initializerDefineProperty(e, i, r, l) { r && Object.defineProperty(e, i, { enumerable: r.enumerable, configurable: r.configurable, writable: r.writable, value: r.initializer ? r.initializer.call(l) : void 0 }); }
  function _defineProperty(e, r, t) { return (r = _toPropertyKey(r)) in e ? Object.defineProperty(e, r, { value: t, enumerable: !0, configurable: !0, writable: !0 }) : e[r] = t, e; }
  function _toPropertyKey(t) { var i = _toPrimitive(t, "string"); return "symbol" == typeof i ? i : i + ""; }
  function _toPrimitive(t, r) { if ("object" != typeof t || !t) return t; var e = t[Symbol.toPrimitive]; if (void 0 !== e) { var i = e.call(t, r || "default"); if ("object" != typeof i) return i; throw new TypeError("@@toPrimitive must return a primitive value."); } return ("string" === r ? String : Number)(t); }
  function _applyDecoratedDescriptor(i, e, r, n, l) { var a = {}; return Object.keys(n).forEach(function (i) { a[i] = n[i]; }), a.enumerable = !!a.enumerable, a.configurable = !!a.configurable, ("value" in a || a.initializer) && (a.writable = !0), a = r.slice().reverse().reduce(function (r, n) { return n(i, e, r) || r; }, a), l && void 0 !== a.initializer && (a.value = a.initializer ? a.initializer.call(l) : void 0, a.initializer = void 0), void 0 === a.initializer ? (Object.defineProperty(i, e, a), null) : a; }
  function _initializerWarningHelper(r, e) { throw Error("Decorating class property failed. Please ensure that transform-class-properties is enabled and runs after the decorators transform."); }
  const __COLOCATED_TEMPLATE__ = Ember.HTMLBars.template(
  /*
    <div class="filter-input" ...attributes>
      {{common/local-label "Sample.samplingArea"}}
  
      <AutoComplete::Base
          @backend={{this.backend}}
          @value={{this.value}}
          @update={{this.updateArea}}
          @sideActionDisabled={{not this.value}}
          @params={{hash
              sideAction=this.convertToGeometry
              sideActionIcon=(icon "map-marker")
              sideActionTooltip=(i18n-t "Search.geometry.fromArea")
              allowIncompleteValue=true
          }}
          class="filter-input-ac"
      >
          <:result as |result attrs|>
              {{hl-subs result.value attrs.query}}
  
              {{#if result.full.object.is_site}}
                  <span class="plutof-ac-suggestion-description">
                      ({{i18n-t "Autocomplete.predefinedArea"}})
                  </span>
              {{/if}}
  
              {{#if result.full.owner}}
                  <span class="plutof-ac-suggestion-description">
                      {{result.full.owner}}
                  </span>
              {{/if}}
          </:result>
      </AutoComplete::Base>
  </div>
  
  */
  {
    "id": "XB2r+Oy1",
    "block": "{\"symbols\":[\"__arg0\",\"__arg1\",\"__arg2\",\"result\",\"attrs\",\"&attrs\"],\"statements\":[[11,\"div\"],[24,0,\"filter-input\"],[17,6],[12],[2,\"\\n    \"],[1,[30,[36,4],[\"Sample.samplingArea\"],null]],[2,\"\\n\\n    \"],[8,\"auto-complete/base\",[[24,0,\"filter-input-ac\"]],[[\"@backend\",\"@value\",\"@update\",\"@sideActionDisabled\",\"@params\",\"@namedBlocksInfo\"],[[32,0,[\"backend\"]],[32,0,[\"value\"]],[32,0,[\"updateArea\"]],[30,[36,5],[[32,0,[\"value\"]]],null],[30,[36,7],null,[[\"sideAction\",\"sideActionIcon\",\"sideActionTooltip\",\"allowIncompleteValue\"],[[32,0,[\"convertToGeometry\"]],[30,[36,6],[\"map-marker\"],null],[30,[36,0],[\"Search.geometry.fromArea\"],null],true]]],[30,[36,7],null,[[\"result\"],[2]]]]],[[\"default\"],[{\"statements\":[[6,[37,2],[[30,[36,8],[[32,1],\"result\"],null]],null,[[\"default\"],[{\"statements\":[[6,[37,3],[[32,2],[32,3]],null,[[\"default\"],[{\"statements\":[[2,\"\\n            \"],[1,[30,[36,1],[[32,4,[\"value\"]],[32,5,[\"query\"]]],null]],[2,\"\\n\\n\"],[6,[37,2],[[32,4,[\"full\",\"object\",\"is_site\"]]],null,[[\"default\"],[{\"statements\":[[2,\"                \"],[10,\"span\"],[14,0,\"plutof-ac-suggestion-description\"],[12],[2,\"\\n                    (\"],[1,[30,[36,0],[\"Autocomplete.predefinedArea\"],null]],[2,\")\\n                \"],[13],[2,\"\\n\"]],\"parameters\":[]}]]],[2,\"\\n\"],[6,[37,2],[[32,4,[\"full\",\"owner\"]]],null,[[\"default\"],[{\"statements\":[[2,\"                \"],[10,\"span\"],[14,0,\"plutof-ac-suggestion-description\"],[12],[2,\"\\n                    \"],[1,[32,4,[\"full\",\"owner\"]]],[2,\"\\n                \"],[13],[2,\"\\n\"]],\"parameters\":[]}]]],[2,\"        \"]],\"parameters\":[4,5]}]]]],\"parameters\":[]}]]]],\"parameters\":[1,2,3]}]]],[2,\"\\n\"],[13],[2,\"\\n\"]],\"hasEval\":false,\"upvars\":[\"i18n-t\",\"hl-subs\",\"if\",\"let\",\"common/local-label\",\"not\",\"icon\",\"hash\",\"-is-named-block-invocation\"]}",
    "moduleName": "plutof/components/search/filters/sampling-area.hbs"
  });
  let SearchAreaFilter = (_dec = Ember.inject.service, _dec2 = Ember.inject.service, _dec3 = Ember._action, _dec4 = Ember.computed.reads('args.areaFilter.value.firstObject'), _dec5 = Ember._action, _class = class SearchAreaFilter extends _component.default {
    constructor(...args) {
      super(...args);
      _initializerDefineProperty(this, "ajax", _descriptor, this);
      _initializerDefineProperty(this, "store", _descriptor2, this);
      _defineProperty(this, "backend", new _backends.ModelAutocomplete({
        ajax: this.ajax,
        store: this.store,
        modelName: 'sample/samplingarea'
      }));
      _initializerDefineProperty(this, "value", _descriptor3, this);
    }
    updateArea(area) {
      this.args.areaFilter.set('value', area ? [area] : []);
      this.args.geomFilter.set('value', null);
    }
    convertToGeometry() {
      this.args.geomFilter.set('value', this.value.geom);
      this.args.areaFilter.set('value', []);
    }
  }, _descriptor = _applyDecoratedDescriptor(_class.prototype, "ajax", [_dec], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor2 = _applyDecoratedDescriptor(_class.prototype, "store", [_dec2], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _applyDecoratedDescriptor(_class.prototype, "updateArea", [_dec3], Object.getOwnPropertyDescriptor(_class.prototype, "updateArea"), _class.prototype), _descriptor3 = _applyDecoratedDescriptor(_class.prototype, "value", [_dec4], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _applyDecoratedDescriptor(_class.prototype, "convertToGeometry", [_dec5], Object.getOwnPropertyDescriptor(_class.prototype, "convertToGeometry"), _class.prototype), _class);
  var _default = _exports.default = Ember._setComponentTemplate(__COLOCATED_TEMPLATE__, SearchAreaFilter);
});