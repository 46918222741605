define("plutof/templates/conservation-lab/red-list/view", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = Ember.HTMLBars.template({
    "id": "G9GQpzNL",
    "block": "{\"symbols\":[],\"statements\":[[8,\"navbar/view\",[],[[\"@icon\",\"@title\",\"@record\",\"@module\",\"@editRoute\",\"@deletable\",\"@permissions\"],[[30,[36,0],[\"conservation\"],null],[30,[36,1],[\"conservation.redList.view.title\"],null],[32,0,[\"model\",\"redlist\"]],\"redbook/redlist\",\"conservation-lab.red-list.edit\",true,[32,0,[\"model\",\"permissions\"]]]],null],[2,\"\\n\\n\"],[8,\"conservation/red-list/view/fields\",[],[[\"@redlist\"],[[32,0,[\"model\",\"redlist\"]]]],null],[2,\"\\n\\n\"],[8,\"conservation/red-list/view/assessments\",[],[[\"@redlist\",\"@assessments\",\"@canModify\",\"@add\"],[[32,0,[\"model\",\"redlist\"]],[32,0,[\"model\",\"assessments\"]],[32,0,[\"model\",\"permissions\",\"canModify\"]],[32,0,[\"addAssessment\"]]]],null],[2,\"\\n\\n\"],[8,\"profile/groups/managing-group/view\",[],[[\"@workgroupId\"],[[32,0,[\"model\",\"workgroup\",\"id\"]]]],null],[2,\"\\n\\n\"],[8,\"linked-items/view\",[],[[\"@disabledTabs\",\"@object\"],[[30,[36,2],[\"glossaries\",\"links\",\"references\",\"permits\"],null],[32,0,[\"model\",\"redlist\"]]]],null],[2,\"\\n\\n\"],[8,\"conservation/red-list/view/permissions\",[],[[\"@redlist\",\"@workgroup\"],[[32,0,[\"model\",\"redlist\"]],[32,0,[\"model\",\"workgroup\"]]]],null],[2,\"\\n\"]],\"hasEval\":false,\"upvars\":[\"icon\",\"i18n-t\",\"list\"]}",
    "moduleName": "plutof/templates/conservation-lab/red-list/view.hbs"
  });
});