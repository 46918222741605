define("plutof/models/photobank/collectionobject", ["exports", "@ember-data/model", "plutof/misc/content_types", "plutof/models/plutof-model", "plutof/utils/display-name-from-value", "plutof/utils/structures"], function (_exports, _model, _content_types, _plutofModel, _displayNameFromValue, _structures) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _dec, _dec2, _dec3, _dec4, _dec5, _dec6, _dec7, _dec8, _dec9, _dec10, _dec11, _dec12, _dec13, _dec14, _dec15, _dec16, _dec17, _dec18, _dec19, _dec20, _dec21, _class, _descriptor, _descriptor2, _descriptor3, _descriptor4, _descriptor5, _descriptor6, _descriptor7, _descriptor8, _descriptor9, _descriptor10, _descriptor11, _descriptor12, _descriptor13, _descriptor14, _descriptor15, _descriptor16, _descriptor17, _descriptor18, _descriptor19, _descriptor20, _descriptor21;
  function _initializerDefineProperty(e, i, r, l) { r && Object.defineProperty(e, i, { enumerable: r.enumerable, configurable: r.configurable, writable: r.writable, value: r.initializer ? r.initializer.call(l) : void 0 }); }
  function _defineProperty(e, r, t) { return (r = _toPropertyKey(r)) in e ? Object.defineProperty(e, r, { value: t, enumerable: !0, configurable: !0, writable: !0 }) : e[r] = t, e; }
  function _toPropertyKey(t) { var i = _toPrimitive(t, "string"); return "symbol" == typeof i ? i : i + ""; }
  function _toPrimitive(t, r) { if ("object" != typeof t || !t) return t; var e = t[Symbol.toPrimitive]; if (void 0 !== e) { var i = e.call(t, r || "default"); if ("object" != typeof i) return i; throw new TypeError("@@toPrimitive must return a primitive value."); } return ("string" === r ? String : Number)(t); }
  function _applyDecoratedDescriptor(i, e, r, n, l) { var a = {}; return Object.keys(n).forEach(function (i) { a[i] = n[i]; }), a.enumerable = !!a.enumerable, a.configurable = !!a.configurable, ("value" in a || a.initializer) && (a.writable = !0), a = r.slice().reverse().reduce(function (r, n) { return n(i, e, r) || r; }, a), l && void 0 !== a.initializer && (a.value = a.initializer ? a.initializer.call(l) : void 0, a.initializer = void 0), void 0 === a.initializer ? (Object.defineProperty(i, e, a), null) : a; }
  function _initializerWarningHelper(r, e) { throw Error("Decorating class property failed. Please ensure that transform-class-properties is enabled and runs after the decorators transform."); }
  const displayName = (0, _displayNameFromValue.displayNames)('photobank/collectionobject');
  let CollectionObject = (_dec = displayName('category'), _dec2 = (0, _model.attr)('string'), _dec3 = (0, _model.attr)('string'), _dec4 = (0, _model.attr)('enum'), _dec5 = (0, _model.attr)('string'), _dec6 = (0, _model.attr)('string'), _dec7 = (0, _model.belongsTo)('agent/collection'), _dec8 = (0, _model.belongsTo)('agent/repository'), _dec9 = (0, _model.attr)('string'), _dec10 = (0, _model.attr)('number'), _dec11 = (0, _model.attr)('number'), _dec12 = (0, _model.attr)('number'), _dec13 = (0, _model.attr)('enum'), _dec14 = (0, _model.belongsTo)('sample/samplingevent'), _dec15 = (0, _model.belongsTo)('study/study'), _dec16 = (0, _model.attr)('nullable-string'), _dec17 = (0, _model.attr)('string'), _dec18 = (0, _model.attr)('boolean'), _dec19 = (0, _model.attr)(), _dec20 = (0, _model.attr)({
    defaultValue: () => []
  }), _dec21 = Ember.computed.reads('object_idprim'), _class = class CollectionObject extends _plutofModel.default {
    constructor(...args) {
      super(...args);
      _initializerDefineProperty(this, "categoryName", _descriptor, this);
      _initializerDefineProperty(this, "collected_by", _descriptor2, this);
      _initializerDefineProperty(this, "object_idprim", _descriptor3, this);
      _initializerDefineProperty(this, "category", _descriptor4, this);
      _initializerDefineProperty(this, "title", _descriptor5, this);
      _initializerDefineProperty(this, "description", _descriptor6, this);
      _initializerDefineProperty(this, "deposited_in", _descriptor7, this);
      _initializerDefineProperty(this, "sub_repository", _descriptor8, this);
      _initializerDefineProperty(this, "location_in_collection", _descriptor9, this);
      _initializerDefineProperty(this, "dimension_height", _descriptor10, this);
      _initializerDefineProperty(this, "dimension_width", _descriptor11, this);
      _initializerDefineProperty(this, "dimension_length", _descriptor12, this);
      _initializerDefineProperty(this, "colour_scheme", _descriptor13, this);
      _initializerDefineProperty(this, "samplingevent", _descriptor14, this);
      _initializerDefineProperty(this, "project", _descriptor15, this);
      _initializerDefineProperty(this, "determined_date", _descriptor16, this);
      _initializerDefineProperty(this, "determined_date_format", _descriptor17, this);
      _initializerDefineProperty(this, "on_clipboard", _descriptor18, this);
      _initializerDefineProperty(this, "related_objects", _descriptor19, this);
      // { url, name }
      _initializerDefineProperty(this, "taxa", _descriptor20, this);
      _initializerDefineProperty(this, "representation", _descriptor21, this);
    }
    destroyCompletely() {
      var self = this;
      var id = self.get('id');
      var store = self.get('store');
      var ctypePromise = (0, _content_types.get_ctype_by_name)(store, 'photobank/collectionobject');
      function find(model, params) {
        return store.query(model, params).then(function (results) {
          return results.toArray();
        });
      }
      return Ember.RSVP.all([find('photobank/additionalidentifier', {
        collection_object: id
      }), find('photobank/customitem', {
        collection_object: id
      }),
      // Agents are destroyed by preparation.destroyCompletely()
      find('photobank/preparation', {
        collection_object: id
      }), ctypePromise.then(function (ctype) {
        var itemModels = ['agent/item', 'filerepository/item', 'glossary/item', 'reference/item', 'reference/externallink'];
        var params = {
          content_type: ctype.get('id'),
          object_id: id
        };
        return Ember.RSVP.all(itemModels.map(function (model) {
          return store.query(model, params).toArray();
        })).then(_structures.concat);
      })]).then(function (dependants) {
        return Ember.RSVP.all((0, _structures.concat)(dependants).invoke('destroyCompletely')).then(function () {
          return self.destroyRecord();
        });
      });
    }
  }, _descriptor = _applyDecoratedDescriptor(_class.prototype, "categoryName", [_dec], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor2 = _applyDecoratedDescriptor(_class.prototype, "collected_by", [_dec2], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor3 = _applyDecoratedDescriptor(_class.prototype, "object_idprim", [_dec3], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor4 = _applyDecoratedDescriptor(_class.prototype, "category", [_dec4], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor5 = _applyDecoratedDescriptor(_class.prototype, "title", [_dec5], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor6 = _applyDecoratedDescriptor(_class.prototype, "description", [_dec6], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor7 = _applyDecoratedDescriptor(_class.prototype, "deposited_in", [_dec7], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor8 = _applyDecoratedDescriptor(_class.prototype, "sub_repository", [_dec8], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor9 = _applyDecoratedDescriptor(_class.prototype, "location_in_collection", [_dec9], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor10 = _applyDecoratedDescriptor(_class.prototype, "dimension_height", [_dec10], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor11 = _applyDecoratedDescriptor(_class.prototype, "dimension_width", [_dec11], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor12 = _applyDecoratedDescriptor(_class.prototype, "dimension_length", [_dec12], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor13 = _applyDecoratedDescriptor(_class.prototype, "colour_scheme", [_dec13], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor14 = _applyDecoratedDescriptor(_class.prototype, "samplingevent", [_dec14], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor15 = _applyDecoratedDescriptor(_class.prototype, "project", [_dec15], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor16 = _applyDecoratedDescriptor(_class.prototype, "determined_date", [_dec16], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor17 = _applyDecoratedDescriptor(_class.prototype, "determined_date_format", [_dec17], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor18 = _applyDecoratedDescriptor(_class.prototype, "on_clipboard", [_dec18], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor19 = _applyDecoratedDescriptor(_class.prototype, "related_objects", [_dec19], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor20 = _applyDecoratedDescriptor(_class.prototype, "taxa", [_dec20], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor21 = _applyDecoratedDescriptor(_class.prototype, "representation", [_dec21], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _class);
  var _default = _exports.default = CollectionObject;
});