define("plutof/templates/components/tables/bulk-update", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = Ember.HTMLBars.template({
    "id": "t0v3BAW3",
    "block": "{\"symbols\":[\"&default\"],\"statements\":[[10,\"div\"],[14,0,\"table-bulk-update__table\"],[12],[2,\"\\n    \"],[18,1,[[30,[36,3],null,[[\"isSelected\",\"selection\",\"selectionSize\",\"update\",\"togglePanel\",\"isEmpty\",\"selector\",\"selectAll\",\"updatePanel\"],[[30,[36,1],[[32,0],[32,0,[\"isSelected\"]]],null],[32,0,[\"selection\"]],[32,0,[\"selectionSize\"]],[30,[36,1],[[32,0],[32,0,[\"update\"]]],null],[30,[36,1],[[32,0],[32,0,[\"togglePanel\"]]],null],[30,[36,2],[[32,0,[\"selectionSize\"]]],null],[30,[36,0],[\"tables/-bulk-update/selector\"],[[\"selection\",\"toggle\"],[[32,0,[\"selection\"]],[30,[36,1],[[32,0],[32,0,[\"toggle\"]]],null]]]],[30,[36,0],[\"tables/-bulk-update/select-all\"],[[\"select\",\"clean\",\"selectionSize\"],[[30,[36,1],[[32,0],[32,0,[\"add\"]]],null],[30,[36,1],[[32,0],[32,0,[\"clean\"]]],null],[32,0,[\"selectionSize\"]]]]],[30,[36,0],[\"tables/-bulk-update/update-panel\"],[[\"open\"],[[32,0,[\"isOpen\"]]]]]]]]]],[2,\"\\n\"],[13],[2,\"\\n\"]],\"hasEval\":false,\"upvars\":[\"component\",\"action\",\"not\",\"hash\"]}",
    "moduleName": "plutof/templates/components/tables/bulk-update.hbs"
  });
});