define("plutof/templates/permit/view", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = Ember.HTMLBars.template({
    "id": "02sLVO/y",
    "block": "{\"symbols\":[],\"statements\":[[8,\"navbar/view\",[],[[\"@icon\",\"@title\",\"@record\",\"@module\",\"@editRoute\",\"@deletable\",\"@permissions\"],[[30,[36,0],[\"permit\"],null],[30,[36,1],[\"permit.permit\"],null],[32,0,[\"model\",\"permit\"]],\"permit/permit\",\"permit.edit\",true,[32,0,[\"model\",\"permissions\"]]]],null],[2,\"\\n\\n\"],[8,\"collection-lab/permit/view\",[],[[\"@permit\",\"@items\"],[[32,0,[\"model\",\"permit\"]],[32,0,[\"model\",\"items\"]]]],null],[2,\"\\n\\n\"],[8,\"linked-items/view\",[],[[\"@object\"],[[32,0,[\"model\",\"permit\"]]]],null],[2,\"\\n\\n\"],[8,\"history/view\",[],[[\"@record\"],[[32,0,[\"model\",\"permit\"]]]],null],[2,\"\\n\\n\"],[8,\"collection-lab/permit/view-permissions\",[],[[\"@permit\"],[[32,0,[\"model\",\"permit\"]]]],null],[2,\"\\n\"]],\"hasEval\":false,\"upvars\":[\"icon\",\"i18n-t\"]}",
    "moduleName": "plutof/templates/permit/view.hbs"
  });
});