define("plutof/templates/components/auth/identity-provider-buttons/google-oauth2", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = Ember.HTMLBars.template({
    "id": "ckglSnfL",
    "block": "{\"symbols\":[\"@clicked\"],\"statements\":[[11,\"img\"],[24,\"src\",\"/assets/images/auth/google-button.png\"],[24,0,\"social-login-button\"],[4,[38,0],[\"click\",[32,1]],null],[12],[13],[2,\"\\n\"]],\"hasEval\":false,\"upvars\":[\"on\"]}",
    "moduleName": "plutof/templates/components/auth/identity-provider-buttons/google-oauth2.hbs"
  });
});