define("plutof/templates/components/materialsample/sub-name", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = Ember.HTMLBars.template({
    "id": "5hGwLNgI",
    "block": "{\"symbols\":[\"@cell\",\"@materialsample\"],\"statements\":[[8,[32,1],[[16,0,[31,[[30,[36,1],[[32,0,[\"hasError\"]],\"has-error\"],null]]]]],[[],[]],[[\"default\"],[{\"statements\":[[2,\"\\n    \"],[10,\"div\"],[14,0,\"plutof-label\"],[12],[2,\"\\n        \"],[1,[30,[36,0],[\"MaterialSamples.name\"],null]],[2,\"\\n\\n\"],[6,[37,2],[[32,0,[\"itemIsUnique\"]]],null,[[\"default\"],[{\"statements\":[[2,\"            (\"],[1,[30,[36,0],[\"Specimen.alreadyExists\"],null]],[2,\")\\n\"]],\"parameters\":[]}]]],[2,\"    \"],[13],[2,\"\\n\\n    \"],[8,\"input\",[[24,\"placeholder\",\"Name\"],[24,0,\"mini-input occurrences-table__focus-candidate\"],[24,\"data-test\",\"name\"]],[[\"@type\",\"@value\"],[\"text\",[32,2,[\"name\"]]]],null],[2,\"\\n\"]],\"parameters\":[]}]]],[2,\"\\n\"]],\"hasEval\":false,\"upvars\":[\"i18n-t\",\"if\",\"unless\"]}",
    "moduleName": "plutof/templates/components/materialsample/sub-name.hbs"
  });
});