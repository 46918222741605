define("plutof/components/auto-complete/bind-multiple", ["exports", "@glimmer/component"], function (_exports, _component) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _dec, _dec2, _class;
  function _applyDecoratedDescriptor(i, e, r, n, l) { var a = {}; return Object.keys(n).forEach(function (i) { a[i] = n[i]; }), a.enumerable = !!a.enumerable, a.configurable = !!a.configurable, ("value" in a || a.initializer) && (a.writable = !0), a = r.slice().reverse().reduce(function (r, n) { return n(i, e, r) || r; }, a), l && void 0 !== a.initializer && (a.value = a.initializer ? a.initializer.call(l) : void 0, a.initializer = void 0), void 0 === a.initializer ? (Object.defineProperty(i, e, a), null) : a; }
  const __COLOCATED_TEMPLATE__ = Ember.HTMLBars.template(
  /*
    {{! Simple wrapper to bind autocomplete to multi-valued fields}}
  {{yield this.add this.remove}}
  
  <Common::RecordList @records={{@value}} @remove={{this.remove}} />
  
  */
  {
    "id": "629egsOF",
    "block": "{\"symbols\":[\"&default\",\"@value\"],\"statements\":[[18,1,[[32,0,[\"add\"]],[32,0,[\"remove\"]]]],[2,\"\\n\\n\"],[8,\"common/record-list\",[],[[\"@records\",\"@remove\"],[[32,2],[32,0,[\"remove\"]]]],null],[2,\"\\n\"]],\"hasEval\":false,\"upvars\":[]}",
    "moduleName": "plutof/components/auto-complete/bind-multiple.hbs"
  });
  let AutoCompleteMultipleAdapter = _exports.default = (_dec = Ember._action, _dec2 = Ember._action, _class = class AutoCompleteMultipleAdapter extends _component.default {
    add(item) {
      if (item) {
        this.args.value.pushObject(item);
      }
    }
    remove(item) {
      this.args.value.removeObject(item);
    }
  }, _applyDecoratedDescriptor(_class.prototype, "add", [_dec], Object.getOwnPropertyDescriptor(_class.prototype, "add"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "remove", [_dec2], Object.getOwnPropertyDescriptor(_class.prototype, "remove"), _class.prototype), _class);
  Ember._setComponentTemplate(__COLOCATED_TEMPLATE__, AutoCompleteMultipleAdapter);
});