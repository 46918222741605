define("plutof/components/measurements/add-extra/panel", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const __COLOCATED_TEMPLATE__ = Ember.HTMLBars.template(
  /*
    <Measurements::AddExtra::Wrap
      @measurements={{@measurements}}
      @module={{@module}}
      as |extraMeasurements|
  >
      <PlutofPanel::ItemList @collapse={{default-to @collapse (not @measurements.objectMeasurements.length)}} as |panel|>
          <panel.header @title={{or @title (i18n-t "General.measurements")}}>
              <panel.button @clicked={{extraMeasurements.request}}>
                  {{i18n-t "General.Add"}}
              </panel.button>
          </panel.header>
  
          <panel.content data-marker="measurements">
              {{yield}}
          </panel.content>
      </PlutofPanel::ItemList>
  </Measurements::AddExtra::Wrap>
  
  */
  {
    "id": "VTDrd1Jc",
    "block": "{\"symbols\":[\"extraMeasurements\",\"panel\",\"@measurements\",\"@module\",\"@collapse\",\"@title\",\"&default\"],\"statements\":[[8,\"measurements/add-extra/wrap\",[],[[\"@measurements\",\"@module\"],[[32,3],[32,4]]],[[\"default\"],[{\"statements\":[[2,\"\\n    \"],[8,\"plutof-panel/item-list\",[],[[\"@collapse\"],[[30,[36,1],[[32,5],[30,[36,0],[[32,3,[\"objectMeasurements\",\"length\"]]],null]],null]]],[[\"default\"],[{\"statements\":[[2,\"\\n        \"],[8,[32,2,[\"header\"]],[],[[\"@title\"],[[30,[36,3],[[32,6],[30,[36,2],[\"General.measurements\"],null]],null]]],[[\"default\"],[{\"statements\":[[2,\"\\n            \"],[8,[32,2,[\"button\"]],[],[[\"@clicked\"],[[32,1,[\"request\"]]]],[[\"default\"],[{\"statements\":[[2,\"\\n                \"],[1,[30,[36,2],[\"General.Add\"],null]],[2,\"\\n            \"]],\"parameters\":[]}]]],[2,\"\\n        \"]],\"parameters\":[]}]]],[2,\"\\n\\n        \"],[8,[32,2,[\"content\"]],[[24,\"data-marker\",\"measurements\"]],[[],[]],[[\"default\"],[{\"statements\":[[2,\"\\n            \"],[18,7,null],[2,\"\\n        \"]],\"parameters\":[]}]]],[2,\"\\n    \"]],\"parameters\":[2]}]]],[2,\"\\n\"]],\"parameters\":[1]}]]],[2,\"\\n\"]],\"hasEval\":false,\"upvars\":[\"not\",\"default-to\",\"i18n-t\",\"or\"]}",
    "moduleName": "plutof/components/measurements/add-extra/panel.hbs"
  });
  var _default = _exports.default = Ember._setComponentTemplate(__COLOCATED_TEMPLATE__, Ember._templateOnlyComponent());
});