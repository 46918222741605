define("plutof/templates/experiment/dna/add", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = Ember.HTMLBars.template({
    "id": "ZPrRjDEM",
    "block": "{\"symbols\":[],\"statements\":[[10,\"div\"],[14,0,\"route-marker-dna-add\"],[12],[13],[2,\"\\n\\n\"],[8,\"navbar/edit\",[],[[\"@icon\",\"@title\",\"@record\",\"@reset\",\"@infoTitle\",\"@infoContent\"],[[30,[36,0],[\"experiment\"],null],[30,[36,1],[\"experiment.dna.routeTitles.add\"],null],[32,0,[\"model\",\"dna\"]],[30,[36,2],[\"triggerReset\"],null],[30,[36,1],[\"experiment.general.experiments\"],null],\"information/content/dna-lab/general\"]],null],[2,\"\\n\\n\"],[1,[30,[36,5],null,[[\"dna\",\"validationContext\",\"cancel\",\"save\"],[[35,4,[\"dna\"]],[32,0,[\"validationContext\"]],[30,[36,3],[[32,0],\"cancel\"],null],[30,[36,3],[[32,0],\"saveDNA\"],null]]]]],[2,\"\\n\"]],\"hasEval\":false,\"upvars\":[\"icon\",\"i18n-t\",\"route-action\",\"action\",\"model\",\"experiment/dna/edit-form\"]}",
    "moduleName": "plutof/templates/experiment/dna/add.hbs"
  });
});