define("plutof/templates/components/unite/determinations/view-multiple", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = Ember.HTMLBars.template({
    "id": "JkfxmLn6",
    "block": "{\"symbols\":[\"panel\",\"determination\"],\"statements\":[[8,\"plutof-panel/item-list\",[],[[],[]],[[\"default\"],[{\"statements\":[[2,\"\\n    \"],[8,[32,1,[\"header\"]],[],[[\"@title\",\"@count\"],[[30,[36,0],[\"UniteSH.determinations\"],null],[32,0,[\"pagination\",\"objectCount\"]]]],null],[2,\"\\n\\n    \"],[8,[32,1,[\"innerPanels\"]],[],[[],[]],[[\"default\"],[{\"statements\":[[2,\"\\n\"],[6,[37,2],[[30,[36,1],[[30,[36,1],[[32,0,[\"pagination\",\"objects\"]]],null]],null]],null,[[\"default\"],[{\"statements\":[[2,\"            \"],[8,\"unite/determinations/view-single\",[],[[\"@determination\"],[[32,2]]],null],[2,\"\\n\"]],\"parameters\":[2]}]]],[2,\"\\n        \"],[8,\"pagination/load-more\",[],[[\"@pagination\"],[[32,0,[\"pagination\"]]]],null],[2,\"\\n    \"]],\"parameters\":[]}]]],[2,\"\\n\"]],\"parameters\":[1]}]]],[2,\"\\n\"]],\"hasEval\":false,\"upvars\":[\"i18n-t\",\"-track-array\",\"each\"]}",
    "moduleName": "plutof/templates/components/unite/determinations/view-multiple.hbs"
  });
});