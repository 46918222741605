define("plutof/templates/components/multiple-selectizer", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = Ember.HTMLBars.template({
    "id": "Arg2Wyl4",
    "block": "{\"symbols\":[\"item\",\"@selected\",\"@nameProperty\"],\"statements\":[[1,[30,[36,6],null,[[\"content\",\"optionLabelPath\",\"optionValuePath\",\"disabled\",\"value\"],[[35,5],[35,4],[35,3],[35,2],[35,1]]]]],[2,\"\\n\\n\"],[6,[37,8],[[35,7]],null,[[\"default\"],[{\"statements\":[[2,\"    \"],[8,\"common/record-list\",[],[[\"@records\",\"@remove\"],[[32,2],[32,0,[\"removeRecord\"]]]],[[\"default\"],[{\"statements\":[[2,\"\\n        \"],[1,[30,[36,0],[[32,1],[32,3]],null]],[2,\"\\n    \"]],\"parameters\":[1]}]]],[2,\"\\n\"]],\"parameters\":[]}]]]],\"hasEval\":false,\"upvars\":[\"get\",\"selection\",\"disabled\",\"valuePath\",\"labelPath\",\"content\",\"plutof-select/value\",\"hideList\",\"unless\"]}",
    "moduleName": "plutof/templates/components/multiple-selectizer.hbs"
  });
});