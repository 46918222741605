define("plutof/components/search/tri-state-tooltip", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const __COLOCATED_TEMPLATE__ = Ember.HTMLBars.template(
  /*
    <span class="monospace">[✔]</span> {{i18n-t "Search.booleanTrue"}} <br />
  <span class="monospace">[&nbsp;]</span> {{i18n-t "Search.booleanFalse"}} <br />
  <span class="monospace">[-]</span> {{i18n-t "Search.booleanIndeterminate"}} <br />
  
  */
  {
    "id": "VbJi9Mq1",
    "block": "{\"symbols\":[],\"statements\":[[10,\"span\"],[14,0,\"monospace\"],[12],[2,\"[✔]\"],[13],[2,\" \"],[1,[30,[36,0],[\"Search.booleanTrue\"],null]],[2,\" \"],[10,\"br\"],[12],[13],[2,\"\\n\"],[10,\"span\"],[14,0,\"monospace\"],[12],[2,\"[ ]\"],[13],[2,\" \"],[1,[30,[36,0],[\"Search.booleanFalse\"],null]],[2,\" \"],[10,\"br\"],[12],[13],[2,\"\\n\"],[10,\"span\"],[14,0,\"monospace\"],[12],[2,\"[-]\"],[13],[2,\" \"],[1,[30,[36,0],[\"Search.booleanIndeterminate\"],null]],[2,\" \"],[10,\"br\"],[12],[13],[2,\"\\n\"]],\"hasEval\":false,\"upvars\":[\"i18n-t\"]}",
    "moduleName": "plutof/components/search/tri-state-tooltip.hbs"
  });
  var _default = _exports.default = Ember._setComponentTemplate(__COLOCATED_TEMPLATE__, Ember._templateOnlyComponent());
});