define("plutof/components/information/article", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const __COLOCATED_TEMPLATE__ = Ember.HTMLBars.template(
  /*
    <article class="info-article">
      {{yield (hash
          heading=(component "information/article/-heading")
          paragraph=(component "information/article/-paragraph")
          list=(component "information/article/-list")
      )}}
  </article>
  
  */
  {
    "id": "w1scfDwy",
    "block": "{\"symbols\":[\"&default\"],\"statements\":[[10,\"article\"],[14,0,\"info-article\"],[12],[2,\"\\n    \"],[18,1,[[30,[36,1],null,[[\"heading\",\"paragraph\",\"list\"],[[30,[36,0],[\"information/article/-heading\"],null],[30,[36,0],[\"information/article/-paragraph\"],null],[30,[36,0],[\"information/article/-list\"],null]]]]]],[2,\"\\n\"],[13],[2,\"\\n\"]],\"hasEval\":false,\"upvars\":[\"component\",\"hash\"]}",
    "moduleName": "plutof/components/information/article.hbs"
  });
  var _default = _exports.default = Ember._setComponentTemplate(__COLOCATED_TEMPLATE__, Ember._templateOnlyComponent());
});