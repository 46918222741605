define("plutof/components/filerepository/edit", ["exports", "plutof/components/filerepository/item-list", "plutof/components/linked-items/edit", "plutof/utils/access", "plutof/utils/promises", "plutof/utils/store", "plutof/mixins/component-validations"], function (_exports, _itemList, _edit, _access, _promises, _store, _componentValidations) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  _exports.loadFileModel = loadFileModel;
  function _defineProperty(e, r, t) { return (r = _toPropertyKey(r)) in e ? Object.defineProperty(e, r, { value: t, enumerable: !0, configurable: !0, writable: !0 }) : e[r] = t, e; }
  function _toPropertyKey(t) { var i = _toPrimitive(t, "string"); return "symbol" == typeof i ? i : i + ""; }
  function _toPrimitive(t, r) { if ("object" != typeof t || !t) return t; var e = t[Symbol.toPrimitive]; if (void 0 !== e) { var i = e.call(t, r || "default"); if ("object" != typeof i) return i; throw new TypeError("@@toPrimitive must return a primitive value."); } return ("string" === r ? String : Number)(t); }
  const __COLOCATED_TEMPLATE__ = Ember.HTMLBars.template(
  /*
    <Filerepository::-Edit::GeneralData
      @file={{@model.file}}
      @validationContext={{@validationContext.file}}
      @markedForDeleted={{@model.toDelete}}
      @creators={{@model.creators}} />
  
  <PlutofPanel::Simple
      @title={{i18n-t "FileRepository.linkedObjects"}}
      @collapse={{@model.fileItems.isEmpty}}
  >
      <Filerepository::ItemList @data={{@model.fileItems}} />
  </PlutofPanel::Simple>
  
  <LinkedItems::Edit @data={{@model.items}} />
  
  <Access::EditRights @rights={{@model.accessRights}}
      @enableOwnerEdit={{true}}
      @validationContext={{this.validationContext.access}} />
  
  */
  {
    "id": "4Auxu5he",
    "block": "{\"symbols\":[\"@model\",\"@validationContext\"],\"statements\":[[8,\"filerepository/-edit/general-data\",[],[[\"@file\",\"@validationContext\",\"@markedForDeleted\",\"@creators\"],[[32,1,[\"file\"]],[32,2,[\"file\"]],[32,1,[\"toDelete\"]],[32,1,[\"creators\"]]]],null],[2,\"\\n\\n\"],[8,\"plutof-panel/simple\",[],[[\"@title\",\"@collapse\"],[[30,[36,0],[\"FileRepository.linkedObjects\"],null],[32,1,[\"fileItems\",\"isEmpty\"]]]],[[\"default\"],[{\"statements\":[[2,\"\\n    \"],[8,\"filerepository/item-list\",[],[[\"@data\"],[[32,1,[\"fileItems\"]]]],null],[2,\"\\n\"]],\"parameters\":[]}]]],[2,\"\\n\\n\"],[8,\"linked-items/edit\",[],[[\"@data\"],[[32,1,[\"items\"]]]],null],[2,\"\\n\\n\"],[8,\"access/edit-rights\",[],[[\"@rights\",\"@enableOwnerEdit\",\"@validationContext\"],[[32,1,[\"accessRights\"]],true,[32,0,[\"validationContext\",\"access\"]]]],null],[2,\"\\n\"]],\"hasEval\":false,\"upvars\":[\"i18n-t\"]}",
    "moduleName": "plutof/components/filerepository/edit.hbs"
  });
  class FileModel {
    constructor({
      store,
      file,
      creators,
      fileItems,
      items,
      accessRights
    }) {
      _defineProperty(this, "toDelete", []);
      this.store = store;
      this.file = file;
      this.creators = creators;
      this.fileItems = fileItems;
      this.items = items;
      this.accessRights = accessRights;
    }
    async save() {
      await this.file.save();
      await Ember.RSVP.Promise.all(this.toDelete.invoke('destroyRecord'));
      await Ember.RSVP.Promise.all([_promises.default.saveSequentially(this.creators), this.items.save(this.file, this.accessRights), this.fileItems.save()]);
      await this.accessRights.saveWithTarget([this.file]);
    }
  }
  const LINK_MODELS = ['agent/organization', 'study/study', 'sample/samplingarea', 'sequence/sequence', 'specimen/specimen', 'livingculture/strain'];
  async function loadFileModel(store, ajax, id) {
    const file = await store.findRecord('filerepository/file', id);
    const [creators, fileItems, items, accessRights] = await Ember.RSVP.Promise.all([(0, _store.query)(store, 'filerepository/creator', {
      file: id,
      ordering: 'id'
    }), _itemList.ItemListData.load(store, file, LINK_MODELS), (0, _edit.loadLinkedItemsData)(store, file, {
      disabledTabs: ['files', 'links', 'permits']
    }), (0, _access.get_permissions)(store, ajax, file)]);
    const model = new FileModel({
      store,
      file,
      creators,
      fileItems,
      items,
      accessRights
    });
    return model;
  }
  class FileEdit extends Ember.Component.extend(_componentValidations.default) {
    constructor(...args) {
      super(...args);
      _defineProperty(this, "validationChildren", [{
        name: 'file',
        label: 'General.generalData'
      }, {
        name: 'metadata',
        label: 'FileRepository.metadata'
      }, {
        name: 'access',
        label: 'General.access'
      }]);
    }
  }
  var _default = _exports.default = Ember._setComponentTemplate(__COLOCATED_TEMPLATE__, FileEdit);
});