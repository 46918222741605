define("plutof/components/identifiers/related-ids", ["exports", "@glimmer/component", "plutof/utils/notifications"], function (_exports, _component, _notifications) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _dec, _dec2, _dec3, _class, _descriptor, _descriptor2;
  function _initializerDefineProperty(e, i, r, l) { r && Object.defineProperty(e, i, { enumerable: r.enumerable, configurable: r.configurable, writable: r.writable, value: r.initializer ? r.initializer.call(l) : void 0 }); }
  function _defineProperty(e, r, t) { return (r = _toPropertyKey(r)) in e ? Object.defineProperty(e, r, { value: t, enumerable: !0, configurable: !0, writable: !0 }) : e[r] = t, e; }
  function _toPropertyKey(t) { var i = _toPrimitive(t, "string"); return "symbol" == typeof i ? i : i + ""; }
  function _toPrimitive(t, r) { if ("object" != typeof t || !t) return t; var e = t[Symbol.toPrimitive]; if (void 0 !== e) { var i = e.call(t, r || "default"); if ("object" != typeof i) return i; throw new TypeError("@@toPrimitive must return a primitive value."); } return ("string" === r ? String : Number)(t); }
  function _applyDecoratedDescriptor(i, e, r, n, l) { var a = {}; return Object.keys(n).forEach(function (i) { a[i] = n[i]; }), a.enumerable = !!a.enumerable, a.configurable = !!a.configurable, ("value" in a || a.initializer) && (a.writable = !0), a = r.slice().reverse().reduce(function (r, n) { return n(i, e, r) || r; }, a), l && void 0 !== a.initializer && (a.value = a.initializer ? a.initializer.call(l) : void 0, a.initializer = void 0), void 0 === a.initializer ? (Object.defineProperty(i, e, a), null) : a; }
  function _initializerWarningHelper(r, e) { throw Error("Decorating class property failed. Please ensure that transform-class-properties is enabled and runs after the decorators transform."); }
  const __COLOCATED_TEMPLATE__ = Ember.HTMLBars.template(
  /*
    {{#resolve-promise this.ids as |ids|}}
      {{#each ids as |id|}}
          <div>
              {{#if id.isRecordId}}
                  {{id.full_code}}
              {{else}}
                  {{#link-to id.viewRoute id.id}}
                      {{id.full_code}}
                  {{/link-to}}
              {{/if}}
          </div>
      {{/each}}
  {{/resolve-promise}}
  
  */
  {
    "id": "UkQ9Ay4E",
    "block": "{\"symbols\":[\"ids\",\"id\"],\"statements\":[[6,[37,4],[[32,0,[\"ids\"]]],null,[[\"default\"],[{\"statements\":[[6,[37,3],[[30,[36,2],[[30,[36,2],[[32,1]],null]],null]],null,[[\"default\"],[{\"statements\":[[2,\"        \"],[10,\"div\"],[12],[2,\"\\n\"],[6,[37,1],[[32,2,[\"isRecordId\"]]],null,[[\"default\",\"else\"],[{\"statements\":[[2,\"                \"],[1,[32,2,[\"full_code\"]]],[2,\"\\n\"]],\"parameters\":[]},{\"statements\":[[6,[37,0],null,[[\"route\",\"model\"],[[32,2,[\"viewRoute\"]],[32,2,[\"id\"]]]],[[\"default\"],[{\"statements\":[[2,\"                    \"],[1,[32,2,[\"full_code\"]]],[2,\"\\n\"]],\"parameters\":[]}]]]],\"parameters\":[]}]]],[2,\"        \"],[13],[2,\"\\n\"]],\"parameters\":[2]}]]]],\"parameters\":[1]}]]]],\"hasEval\":false,\"upvars\":[\"link-to\",\"if\",\"-track-array\",\"each\",\"resolve-promise\"]}",
    "moduleName": "plutof/components/identifiers/related-ids.hbs"
  });
  const VIEW_ROUTES = {
    'taxonoccurrence/specimen/specimen': 'specimen.view',
    'taxonoccurrence/livingculture/strain': 'livingspecimen.view',
    'taxonoccurrence/materialsample/materialsample': 'materialsample.view'
  };
  let RelatedIds = (_dec = Ember.inject.service, _dec2 = Ember.inject.service, _dec3 = Ember.computed('args.record.id'), _class = class RelatedIds extends _component.default {
    constructor(...args) {
      super(...args);
      _initializerDefineProperty(this, "store", _descriptor, this);
      _initializerDefineProperty(this, "ajax", _descriptor2, this);
    }
    _load() {
      const record = this.args.record;
      const url = `${record.url}related-ids/`;
      return this.ajax.request(url).then(ids => ids.map(id => {
        return {
          ...id,
          viewRoute: VIEW_ROUTES[record.constructor.modelName],
          isRecordId: id.id === parseInt(record.id)
        };
      })).catch(_notifications.reportError);
    }
    get ids() {
      return this._load();
    }
  }, _descriptor = _applyDecoratedDescriptor(_class.prototype, "store", [_dec], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor2 = _applyDecoratedDescriptor(_class.prototype, "ajax", [_dec2], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _applyDecoratedDescriptor(_class.prototype, "ids", [_dec3], Object.getOwnPropertyDescriptor(_class.prototype, "ids"), _class.prototype), _class);
  var _default = _exports.default = Ember._setComponentTemplate(__COLOCATED_TEMPLATE__, RelatedIds);
});