define("plutof/components/search/query/active-filters", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const __COLOCATED_TEMPLATE__ = Ember.HTMLBars.template(
  /*
    {{common/local-label "Search.activeFilters"}}
  
  <div class="active-filters spacer-large-below">
      {{#each @filters key="name" as |filter|}}
          {{#if (not (is-equal filter.name "textQuery"))}}
              {{#if (not filter.isEmpty)}}
                  <div class="active-filters__filter plutof-fade-in">
                      {{#fade-element}}
                          <div class="plutof-fade-in">
                              <Search::Query::ActiveFilter
                                  @filter={{filter}}
                                  @clear={{fn @clear filter}} />
                          </div>
                      {{/fade-element}}
                  </div>
              {{/if}}
          {{/if}}
      {{/each}}
  
      {{#if @absoluteOrdering}}
          <div class="active-filters__filter">
              {{i18n-t "Search.ordering"}}
              {{! TODO: Button }}
              <span {{on "click" @clearOrdering}} class="icon-remove--red clickable"></span>
          </div>
      {{/if}}
  </div>
  
  */
  {
    "id": "auCgAuB7",
    "block": "{\"symbols\":[\"filter\",\"@clearOrdering\",\"@clear\",\"@filters\",\"@absoluteOrdering\"],\"statements\":[[1,[30,[36,7],[\"Search.activeFilters\"],null]],[2,\"\\n\\n\"],[10,\"div\"],[14,0,\"active-filters spacer-large-below\"],[12],[2,\"\\n\"],[6,[37,9],[[30,[36,8],[[30,[36,8],[[32,4]],null]],null]],[[\"key\"],[\"name\"]],[[\"default\"],[{\"statements\":[[6,[37,5],[[30,[36,4],[[30,[36,6],[[32,1,[\"name\"]],\"textQuery\"],null]],null]],null,[[\"default\"],[{\"statements\":[[6,[37,5],[[30,[36,4],[[32,1,[\"isEmpty\"]]],null]],null,[[\"default\"],[{\"statements\":[[2,\"                \"],[10,\"div\"],[14,0,\"active-filters__filter plutof-fade-in\"],[12],[2,\"\\n\"],[6,[37,3],null,null,[[\"default\"],[{\"statements\":[[2,\"                        \"],[10,\"div\"],[14,0,\"plutof-fade-in\"],[12],[2,\"\\n                            \"],[8,\"search/query/active-filter\",[],[[\"@filter\",\"@clear\"],[[32,1],[30,[36,2],[[32,3],[32,1]],null]]],null],[2,\"\\n                        \"],[13],[2,\"\\n\"]],\"parameters\":[]}]]],[2,\"                \"],[13],[2,\"\\n\"]],\"parameters\":[]}]]]],\"parameters\":[]}]]]],\"parameters\":[1]}]]],[2,\"\\n\"],[6,[37,5],[[32,5]],null,[[\"default\"],[{\"statements\":[[2,\"        \"],[10,\"div\"],[14,0,\"active-filters__filter\"],[12],[2,\"\\n            \"],[1,[30,[36,0],[\"Search.ordering\"],null]],[2,\"\\n\"],[2,\"            \"],[11,\"span\"],[24,0,\"icon-remove--red clickable\"],[4,[38,1],[\"click\",[32,2]],null],[12],[13],[2,\"\\n        \"],[13],[2,\"\\n\"]],\"parameters\":[]}]]],[13],[2,\"\\n\"]],\"hasEval\":false,\"upvars\":[\"i18n-t\",\"on\",\"fn\",\"fade-element\",\"not\",\"if\",\"is-equal\",\"common/local-label\",\"-track-array\",\"each\"]}",
    "moduleName": "plutof/components/search/query/active-filters.hbs"
  });
  var _default = _exports.default = Ember._setComponentTemplate(__COLOCATED_TEMPLATE__, Ember._templateOnlyComponent());
});