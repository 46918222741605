define("plutof/components/related-objects/content/events", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const __COLOCATED_TEMPLATE__ = Ember.HTMLBars.template(
  /*
    <I18n::ModelStrings @model="sample.samplingevent" as |translations|>
      <RelatedObjects::Content @pagination={{@pagination}} as |Field event|>
          <Field @label={{i18n-t "RelatedObjectsViewColumn.name"}}>
              {{#link-to "event.view" event.id}}
                  {{@area.representation}} {{formatted-timespan event.timespan_begin event.timespan_end}}
              {{/link-to}}
          </Field>
  
          <Field @label={{translations.event_id.label}}>
              {{event.event_id}}
          </Field>
  
          <Field @label={{i18n-t "RelatedObjectsViewColumn.collectionDate"}}>
              {{formatted-date event.timespan_begin}}
          </Field>
  
          <Field @label={{i18n-t "RelatedObjectsViewColumn.collectedBy"}}>
              {{event.collected_by}}
          </Field>
  
          <Field @label={{i18n-t "RelatedObjectsViewColumn.modified"}}>
              {{formatted-date event.updated_at}}
          </Field>
      </RelatedObjects::Content>
  </I18n::ModelStrings>
  
  */
  {
    "id": "pqT9D084",
    "block": "{\"symbols\":[\"translations\",\"Field\",\"event\",\"@area\",\"@pagination\"],\"statements\":[[8,\"i18n/model-strings\",[],[[\"@model\"],[\"sample.samplingevent\"]],[[\"default\"],[{\"statements\":[[2,\"\\n    \"],[8,\"related-objects/content\",[],[[\"@pagination\"],[[32,5]]],[[\"default\"],[{\"statements\":[[2,\"\\n        \"],[8,[32,2],[],[[\"@label\"],[[30,[36,1],[\"RelatedObjectsViewColumn.name\"],null]]],[[\"default\"],[{\"statements\":[[2,\"\\n\"],[6,[37,2],null,[[\"route\",\"model\"],[\"event.view\",[32,3,[\"id\"]]]],[[\"default\"],[{\"statements\":[[2,\"                \"],[1,[32,4,[\"representation\"]]],[2,\" \"],[1,[30,[36,0],[[32,3,[\"timespan_begin\"]],[32,3,[\"timespan_end\"]]],null]],[2,\"\\n\"]],\"parameters\":[]}]]],[2,\"        \"]],\"parameters\":[]}]]],[2,\"\\n\\n        \"],[8,[32,2],[],[[\"@label\"],[[32,1,[\"event_id\",\"label\"]]]],[[\"default\"],[{\"statements\":[[2,\"\\n            \"],[1,[32,3,[\"event_id\"]]],[2,\"\\n        \"]],\"parameters\":[]}]]],[2,\"\\n\\n        \"],[8,[32,2],[],[[\"@label\"],[[30,[36,1],[\"RelatedObjectsViewColumn.collectionDate\"],null]]],[[\"default\"],[{\"statements\":[[2,\"\\n            \"],[1,[30,[36,3],[[32,3,[\"timespan_begin\"]]],null]],[2,\"\\n        \"]],\"parameters\":[]}]]],[2,\"\\n\\n        \"],[8,[32,2],[],[[\"@label\"],[[30,[36,1],[\"RelatedObjectsViewColumn.collectedBy\"],null]]],[[\"default\"],[{\"statements\":[[2,\"\\n            \"],[1,[32,3,[\"collected_by\"]]],[2,\"\\n        \"]],\"parameters\":[]}]]],[2,\"\\n\\n        \"],[8,[32,2],[],[[\"@label\"],[[30,[36,1],[\"RelatedObjectsViewColumn.modified\"],null]]],[[\"default\"],[{\"statements\":[[2,\"\\n            \"],[1,[30,[36,3],[[32,3,[\"updated_at\"]]],null]],[2,\"\\n        \"]],\"parameters\":[]}]]],[2,\"\\n    \"]],\"parameters\":[2,3]}]]],[2,\"\\n\"]],\"parameters\":[1]}]]],[2,\"\\n\"]],\"hasEval\":false,\"upvars\":[\"formatted-timespan\",\"i18n-t\",\"link-to\",\"formatted-date\"]}",
    "moduleName": "plutof/components/related-objects/content/events.hbs"
  });
  var _default = _exports.default = Ember._setComponentTemplate(__COLOCATED_TEMPLATE__, Ember._templateOnlyComponent());
});