define("plutof/templates/components/publishing/gbif/project-form", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = Ember.HTMLBars.template({
    "id": "vRuLyhWb",
    "block": "{\"symbols\":[\"@dataset\"],\"statements\":[[8,\"layout/regular-grid\",[],[[\"@columns\"],[3]],[[\"default\"],[{\"statements\":[[2,\"\\n    \"],[10,\"div\"],[12],[2,\"\\n        \"],[1,[30,[36,0],null,[[\"path\",\"source\"],[\"dataResource.project.title\",\"local\"]]]],[2,\"\\n        \"],[1,[30,[36,1],null,[[\"type\",\"value\",\"class\"],[\"text\",[32,1,[\"project_title\"]],\"form-control\"]]]],[2,\"\\n    \"],[13],[2,\"\\n\\n    \"],[10,\"div\"],[12],[2,\"\\n        \"],[1,[30,[36,0],null,[[\"path\",\"source\",\"tooltipText\"],[\"dataResource.project.abstract\",\"local\",[30,[36,2],[\"dataResource.tooltips.projectAbstract\"],null]]]]],[2,\"\\n        \"],[8,\"flexible-textarea\",[[24,0,\"form-control\"]],[[\"@value\"],[[34,3,[\"project_abstract\"]]]],null],[2,\"\\n    \"],[13],[2,\"\\n\\n    \"],[10,\"div\"],[12],[2,\"\\n        \"],[1,[30,[36,0],null,[[\"path\",\"source\",\"tooltipText\"],[\"dataResource.project.funding\",\"local\",[30,[36,2],[\"dataResource.tooltips.projectFunding\"],null]]]]],[2,\"\\n        \"],[8,\"flexible-textarea\",[[24,0,\"form-control\"]],[[\"@value\"],[[34,3,[\"project_funding\"]]]],null],[2,\"\\n    \"],[13],[2,\"\\n\"]],\"parameters\":[]}]]],[2,\"\\n\"]],\"hasEval\":false,\"upvars\":[\"plutof-labelc\",\"input\",\"i18n-t\",\"dataset\"]}",
    "moduleName": "plutof/templates/components/publishing/gbif/project-form.hbs"
  });
});