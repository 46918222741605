define("plutof/components/conservation/red-list/-edit/area", ["exports", "@glimmer/component", "ol/format", "plutof/misc/fileupload", "plutof/utils/file-serialization", "plutof/utils/validations", "plutof/utils/notifications", "plutof/utils/errors"], function (_exports, _component, _format, _fileupload, _fileSerialization, _validations, _notifications, _errors) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _dec, _dec2, _dec3, _class, _descriptor, _descriptor2;
  function _initializerDefineProperty(e, i, r, l) { r && Object.defineProperty(e, i, { enumerable: r.enumerable, configurable: r.configurable, writable: r.writable, value: r.initializer ? r.initializer.call(l) : void 0 }); }
  function _defineProperty(e, r, t) { return (r = _toPropertyKey(r)) in e ? Object.defineProperty(e, r, { value: t, enumerable: !0, configurable: !0, writable: !0 }) : e[r] = t, e; }
  function _toPropertyKey(t) { var i = _toPrimitive(t, "string"); return "symbol" == typeof i ? i : i + ""; }
  function _toPrimitive(t, r) { if ("object" != typeof t || !t) return t; var e = t[Symbol.toPrimitive]; if (void 0 !== e) { var i = e.call(t, r || "default"); if ("object" != typeof i) return i; throw new TypeError("@@toPrimitive must return a primitive value."); } return ("string" === r ? String : Number)(t); }
  function _applyDecoratedDescriptor(i, e, r, n, l) { var a = {}; return Object.keys(n).forEach(function (i) { a[i] = n[i]; }), a.enumerable = !!a.enumerable, a.configurable = !!a.configurable, ("value" in a || a.initializer) && (a.writable = !0), a = r.slice().reverse().reduce(function (r, n) { return n(i, e, r) || r; }, a), l && void 0 !== a.initializer && (a.value = a.initializer ? a.initializer.call(l) : void 0, a.initializer = void 0), void 0 === a.initializer ? (Object.defineProperty(i, e, a), null) : a; }
  function _initializerWarningHelper(r, e) { throw Error("Decorating class property failed. Please ensure that transform-class-properties is enabled and runs after the decorators transform."); }
  const __COLOCATED_TEMPLATE__ = Ember.HTMLBars.template(
  /*
    <PlutofPanel::Simple
      @title={{i18n-t "conservation.redList.fields.area"}}
      @invalid={{not this.present}}
  >
      <div class="out-of-form-field spacer-large-below">
          <button
              {{on "click" this.upload}}
              class="btn btn-default btn-xs"
          >
              <span class={{icon "upload"}}></span>
              {{i18n-t "conservation.redList.add.uploadArea"}}
          </button>
      </div>
      {{! TODO: GeoJSON upload button }}
      <Map::EditMap
          @geodata={{@redlist.area_polygon}}
          @enabledControls={{list "navigate" "modify" "polygon" "box" "circle" "latlon" "layerset" "clear"}} />
  </PlutofPanel::Simple>
  
  */
  {
    "id": "iPZKszel",
    "block": "{\"symbols\":[\"@redlist\"],\"statements\":[[8,\"plutof-panel/simple\",[],[[\"@title\",\"@invalid\"],[[30,[36,0],[\"conservation.redList.fields.area\"],null],[30,[36,1],[[32,0,[\"present\"]]],null]]],[[\"default\"],[{\"statements\":[[2,\"\\n    \"],[10,\"div\"],[14,0,\"out-of-form-field spacer-large-below\"],[12],[2,\"\\n        \"],[11,\"button\"],[24,0,\"btn btn-default btn-xs\"],[4,[38,2],[\"click\",[32,0,[\"upload\"]]],null],[12],[2,\"\\n            \"],[10,\"span\"],[15,0,[30,[36,3],[\"upload\"],null]],[12],[13],[2,\"\\n            \"],[1,[30,[36,0],[\"conservation.redList.add.uploadArea\"],null]],[2,\"\\n        \"],[13],[2,\"\\n    \"],[13],[2,\"\\n\"],[2,\"    \"],[8,\"map/edit-map\",[],[[\"@geodata\",\"@enabledControls\"],[[32,1,[\"area_polygon\"]],[30,[36,4],[\"navigate\",\"modify\",\"polygon\",\"box\",\"circle\",\"latlon\",\"layerset\",\"clear\"],null]]],null],[2,\"\\n\"]],\"parameters\":[]}]]],[2,\"\\n\"]],\"hasEval\":false,\"upvars\":[\"i18n-t\",\"not\",\"on\",\"icon\",\"list\"]}",
    "moduleName": "plutof/components/conservation/red-list/-edit/area.hbs"
  });
  const ACCEPTED_GEOMETRY_TYPE = new Set(['Polygon', 'MultiPolygon']);
  let RedListArea = (_dec = Ember.inject.service, _dec2 = Ember.computed.notEmpty('args.redlist.area_polygon'), _dec3 = Ember._action, _class = class RedListArea extends _component.default {
    constructor(...args) {
      super(...args);
      _initializerDefineProperty(this, "i18n", _descriptor, this);
      _initializerDefineProperty(this, "present", _descriptor2, this);
    }
    // TODO: Should probably extract the geometry merging code
    // from area add route and use it here
    extractGeometry(geojson) {
      if (geojson.type === 'FeatureCollection') {
        if (geojson.features.length !== 1) {
          // XXX TODO: Translate
          throw (0, _errors.userError)(this.i18n.translate('conservation.redList.add.geojsonMustHaveExactlyOneFeature', {
            hash: {
              count: geojson.features.length
            }
          }));
        }
        return this.extractGeometry(geojson.features[0]);
      }
      if (geojson.type === 'Feature') {
        return geojson.geometry;
      }
      return geojson;
    }
    async upload() {
      try {
        const file = await (0, _fileupload.selectFile)();
        if (!file) {
          return;
        }
        const geojson = JSON.parse(await (0, _fileSerialization.readFile)(file));
        const geometryData = this.extractGeometry(geojson);
        if (!ACCEPTED_GEOMETRY_TYPE.has(geometryData.type)) {
          throw (0, _errors.userError)(this.i18n.translate('conservation.redList.add.unsupportedGeometryType'));
        }
        const geometry = new _format.GeoJSON().readGeometry(geometryData);
        const wkt = new _format.WKT().writeGeometry(geometry);
        this.args.redlist.set('area_polygon', wkt);
      } catch (err) {
        (0, _notifications.reportError)(err);
      }
    }
  }, _descriptor = _applyDecoratedDescriptor(_class.prototype, "i18n", [_dec], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor2 = _applyDecoratedDescriptor(_class.prototype, "present", [_validations.validator, _dec2], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _applyDecoratedDescriptor(_class.prototype, "upload", [_dec3], Object.getOwnPropertyDescriptor(_class.prototype, "upload"), _class.prototype), _class);
  var _default = _exports.default = Ember._setComponentTemplate(__COLOCATED_TEMPLATE__, RedListArea);
});