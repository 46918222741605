define("plutof/templates/components/invalid-panels", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = Ember.HTMLBars.template({
    "id": "LQ0TIJc4",
    "block": "{\"symbols\":[\"context\"],\"statements\":[[6,[37,2],[[35,6,[\"invalidContexts\"]]],null,[[\"default\"],[{\"statements\":[[2,\"    \"],[10,\"p\"],[12],[2,\"\\n\"],[6,[37,5],[[30,[36,4],[[30,[36,4],[[35,3]],null]],null]],null,[[\"default\"],[{\"statements\":[[6,[37,2],[[32,1,[\"label\"]]],null,[[\"default\"],[{\"statements\":[[2,\"                \"],[8,\"tool-tip\",[[24,0,\"plutof-tooltip-hover--inline\"]],[[\"@tooltip\"],[[30,[36,0],[\"panel.invalidPanelTooltip\"],[[\"name\"],[[32,1,[\"label\"]]]]]]],[[\"default\"],[{\"statements\":[[2,\"\\n                    \"],[11,\"strong\"],[24,0,\"invalid-panel clickable\"],[4,[38,1],[[32,0],\"gotoAnchor\",[32,1]],null],[12],[2,\"\\n                        \"],[1,[32,1,[\"label\"]]],[2,\"\\n                    \"],[13],[2,\"\\n\\n\"],[2,\"                     \\n                \"]],\"parameters\":[]}]]],[2,\"\\n\"]],\"parameters\":[]}]]]],\"parameters\":[1]}]]],[2,\"    \"],[13],[2,\"\\n\"]],\"parameters\":[]}]]]],\"hasEval\":false,\"upvars\":[\"i18n-t\",\"action\",\"if\",\"invalidSubcontexts\",\"-track-array\",\"each\",\"validationContext\"]}",
    "moduleName": "plutof/templates/components/invalid-panels.hbs"
  });
});