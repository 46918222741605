define("plutof/templates/components/datacite/resource-row-form", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = Ember.HTMLBars.template({
    "id": "TB9eo9tw",
    "block": "{\"symbols\":[\"value\",\"update\",\"@resource\",\"@remove\"],\"statements\":[[10,\"tr\"],[12],[2,\"\\n    \"],[10,\"td\"],[15,0,[30,[36,1],[[32,0,[\"filePresent\"]],\"has-error\"],null]],[12],[2,\"\\n\"],[6,[37,2],[[32,3,[\"linked_file\"]]],null,[[\"default\"],[{\"statements\":[[2,\"            \"],[8,\"filerepository/auto-complete\",[[24,\"data-test\",\"file\"]],[[\"@value\",\"@update\",\"@access\",\"@params\"],[[32,1],[32,2],\"owner\",[30,[36,0],null,[[\"mini\"],[true]]]]],null],[2,\"\\n\"]],\"parameters\":[1,2]}]]],[2,\"    \"],[13],[2,\"\\n\\n    \"],[10,\"td\"],[12],[2,\"\\n        \"],[8,\"input\",[[24,0,\"mini-input\"],[24,\"data-test\",\"size\"]],[[\"@type\",\"@value\"],[\"text\",[32,3,[\"size\"]]]],null],[2,\"\\n    \"],[13],[2,\"\\n\\n    \"],[10,\"td\"],[12],[2,\"\\n        \"],[8,\"flexible-textarea\",[[24,0,\"mini-input\"],[24,\"data-test\",\"remarks\"]],[[\"@value\"],[[32,3,[\"remarks\"]]]],null],[2,\"\\n    \"],[13],[2,\"\\n\\n    \"],[10,\"td\"],[14,0,\"data-table-icon-cell align-right\"],[12],[2,\"\\n        \"],[11,\"button\"],[24,0,\"btn btn-link data-table-btn\"],[4,[38,3],[\"click\",[32,4]],null],[12],[2,\"\\n            \"],[10,\"span\"],[14,0,\"icon-remove--red\"],[12],[13],[2,\"\\n        \"],[13],[2,\"\\n    \"],[13],[2,\"\\n\"],[13],[2,\"\\n\"]],\"hasEval\":false,\"upvars\":[\"hash\",\"unless\",\"async/bind-relation\",\"on\"]}",
    "moduleName": "plutof/templates/components/datacite/resource-row-form.hbs"
  });
});