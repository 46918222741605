define("plutof/templates/components/navbar/-navbar-base/go-back", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = Ember.HTMLBars.template({
    "id": "QexCGny2",
    "block": "{\"symbols\":[\"@sameRoute\"],\"statements\":[[8,\"navbar/-navbar-base/button\",[[24,0,\"go-back-link\"]],[[\"@clicked\"],[[30,[36,0],[\"goBack\",null,[29],[32,1]],null]]],[[\"default\"],[{\"statements\":[[2,\"\\n    \"],[10,\"span\"],[15,0,[30,[36,1],[\"back\"],null]],[12],[13],[2,\"\\n    \"],[1,[30,[36,2],[\"General.Back\"],null]],[2,\"\\n\"]],\"parameters\":[]}]]],[2,\"\\n\"]],\"hasEval\":false,\"upvars\":[\"route-action\",\"icon\",\"i18n-t\"]}",
    "moduleName": "plutof/templates/components/navbar/-navbar-base/go-back.hbs"
  });
});