define("plutof/components/import/selected-fields-table", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const __COLOCATED_TEMPLATE__ = Ember.HTMLBars.template(
  /*
    <table class="data-table data-table--break table-hover">
      <thead>
          <tr>
              <th>{{i18n-t "Import.template.column"}}</th>
              <th>{{i18n-t "Import.template.unit"}}</th>
              <th>{{i18n-t "Import.template.type"}}</th>
          </tr>
      </thead>
  
      <tbody>
          {{#each @fields as |field|}}
              <tr>
                  <td>{{field.title}}</td>
                  <td>{{field.unit}}</td>
                  <td>{{field.typeName}}</td>
              </tr>
          {{/each}}
      </tbody>
  </table>
  
  */
  {
    "id": "yal/bWWA",
    "block": "{\"symbols\":[\"field\",\"@fields\"],\"statements\":[[10,\"table\"],[14,0,\"data-table data-table--break table-hover\"],[12],[2,\"\\n    \"],[10,\"thead\"],[12],[2,\"\\n        \"],[10,\"tr\"],[12],[2,\"\\n            \"],[10,\"th\"],[12],[1,[30,[36,0],[\"Import.template.column\"],null]],[13],[2,\"\\n            \"],[10,\"th\"],[12],[1,[30,[36,0],[\"Import.template.unit\"],null]],[13],[2,\"\\n            \"],[10,\"th\"],[12],[1,[30,[36,0],[\"Import.template.type\"],null]],[13],[2,\"\\n        \"],[13],[2,\"\\n    \"],[13],[2,\"\\n\\n    \"],[10,\"tbody\"],[12],[2,\"\\n\"],[6,[37,2],[[30,[36,1],[[30,[36,1],[[32,2]],null]],null]],null,[[\"default\"],[{\"statements\":[[2,\"            \"],[10,\"tr\"],[12],[2,\"\\n                \"],[10,\"td\"],[12],[1,[32,1,[\"title\"]]],[13],[2,\"\\n                \"],[10,\"td\"],[12],[1,[32,1,[\"unit\"]]],[13],[2,\"\\n                \"],[10,\"td\"],[12],[1,[32,1,[\"typeName\"]]],[13],[2,\"\\n            \"],[13],[2,\"\\n\"]],\"parameters\":[1]}]]],[2,\"    \"],[13],[2,\"\\n\"],[13],[2,\"\\n\"]],\"hasEval\":false,\"upvars\":[\"i18n-t\",\"-track-array\",\"each\"]}",
    "moduleName": "plutof/components/import/selected-fields-table.hbs"
  });
  var _default = _exports.default = Ember._setComponentTemplate(__COLOCATED_TEMPLATE__, Ember._templateOnlyComponent());
});