define("plutof/templates/components/expanded-select", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = Ember.HTMLBars.template({
    "id": "pNXHH8E+",
    "block": "{\"symbols\":[\"entry\",\"detail\",\"&default\"],\"statements\":[[10,\"ul\"],[14,0,\"checked-list\"],[12],[2,\"\\n\"],[6,[37,2],[[30,[36,1],[[30,[36,1],[[35,5]],null]],null]],null,[[\"default\"],[{\"statements\":[[2,\"        \"],[11,\"li\"],[24,0,\"checked-list__item\"],[4,[38,4],[[32,0],\"select\",[32,1]],[[\"on\"],[\"click\"]]],[12],[2,\"\\n            \"],[18,3,[[32,1,[\"object\"]]]],[2,\"\\n\\n\"],[6,[37,3],[[32,1,[\"selected\"]]],null,[[\"default\"],[{\"statements\":[[2,\"                \"],[10,\"span\"],[14,0,\"fas fa-check-circle checked-list__check\"],[12],[13],[2,\"\\n\"],[6,[37,3],[[35,0]],null,[[\"default\"],[{\"statements\":[[2,\"                    \"],[10,\"p\"],[12],[2,\"\\n\"],[6,[37,2],[[30,[36,1],[[30,[36,1],[[35,0]],null]],null]],null,[[\"default\"],[{\"statements\":[[2,\"                            \"],[10,\"b\"],[12],[1,[32,2,[\"name\"]]],[2,\":\"],[13],[2,\" \"],[1,[32,2,[\"value\"]]],[10,\"br\"],[12],[13],[2,\"\\n\"]],\"parameters\":[2]}]]],[2,\"                    \"],[13],[2,\"\\n\"]],\"parameters\":[]}]]]],\"parameters\":[]}]]],[2,\"        \"],[13],[2,\"\\n\"]],\"parameters\":[1]}]]],[13],[2,\"\\n\"]],\"hasEval\":false,\"upvars\":[\"details\",\"-track-array\",\"each\",\"if\",\"action\",\"entries\"]}",
    "moduleName": "plutof/templates/components/expanded-select.hbs"
  });
});