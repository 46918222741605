define("plutof/components/livingspecimen/view/isolation", ["exports", "@glimmer/component", "plutof/utils/model"], function (_exports, _component, _model) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _dec, _class, _descriptor;
  function _initializerDefineProperty(e, i, r, l) { r && Object.defineProperty(e, i, { enumerable: r.enumerable, configurable: r.configurable, writable: r.writable, value: r.initializer ? r.initializer.call(l) : void 0 }); }
  function _defineProperty(e, r, t) { return (r = _toPropertyKey(r)) in e ? Object.defineProperty(e, r, { value: t, enumerable: !0, configurable: !0, writable: !0 }) : e[r] = t, e; }
  function _toPropertyKey(t) { var i = _toPrimitive(t, "string"); return "symbol" == typeof i ? i : i + ""; }
  function _toPrimitive(t, r) { if ("object" != typeof t || !t) return t; var e = t[Symbol.toPrimitive]; if (void 0 !== e) { var i = e.call(t, r || "default"); if ("object" != typeof i) return i; throw new TypeError("@@toPrimitive must return a primitive value."); } return ("string" === r ? String : Number)(t); }
  function _applyDecoratedDescriptor(i, e, r, n, l) { var a = {}; return Object.keys(n).forEach(function (i) { a[i] = n[i]; }), a.enumerable = !!a.enumerable, a.configurable = !!a.configurable, ("value" in a || a.initializer) && (a.writable = !0), a = r.slice().reverse().reduce(function (r, n) { return n(i, e, r) || r; }, a), l && void 0 !== a.initializer && (a.value = a.initializer ? a.initializer.call(l) : void 0, a.initializer = void 0), void 0 === a.initializer ? (Object.defineProperty(i, e, a), null) : a; }
  function _initializerWarningHelper(r, e) { throw Error("Decorating class property failed. Please ensure that transform-class-properties is enabled and runs after the decorators transform."); }
  const __COLOCATED_TEMPLATE__ = Ember.HTMLBars.template(
  /*
    <PlutofPanel::Simple
      @title={{i18n-t "LivingCultures.panelIsolation"}}
      @collapse={{not this.nonEmpty}}
      data-test="Livingspecimen::View::Isolation"
  >
      <GenericViews::Record @record={{@livingspecimen}} as |view|>
          <Layout::RegularGrid @columns={{4}} as |grid|>
              {{view.field "isolated_by"}}
              {{view.field "isolation_date"}}
  
              {{#grid.span 2}}
                  {{view.field "isolated_in"}}
              {{/grid.span}}
  
              {{view.field "isolation_method"}}
              {{view.field "isolated_at"}}
  
              {{#grid.span 2}}
                  {{view.field "isolation_remarks"}}
              {{/grid.span}}
  
              {{view.field "isolation_monosporic_strains"}}
          </Layout::RegularGrid>
      </GenericViews::Record>
  </PlutofPanel::Simple>
  
  */
  {
    "id": "G7/3aP9W",
    "block": "{\"symbols\":[\"view\",\"grid\",\"@livingspecimen\"],\"statements\":[[8,\"plutof-panel/simple\",[[24,\"data-test\",\"Livingspecimen::View::Isolation\"]],[[\"@title\",\"@collapse\"],[[30,[36,1],[\"LivingCultures.panelIsolation\"],null],[30,[36,2],[[32,0,[\"nonEmpty\"]]],null]]],[[\"default\"],[{\"statements\":[[2,\"\\n    \"],[8,\"generic-views/record\",[],[[\"@record\"],[[32,3]]],[[\"default\"],[{\"statements\":[[2,\"\\n        \"],[8,\"layout/regular-grid\",[],[[\"@columns\"],[4]],[[\"default\"],[{\"statements\":[[2,\"\\n            \"],[1,[30,[36,0],[[32,1,[\"field\"]],\"isolated_by\"],null]],[2,\"\\n            \"],[1,[30,[36,0],[[32,1,[\"field\"]],\"isolation_date\"],null]],[2,\"\\n\\n\"],[6,[37,0],[[32,2,[\"span\"]],2],null,[[\"default\"],[{\"statements\":[[2,\"                \"],[1,[30,[36,0],[[32,1,[\"field\"]],\"isolated_in\"],null]],[2,\"\\n\"]],\"parameters\":[]}]]],[2,\"\\n            \"],[1,[30,[36,0],[[32,1,[\"field\"]],\"isolation_method\"],null]],[2,\"\\n            \"],[1,[30,[36,0],[[32,1,[\"field\"]],\"isolated_at\"],null]],[2,\"\\n\\n\"],[6,[37,0],[[32,2,[\"span\"]],2],null,[[\"default\"],[{\"statements\":[[2,\"                \"],[1,[30,[36,0],[[32,1,[\"field\"]],\"isolation_remarks\"],null]],[2,\"\\n\"]],\"parameters\":[]}]]],[2,\"\\n            \"],[1,[30,[36,0],[[32,1,[\"field\"]],\"isolation_monosporic_strains\"],null]],[2,\"\\n        \"]],\"parameters\":[2]}]]],[2,\"\\n    \"]],\"parameters\":[1]}]]],[2,\"\\n\"]],\"parameters\":[]}]]],[2,\"\\n\"]],\"hasEval\":false,\"upvars\":[\"component\",\"i18n-t\",\"not\"]}",
    "moduleName": "plutof/components/livingspecimen/view/isolation.hbs"
  });
  let LivingSpecimenViewIsolation = (_dec = _model.computed.notAllAreEmpty(['args.livingspecimen.isolated_by.id', 'args.livingspecimen.isolation_date', 'args.livingspecimen.isolated_in.id', 'args.livingspecimen.isolation_method', 'args.livingspecimen.isolation_remarks', 'args.livingspecimen.isolation_monosporic_strains']), _class = class LivingSpecimenViewIsolation extends _component.default {
    constructor(...args) {
      super(...args);
      _initializerDefineProperty(this, "nonEmpty", _descriptor, this);
    }
  }, _descriptor = _applyDecoratedDescriptor(_class.prototype, "nonEmpty", [_dec], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _class);
  var _default = _exports.default = Ember._setComponentTemplate(__COLOCATED_TEMPLATE__, LivingSpecimenViewIsolation);
});