define("plutof/templates/components/sample/gathering-agents-view", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = Ember.HTMLBars.template({
    "id": "crLek5ja",
    "block": "{\"symbols\":[\"agent\",\"@agents\"],\"statements\":[[8,\"common/record-list-view\",[],[[\"@records\"],[[32,2]]],[[\"default\"],[{\"statements\":[[2,\"\\n\"],[6,[37,0],null,[[\"class\",\"route\",\"model\"],[\"gathering-agents-view__agent\",\"person.view\",[32,1,[\"id\"]]]],[[\"default\"],[{\"statements\":[[2,\"        \"],[1,[32,1,[\"representation\"]]],[2,\"\\n\"]],\"parameters\":[]}]]]],\"parameters\":[1]}]]],[2,\"\\n\"]],\"hasEval\":false,\"upvars\":[\"link-to\"]}",
    "moduleName": "plutof/templates/components/sample/gathering-agents-view.hbs"
  });
});