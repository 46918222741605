define("plutof/components/access/view-rights-base", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const __COLOCATED_TEMPLATE__ = Ember.HTMLBars.template(
  /*
    <div class="view-rights">
      {{yield (hash
          panel=(component "access/-view-rights/panel")
          column=(component "access/-view-rights/column")
          owner=(component "access/-view-rights/owner")
          history=(component "access/-view-rights/history")
          derivedFrom=(component "access/-view-rights/derived-from")
  
          view=(hash
              public=(component "access/-view-rights/view-public")
              selected=(component "access/-view-rights/view-selected")
              private=(component "access/-view-rights/view-private")
          )
  
          edit=(hash
              selected=(component "access/-view-rights/edit-selected")
              private=(component "access/-view-rights/edit-private")
          )
      )}}
  </div>
  
  */
  {
    "id": "r/wkpIYo",
    "block": "{\"symbols\":[\"&default\"],\"statements\":[[10,\"div\"],[14,0,\"view-rights\"],[12],[2,\"\\n    \"],[18,1,[[30,[36,1],null,[[\"panel\",\"column\",\"owner\",\"history\",\"derivedFrom\",\"view\",\"edit\"],[[30,[36,0],[\"access/-view-rights/panel\"],null],[30,[36,0],[\"access/-view-rights/column\"],null],[30,[36,0],[\"access/-view-rights/owner\"],null],[30,[36,0],[\"access/-view-rights/history\"],null],[30,[36,0],[\"access/-view-rights/derived-from\"],null],[30,[36,1],null,[[\"public\",\"selected\",\"private\"],[[30,[36,0],[\"access/-view-rights/view-public\"],null],[30,[36,0],[\"access/-view-rights/view-selected\"],null],[30,[36,0],[\"access/-view-rights/view-private\"],null]]]],[30,[36,1],null,[[\"selected\",\"private\"],[[30,[36,0],[\"access/-view-rights/edit-selected\"],null],[30,[36,0],[\"access/-view-rights/edit-private\"],null]]]]]]]]],[2,\"\\n\"],[13],[2,\"\\n\"]],\"hasEval\":false,\"upvars\":[\"component\",\"hash\"]}",
    "moduleName": "plutof/components/access/view-rights-base.hbs"
  });
  var _default = _exports.default = Ember._setComponentTemplate(__COLOCATED_TEMPLATE__, Ember._templateOnlyComponent());
});