define("plutof/components/collection/auto-complete", ["exports", "@glimmer/component", "plutof/components/auto-complete/backends"], function (_exports, _component, _backends) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _dec, _dec2, _dec3, _dec4, _class, _descriptor, _descriptor2, _descriptor3;
  function _initializerDefineProperty(e, i, r, l) { r && Object.defineProperty(e, i, { enumerable: r.enumerable, configurable: r.configurable, writable: r.writable, value: r.initializer ? r.initializer.call(l) : void 0 }); }
  function _defineProperty(e, r, t) { return (r = _toPropertyKey(r)) in e ? Object.defineProperty(e, r, { value: t, enumerable: !0, configurable: !0, writable: !0 }) : e[r] = t, e; }
  function _toPropertyKey(t) { var i = _toPrimitive(t, "string"); return "symbol" == typeof i ? i : i + ""; }
  function _toPrimitive(t, r) { if ("object" != typeof t || !t) return t; var e = t[Symbol.toPrimitive]; if (void 0 !== e) { var i = e.call(t, r || "default"); if ("object" != typeof i) return i; throw new TypeError("@@toPrimitive must return a primitive value."); } return ("string" === r ? String : Number)(t); }
  function _applyDecoratedDescriptor(i, e, r, n, l) { var a = {}; return Object.keys(n).forEach(function (i) { a[i] = n[i]; }), a.enumerable = !!a.enumerable, a.configurable = !!a.configurable, ("value" in a || a.initializer) && (a.writable = !0), a = r.slice().reverse().reduce(function (r, n) { return n(i, e, r) || r; }, a), l && void 0 !== a.initializer && (a.value = a.initializer ? a.initializer.call(l) : void 0, a.initializer = void 0), void 0 === a.initializer ? (Object.defineProperty(i, e, a), null) : a; }
  function _initializerWarningHelper(r, e) { throw Error("Decorating class property failed. Please ensure that transform-class-properties is enabled and runs after the decorators transform."); }
  const __COLOCATED_TEMPLATE__ = Ember.HTMLBars.template(
  /*
    <AutoComplete::Base
      @backend={{this.backend}}
      @value={{@value}}
      @update={{@update}}
      @disabled={{@disabled}}
      @params={{merge-params @params (hash
          sideAction=this.selectDefault
          sideActionIcon=(icon "collection")
      )}}
      ...attributes />
  
  */
  {
    "id": "aAbG+cP8",
    "block": "{\"symbols\":[\"@value\",\"@update\",\"@disabled\",\"@params\",\"&attrs\"],\"statements\":[[8,\"auto-complete/base\",[[17,5]],[[\"@backend\",\"@value\",\"@update\",\"@disabled\",\"@params\"],[[32,0,[\"backend\"]],[32,1],[32,2],[32,3],[30,[36,2],[[32,4],[30,[36,1],null,[[\"sideAction\",\"sideActionIcon\"],[[32,0,[\"selectDefault\"]],[30,[36,0],[\"collection\"],null]]]]],null]]],null],[2,\"\\n\"]],\"hasEval\":false,\"upvars\":[\"icon\",\"hash\",\"merge-params\"]}",
    "moduleName": "plutof/components/collection/auto-complete.hbs"
  });
  const TYPES = {
    'specimen/specimen': 'default_specimen_collection',
    'livingculture/strain': 'default_living_specimen_collection',
    'materialsample/materialsample': 'default_material_sample_collection',
    'photobank/collectionobject': 'default_photobank_collection'
  };
  let CollectionAutocomplete = (_dec = Ember.inject.service, _dec2 = Ember.inject.service, _dec3 = Ember.inject.service, _dec4 = Ember._action, _class = class CollectionAutocomplete extends _component.default {
    constructor(...args) {
      super(...args);
      _initializerDefineProperty(this, "ajax", _descriptor, this);
      _initializerDefineProperty(this, "settings", _descriptor2, this);
      _initializerDefineProperty(this, "store", _descriptor3, this);
      _defineProperty(this, "backend", new _backends.ModelAutocomplete({
        ajax: this.ajax,
        store: this.store,
        modelName: 'agent/collection',
        filters: this.args.filters || {}
      }));
    }
    // XXX: For some reason, async action, when passed to
    // Autocomplete::Base as @sideAction, is treated as
    // falsy by {{#if}}. Probably some weird babel & runtime
    // interaction
    // TODO: This should be a task
    async _selectDefault() {
      const field = TYPES[this.args.type];
      const settings = await this.settings.wait();
      const collection = await settings[field];
      this.args.update(collection);
    }
    selectDefault() {
      return this._selectDefault();
    }
  }, _descriptor = _applyDecoratedDescriptor(_class.prototype, "ajax", [_dec], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor2 = _applyDecoratedDescriptor(_class.prototype, "settings", [_dec2], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor3 = _applyDecoratedDescriptor(_class.prototype, "store", [_dec3], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _applyDecoratedDescriptor(_class.prototype, "selectDefault", [_dec4], Object.getOwnPropertyDescriptor(_class.prototype, "selectDefault"), _class.prototype), _class);
  var _default = _exports.default = Ember._setComponentTemplate(__COLOCATED_TEMPLATE__, CollectionAutocomplete);
});