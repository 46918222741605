define("plutof/components/common/ui/table-form/text-input", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const __COLOCATED_TEMPLATE__ = Ember.HTMLBars.template(
  /*
    <Input
      @type="text"
      @value={{@value}}
      class="mini-input" />
  
  */
  {
    "id": "rKDw/m4r",
    "block": "{\"symbols\":[\"@value\"],\"statements\":[[8,\"input\",[[24,0,\"mini-input\"]],[[\"@type\",\"@value\"],[\"text\",[32,1]]],null],[2,\"\\n\"]],\"hasEval\":false,\"upvars\":[]}",
    "moduleName": "plutof/components/common/ui/table-form/text-input.hbs"
  });
  var _default = _exports.default = Ember._setComponentTemplate(__COLOCATED_TEMPLATE__, Ember._templateOnlyComponent());
});