define("plutof/components/search/filter-panels/taxon-description-properties", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const __COLOCATED_TEMPLATE__ = Ember.HTMLBars.template(
  /*
    <PlutofPanel::Simple
      @title={{i18n-t "Search.panelProperties"}}
      @collapse={{true}}
  >
      <Search::FilterPanels::CommonProperties::Agents @filterInputs={{@filterInputs}} />
  
      <Layout::RegularGrid @columns={{6}}>
          <Search::FilterInput @filter={{@filterInputs.reference}} />
          <Search::FilterInput @filter={{@filterInputs.keywords}} />
      </Layout::RegularGrid>
  
      <Layout::RegularGrid @columns={{6}}>
          <Search::FilterPanels::CommonProperties::Checkboxes @filterInputs={{@filterInputs}} />
      </Layout::RegularGrid>
  </PlutofPanel::Simple>
  
  */
  {
    "id": "y/tDpZIl",
    "block": "{\"symbols\":[\"@filterInputs\"],\"statements\":[[8,\"plutof-panel/simple\",[],[[\"@title\",\"@collapse\"],[[30,[36,0],[\"Search.panelProperties\"],null],true]],[[\"default\"],[{\"statements\":[[2,\"\\n    \"],[8,\"search/filter-panels/common-properties/agents\",[],[[\"@filterInputs\"],[[32,1]]],null],[2,\"\\n\\n    \"],[8,\"layout/regular-grid\",[],[[\"@columns\"],[6]],[[\"default\"],[{\"statements\":[[2,\"\\n        \"],[8,\"search/filter-input\",[],[[\"@filter\"],[[32,1,[\"reference\"]]]],null],[2,\"\\n        \"],[8,\"search/filter-input\",[],[[\"@filter\"],[[32,1,[\"keywords\"]]]],null],[2,\"\\n    \"]],\"parameters\":[]}]]],[2,\"\\n\\n    \"],[8,\"layout/regular-grid\",[],[[\"@columns\"],[6]],[[\"default\"],[{\"statements\":[[2,\"\\n        \"],[8,\"search/filter-panels/common-properties/checkboxes\",[],[[\"@filterInputs\"],[[32,1]]],null],[2,\"\\n    \"]],\"parameters\":[]}]]],[2,\"\\n\"]],\"parameters\":[]}]]],[2,\"\\n\"]],\"hasEval\":false,\"upvars\":[\"i18n-t\"]}",
    "moduleName": "plutof/components/search/filter-panels/taxon-description-properties.hbs"
  });
  var _default = _exports.default = Ember._setComponentTemplate(__COLOCATED_TEMPLATE__, Ember._templateOnlyComponent());
});