define("plutof/templates/analyses-lab/index", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = Ember.HTMLBars.template({
    "id": "YXm4ND4u",
    "block": "{\"symbols\":[\"hub\"],\"statements\":[[8,\"test/route-marker\",[],[[\"@route\"],[\"analyses-lab.index\"]],null],[2,\"\\n\\n\"],[8,\"layout/module-hub\",[],[[\"@icon\",\"@title\"],[[30,[36,0],[\"analysis\"],null],[30,[36,1],[\"General.AnalysesLab\"],null]]],[[\"default\"],[{\"statements\":[[2,\"\\n    \"],[8,\"layout/regular-grid\",[],[[\"@columns\"],[3]],[[\"default\"],[{\"statements\":[[2,\"\\n        \"],[8,[32,1,[\"link\"]],[],[[\"@title\",\"@description\",\"@route\",\"@icon\"],[[30,[36,1],[\"General.SequenceAnalyze\"],null],[30,[36,1],[\"Analysis.description\"],null],\"analysis\",[30,[36,0],[\"analysis\"],null]]],null],[2,\"\\n    \"]],\"parameters\":[]}]]],[2,\"\\n\"]],\"parameters\":[1]}]]],[2,\"\\n\"]],\"hasEval\":false,\"upvars\":[\"icon\",\"i18n-t\"]}",
    "moduleName": "plutof/templates/analyses-lab/index.hbs"
  });
});