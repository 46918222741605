define("plutof/components/search/-results-info/normal", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const __COLOCATED_TEMPLATE__ = Ember.HTMLBars.template(
  /*
    <div ...attributes class="equal-flex__child">
      <strong class="table-control__info-text">
          {{@info}}
      </strong>
  </div>
  
  */
  {
    "id": "ktD5VtOm",
    "block": "{\"symbols\":[\"&attrs\",\"@info\"],\"statements\":[[11,\"div\"],[17,1],[24,0,\"equal-flex__child\"],[12],[2,\"\\n    \"],[10,\"strong\"],[14,0,\"table-control__info-text\"],[12],[2,\"\\n        \"],[1,[32,2]],[2,\"\\n    \"],[13],[2,\"\\n\"],[13],[2,\"\\n\"]],\"hasEval\":false,\"upvars\":[]}",
    "moduleName": "plutof/components/search/-results-info/normal.hbs"
  });
  var _default = _exports.default = Ember._setComponentTemplate(__COLOCATED_TEMPLATE__, Ember._templateOnlyComponent());
});