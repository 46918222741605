define("plutof/components/search/column-control/column", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const __COLOCATED_TEMPLATE__ = Ember.HTMLBars.template(
  /*
    {{! TODO: Better name? }}
  
  <div class="column-control">
      {{#component @column
          toggle=(fn @toggleColumn @header)
          visible=@header.isVisible
      }}
          {{@header.config.label}}
  
          {{#if (is-equal @absoluteOrdering @header.ordering)}}
              &nbsp;
              <span class="fas {{if @header.ascendingOrder 'fa-arrow-up' 'fa-arrow-down'}}"></span>
          {{/if}}
      {{/component}}
  
      {{#if @pinColumn}}
          {{component @pin
              pinColumn=(fn @pinColumn @header)
              isPinned=@header.isPinned}}
      {{/if}}
  </div>
  
  */
  {
    "id": "LUInEuXg",
    "block": "{\"symbols\":[\"@header\",\"@pinColumn\",\"@pin\",\"@absoluteOrdering\",\"@toggleColumn\",\"@column\"],\"statements\":[[2,\"\\n\"],[10,\"div\"],[14,0,\"column-control\"],[12],[2,\"\\n\"],[6,[37,1],[[32,6]],[[\"toggle\",\"visible\"],[[30,[36,0],[[32,5],[32,1]],null],[32,1,[\"isVisible\"]]]],[[\"default\"],[{\"statements\":[[2,\"        \"],[1,[32,1,[\"config\",\"label\"]]],[2,\"\\n\\n\"],[6,[37,2],[[30,[36,3],[[32,4],[32,1,[\"ordering\"]]],null]],null,[[\"default\"],[{\"statements\":[[2,\"             \\n            \"],[10,\"span\"],[15,0,[31,[\"fas \",[30,[36,2],[[32,1,[\"ascendingOrder\"]],\"fa-arrow-up\",\"fa-arrow-down\"],null]]]],[12],[13],[2,\"\\n\"]],\"parameters\":[]}]]]],\"parameters\":[]}]]],[2,\"\\n\"],[6,[37,2],[[32,2]],null,[[\"default\"],[{\"statements\":[[2,\"        \"],[1,[30,[36,1],[[32,3]],[[\"pinColumn\",\"isPinned\"],[[30,[36,0],[[32,2],[32,1]],null],[32,1,[\"isPinned\"]]]]]],[2,\"\\n\"]],\"parameters\":[]}]]],[13],[2,\"\\n\"]],\"hasEval\":false,\"upvars\":[\"fn\",\"component\",\"if\",\"is-equal\"]}",
    "moduleName": "plutof/components/search/column-control/column.hbs"
  });
  var _default = _exports.default = Ember._setComponentTemplate(__COLOCATED_TEMPLATE__, Ember._templateOnlyComponent());
});