define("plutof/components/clipboard/-export/list-of-species", ["exports", "@glimmer/component", "plutof/utils/reflection", "plutof/misc/options-getter", "plutof/components/clipboard/-export/utils"], function (_exports, _component, _reflection, _optionsGetter, _utils) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = _exports.ListOfSpeciesExportModel = void 0;
  var _dec, _dec2, _class, _descriptor, _descriptor2;
  function _initializerDefineProperty(e, i, r, l) { r && Object.defineProperty(e, i, { enumerable: r.enumerable, configurable: r.configurable, writable: r.writable, value: r.initializer ? r.initializer.call(l) : void 0 }); }
  function _defineProperty(e, r, t) { return (r = _toPropertyKey(r)) in e ? Object.defineProperty(e, r, { value: t, enumerable: !0, configurable: !0, writable: !0 }) : e[r] = t, e; }
  function _toPropertyKey(t) { var i = _toPrimitive(t, "string"); return "symbol" == typeof i ? i : i + ""; }
  function _toPrimitive(t, r) { if ("object" != typeof t || !t) return t; var e = t[Symbol.toPrimitive]; if (void 0 !== e) { var i = e.call(t, r || "default"); if ("object" != typeof i) return i; throw new TypeError("@@toPrimitive must return a primitive value."); } return ("string" === r ? String : Number)(t); }
  function _applyDecoratedDescriptor(i, e, r, n, l) { var a = {}; return Object.keys(n).forEach(function (i) { a[i] = n[i]; }), a.enumerable = !!a.enumerable, a.configurable = !!a.configurable, ("value" in a || a.initializer) && (a.writable = !0), a = r.slice().reverse().reduce(function (r, n) { return n(i, e, r) || r; }, a), l && void 0 !== a.initializer && (a.value = a.initializer ? a.initializer.call(l) : void 0, a.initializer = void 0), void 0 === a.initializer ? (Object.defineProperty(i, e, a), null) : a; }
  function _initializerWarningHelper(r, e) { throw Error("Decorating class property failed. Please ensure that transform-class-properties is enabled and runs after the decorators transform."); }
  const __COLOCATED_TEMPLATE__ = Ember.HTMLBars.template(
  /*
    {{#if @model.options}}
      <Layout::RegularGrid @columns={{2}}>
          <div>
              <Common::CheckBox @value={{@model.separateSynonyms}}>
                  {{plutof-labelc path=@model.options.separate_synonyms source="options"}}
              </Common::CheckBox>
          </div>
  
          <div>
              {{plutof-labelc path=@model.options.vernacular_names_language source="options"}}
  
              {{#async/bind-relation @model.language as |value update|}}
                  <AutoComplete::Model
                      @model="geography/language"
                      @value={{value}}
                      @update={{update}} />
              {{/async/bind-relation}}
          </div>
      </Layout::RegularGrid>
  {{/if}}
  
  */
  {
    "id": "DC8vjpY9",
    "block": "{\"symbols\":[\"value\",\"update\",\"@model\"],\"statements\":[[6,[37,2],[[32,3,[\"options\"]]],null,[[\"default\"],[{\"statements\":[[2,\"    \"],[8,\"layout/regular-grid\",[],[[\"@columns\"],[2]],[[\"default\"],[{\"statements\":[[2,\"\\n        \"],[10,\"div\"],[12],[2,\"\\n            \"],[8,\"common/check-box\",[],[[\"@value\"],[[32,3,[\"separateSynonyms\"]]]],[[\"default\"],[{\"statements\":[[2,\"\\n                \"],[1,[30,[36,0],null,[[\"path\",\"source\"],[[32,3,[\"options\",\"separate_synonyms\"]],\"options\"]]]],[2,\"\\n            \"]],\"parameters\":[]}]]],[2,\"\\n        \"],[13],[2,\"\\n\\n        \"],[10,\"div\"],[12],[2,\"\\n            \"],[1,[30,[36,0],null,[[\"path\",\"source\"],[[32,3,[\"options\",\"vernacular_names_language\"]],\"options\"]]]],[2,\"\\n\\n\"],[6,[37,1],[[32,3,[\"language\"]]],null,[[\"default\"],[{\"statements\":[[2,\"                \"],[8,\"auto-complete/model\",[],[[\"@model\",\"@value\",\"@update\"],[\"geography/language\",[32,1],[32,2]]],null],[2,\"\\n\"]],\"parameters\":[1,2]}]]],[2,\"        \"],[13],[2,\"\\n    \"]],\"parameters\":[]}]]],[2,\"\\n\"]],\"parameters\":[]}]]]],\"hasEval\":false,\"upvars\":[\"plutof-labelc\",\"async/bind-relation\",\"if\"]}",
    "moduleName": "plutof/components/clipboard/-export/list-of-species.hbs"
  });
  let ListOfSpeciesExportModel = _exports.ListOfSpeciesExportModel = (_dec = Ember._tracked, _dec2 = Ember._tracked, _class = class ListOfSpeciesExportModel extends _utils.ExportFormatModel {
    constructor({
      endpoint,
      options
    }) {
      super({
        endpoint
      });
      _initializerDefineProperty(this, "language", _descriptor, this);
      _initializerDefineProperty(this, "separateSynonyms", _descriptor2, this);
      this.options = options;
    }
    static async create(store, endpoint) {
      const ajax = (0, _reflection.getService)(store, 'ajax');
      const options = await (0, _optionsGetter.getPromise)(ajax, endpoint);
      return new ListOfSpeciesExportModel({
        endpoint,
        options: options.actions.POST
      });
    }
    prepareSaveData(objectList) {
      const languageId = Ember.get(this, 'language.id') ?? null;
      return {
        object_list: objectList,
        vernacular_names_language: languageId,
        separate_synonyms: this.separateSynonyms
      };
    }
  }, _descriptor = _applyDecoratedDescriptor(_class.prototype, "language", [_dec], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return null;
    }
  }), _descriptor2 = _applyDecoratedDescriptor(_class.prototype, "separateSynonyms", [_dec2], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return false;
    }
  }), _class);
  class ListOfSpeciesExport extends _component.default {}
  _exports.default = ListOfSpeciesExport;
  Ember._setComponentTemplate(__COLOCATED_TEMPLATE__, ListOfSpeciesExport);
});