define("plutof/components/search/filter-panels/occurrence-taxon", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const __COLOCATED_TEMPLATE__ = Ember.HTMLBars.template(
  /*
    <PlutofPanel::Simple
      @title={{i18n-t "Search.panelTaxon"}}
      @collapse={{true}}
      data-panel-name="taxon-properties"
  >
      <Search::FilterPanels::OccurrenceTaxonInputs @filterInputs={{@filterInputs}} />
  
      <Layout::RegularGrid @columns={{6}}>
          <Search::FilterPanels::OccurrenceTaxonCheckboxes @filterInputs={{@filterInputs}} />
      </Layout::RegularGrid>
  </PlutofPanel::Simple>
  
  */
  {
    "id": "m0sHXd8m",
    "block": "{\"symbols\":[\"@filterInputs\"],\"statements\":[[8,\"plutof-panel/simple\",[[24,\"data-panel-name\",\"taxon-properties\"]],[[\"@title\",\"@collapse\"],[[30,[36,0],[\"Search.panelTaxon\"],null],true]],[[\"default\"],[{\"statements\":[[2,\"\\n    \"],[8,\"search/filter-panels/occurrence-taxon-inputs\",[],[[\"@filterInputs\"],[[32,1]]],null],[2,\"\\n\\n    \"],[8,\"layout/regular-grid\",[],[[\"@columns\"],[6]],[[\"default\"],[{\"statements\":[[2,\"\\n        \"],[8,\"search/filter-panels/occurrence-taxon-checkboxes\",[],[[\"@filterInputs\"],[[32,1]]],null],[2,\"\\n    \"]],\"parameters\":[]}]]],[2,\"\\n\"]],\"parameters\":[]}]]],[2,\"\\n\"]],\"hasEval\":false,\"upvars\":[\"i18n-t\"]}",
    "moduleName": "plutof/components/search/filter-panels/occurrence-taxon.hbs"
  });
  var _default = _exports.default = Ember._setComponentTemplate(__COLOCATED_TEMPLATE__, Ember._templateOnlyComponent());
});