define("plutof/components/information/content/general/map-controls", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const __COLOCATED_TEMPLATE__ = Ember.HTMLBars.template(
  /*
    <h4> {{i18n-t 'Map.controls.controls'}}</h4>
  <img src="/assets/images/map-controls.png" class="img-responsive">
  
  <br>
  
  <h4> {{i18n-t 'Map.markers'}}</h4>
  <img src="/assets/images/map-markers.png" class="img-responsive">
  
  */
  {
    "id": "D/KwPy06",
    "block": "{\"symbols\":[],\"statements\":[[10,\"h4\"],[12],[2,\" \"],[1,[30,[36,0],[\"Map.controls.controls\"],null]],[13],[2,\"\\n\"],[10,\"img\"],[14,\"src\",\"/assets/images/map-controls.png\"],[14,0,\"img-responsive\"],[12],[13],[2,\"\\n\\n\"],[10,\"br\"],[12],[13],[2,\"\\n\\n\"],[10,\"h4\"],[12],[2,\" \"],[1,[30,[36,0],[\"Map.markers\"],null]],[13],[2,\"\\n\"],[10,\"img\"],[14,\"src\",\"/assets/images/map-markers.png\"],[14,0,\"img-responsive\"],[12],[13],[2,\"\\n\"]],\"hasEval\":false,\"upvars\":[\"i18n-t\"]}",
    "moduleName": "plutof/components/information/content/general/map-controls.hbs"
  });
  var _default = _exports.default = Ember._setComponentTemplate(__COLOCATED_TEMPLATE__, Ember._templateOnlyComponent());
});