define("plutof/components/common/check-box", ["exports", "@glimmer/component"], function (_exports, _component) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _dec, _class;
  function _applyDecoratedDescriptor(i, e, r, n, l) { var a = {}; return Object.keys(n).forEach(function (i) { a[i] = n[i]; }), a.enumerable = !!a.enumerable, a.configurable = !!a.configurable, ("value" in a || a.initializer) && (a.writable = !0), a = r.slice().reverse().reduce(function (r, n) { return n(i, e, r) || r; }, a), l && void 0 !== a.initializer && (a.value = a.initializer ? a.initializer.call(l) : void 0, a.initializer = void 0), void 0 === a.initializer ? (Object.defineProperty(i, e, a), null) : a; }
  const __COLOCATED_TEMPLATE__ = Ember.HTMLBars.template(
  /*
    <label class="plutof-checkbox {{if @light 'plutof-checkbox--light'}}" ...attributes>
      <Input
          @type="checkbox"
          @checked={{@value}}
          @disabled={{@disabled}}
          {{on "input" this._changed}}
          class="plutof-checkbox__checkbox" />
  
      {{yield}}
  </label>
  
  */
  {
    "id": "20HqP4qY",
    "block": "{\"symbols\":[\"@light\",\"&attrs\",\"@value\",\"@disabled\",\"&default\"],\"statements\":[[11,\"label\"],[16,0,[31,[\"plutof-checkbox \",[30,[36,0],[[32,1],\"plutof-checkbox--light\"],null]]]],[17,2],[12],[2,\"\\n    \"],[8,\"input\",[[24,0,\"plutof-checkbox__checkbox\"],[4,[38,1],[\"input\",[32,0,[\"_changed\"]]],null]],[[\"@type\",\"@checked\",\"@disabled\"],[\"checkbox\",[32,3],[32,4]]],null],[2,\"\\n\\n    \"],[18,5,null],[2,\"\\n\"],[13],[2,\"\\n\"]],\"hasEval\":false,\"upvars\":[\"if\",\"on\"]}",
    "moduleName": "plutof/components/common/check-box.hbs"
  });
  let CommonCheckBox = (_dec = Ember._action, _class = class CommonCheckBox extends _component.default {
    _changed(event) {
      if (this.args.changed) {
        this.args.changed(event.target.checked);
      }
    }
  }, _applyDecoratedDescriptor(_class.prototype, "_changed", [_dec], Object.getOwnPropertyDescriptor(_class.prototype, "_changed"), _class.prototype), _class);
  var _default = _exports.default = Ember._setComponentTemplate(__COLOCATED_TEMPLATE__, CommonCheckBox);
});