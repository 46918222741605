define("plutof/templates/conservation-lab/red-list-assessment/view", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = Ember.HTMLBars.template({
    "id": "x4M9dJVy",
    "block": "{\"symbols\":[],\"statements\":[[8,\"navbar/view\",[],[[\"@icon\",\"@title\",\"@record\",\"@module\",\"@editRoute\",\"@deletable\",\"@permissions\"],[[30,[36,0],[\"conservation\"],null],[30,[36,1],[\"conservation.redListAssessment.view.title\"],null],[32,0,[\"model\",\"assessment\"]],\"redbook/redlistassessment\",\"conservation-lab.red-list-assessment.edit\",true,[32,0,[\"model\",\"permissions\"]]]],null],[2,\"\\n\\n\"],[8,\"conservation/red-list-assessment/view\",[],[[\"@assessment\"],[[32,0,[\"model\",\"assessment\"]]]],null],[2,\"\\n\\n\"],[8,\"measurements/measurement-view/panel\",[],[[\"@object\",\"@mainform\"],[[32,0,[\"model\",\"assessment\"]],[32,0,[\"model\",\"assessment\",\"red_list\",\"form\"]]]],null],[2,\"\\n\\n\"],[8,\"conservation/red-list-assessment/view-permissions\",[],[[\"@assessment\"],[[32,0,[\"model\",\"assessment\"]]]],null],[2,\"\\n\"]],\"hasEval\":false,\"upvars\":[\"icon\",\"i18n-t\"]}",
    "moduleName": "plutof/templates/conservation-lab/red-list-assessment/view.hbs"
  });
});