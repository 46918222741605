define("plutof/templates/components/navbar/edit", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = Ember.HTMLBars.template({
    "id": "2ONonvZt",
    "block": "{\"symbols\":[\"navbar\",\"slots\",\"@reset\",\"@kind\",\"@icon\",\"@title\",\"@infoTitle\",\"@infoContent\",\"&default\"],\"statements\":[[8,\"navbar/navbar-base\",[],[[\"@kind\"],[[30,[36,2],[[32,4],\"edit\"],null]]],[[\"default\"],[{\"statements\":[[2,\"\\n    \"],[8,[32,2,[\"header\"]],[],[[],[]],[[\"default\"],[{\"statements\":[[2,\"\\n        \"],[8,[32,1,[\"header\"]],[],[[\"@icon\",\"@title\"],[[32,5],[32,6]]],null],[2,\"\\n    \"]],\"parameters\":[]}]]],[2,\"\\n\\n    \"],[8,[32,2,[\"primaryControls\"]],[],[[],[]],[[\"default\"],[{\"statements\":[[2,\"\\n        \"],[8,[32,1,[\"info\"]],[],[[\"@title\",\"@content\"],[[30,[36,2],[[32,7],[30,[36,2],[[32,6],[30,[36,1],[\"General.Info\"],null]],null]],null],[32,8]]],null],[2,\"\\n\\n        \"],[18,9,[[32,1],[30,[36,4],null,[[\"primaryControls\"],[[30,[36,3],[\"named-slot\"],[[\"tagName\"],[\"\"]]]]]]]],[2,\"\\n    \"]],\"parameters\":[]}]]],[2,\"\\n\\n    \"],[8,[32,2,[\"secondaryControls\"]],[],[[],[]],[[\"default\"],[{\"statements\":[[2,\"\\n        \"],[18,9,[[32,1],[30,[36,4],null,[[\"secondaryControls\"],[[30,[36,3],[\"named-slot\"],[[\"tagName\"],[\"\"]]]]]]]],[2,\"\\n\\n        \"],[8,[32,1,[\"bookmark\"]],[],[[],[]],null],[2,\"\\n\\n\"],[6,[37,5],[[32,3]],null,[[\"default\"],[{\"statements\":[[2,\"            \"],[8,[32,1,[\"button\"]],[],[[\"@clicked\"],[[30,[36,0],[[32,0],[32,3]],null]]],[[\"default\"],[{\"statements\":[[2,\"\\n                \"],[10,\"span\"],[14,0,\"fas fa-sync-alt\"],[12],[13],[2,\"\\n                \"],[1,[30,[36,1],[\"General.Reset\"],null]],[2,\"\\n            \"]],\"parameters\":[]}]]],[2,\"\\n\"]],\"parameters\":[]}]]],[2,\"\\n        \"],[8,[32,1,[\"goBack\"]],[],[[],[]],null],[2,\"\\n    \"]],\"parameters\":[]}]]],[2,\"\\n\"]],\"parameters\":[1,2]}]]],[2,\"\\n\"]],\"hasEval\":false,\"upvars\":[\"action\",\"i18n-t\",\"or\",\"component\",\"hash\",\"if\"]}",
    "moduleName": "plutof/templates/components/navbar/edit.hbs"
  });
});