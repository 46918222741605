define("plutof/components/trait/list/-fields", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const __COLOCATED_TEMPLATE__ = Ember.HTMLBars.template(
  /*
    <@field @label={{backend-translations/label "measurement.measurement.name"}}>
      {{@trait.name}}
  </@field>
  
  <@field @label={{backend-translations/label "measurement.measurement.description"}}>
      {{@trait.description}}
  </@field>
  
  <@field @label={{backend-translations/label "measurement.measurement.type"}}>
      {{@trait.type}}
  </@field>
  
  */
  {
    "id": "IqDXB6Tn",
    "block": "{\"symbols\":[\"@field\",\"@trait\"],\"statements\":[[8,[32,1],[],[[\"@label\"],[[30,[36,0],[\"measurement.measurement.name\"],null]]],[[\"default\"],[{\"statements\":[[2,\"\\n    \"],[1,[32,2,[\"name\"]]],[2,\"\\n\"]],\"parameters\":[]}]]],[2,\"\\n\\n\"],[8,[32,1],[],[[\"@label\"],[[30,[36,0],[\"measurement.measurement.description\"],null]]],[[\"default\"],[{\"statements\":[[2,\"\\n    \"],[1,[32,2,[\"description\"]]],[2,\"\\n\"]],\"parameters\":[]}]]],[2,\"\\n\\n\"],[8,[32,1],[],[[\"@label\"],[[30,[36,0],[\"measurement.measurement.type\"],null]]],[[\"default\"],[{\"statements\":[[2,\"\\n    \"],[1,[32,2,[\"type\"]]],[2,\"\\n\"]],\"parameters\":[]}]]],[2,\"\\n\"]],\"hasEval\":false,\"upvars\":[\"backend-translations/label\"]}",
    "moduleName": "plutof/components/trait/list/-fields.hbs"
  });
  var _default = _exports.default = Ember._setComponentTemplate(__COLOCATED_TEMPLATE__, Ember._templateOnlyComponent());
});