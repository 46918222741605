define("plutof/templates/collection/add", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = Ember.HTMLBars.template({
    "id": "OU4/aSGw",
    "block": "{\"symbols\":[],\"statements\":[[8,\"navbar/edit\",[],[[\"@icon\",\"@title\",\"@record\",\"@reset\",\"@infoTitle\",\"@infoContent\"],[[30,[36,0],[\"collection\"],null],[30,[36,1],[\"collection.add.title\"],null],[32,0,[\"model\",\"collection\"]],[30,[36,2],[\"triggerReset\"],null],[30,[36,1],[\"collection.collection\"],null],\"information/content/collection/general\"]],null],[2,\"\\n\\n\"],[8,\"collection/edit\",[],[[\"@collection\",\"@repositories\",\"@linkedItems\",\"@objectsToDelete\",\"@validationContext\",\"@setLogo\",\"@save\",\"@cancel\"],[[32,0,[\"model\",\"collection\"]],[32,0,[\"model\",\"repositories\"]],[32,0,[\"model\",\"linkedItems\"]],[32,0,[\"model\",\"objectsToDelete\"]],[32,0,[\"validationContext\"]],[32,0,[\"setLogo\"]],[32,0,[\"save\"]],[30,[36,3],[[32,0],\"cancel\"],null]]],null],[2,\"\\n\"]],\"hasEval\":false,\"upvars\":[\"icon\",\"i18n-t\",\"route-action\",\"action\"]}",
    "moduleName": "plutof/templates/collection/add.hbs"
  });
});