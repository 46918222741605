define("plutof/components/specimen/view/exsiccata", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const __COLOCATED_TEMPLATE__ = Ember.HTMLBars.template(
  /*
    {{#resolve-promise @specimen.exsiccata as |exsiccata|}}
      <PlutofPanel::Simple
          @title={{i18n-t "General.exsiccata"}}
          @collapse={{not exsiccata}}
      >
          {{#let (annotation/fields-modified @specimen 'exsiccata') as |annotated|}}
              <GenericViews::Record @record={{exsiccata}} as |view|>
                  <Layout::RegularGrid @columns={{4}} @cellGap={{true}}>
                      {{view.attr "title" annotated=annotated}}
                      {{view.attr "abbreviation" annotated=annotated}}
                      {{view.attr "groups" annotated=annotated}}
  
                      {{#view.attr
                          label=(component "plutof-labelc" path="specimen.exsiccata_no")
                          annotated=(annotation/fields-modified @specimen 'exsiccata_no')
                      }}
                          {{@specimen.exsiccata_no}}
                      {{/view.attr}}
                  </Layout::RegularGrid>
              </GenericViews::Record>
          {{/let}}
      </PlutofPanel::Simple>
  {{/resolve-promise}}
  
  */
  {
    "id": "I1GEAOt0",
    "block": "{\"symbols\":[\"exsiccata\",\"annotated\",\"view\",\"@specimen\"],\"statements\":[[6,[37,5],[[32,4,[\"exsiccata\"]]],null,[[\"default\"],[{\"statements\":[[2,\"    \"],[8,\"plutof-panel/simple\",[],[[\"@title\",\"@collapse\"],[[30,[36,2],[\"General.exsiccata\"],null],[30,[36,3],[[32,1]],null]]],[[\"default\"],[{\"statements\":[[2,\"\\n\"],[6,[37,4],[[30,[36,1],[[32,4],\"exsiccata\"],null]],null,[[\"default\"],[{\"statements\":[[2,\"            \"],[8,\"generic-views/record\",[],[[\"@record\"],[[32,1]]],[[\"default\"],[{\"statements\":[[2,\"\\n                \"],[8,\"layout/regular-grid\",[],[[\"@columns\",\"@cellGap\"],[4,true]],[[\"default\"],[{\"statements\":[[2,\"\\n                    \"],[1,[30,[36,0],[[32,3,[\"attr\"]],\"title\"],[[\"annotated\"],[[32,2]]]]],[2,\"\\n                    \"],[1,[30,[36,0],[[32,3,[\"attr\"]],\"abbreviation\"],[[\"annotated\"],[[32,2]]]]],[2,\"\\n                    \"],[1,[30,[36,0],[[32,3,[\"attr\"]],\"groups\"],[[\"annotated\"],[[32,2]]]]],[2,\"\\n\\n\"],[6,[37,0],[[32,3,[\"attr\"]]],[[\"label\",\"annotated\"],[[30,[36,0],[\"plutof-labelc\"],[[\"path\"],[\"specimen.exsiccata_no\"]]],[30,[36,1],[[32,4],\"exsiccata_no\"],null]]],[[\"default\"],[{\"statements\":[[2,\"                        \"],[1,[32,4,[\"exsiccata_no\"]]],[2,\"\\n\"]],\"parameters\":[]}]]],[2,\"                \"]],\"parameters\":[]}]]],[2,\"\\n            \"]],\"parameters\":[3]}]]],[2,\"\\n\"]],\"parameters\":[2]}]]],[2,\"    \"]],\"parameters\":[]}]]],[2,\"\\n\"]],\"parameters\":[1]}]]]],\"hasEval\":false,\"upvars\":[\"component\",\"annotation/fields-modified\",\"i18n-t\",\"not\",\"let\",\"resolve-promise\"]}",
    "moduleName": "plutof/components/specimen/view/exsiccata.hbs"
  });
  var _default = _exports.default = Ember._setComponentTemplate(__COLOCATED_TEMPLATE__, Ember._templateOnlyComponent());
});