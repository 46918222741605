define("plutof/components/filerepository/type-icon", ["exports", "@glimmer/component", "plutof/helpers/icon", "plutof/models/filerepository/file"], function (_exports, _component, _icon, _file) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const __COLOCATED_TEMPLATE__ = Ember.HTMLBars.template(
  /*
    <span class={{this.icon}}></span>
  
  */
  {
    "id": "f7KvAqUi",
    "block": "{\"symbols\":[],\"statements\":[[10,\"span\"],[15,0,[32,0,[\"icon\"]]],[12],[13],[2,\"\\n\"]],\"hasEval\":false,\"upvars\":[]}",
    "moduleName": "plutof/components/filerepository/type-icon.hbs"
  });
  const ICONS = {
    [_file.FileType.Other]: (0, _icon.icon)('file'),
    [_file.FileType.Dataset]: (0, _icon.icon)('file-dataset'),
    [_file.FileType.Sound]: (0, _icon.icon)('file-sound'),
    [_file.FileType.Text]: (0, _icon.icon)('file-text'),
    [_file.FileType.Application]: (0, _icon.icon)('file-application'),
    [_file.FileType.Video]: (0, _icon.icon)('file-video'),
    [_file.FileType.Image]: (0, _icon.icon)('file-image'),
    [_file.FileType.Model]: (0, _icon.icon)('file-3d')
  };
  class FileTypeIcon extends _component.default {
    get icon() {
      return ICONS[this.args.type || _file.FileType.Other];
    }
  }
  var _default = _exports.default = Ember._setComponentTemplate(__COLOCATED_TEMPLATE__, FileTypeIcon);
});