define("plutof/templates/components/materialsample/sub-repository", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = Ember.HTMLBars.template({
    "id": "PZlNdjYK",
    "block": "{\"symbols\":[\"value\",\"update\",\"@cell\",\"@materialsample\"],\"statements\":[[8,[32,3],[[24,0,\"organization-cell\"]],[[\"@label\"],[[30,[36,1],[\"plutof-labelc\"],[[\"path\"],[\"materialsample.deposited_in\"]]]]],[[\"default\"],[{\"statements\":[[2,\"\\n\"],[6,[37,2],[[32,4,[\"deposited_in\"]]],null,[[\"default\"],[{\"statements\":[[2,\"        \"],[8,\"collection/auto-complete\",[[24,\"data-test\",\"deposited_in\"]],[[\"@value\",\"@update\",\"@type\",\"@filters\",\"@params\"],[[32,1],[32,2],\"materialsample/materialsample\",[30,[36,0],null,[[\"access\"],[\"edit\"]]],[30,[36,0],null,[[\"mini\"],[true]]]]],null],[2,\"\\n\"]],\"parameters\":[1,2]}]]]],\"parameters\":[]}]]],[2,\"\\n\\n\"],[8,[32,3],[],[[\"@label\"],[[30,[36,1],[\"common/local-label\",\"MaterialSamples.subRepository\"],null]]],[[\"default\"],[{\"statements\":[[2,\"\\n    \"],[8,\"plutof-select/object\",[[24,0,\"mini-input__select\"],[24,\"data-test\",\"sub_repository\"]],[[\"@content\",\"@optionLabelPath\",\"@selection\",\"@selectClass\"],[[32,0,[\"subrepositories\"]],\"name\",[32,4,[\"sub_repository\"]],\"mini-input\"]],null],[2,\"\\n\"]],\"parameters\":[]}]]],[2,\"\\n\"]],\"hasEval\":false,\"upvars\":[\"hash\",\"component\",\"async/bind-relation\"]}",
    "moduleName": "plutof/templates/components/materialsample/sub-repository.hbs"
  });
});