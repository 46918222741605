define("plutof/components/annotation/bulk/field", ["exports", "@glimmer/component"], function (_exports, _component) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _dec, _dec2, _class;
  function _applyDecoratedDescriptor(i, e, r, n, l) { var a = {}; return Object.keys(n).forEach(function (i) { a[i] = n[i]; }), a.enumerable = !!a.enumerable, a.configurable = !!a.configurable, ("value" in a || a.initializer) && (a.writable = !0), a = r.slice().reverse().reduce(function (r, n) { return n(i, e, r) || r; }, a), l && void 0 !== a.initializer && (a.value = a.initializer ? a.initializer.call(l) : void 0, a.initializer = void 0), void 0 === a.initializer ? (Object.defineProperty(i, e, a), null) : a; }
  const __COLOCATED_TEMPLATE__ = Ember.HTMLBars.template(
  /*
    <div>
      <Common::Ui::ControlLabel>
          <:label>
              <div class="plutof-label">
                  {{@field.label}}
              </div>
          </:label>
  
          <:controls as |controls|>
              <controls.toggle
                  @checked={{@field.active}}
                  @toggle={{@field.toggle}}
              >
                  {{i18n-t "annotation.annotate"}}
              </controls.toggle>
          </:controls>
      </Common::Ui::ControlLabel>
  
      <div>
          {{yield (hash
              value=@field.value
              update=@field.update
              disabled=this.disabled
              boundValue=this.boundValue
          )}}
      </div>
  </div>
  
  */
  {
    "id": "Pbb1YGMc",
    "block": "{\"symbols\":[\"__arg0\",\"__arg1\",\"controls\",\"@field\",\"&default\"],\"statements\":[[10,\"div\"],[12],[2,\"\\n    \"],[8,\"common/ui/control-label\",[],[[\"@namedBlocksInfo\"],[[30,[36,4],null,[[\"label\",\"controls\"],[0,1]]]]],[[\"default\"],[{\"statements\":[[6,[37,3],[[30,[36,2],[[32,1],\"label\"],null]],null,[[\"default\",\"else\"],[{\"statements\":[[2,\"\\n            \"],[10,\"div\"],[14,0,\"plutof-label\"],[12],[2,\"\\n                \"],[1,[32,4,[\"label\"]]],[2,\"\\n            \"],[13],[2,\"\\n        \"]],\"parameters\":[]},{\"statements\":[[6,[37,3],[[30,[36,2],[[32,1],\"controls\"],null]],null,[[\"default\"],[{\"statements\":[[6,[37,1],[[32,2]],null,[[\"default\"],[{\"statements\":[[2,\"\\n            \"],[8,[32,3,[\"toggle\"]],[],[[\"@checked\",\"@toggle\"],[[32,4,[\"active\"]],[32,4,[\"toggle\"]]]],[[\"default\"],[{\"statements\":[[2,\"\\n                \"],[1,[30,[36,0],[\"annotation.annotate\"],null]],[2,\"\\n            \"]],\"parameters\":[]}]]],[2,\"\\n        \"]],\"parameters\":[3]}]]]],\"parameters\":[]}]]]],\"parameters\":[]}]]]],\"parameters\":[1,2]}]]],[2,\"\\n\\n    \"],[10,\"div\"],[12],[2,\"\\n        \"],[18,5,[[30,[36,4],null,[[\"value\",\"update\",\"disabled\",\"boundValue\"],[[32,4,[\"value\"]],[32,4,[\"update\"]],[32,0,[\"disabled\"]],[32,0,[\"boundValue\"]]]]]]],[2,\"\\n    \"],[13],[2,\"\\n\"],[13],[2,\"\\n\"]],\"hasEval\":false,\"upvars\":[\"i18n-t\",\"let\",\"-is-named-block-invocation\",\"if\",\"hash\"]}",
    "moduleName": "plutof/components/annotation/bulk/field.hbs"
  });
  let BulkAnnotationField = (_dec = Ember.computed('args.field.value'), _dec2 = Ember.computed('args.field.active'), _class = class BulkAnnotationField extends _component.default {
    get boundValue() {
      return this.args.field.value;
    }
    set boundValue(value) {
      this.args.field.update(value);
      return value;
    }
    get disabled() {
      return !this.args.field.active;
    }
  }, _applyDecoratedDescriptor(_class.prototype, "boundValue", [_dec], Object.getOwnPropertyDescriptor(_class.prototype, "boundValue"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "disabled", [_dec2], Object.getOwnPropertyDescriptor(_class.prototype, "disabled"), _class.prototype), _class);
  var _default = _exports.default = Ember._setComponentTemplate(__COLOCATED_TEMPLATE__, BulkAnnotationField);
});