define("plutof/templates/components/transaction/transaction-correspondence", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = Ember.HTMLBars.template({
    "id": "d7bP+gj4",
    "block": "{\"symbols\":[\"form\",\"@remove\",\"@correspondence\"],\"statements\":[[8,\"plutof-panel/inner-form\",[],[[\"@remove\"],[[32,2]]],[[\"default\"],[{\"statements\":[[2,\"\\n    \"],[8,\"generic-forms/record\",[],[[\"@record\"],[[32,3]]],[[\"default\"],[{\"statements\":[[2,\"\\n        \"],[8,\"layout/regular-grid\",[],[[\"@columns\"],[3]],[[\"default\"],[{\"statements\":[[2,\"\\n            \"],[1,[30,[36,0],[[32,1,[\"bind\"]],\"type\"],[[\"valid\"],[[32,0,[\"validations\",\"attrs\",\"correspondence\",\"type\",\"isValid\"]]]]]],[2,\"\\n            \"],[1,[30,[36,0],[[32,1,[\"bind\"]],\"sender\"],[[\"valid\"],[[32,0,[\"validations\",\"attrs\",\"senderExists\",\"isValid\"]]]]]],[2,\"\\n            \"],[1,[30,[36,0],[[32,1,[\"bind\"]],\"receiver\"],[[\"valid\"],[[32,0,[\"validations\",\"attrs\",\"receiverExists\",\"isValid\"]]]]]],[2,\"\\n            \"],[1,[30,[36,0],[[32,1,[\"bind\"]],\"sent_date\"],null]],[2,\"\\n            \"],[1,[30,[36,0],[[32,1,[\"bind\"]],\"received_date\"],null]],[2,\"\\n            \"],[1,[30,[36,0],[[32,1,[\"bind\"]],\"content\"],null]],[2,\"\\n        \"]],\"parameters\":[]}]]],[2,\"\\n    \"]],\"parameters\":[1]}]]],[2,\"\\n\"]],\"parameters\":[]}]]],[2,\"\\n\"]],\"hasEval\":false,\"upvars\":[\"component\"]}",
    "moduleName": "plutof/templates/components/transaction/transaction-correspondence.hbs"
  });
});