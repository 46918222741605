define("plutof/templates/components/experiment/dna/view/record-metadata", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = Ember.HTMLBars.template({
    "id": "rDWtAX0k",
    "block": "{\"symbols\":[\"vr\",\"@record\"],\"statements\":[[8,\"plutof-panel/simple\",[],[[\"@title\"],[[30,[36,1],[\"General.access\"],null]]],[[\"default\"],[{\"statements\":[[2,\"\\n    \"],[8,\"generic-views/record\",[],[[\"@record\"],[[32,2]]],[[\"default\"],[{\"statements\":[[2,\"\\n        \"],[8,\"layout/regular-grid\",[],[[\"@columns\"],[2]],[[\"default\"],[{\"statements\":[[2,\"\\n            \"],[10,\"div\"],[12],[2,\"\\n                \"],[8,\"plutof-labelc\",[],[[\"@path\"],[\"agent.organization.managing_group\"]],null],[2,\"\\n\\n\"],[6,[37,2],[[32,2,[\"collection\",\"id\"]]],null,[[\"default\",\"else\"],[{\"statements\":[[6,[37,0],null,[[\"route\",\"model\"],[\"profile.groups.group\",[32,2,[\"collection\",\"managing_group\",\"id\"]]]],[[\"default\"],[{\"statements\":[[2,\"                        \"],[1,[32,2,[\"collection\",\"managing_group\",\"representation\"]]],[2,\"\\n\"]],\"parameters\":[]}]]]],\"parameters\":[]},{\"statements\":[[6,[37,0],null,[[\"route\",\"model\"],[\"profile.groups.group\",[32,2,[\"dna_extraction\",\"laboratory\",\"managing_group\",\"id\"]]]],[[\"default\"],[{\"statements\":[[2,\"                        \"],[1,[32,2,[\"dna_extraction\",\"laboratory\",\"managing_group\",\"representation\"]]],[2,\"\\n\"]],\"parameters\":[]}]]]],\"parameters\":[]}]]],[2,\"            \"],[13],[2,\"\\n\\n            \"],[8,\"access/view-rights-base\",[],[[],[]],[[\"default\"],[{\"statements\":[[2,\"\\n                \"],[8,[32,1,[\"history\"]],[],[[\"@createdBy\",\"@createdAt\",\"@updatedBy\",\"@updatedAt\"],[[32,2,[\"created_by\",\"representation\"]],[32,2,[\"created_at\"]],[32,2,[\"updated_by\",\"representation\"]],[32,2,[\"updated_at\"]]]],null],[2,\"\\n            \"]],\"parameters\":[1]}]]],[2,\"\\n        \"]],\"parameters\":[]}]]],[2,\"\\n    \"]],\"parameters\":[]}]]],[2,\"\\n\"]],\"parameters\":[]}]]],[2,\"\\n\"]],\"hasEval\":false,\"upvars\":[\"link-to\",\"i18n-t\",\"if\"]}",
    "moduleName": "plutof/templates/components/experiment/dna/view/record-metadata.hbs"
  });
});