define("plutof/components/plutof-panel/item-list/add-buttons", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const __COLOCATED_TEMPLATE__ = Ember.HTMLBars.template(
  /*
    <@button
      @clicked={{fn @add 1}}
      @disabled={{@disable}}
  >
      {{i18n-t "General.Add"}} 1
  </@button>
  
  <@button
      @clicked={{fn @add 10}}
      @disabled={{@disable}}
  >
      {{i18n-t "General.Add"}} 10
  </@button>
  
  */
  {
    "id": "MqUoOq4T",
    "block": "{\"symbols\":[\"@button\",\"@add\",\"@disable\"],\"statements\":[[8,[32,1],[],[[\"@clicked\",\"@disabled\"],[[30,[36,0],[[32,2],1],null],[32,3]]],[[\"default\"],[{\"statements\":[[2,\"\\n    \"],[1,[30,[36,1],[\"General.Add\"],null]],[2,\" 1\\n\"]],\"parameters\":[]}]]],[2,\"\\n\\n\"],[8,[32,1],[],[[\"@clicked\",\"@disabled\"],[[30,[36,0],[[32,2],10],null],[32,3]]],[[\"default\"],[{\"statements\":[[2,\"\\n    \"],[1,[30,[36,1],[\"General.Add\"],null]],[2,\" 10\\n\"]],\"parameters\":[]}]]],[2,\"\\n\"]],\"hasEval\":false,\"upvars\":[\"fn\",\"i18n-t\"]}",
    "moduleName": "plutof/components/plutof-panel/item-list/add-buttons.hbs"
  });
  var _default = _exports.default = Ember._setComponentTemplate(__COLOCATED_TEMPLATE__, Ember._templateOnlyComponent());
});