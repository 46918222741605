define("plutof/templates/taxonomy/edit", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = Ember.HTMLBars.template({
    "id": "Vmwx1t+4",
    "block": "{\"symbols\":[],\"statements\":[[8,\"test/route-marker\",[],[[\"@route\"],[\"taxonomy.edit\"]],null],[2,\"\\n\\n\"],[8,\"navbar/edit\",[],[[\"@icon\",\"@title\",\"@record\",\"@infoContent\"],[[30,[36,0],[\"taxon-node\"],null],[30,[36,1],[\"Taxonomy.editTaxon\"],null],[32,0,[\"model\",\"traxon\"]],\"information/content/taxon/general\"]],null],[2,\"\\n\\n\"],[8,\"taxonomy/edit\",[],[[\"@taxon\",\"@commonNames\",\"@linkedItems\",\"@addVernacularName\",\"@removeVernacularName\",\"@disableRankField\",\"@validationContext\"],[[32,0,[\"model\",\"taxon\"]],[32,0,[\"model\",\"commonNames\"]],[32,0,[\"model\",\"linkedItems\"]],[32,0,[\"addVernacularName\"]],[32,0,[\"removeVernacularName\"]],[32,0,[\"model\",\"hasRank\"]],[32,0,[\"validationContext\"]]]],null],[2,\"\\n\\n\"],[8,\"taxonomy/edit-remarks-panel\",[],[[\"@taxon\",\"@title\"],[[32,0,[\"model\",\"taxon\"]],[30,[36,1],[\"Taxonomy.editPanelTitle\"],null]]],null],[2,\"\\n\\n\"],[8,\"invalid-panels\",[],[[\"@validationContext\"],[[32,0,[\"validationContext\"]]]],null],[2,\"\\n\\n\"],[10,\"div\"],[14,0,\"action-buttons\"],[12],[2,\"\\n    \"],[8,\"common/save-button\",[],[[\"@save\",\"@disabled\"],[[32,0,[\"save\"]],[32,0,[\"validationContext\",\"isInvalid\"]]]],null],[2,\"\\n\\n    \"],[8,\"common/cancel-button\",[],[[\"@cancel\"],[[32,0,[\"cancel\"]]]],null],[2,\"\\n\"],[13],[2,\"\\n\"]],\"hasEval\":false,\"upvars\":[\"icon\",\"i18n-t\"]}",
    "moduleName": "plutof/templates/taxonomy/edit.hbs"
  });
});