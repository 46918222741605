define("plutof/templates/components/experiment/common/material-container", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = Ember.HTMLBars.template({
    "id": "IhoSycgf",
    "block": "{\"symbols\":[\"&default\"],\"statements\":[[10,\"div\"],[12],[2,\"\\n    \"],[18,1,[[30,[36,2],null,[[\"selectionSize\",\"selection\",\"materialQueryParams\",\"panelIsOpen\",\"toggleMultiple\",\"toggleForwardingPanel\",\"forwardSelection\",\"targetAutocompleteData\",\"selector\"],[[32,0,[\"selectionSize\"]],[32,0,[\"selection\"]],[32,0,[\"materialQueryParams\"]],[32,0,[\"panelIsOpen\"]],[30,[36,0],[[32,0],[32,0,[\"toggleMultiple\"]]],null],[30,[36,0],[[32,0],[32,0,[\"toggleForwardingPanel\"]]],null],[30,[36,0],[[32,0],[32,0,[\"forwardSelection\"]]],null],[32,0,[\"targetAutocompleteData\"]],[30,[36,1],[\"experiment/common/material-selector\"],[[\"selection\",\"toggle\"],[[32,0,[\"selection\"]],[30,[36,0],[[32,0],[32,0,[\"toggle\"]]],null]]]]]]]]],[2,\"\\n\"],[13],[2,\"\\n\"]],\"hasEval\":false,\"upvars\":[\"action\",\"component\",\"hash\"]}",
    "moduleName": "plutof/templates/components/experiment/common/material-container.hbs"
  });
});