define("plutof/components/event/edit", ["exports", "plutof/mixins/component-validations"], function (_exports, _componentValidations) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  function _defineProperty(e, r, t) { return (r = _toPropertyKey(r)) in e ? Object.defineProperty(e, r, { value: t, enumerable: !0, configurable: !0, writable: !0 }) : e[r] = t, e; }
  function _toPropertyKey(t) { var i = _toPrimitive(t, "string"); return "symbol" == typeof i ? i : i + ""; }
  function _toPrimitive(t, r) { if ("object" != typeof t || !t) return t; var e = t[Symbol.toPrimitive]; if (void 0 !== e) { var i = e.call(t, r || "default"); if ("object" != typeof i) return i; throw new TypeError("@@toPrimitive must return a primitive value."); } return ("string" === r ? String : Number)(t); }
  const __COLOCATED_TEMPLATE__ = Ember.HTMLBars.template(
  /*
    <Sample::SampleBreadcrumbs @event={{@event}} />
  
  <PlutofPanel::Simple @title={{i18n-t "General.generalData"}}>
      <Sample::EventFieldsAddedit
          @event={{@event}}
          @agents={{@gatheringAgents}}
          @validationContext={{@validationContext.event}}
          data-test="Event::GeneralData" />
  </PlutofPanel::Simple>
  
  <Habitat::EditPanel
      @habitat={{@habitat}}
      @measurements={{@habitatMeasurementsData}}
      @validationContext={{validationContext.habitat}}
      @collapse={{false}}
      data-test="Event::HabitatData" />
  
  <Measurements::AddExtra::Panel
      @measurements={{@measurementsData}}
      @module="samplingevent"
  >
      <Measurements::MeasurementForm
          @object={{@event}}
          @data={{@measurementsData}}
          @row_length={{4}}
          @validationContext={{@validationContext.measurements}} />
  </Measurements::AddExtra::Panel>
  
  <LinkedItems::Edit
      @data={{@associatedData}}
      @validationContext={{@validationContext.associatedData}} />
  
  <InvalidPanels @validationContext={{@validationContext}} />
  
  <div class="action-buttons">
      <Common::SaveButton @save={{@save}} @disabled={{@validationContext.isInvalid}} />
      <Common::CancelButton @cancel={{route-action "goBack"}} />
  </div>
  
  */
  {
    "id": "H9PxKYEO",
    "block": "{\"symbols\":[\"@event\",\"@gatheringAgents\",\"@validationContext\",\"@habitat\",\"@habitatMeasurementsData\",\"@measurementsData\",\"@associatedData\",\"@save\"],\"statements\":[[8,\"sample/sample-breadcrumbs\",[],[[\"@event\"],[[32,1]]],null],[2,\"\\n\\n\"],[8,\"plutof-panel/simple\",[],[[\"@title\"],[[30,[36,0],[\"General.generalData\"],null]]],[[\"default\"],[{\"statements\":[[2,\"\\n    \"],[8,\"sample/event-fields-addedit\",[[24,\"data-test\",\"Event::GeneralData\"]],[[\"@event\",\"@agents\",\"@validationContext\"],[[32,1],[32,2],[32,3,[\"event\"]]]],null],[2,\"\\n\"]],\"parameters\":[]}]]],[2,\"\\n\\n\"],[8,\"habitat/edit-panel\",[[24,\"data-test\",\"Event::HabitatData\"]],[[\"@habitat\",\"@measurements\",\"@validationContext\",\"@collapse\"],[[32,4],[32,5],[34,1,[\"habitat\"]],false]],null],[2,\"\\n\\n\"],[8,\"measurements/add-extra/panel\",[],[[\"@measurements\",\"@module\"],[[32,6],\"samplingevent\"]],[[\"default\"],[{\"statements\":[[2,\"\\n    \"],[8,\"measurements/measurement-form\",[],[[\"@object\",\"@data\",\"@row_length\",\"@validationContext\"],[[32,1],[32,6],4,[32,3,[\"measurements\"]]]],null],[2,\"\\n\"]],\"parameters\":[]}]]],[2,\"\\n\\n\"],[8,\"linked-items/edit\",[],[[\"@data\",\"@validationContext\"],[[32,7],[32,3,[\"associatedData\"]]]],null],[2,\"\\n\\n\"],[8,\"invalid-panels\",[],[[\"@validationContext\"],[[32,3]]],null],[2,\"\\n\\n\"],[10,\"div\"],[14,0,\"action-buttons\"],[12],[2,\"\\n    \"],[8,\"common/save-button\",[],[[\"@save\",\"@disabled\"],[[32,8],[32,3,[\"isInvalid\"]]]],null],[2,\"\\n    \"],[8,\"common/cancel-button\",[],[[\"@cancel\"],[[30,[36,2],[\"goBack\"],null]]],null],[2,\"\\n\"],[13],[2,\"\\n\"]],\"hasEval\":false,\"upvars\":[\"i18n-t\",\"validationContext\",\"route-action\"]}",
    "moduleName": "plutof/components/event/edit.hbs"
  });
  class EventEdit extends Ember.Component.extend(_componentValidations.default) {
    constructor(...args) {
      super(...args);
      _defineProperty(this, "validationChildren", [{
        name: 'event',
        label: 'General.generalData'
      }, {
        name: 'habitat',
        label: 'SamplingEvent.habitat'
      }, {
        name: 'measurements',
        label: 'General.measurements'
      }, {
        name: 'associatedData',
        label: 'General.associatedData'
      }]);
    }
  }
  var _default = _exports.default = Ember._setComponentTemplate(__COLOCATED_TEMPLATE__, EventEdit);
});