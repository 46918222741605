define("plutof/templates/components/compound/cluster-list", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = Ember.HTMLBars.template({
    "id": "Lwag84qR",
    "block": "{\"symbols\":[\"pagination\",\"Field\",\"cluster\"],\"statements\":[[6,[37,2],[[32,0,[\"listPagination\"]]],null,[[\"default\"],[{\"statements\":[[2,\"    \"],[8,\"plutof-panel/headerless\",[],[[],[]],[[\"default\"],[{\"statements\":[[2,\"\\n        \"],[8,\"tables/list\",[],[[\"@records\"],[[32,1,[\"objects\"]]]],[[\"default\"],[{\"statements\":[[2,\"\\n            \"],[8,[32,2],[],[[\"@label\"],[[30,[36,0],[\"clusters.title\"],null]]],[[\"default\"],[{\"statements\":[[2,\"\\n\"],[6,[37,1],null,[[\"route\",\"model\"],[\"compound.view\",[32,3,[\"id\"]]]],[[\"default\"],[{\"statements\":[[2,\"                    \"],[1,[32,3,[\"code\"]]],[2,\"\\n\"]],\"parameters\":[]}]]],[2,\"            \"]],\"parameters\":[]}]]],[2,\"\\n\\n            \"],[8,[32,2],[],[[\"@label\"],[[30,[36,0],[\"clusters.nrOfSeq\"],null]]],[[\"default\"],[{\"statements\":[[2,\"\\n                \"],[1,[32,3,[\"no_sequences\"]]],[2,\"\\n            \"]],\"parameters\":[]}]]],[2,\"\\n\\n            \"],[8,[32,2],[],[[\"@label\"],[[30,[36,0],[\"General.taxon\"],null]]],[[\"default\"],[{\"statements\":[[2,\"\\n                \"],[1,[32,3,[\"mostSpecificTaxonName\"]]],[2,\"\\n            \"]],\"parameters\":[]}]]],[2,\"\\n        \"]],\"parameters\":[2,3]}]]],[2,\"\\n    \"]],\"parameters\":[]}]]],[2,\"\\n\\n    \"],[10,\"p\"],[14,0,\"align-center\"],[12],[2,\"\\n        \"],[8,\"pagination/page-controls\",[],[[\"@pagination\"],[[32,1]]],null],[2,\"\\n    \"],[13],[2,\"\\n\"]],\"parameters\":[1]}]]]],\"hasEval\":false,\"upvars\":[\"i18n-t\",\"link-to\",\"resolve-promise\"]}",
    "moduleName": "plutof/templates/components/compound/cluster-list.hbs"
  });
});