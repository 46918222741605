define("plutof/components/information/article/-list-item", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const __COLOCATED_TEMPLATE__ = Ember.HTMLBars.template(
  /*
    <li>
      {{yield}}
  </li>
  
  */
  {
    "id": "teDzWimi",
    "block": "{\"symbols\":[\"&default\"],\"statements\":[[10,\"li\"],[12],[2,\"\\n    \"],[18,1,null],[2,\"\\n\"],[13],[2,\"\\n\"]],\"hasEval\":false,\"upvars\":[]}",
    "moduleName": "plutof/components/information/article/-list-item.hbs"
  });
  var _default = _exports.default = Ember._setComponentTemplate(__COLOCATED_TEMPLATE__, Ember._templateOnlyComponent());
});