define("plutof/templates/components/generic-views/record", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = Ember.HTMLBars.template({
    "id": "wmJSbpmO",
    "block": "{\"symbols\":[\"&default\",\"@record\"],\"statements\":[[6,[37,3],[[30,[36,2],[[32,2],[32,0,[\"modelPath\"]]],null]],null,[[\"default\"],[{\"statements\":[[2,\"    \"],[18,1,[[30,[36,1],null,[[\"attr\",\"field\",\"link\"],[[30,[36,0],[\"generic-views/-record/attr\"],[[\"context\"],[[32,0]]]],[30,[36,0],[\"generic-views/-record/field\"],[[\"context\"],[[32,0]]]],[30,[36,0],[\"generic-views/-record/link\"],[[\"context\"],[[32,0]]]]]]]]],[2,\"\\n\"]],\"parameters\":[]}]]]],\"hasEval\":false,\"upvars\":[\"component\",\"hash\",\"and\",\"if\"]}",
    "moduleName": "plutof/templates/components/generic-views/record.hbs"
  });
});