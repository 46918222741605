define("plutof/templates/components/person-label-tooltip", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = Ember.HTMLBars.template({
    "id": "KCdi7xrs",
    "block": "{\"symbols\":[\"t\",\"info\"],\"statements\":[[6,[37,8],null,null,[[\"default\"],[{\"statements\":[[2,\"    \"],[10,\"div\"],[15,0,[31,[[34,6]]]],[12],[2,\"\\n        \"],[1,[35,1,[\"labelText\"]]],[2,\"\\n    \"],[13],[2,\"\\n\\n\"],[6,[37,7],[[35,1,[\"tooltipText\"]]],null,[[\"default\"],[{\"statements\":[[2,\"        \"],[10,\"span\"],[15,1,[31,[[32,1,[\"tid\"]]]]],[14,0,\"wrapper-tooltip\"],[12],[2,\"\\n            \"],[1,[30,[36,2],[[35,1,[\"tooltipText\"]]],null]],[2,\" \"],[10,\"br\"],[12],[13],[2,\"\\n\\n            \"],[1,[30,[36,3],[\"Agents.personTooltip\"],null]],[2,\"\\n\\n\"],[6,[37,5],[[30,[36,4],[[30,[36,4],[[35,1,[\"infoLinks\"]]],null]],null]],null,[[\"default\"],[{\"statements\":[[2,\"                \"],[1,[30,[36,0],null,[[\"infoLink\"],[[32,2,[\"url\"]]]]]],[2,\"\\n\"]],\"parameters\":[2]}]]],[2,\"        \"],[13],[2,\"\\n\"]],\"parameters\":[]}]]]],\"parameters\":[1]}]]]],\"hasEval\":false,\"upvars\":[\"info-link\",\"data\",\"break-line\",\"i18n-t\",\"-track-array\",\"each\",\"labelClasses\",\"if\",\"tooltip-wrapper\"]}",
    "moduleName": "plutof/templates/components/person-label-tooltip.hbs"
  });
});