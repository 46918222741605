define("plutof/components/test/route-marker", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const __COLOCATED_TEMPLATE__ = Ember.HTMLBars.template(
  /*
    <div data-test="route-{{@route}}"></div>
  
  */
  {
    "id": "NtHqWCBc",
    "block": "{\"symbols\":[\"@route\"],\"statements\":[[10,\"div\"],[15,\"data-test\",[31,[\"route-\",[32,1]]]],[12],[13],[2,\"\\n\"]],\"hasEval\":false,\"upvars\":[]}",
    "moduleName": "plutof/components/test/route-marker.hbs"
  });
  var _default = _exports.default = Ember._setComponentTemplate(__COLOCATED_TEMPLATE__, Ember._templateOnlyComponent());
});