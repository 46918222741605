define("plutof/components/information/content/general/area-project-filter", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const __COLOCATED_TEMPLATE__ = Ember.HTMLBars.template(
  /*
    {{#if @locale.eng}}
      When taxon occurrence <strong>project</strong> has been set, <strong>Parent area</strong> and <strong>Existing area</strong> can be selected only from the project's areas.
  {{else}}
      Kui taksoni esinemisel on valitud <strong>projekt</strong>, saab <strong>ülem ala</strong> ja <strong>olemasolevat ala</strong> valida ainult selle projekti alade hulgast.
  {{/if}}
  
  */
  {
    "id": "U7lccnA+",
    "block": "{\"symbols\":[\"@locale\"],\"statements\":[[6,[37,0],[[32,1,[\"eng\"]]],null,[[\"default\",\"else\"],[{\"statements\":[[2,\"    When taxon occurrence \"],[10,\"strong\"],[12],[2,\"project\"],[13],[2,\" has been set, \"],[10,\"strong\"],[12],[2,\"Parent area\"],[13],[2,\" and \"],[10,\"strong\"],[12],[2,\"Existing area\"],[13],[2,\" can be selected only from the project's areas.\\n\"]],\"parameters\":[]},{\"statements\":[[2,\"    Kui taksoni esinemisel on valitud \"],[10,\"strong\"],[12],[2,\"projekt\"],[13],[2,\", saab \"],[10,\"strong\"],[12],[2,\"ülem ala\"],[13],[2,\" ja \"],[10,\"strong\"],[12],[2,\"olemasolevat ala\"],[13],[2,\" valida ainult selle projekti alade hulgast.\\n\"]],\"parameters\":[]}]]]],\"hasEval\":false,\"upvars\":[\"if\"]}",
    "moduleName": "plutof/components/information/content/general/area-project-filter.hbs"
  });
  var _default = _exports.default = Ember._setComponentTemplate(__COLOCATED_TEMPLATE__, Ember._templateOnlyComponent());
});