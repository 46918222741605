define("plutof/components/annotation/-fields/link", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const __COLOCATED_TEMPLATE__ = Ember.HTMLBars.template(
  /*
    <@field @label={{i18n-t "annotation.annotation"}}>
      <Annotation::-Link
          @annotation={{@annotation}}
          @highlight={{@highlight}} />
  </@field>
  
  */
  {
    "id": "qcP4ke2r",
    "block": "{\"symbols\":[\"@field\",\"@annotation\",\"@highlight\"],\"statements\":[[8,[32,1],[],[[\"@label\"],[[30,[36,0],[\"annotation.annotation\"],null]]],[[\"default\"],[{\"statements\":[[2,\"\\n    \"],[8,\"annotation/-link\",[],[[\"@annotation\",\"@highlight\"],[[32,2],[32,3]]],null],[2,\"\\n\"]],\"parameters\":[]}]]],[2,\"\\n\"]],\"hasEval\":false,\"upvars\":[\"i18n-t\"]}",
    "moduleName": "plutof/components/annotation/-fields/link.hbs"
  });
  var _default = _exports.default = Ember._setComponentTemplate(__COLOCATED_TEMPLATE__, Ember._templateOnlyComponent());
});