define("plutof/components/analysis/ml-sh/predictions", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const __COLOCATED_TEMPLATE__ = Ember.HTMLBars.template(
  /*
    <ol class="analysis-model-predictions">
      {{yield (hash item=(component "analysis/ml-sh/-predictions-item"))}}
  </ol>
  
  */
  {
    "id": "1OCAV+sl",
    "block": "{\"symbols\":[\"&default\"],\"statements\":[[10,\"ol\"],[14,0,\"analysis-model-predictions\"],[12],[2,\"\\n    \"],[18,1,[[30,[36,1],null,[[\"item\"],[[30,[36,0],[\"analysis/ml-sh/-predictions-item\"],null]]]]]],[2,\"\\n\"],[13],[2,\"\\n\"]],\"hasEval\":false,\"upvars\":[\"component\",\"hash\"]}",
    "moduleName": "plutof/components/analysis/ml-sh/predictions.hbs"
  });
  var _default = _exports.default = Ember._setComponentTemplate(__COLOCATED_TEMPLATE__, Ember._templateOnlyComponent());
});