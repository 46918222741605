define("plutof/templates/components/measurements/method-description", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = Ember.HTMLBars.template({
    "id": "UEZT6hT/",
    "block": "{\"symbols\":[],\"statements\":[[10,\"div\"],[14,0,\"measurement-method__toggle\"],[12],[2,\"\\n    \"],[11,\"button\"],[24,0,\"btn-fake-link\"],[4,[38,2],[[32,0],\"toggle\"],null],[12],[2,\"\\n\"],[6,[37,3],[[32,0,[\"open\"]]],null,[[\"default\",\"else\"],[{\"statements\":[[2,\"            \"],[10,\"span\"],[14,0,\"fas fa-caret-up\"],[12],[13],[2,\"\\n\"]],\"parameters\":[]},{\"statements\":[[2,\"            \"],[10,\"span\"],[14,0,\"fas fa-caret-down\"],[12],[13],[2,\"\\n\"]],\"parameters\":[]}]]],[2,\"    \"],[13],[2,\"\\n\"],[13],[2,\"\\n\\n\"],[6,[37,3],[[32,0,[\"open\"]]],null,[[\"default\"],[{\"statements\":[[2,\"    \"],[10,\"div\"],[14,0,\"measurement-method__popup\"],[12],[2,\"\\n        \"],[10,\"div\"],[14,0,\"measurement-method__content\"],[12],[2,\"\\n\"],[2,\"            \"],[10,\"div\"],[14,0,\"plutof-label\"],[12],[2,\"\\n                \"],[1,[30,[36,0],[\"General.measurementMethod\"],null]],[2,\"\\n            \"],[13],[2,\"\\n\\n            \"],[8,\"flexible-textarea\",[[24,0,\"form-control\"]],[[\"@value\"],[[34,1]]],null],[2,\"\\n        \"],[13],[2,\"\\n    \"],[13],[2,\"\\n\"]],\"parameters\":[]}]]]],\"hasEval\":false,\"upvars\":[\"i18n-t\",\"method\",\"action\",\"if\"]}",
    "moduleName": "plutof/templates/components/measurements/method-description.hbs"
  });
});