define("plutof/components/tables/remove-button", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const __COLOCATED_TEMPLATE__ = Ember.HTMLBars.template(
  /*
    <button
      {{on "click" @clicked}}
      class="btn btn-link no-padding"
  >
      <span class={{icon "remove"}}></span>
  </button>
  
  */
  {
    "id": "DVum5r6s",
    "block": "{\"symbols\":[\"@clicked\"],\"statements\":[[11,\"button\"],[24,0,\"btn btn-link no-padding\"],[4,[38,0],[\"click\",[32,1]],null],[12],[2,\"\\n    \"],[10,\"span\"],[15,0,[30,[36,1],[\"remove\"],null]],[12],[13],[2,\"\\n\"],[13],[2,\"\\n\"]],\"hasEval\":false,\"upvars\":[\"on\",\"icon\"]}",
    "moduleName": "plutof/components/tables/remove-button.hbs"
  });
  var _default = _exports.default = Ember._setComponentTemplate(__COLOCATED_TEMPLATE__, Ember._templateOnlyComponent());
});