define("plutof/components/annotation/-summary/apidiff1", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const __COLOCATED_TEMPLATE__ = Ember.HTMLBars.template(
  /*
    <Annotation::-Summary::Changes
      @changes={{@annotation.changes}}
      @containsPreviousValues={{false}} />
  
  */
  {
    "id": "2OWfQQm9",
    "block": "{\"symbols\":[\"@annotation\"],\"statements\":[[8,\"annotation/-summary/changes\",[],[[\"@changes\",\"@containsPreviousValues\"],[[32,1,[\"changes\"]],false]],null],[2,\"\\n\"]],\"hasEval\":false,\"upvars\":[]}",
    "moduleName": "plutof/components/annotation/-summary/apidiff1.hbs"
  });
  var _default = _exports.default = Ember._setComponentTemplate(__COLOCATED_TEMPLATE__, Ember._templateOnlyComponent());
});