define("plutof/templates/components/experiment/common/labware-tube", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = Ember.HTMLBars.template({
    "id": "GHkhMAgn",
    "block": "{\"symbols\":[\"t\",\"shown\",\"@material\",\"@materialType\",\"@disable\",\"@selected\"],\"statements\":[[8,\"tooltip-wrapper\",[],[[],[]],[[\"default\"],[{\"statements\":[[2,\"\\n    \"],[11,\"button\"],[16,\"disabled\",[32,5]],[16,0,[31,[\"labware-tube-container \",[30,[36,1],[[32,6],\"labware-tube-container--selected\"],null]]]],[4,[38,2],[[32,0],[32,0,[\"selectMaterial\"]],[32,3]],null],[12],[2,\"\\n        \"],[10,\"div\"],[15,0,[31,[\"labware-tube \",[30,[36,1],[[32,6],\"labware-tube--selected\"],null]]]],[12],[2,\"\\n            \"],[10,\"div\"],[14,0,\"labware-tube__label\"],[12],[2,\"\\n                \"],[1,[32,0,[\"tubeLabel\"]]],[2,\"\\n            \"],[13],[2,\"\\n        \"],[13],[2,\"\\n\\n        \"],[10,\"div\"],[15,1,[31,[[32,1,[\"tid\"]]]]],[14,0,\"wrapper-tooltip\"],[12],[2,\"\\n\"],[6,[37,1],[[32,2]],null,[[\"default\"],[{\"statements\":[[6,[37,1],[[30,[36,0],[[32,4],\"dna\"],null]],null,[[\"default\",\"else\"],[{\"statements\":[[2,\"                    \"],[8,\"experiment/dna-extraction/view/tube-summary\",[],[[\"@material\",\"@source\"],[[32,3],[32,0,[\"sourceRecord\"]]]],null],[2,\"\\n\"]],\"parameters\":[]},{\"statements\":[[6,[37,1],[[30,[36,0],[[32,4],\"pcr\"],null]],null,[[\"default\",\"else\"],[{\"statements\":[[2,\"                    \"],[8,\"experiment/pcr/view/tube-summary\",[],[[\"@material\",\"@source\"],[[32,3],[32,0,[\"sourceRecord\"]]]],null],[2,\"\\n\"]],\"parameters\":[]},{\"statements\":[[6,[37,1],[[30,[36,0],[[32,4],\"sequencing\"],null]],null,[[\"default\"],[{\"statements\":[[2,\"                    \"],[8,\"experiment/sequencing/view/tube-summary\",[],[[\"@material\",\"@source\"],[[32,3],[32,0,[\"sourceRecord\"]]]],null],[2,\"\\n                \"]],\"parameters\":[]}]]]],\"parameters\":[]}]]]],\"parameters\":[]}]]]],\"parameters\":[]}]]],[2,\"        \"],[13],[2,\"\\n    \"],[13],[2,\"\\n\"]],\"parameters\":[1,2]}]]],[2,\"\\n\"]],\"hasEval\":false,\"upvars\":[\"is-equal\",\"if\",\"action\"]}",
    "moduleName": "plutof/templates/components/experiment/common/labware-tube.hbs"
  });
});