define("plutof/components/annotation/bulk/records", ["exports", "@glimmer/component", "plutof/utils/modules"], function (_exports, _component, _modules) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const __COLOCATED_TEMPLATE__ = Ember.HTMLBars.template(
  /*
    <RelatedObjects::View
      @title={{i18n-t "annotation.routes.bulk.add.records"}}
      @pagination={{@records}}
  >
      <RelatedObjects::Content @pagination={{@records}} as |Field record|>
          <Field @label={{i18n-t "PlutofListViewColumn.name"}}>
              {{#link-to this.module.routes.view record.id}}
                  {{record.name}}
              {{/link-to}}
          </Field>
      </RelatedObjects::Content>
  </RelatedObjects::View>
  
  */
  {
    "id": "Vb3GkGIn",
    "block": "{\"symbols\":[\"Field\",\"record\",\"@records\"],\"statements\":[[8,\"related-objects/view\",[],[[\"@title\",\"@pagination\"],[[30,[36,0],[\"annotation.routes.bulk.add.records\"],null],[32,3]]],[[\"default\"],[{\"statements\":[[2,\"\\n    \"],[8,\"related-objects/content\",[],[[\"@pagination\"],[[32,3]]],[[\"default\"],[{\"statements\":[[2,\"\\n        \"],[8,[32,1],[],[[\"@label\"],[[30,[36,0],[\"PlutofListViewColumn.name\"],null]]],[[\"default\"],[{\"statements\":[[2,\"\\n\"],[6,[37,1],null,[[\"route\",\"model\"],[[32,0,[\"module\",\"routes\",\"view\"]],[32,2,[\"id\"]]]],[[\"default\"],[{\"statements\":[[2,\"                \"],[1,[32,2,[\"name\"]]],[2,\"\\n\"]],\"parameters\":[]}]]],[2,\"        \"]],\"parameters\":[]}]]],[2,\"\\n    \"]],\"parameters\":[1,2]}]]],[2,\"\\n\"]],\"parameters\":[]}]]],[2,\"\\n\"]],\"hasEval\":false,\"upvars\":[\"i18n-t\",\"link-to\"]}",
    "moduleName": "plutof/components/annotation/bulk/records.hbs"
  });
  class BulkAnnotationRecords extends _component.default {
    get module() {
      return _modules.default[this.args.contentType];
    }
  }
  var _default = _exports.default = Ember._setComponentTemplate(__COLOCATED_TEMPLATE__, BulkAnnotationRecords);
});