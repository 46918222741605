define("plutof/components/tables/row-selector/selector", ["exports", "@glimmer/component"], function (_exports, _component) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const __COLOCATED_TEMPLATE__ = Ember.HTMLBars.template(
  /*
    <Input
      @type="checkbox"
      @checked={{this.selected}}
      @disabled={{@disabled}}
      class="table-row-selector__selector" />
  
  */
  {
    "id": "tWsUTvb8",
    "block": "{\"symbols\":[\"@disabled\"],\"statements\":[[8,\"input\",[[24,0,\"table-row-selector__selector\"]],[[\"@type\",\"@checked\",\"@disabled\"],[\"checkbox\",[32,0,[\"selected\"]],[32,1]]],null],[2,\"\\n\"]],\"hasEval\":false,\"upvars\":[]}",
    "moduleName": "plutof/components/tables/row-selector/selector.hbs"
  });
  class TableRowSelectorItem extends _component.default {
    get selected() {
      return Boolean(this.getSelf());
    }
    getSelf() {
      return this.args.selection.find(v => this.args.equality(v, this.args.item));
    }
    set selected(value) {
      if (value) {
        this.args.selection.pushObject(this.args.item);
      } else {
        const match = this.getSelf();
        this.args.selection.removeObject(match);
      }
      return value;
    }
  }
  _exports.default = TableRowSelectorItem;
  Ember._setComponentTemplate(__COLOCATED_TEMPLATE__, TableRowSelectorItem);
});