define("plutof/components/filerepository/local-file-preview", ["exports", "@glimmer/component"], function (_exports, _component) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _dec, _dec2, _dec3, _dec4, _class, _descriptor, _descriptor2;
  function _initializerDefineProperty(e, i, r, l) { r && Object.defineProperty(e, i, { enumerable: r.enumerable, configurable: r.configurable, writable: r.writable, value: r.initializer ? r.initializer.call(l) : void 0 }); }
  function _defineProperty(e, r, t) { return (r = _toPropertyKey(r)) in e ? Object.defineProperty(e, r, { value: t, enumerable: !0, configurable: !0, writable: !0 }) : e[r] = t, e; }
  function _toPropertyKey(t) { var i = _toPrimitive(t, "string"); return "symbol" == typeof i ? i : i + ""; }
  function _toPrimitive(t, r) { if ("object" != typeof t || !t) return t; var e = t[Symbol.toPrimitive]; if (void 0 !== e) { var i = e.call(t, r || "default"); if ("object" != typeof i) return i; throw new TypeError("@@toPrimitive must return a primitive value."); } return ("string" === r ? String : Number)(t); }
  function _applyDecoratedDescriptor(i, e, r, n, l) { var a = {}; return Object.keys(n).forEach(function (i) { a[i] = n[i]; }), a.enumerable = !!a.enumerable, a.configurable = !!a.configurable, ("value" in a || a.initializer) && (a.writable = !0), a = r.slice().reverse().reduce(function (r, n) { return n(i, e, r) || r; }, a), l && void 0 !== a.initializer && (a.value = a.initializer ? a.initializer.call(l) : void 0, a.initializer = void 0), void 0 === a.initializer ? (Object.defineProperty(i, e, a), null) : a; }
  function _initializerWarningHelper(r, e) { throw Error("Decorating class property failed. Please ensure that transform-class-properties is enabled and runs after the decorators transform."); }
  const __COLOCATED_TEMPLATE__ = Ember.HTMLBars.template(
  /*
    {{yield (hash
      preview=this.preview
      previewButton=(component "filerepository/-local-file-preview/button" preview=this.preview)
  )}}
  
  <Ui::Modal::Dialog
      @open={{this.open}}
      @close={{this.close}}
  >
      <:body>
          <img src={{this.imageContent}} class="local-file-preview-image">
      </:body>
  </Ui::Modal::Dialog>
  
  */
  {
    "id": "wwMHKr0i",
    "block": "{\"symbols\":[\"__arg0\",\"&default\"],\"statements\":[[18,2,[[30,[36,1],null,[[\"preview\",\"previewButton\"],[[32,0,[\"preview\"]],[30,[36,0],[\"filerepository/-local-file-preview/button\"],[[\"preview\"],[[32,0,[\"preview\"]]]]]]]]]],[2,\"\\n\\n\"],[8,\"ui/modal/dialog\",[],[[\"@open\",\"@close\",\"@namedBlocksInfo\"],[[32,0,[\"open\"]],[32,0,[\"close\"]],[30,[36,1],null,[[\"body\"],[0]]]]],[[\"default\"],[{\"statements\":[[6,[37,3],[[30,[36,2],[[32,1],\"body\"],null]],null,[[\"default\"],[{\"statements\":[[2,\"\\n        \"],[10,\"img\"],[15,\"src\",[32,0,[\"imageContent\"]]],[14,0,\"local-file-preview-image\"],[12],[13],[2,\"\\n    \"]],\"parameters\":[]}]]]],\"parameters\":[1]}]]],[2,\"\\n\"]],\"hasEval\":false,\"upvars\":[\"component\",\"hash\",\"-is-named-block-invocation\",\"if\"]}",
    "moduleName": "plutof/components/filerepository/local-file-preview.hbs"
  });
  let LocalFilePreview = (_dec = Ember._tracked, _dec2 = Ember._tracked, _dec3 = Ember._action, _dec4 = Ember._action, _class = class LocalFilePreview extends _component.default {
    constructor(...args) {
      super(...args);
      _initializerDefineProperty(this, "imageContent", _descriptor, this);
      _initializerDefineProperty(this, "open", _descriptor2, this);
    }
    preview(file) {
      const reader = new FileReader();
      reader.onload = event => {
        this.imageContent = event.target.result;
        this.open = true;
      };
      reader.readAsDataURL(file);
    }
    close() {
      this.open = false;
    }
  }, _descriptor = _applyDecoratedDescriptor(_class.prototype, "imageContent", [_dec], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor2 = _applyDecoratedDescriptor(_class.prototype, "open", [_dec2], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return false;
    }
  }), _applyDecoratedDescriptor(_class.prototype, "preview", [_dec3], Object.getOwnPropertyDescriptor(_class.prototype, "preview"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "close", [_dec4], Object.getOwnPropertyDescriptor(_class.prototype, "close"), _class.prototype), _class);
  var _default = _exports.default = Ember._setComponentTemplate(__COLOCATED_TEMPLATE__, LocalFilePreview);
});