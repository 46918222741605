define("plutof/components/search/filters/-label-control-toggle", ["exports", "@glimmer/component"], function (_exports, _component) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _dec, _class;
  function _applyDecoratedDescriptor(i, e, r, n, l) { var a = {}; return Object.keys(n).forEach(function (i) { a[i] = n[i]; }), a.enumerable = !!a.enumerable, a.configurable = !!a.configurable, ("value" in a || a.initializer) && (a.writable = !0), a = r.slice().reverse().reduce(function (r, n) { return n(i, e, r) || r; }, a), l && void 0 !== a.initializer && (a.value = a.initializer ? a.initializer.call(l) : void 0, a.initializer = void 0), void 0 === a.initializer ? (Object.defineProperty(i, e, a), null) : a; }
  const __COLOCATED_TEMPLATE__ = Ember.HTMLBars.template(
  /*
    {{#if @filter}}
      <ToolTip @tooltip={{@tooltip}}>
          <button
              {{on "click" this.toggleFilter}}
              disabled={{@disabled}}
              class="label-control__btn {{if @filter.value 'label-control__btn--active'}}"
          >
              {{@label}}
          </button>
      </ToolTip>
  {{/if}}
  
  */
  {
    "id": "kZDZDUBM",
    "block": "{\"symbols\":[\"@tooltip\",\"@disabled\",\"@filter\",\"@label\"],\"statements\":[[6,[37,0],[[32,3]],null,[[\"default\"],[{\"statements\":[[2,\"    \"],[8,\"tool-tip\",[],[[\"@tooltip\"],[[32,1]]],[[\"default\"],[{\"statements\":[[2,\"\\n        \"],[11,\"button\"],[16,\"disabled\",[32,2]],[16,0,[31,[\"label-control__btn \",[30,[36,0],[[32,3,[\"value\"]],\"label-control__btn--active\"],null]]]],[4,[38,1],[\"click\",[32,0,[\"toggleFilter\"]]],null],[12],[2,\"\\n            \"],[1,[32,4]],[2,\"\\n        \"],[13],[2,\"\\n    \"]],\"parameters\":[]}]]],[2,\"\\n\"]],\"parameters\":[]}]]]],\"hasEval\":false,\"upvars\":[\"if\",\"on\"]}",
    "moduleName": "plutof/components/search/filters/-label-control-toggle.hbs"
  });
  let SearchLabelControlToggle = _exports.default = (_dec = Ember._action, _class = class SearchLabelControlToggle extends _component.default {
    toggleFilter() {
      const newValue = !this.args.filter.value;
      Ember.set(this.args.filter, 'value', newValue);
      if (this.args.valueChanged) {
        this.args.valueChanged(newValue);
      }
    }
  }, _applyDecoratedDescriptor(_class.prototype, "toggleFilter", [_dec], Object.getOwnPropertyDescriptor(_class.prototype, "toggleFilter"), _class.prototype), _class);
  Ember._setComponentTemplate(__COLOCATED_TEMPLATE__, SearchLabelControlToggle);
});