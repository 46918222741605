define("plutof/templates/area/add", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = Ember.HTMLBars.template({
    "id": "39rSLYbR",
    "block": "{\"symbols\":[],\"statements\":[[8,\"test/route-marker\",[],[[\"@route\"],[\"area.add\"]],null],[2,\"\\n\\n\"],[8,\"navbar/edit\",[],[[\"@icon\",\"@title\",\"@record\",\"@reset\",\"@infoContent\"],[[30,[36,0],[\"area\"],null],[30,[36,1],[\"Sample.newSArea\"],null],[32,0,[\"model\",\"samplingarea\"]],[30,[36,2],[\"triggerReset\"],null],\"information/content/general/area-event\"]],null],[2,\"\\n\\n\"],[8,\"area/edit\",[],[[\"@area\",\"@measurementsData\",\"@geometryConverterData\",\"@associatedData\",\"@accessRights\",\"@validationContext\",\"@save\"],[[32,0,[\"model\",\"samplingarea\"]],[32,0,[\"model\",\"measurementsData\"]],[32,0,[\"model\",\"geometryConverterData\"]],[32,0,[\"model\",\"itemData\"]],[32,0,[\"model\",\"accessRights\"]],[32,0,[\"validationContext\"]],[32,0,[\"save\"]]]],null],[2,\"\\n\"]],\"hasEval\":false,\"upvars\":[\"icon\",\"i18n-t\",\"route-action\"]}",
    "moduleName": "plutof/templates/area/add.hbs"
  });
});