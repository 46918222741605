define("plutof/templates/components/analysis/matching-source-clipboard-items", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = Ember.HTMLBars.template({
    "id": "kcAJ8SFZ",
    "block": "{\"symbols\":[\"sequence\",\"__arg0\",\"@pagination\"],\"statements\":[[10,\"tr\"],[12],[2,\"\\n    \"],[10,\"td\"],[14,\"colspan\",\"3\"],[12],[2,\"\\n        \"],[8,\"layout/split-row\",[],[[\"@namedBlocksInfo\"],[[30,[36,3],null,[[\"left\",\"right\"],[0,0]]]]],[[\"default\"],[{\"statements\":[[6,[37,1],[[30,[36,0],[[32,2],\"left\"],null]],null,[[\"default\",\"else\"],[{\"statements\":[[2,\"\\n                \"],[10,\"label\"],[14,0,\"analysis-matching-source__clipboard-items-header-label\"],[12],[2,\"\\n                    \"],[1,[30,[36,2],[\"Analysis.clipboardItems\"],[[\"count\"],[[32,3,[\"objectCount\"]]]]]],[2,\"\\n                \"],[13],[2,\"\\n            \"]],\"parameters\":[]},{\"statements\":[[6,[37,1],[[30,[36,0],[[32,2],\"right\"],null]],null,[[\"default\"],[{\"statements\":[[2,\"\\n                \"],[8,\"pagination/page-controls\",[],[[\"@pagination\",\"@removeSpacing\"],[[32,3],true]],null],[2,\"\\n            \"]],\"parameters\":[]}]]]],\"parameters\":[]}]]]],\"parameters\":[2]}]]],[2,\"\\n    \"],[13],[2,\"\\n\"],[13],[2,\"\\n\\n\"],[6,[37,5],[[30,[36,4],[[30,[36,4],[[32,3,[\"objects\"]]],null]],null]],null,[[\"default\"],[{\"statements\":[[2,\"    \"],[10,\"tr\"],[12],[2,\"\\n        \"],[10,\"td\"],[14,\"colspan\",\"3\"],[14,0,\"analysis-matching-source__clipboard-cell mini-input item-table__read-only-value\"],[12],[2,\"\\n            \"],[1,[32,1,[\"accno\"]]],[2,\"\\n        \"],[13],[2,\"\\n    \"],[13],[2,\"\\n\"]],\"parameters\":[1]}]]]],\"hasEval\":false,\"upvars\":[\"-is-named-block-invocation\",\"if\",\"i18n-t\",\"hash\",\"-track-array\",\"each\"]}",
    "moduleName": "plutof/templates/components/analysis/matching-source-clipboard-items.hbs"
  });
});