define("plutof/components/taxonomy/tree-attributes", ["exports", "@glimmer/component", "plutof/utils/validations"], function (_exports, _component, _validations) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _dec, _dec2, _class;
  function _applyDecoratedDescriptor(i, e, r, n, l) { var a = {}; return Object.keys(n).forEach(function (i) { a[i] = n[i]; }), a.enumerable = !!a.enumerable, a.configurable = !!a.configurable, ("value" in a || a.initializer) && (a.writable = !0), a = r.slice().reverse().reduce(function (r, n) { return n(i, e, r) || r; }, a), l && void 0 !== a.initializer && (a.value = a.initializer ? a.initializer.call(l) : void 0, a.initializer = void 0), void 0 === a.initializer ? (Object.defineProperty(i, e, a), null) : a; }
  const __COLOCATED_TEMPLATE__ = Ember.HTMLBars.template(
  /*
    <PlutofPanel::Simple
      @title={{i18n-t "Taxonomy.treeData"}}
      data-test="Taxonomy::TreeAttributes"
  >
      {{#if @rootNode}}
          <div>
              <label>{{i18n-t "Taxonomy.higherTaxonNodeLineage"}}</label>
              {{this.lineage}}
          </div>
      {{/if}}
  
      <div class="{{unless this.nameIsValid 'has-error'}}">
          {{plutof-labelc "tree.name"}}
  
          <Input
              @type="text"
              @value={{@name}}
              class="form-control default-focus-control"
              data-test="name" />
      </div>
  
      <div>
          {{plutof-labelc "tree.root_node"}}
  
          {{#async/bind-relation @rootNode as |value update|}}
              <Taxonomy::Taxon::AutoComplete
                  @value={{value}}
                  @update={{update}}
                  data-test="root_node" />
          {{/async/bind-relation}}
      </div>
  
      <div>
          {{plutof-labelc "tree.origin_tree"}}
  
          {{#async/bind-relation @originTree as |value update|}}
              <AutoComplete::Model
                  @model="taxonomy/tree"
                  @value={{value}}
                  @update={{update}}
                  data-test="origin_tree" />
          {{/async/bind-relation}}
      </div>
  </PlutofPanel::Simple>
  
  */
  {
    "id": "WUMOddN2",
    "block": "{\"symbols\":[\"value\",\"update\",\"value\",\"update\",\"@rootNode\",\"@name\",\"@originTree\"],\"statements\":[[8,\"plutof-panel/simple\",[[24,\"data-test\",\"Taxonomy::TreeAttributes\"]],[[\"@title\"],[[30,[36,0],[\"Taxonomy.treeData\"],null]]],[[\"default\"],[{\"statements\":[[2,\"\\n\"],[6,[37,1],[[32,5]],null,[[\"default\"],[{\"statements\":[[2,\"        \"],[10,\"div\"],[12],[2,\"\\n            \"],[10,\"label\"],[12],[1,[30,[36,0],[\"Taxonomy.higherTaxonNodeLineage\"],null]],[13],[2,\"\\n            \"],[1,[32,0,[\"lineage\"]]],[2,\"\\n        \"],[13],[2,\"\\n\"]],\"parameters\":[]}]]],[2,\"\\n    \"],[10,\"div\"],[15,0,[31,[[30,[36,2],[[32,0,[\"nameIsValid\"]],\"has-error\"],null]]]],[12],[2,\"\\n        \"],[1,[30,[36,3],[\"tree.name\"],null]],[2,\"\\n\\n        \"],[8,\"input\",[[24,0,\"form-control default-focus-control\"],[24,\"data-test\",\"name\"]],[[\"@type\",\"@value\"],[\"text\",[32,6]]],null],[2,\"\\n    \"],[13],[2,\"\\n\\n    \"],[10,\"div\"],[12],[2,\"\\n        \"],[1,[30,[36,3],[\"tree.root_node\"],null]],[2,\"\\n\\n\"],[6,[37,4],[[32,5]],null,[[\"default\"],[{\"statements\":[[2,\"            \"],[8,\"taxonomy/taxon/auto-complete\",[[24,\"data-test\",\"root_node\"]],[[\"@value\",\"@update\"],[[32,3],[32,4]]],null],[2,\"\\n\"]],\"parameters\":[3,4]}]]],[2,\"    \"],[13],[2,\"\\n\\n    \"],[10,\"div\"],[12],[2,\"\\n        \"],[1,[30,[36,3],[\"tree.origin_tree\"],null]],[2,\"\\n\\n\"],[6,[37,4],[[32,7]],null,[[\"default\"],[{\"statements\":[[2,\"            \"],[8,\"auto-complete/model\",[[24,\"data-test\",\"origin_tree\"]],[[\"@model\",\"@value\",\"@update\"],[\"taxonomy/tree\",[32,1],[32,2]]],null],[2,\"\\n\"]],\"parameters\":[1,2]}]]],[2,\"    \"],[13],[2,\"\\n\"]],\"parameters\":[]}]]],[2,\"\\n\"]],\"hasEval\":false,\"upvars\":[\"i18n-t\",\"if\",\"unless\",\"plutof-labelc\",\"async/bind-relation\"]}",
    "moduleName": "plutof/components/taxonomy/tree-attributes.hbs"
  });
  let TreeAttributes = (_dec = Ember.computed('args.name'), _dec2 = Ember.computed('args.rootNode.lineage.@each.representation'), _class = class TreeAttributes extends _component.default {
    get nameIsValid() {
      return this.args.name.length > 0;
    }
    get lineage() {
      return this.args.rootNode.lineage.mapBy('representation').join(' / ');
    }
  }, _applyDecoratedDescriptor(_class.prototype, "nameIsValid", [_validations.validator, _dec], Object.getOwnPropertyDescriptor(_class.prototype, "nameIsValid"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "lineage", [_dec2], Object.getOwnPropertyDescriptor(_class.prototype, "lineage"), _class.prototype), _class);
  var _default = _exports.default = Ember._setComponentTemplate(__COLOCATED_TEMPLATE__, TreeAttributes);
});