define("plutof/templates/components/search/repository-input", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = Ember.HTMLBars.template({
    "id": "zlWmeaQW",
    "block": "{\"symbols\":[\"add\",\"@repositoryFilter\",\"@subrepositoryFilter\"],\"statements\":[[8,\"layout/regular-grid\",[[24,0,\"filter-input\"]],[[\"@columns\"],[2]],[[\"default\"],[{\"statements\":[[2,\"\\n    \"],[10,\"div\"],[12],[2,\"\\n        \"],[10,\"div\"],[14,0,\"plutof-label\"],[12],[2,\"\\n            \"],[1,[32,2,[\"config\",\"label\"]]],[2,\"\\n        \"],[13],[2,\"\\n\\n        \"],[8,\"collection/auto-complete\",[],[[\"@value\",\"@update\"],[[32,0,[\"collection\"]],[32,0,[\"updateCollection\"]]]],null],[2,\"\\n    \"],[13],[2,\"\\n\\n    \"],[10,\"div\"],[12],[2,\"\\n        \"],[10,\"div\"],[14,0,\"plutof-label\"],[12],[1,[32,3,[\"config\",\"label\"]]],[13],[2,\"\\n\\n        \"],[8,\"auto-complete/bind-multiple\",[],[[\"@value\"],[[32,3,[\"value\"]]]],[[\"default\"],[{\"statements\":[[2,\"\\n            \"],[8,\"auto-complete/model\",[],[[\"@model\",\"@update\",\"@filters\",\"@disabled\"],[\"agent/repository\",[32,1],[32,0,[\"subrepositoryAutocompleteFilters\"]],[32,0,[\"noRepository\"]]]],null],[2,\"\\n        \"]],\"parameters\":[1]}]]],[2,\"\\n    \"],[13],[2,\"\\n\"]],\"parameters\":[]}]]],[2,\"\\n\"]],\"hasEval\":false,\"upvars\":[]}",
    "moduleName": "plutof/templates/components/search/repository-input.hbs"
  });
});