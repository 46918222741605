define("plutof/components/information/content/event/add", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const __COLOCATED_TEMPLATE__ = Ember.HTMLBars.template(
  /*
    <Information::Content::General::AreaEvent @locale={{@locale}} />
  
  */
  {
    "id": "29Cb6dAS",
    "block": "{\"symbols\":[\"@locale\"],\"statements\":[[8,\"information/content/general/area-event\",[],[[\"@locale\"],[[32,1]]],null],[2,\"\\n\"]],\"hasEval\":false,\"upvars\":[]}",
    "moduleName": "plutof/components/information/content/event/add.hbs"
  });
  var _default = _exports.default = Ember._setComponentTemplate(__COLOCATED_TEMPLATE__, Ember._templateOnlyComponent());
});