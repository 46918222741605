define("plutof/components/identifiers/identifier-input", ["exports", "@glimmer/component"], function (_exports, _component) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _dec, _class;
  function _applyDecoratedDescriptor(i, e, r, n, l) { var a = {}; return Object.keys(n).forEach(function (i) { a[i] = n[i]; }), a.enumerable = !!a.enumerable, a.configurable = !!a.configurable, ("value" in a || a.initializer) && (a.writable = !0), a = r.slice().reverse().reduce(function (r, n) { return n(i, e, r) || r; }, a), l && void 0 !== a.initializer && (a.value = a.initializer ? a.initializer.call(l) : void 0, a.initializer = void 0), void 0 === a.initializer ? (Object.defineProperty(i, e, a), null) : a; }
  const __COLOCATED_TEMPLATE__ = Ember.HTMLBars.template(
  /*
    <div class="identifier-input">
      <div class="identifier-input__prefix">
          {{@prefix}}
      </div>
  
      <Input
          @type="number"
          @value={{this.stringValue}}
          class="form-control identifier-input__number" />
  </div>
  
  */
  {
    "id": "n/LRIXds",
    "block": "{\"symbols\":[\"@prefix\"],\"statements\":[[10,\"div\"],[14,0,\"identifier-input\"],[12],[2,\"\\n    \"],[10,\"div\"],[14,0,\"identifier-input__prefix\"],[12],[2,\"\\n        \"],[1,[32,1]],[2,\"\\n    \"],[13],[2,\"\\n\\n    \"],[8,\"input\",[[24,0,\"form-control identifier-input__number\"]],[[\"@type\",\"@value\"],[\"number\",[32,0,[\"stringValue\"]]]],null],[2,\"\\n\"],[13],[2,\"\\n\"]],\"hasEval\":false,\"upvars\":[]}",
    "moduleName": "plutof/components/identifiers/identifier-input.hbs"
  });
  let IdentifierInput = (_dec = Ember.computed('args.number'), _class = class IdentifierInput extends _component.default {
    get stringValue() {
      return Ember.isNone(this.args.number) ? '' : this.args.number.toString();
    }
    set stringValue(value) {
      const number = parseInt(value);
      if (!isNaN(number)) {
        this.args.update(number);
      } else {
        this.args.update(null);
      }
      return value;
    }
  }, _applyDecoratedDescriptor(_class.prototype, "stringValue", [_dec], Object.getOwnPropertyDescriptor(_class.prototype, "stringValue"), _class.prototype), _class);
  var _default = _exports.default = Ember._setComponentTemplate(__COLOCATED_TEMPLATE__, IdentifierInput);
});