define("plutof/templates/components/menu/button-bar", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = Ember.HTMLBars.template({
    "id": "HFeFCArY",
    "block": "{\"symbols\":[\"@logOut\",\"@changeLanguage\"],\"statements\":[[8,\"menu/-notifications\",[],[[],[]],null],[2,\"\\n\\n\"],[11,\"button\"],[24,0,\"main-menu-button-bar__btn\"],[4,[38,2],[\"click\",[32,0,[\"personalMenu\",\"open\"]]],null],[12],[2,\"\\n    \"],[10,\"span\"],[14,0,\"main-menu-button-bar__btn__icon icon-my-menu\"],[12],[13],[2,\"\\n\\n    \"],[10,\"span\"],[14,0,\"main-menu-button-bar__btn__caption\"],[12],[2,\"\\n        \"],[1,[30,[36,1],[\"General.myMenu\"],null]],[2,\"\\n    \"],[13],[2,\"\\n\"],[13],[2,\"\\n\\n\"],[6,[37,3],null,[[\"class\",\"route\"],[\"main-menu-button-bar__btn\",\"profile\"]],[[\"default\"],[{\"statements\":[[2,\"    \"],[10,\"span\"],[14,0,\"main-menu-button-bar__btn__icon fas fa-sliders-h\"],[12],[13],[2,\"\\n\\n    \"],[10,\"span\"],[14,0,\"main-menu-button-bar__btn__caption\"],[12],[2,\"\\n        \"],[1,[30,[36,1],[\"General.Settings\"],null]],[2,\"\\n    \"],[13],[2,\"\\n\"]],\"parameters\":[]}]]],[2,\"\\n\"],[6,[37,3],null,[[\"class\",\"route\"],[\"main-menu-button-bar__btn\",\"index\"]],[[\"default\"],[{\"statements\":[[2,\"    \"],[10,\"span\"],[14,0,\"main-menu-button-bar__btn__icon icon-person\"],[12],[13],[2,\"\\n\\n    \"],[10,\"span\"],[14,0,\"main-menu-button-bar__btn__caption\"],[12],[2,\"\\n        \"],[1,[34,0]],[2,\"\\n    \"],[13],[2,\"\\n\"]],\"parameters\":[]}]]],[2,\"\\n\"],[11,\"button\"],[24,1,\"logout-button\"],[24,0,\"main-menu-button-bar__btn\"],[24,4,\"button\"],[4,[38,4],[[32,0],[32,1]],null],[12],[2,\"\\n    \"],[10,\"span\"],[14,0,\"main-menu-button-bar__btn__icon fas fa-sign-out-alt\"],[12],[13],[2,\"\\n\\n    \"],[10,\"span\"],[14,0,\"main-menu-button-bar__btn__caption\"],[12],[2,\"\\n        \"],[1,[30,[36,1],[\"General.logout\"],null]],[2,\"\\n    \"],[13],[2,\"\\n\"],[13],[2,\"\\n\\n\"],[1,[30,[36,5],null,[[\"switch\"],[[32,2]]]]],[2,\"\\n\"]],\"hasEval\":false,\"upvars\":[\"menu/profile-name\",\"i18n-t\",\"on\",\"link-to\",\"action\",\"menu/language-buttons\"]}",
    "moduleName": "plutof/templates/components/menu/button-bar.hbs"
  });
});