define("plutof/components/filerepository/gallery/-preview", ["exports", "@glimmer/component", "plutof/models/filerepository/file"], function (_exports, _component, _file) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  function _defineProperty(e, r, t) { return (r = _toPropertyKey(r)) in e ? Object.defineProperty(e, r, { value: t, enumerable: !0, configurable: !0, writable: !0 }) : e[r] = t, e; }
  function _toPropertyKey(t) { var i = _toPrimitive(t, "string"); return "symbol" == typeof i ? i : i + ""; }
  function _toPrimitive(t, r) { if ("object" != typeof t || !t) return t; var e = t[Symbol.toPrimitive]; if (void 0 !== e) { var i = e.call(t, r || "default"); if ("object" != typeof i) return i; throw new TypeError("@@toPrimitive must return a primitive value."); } return ("string" === r ? String : Number)(t); }
  const __COLOCATED_TEMPLATE__ = Ember.HTMLBars.template(
  /*
    {{#if (is-equal @type this.fileType.Image)}}
      <Gallery::Image
          src={{@links.large_link}}
          alt={{@filename}}
          class="img-responsive gallery-media-layer__img" />
  {{else if (is-equal @type this.fileType.Video)}}
      <div class="gallery-media-layer__video-container">
          <video controls src={{@links.link}} class="gallery-media-layer__video" />
      </div>
  {{else if (is-equal @type this.fileType.Sound)}}
      <audio controls="controls" src={{@links.link}} class="gallery-media-layer__audio" />
  {{else}}
      <div class="gallery-media-layer__document">
          <span class="fas fa-file gallery-media-layer__icon"></span>
      </div>
  {{/if}}
  
  */
  {
    "id": "5EhFXJyK",
    "block": "{\"symbols\":[\"@links\",\"@type\",\"@filename\"],\"statements\":[[6,[37,1],[[30,[36,0],[[32,2],[32,0,[\"fileType\",\"Image\"]]],null]],null,[[\"default\",\"else\"],[{\"statements\":[[2,\"    \"],[8,\"gallery/image\",[[16,\"src\",[32,1,[\"large_link\"]]],[16,\"alt\",[32,3]],[24,0,\"img-responsive gallery-media-layer__img\"]],[[],[]],null],[2,\"\\n\"]],\"parameters\":[]},{\"statements\":[[6,[37,1],[[30,[36,0],[[32,2],[32,0,[\"fileType\",\"Video\"]]],null]],null,[[\"default\",\"else\"],[{\"statements\":[[2,\"    \"],[10,\"div\"],[14,0,\"gallery-media-layer__video-container\"],[12],[2,\"\\n        \"],[10,\"video\"],[14,\"controls\",\"\"],[15,\"src\",[32,1,[\"link\"]]],[14,0,\"gallery-media-layer__video\"],[12],[13],[2,\"\\n    \"],[13],[2,\"\\n\"]],\"parameters\":[]},{\"statements\":[[6,[37,1],[[30,[36,0],[[32,2],[32,0,[\"fileType\",\"Sound\"]]],null]],null,[[\"default\",\"else\"],[{\"statements\":[[2,\"    \"],[10,\"audio\"],[14,\"controls\",\"controls\"],[15,\"src\",[32,1,[\"link\"]]],[14,0,\"gallery-media-layer__audio\"],[12],[13],[2,\"\\n\"]],\"parameters\":[]},{\"statements\":[[2,\"    \"],[10,\"div\"],[14,0,\"gallery-media-layer__document\"],[12],[2,\"\\n        \"],[10,\"span\"],[14,0,\"fas fa-file gallery-media-layer__icon\"],[12],[13],[2,\"\\n    \"],[13],[2,\"\\n\"]],\"parameters\":[]}]]]],\"parameters\":[]}]]]],\"parameters\":[]}]]]],\"hasEval\":false,\"upvars\":[\"is-equal\",\"if\"]}",
    "moduleName": "plutof/components/filerepository/gallery/-preview.hbs"
  });
  class GalleryFilePreview extends _component.default {
    constructor(...args) {
      super(...args);
      // XXX TODO: Use string types instead of opaque numbers
      _defineProperty(this, "fileType", _file.FileType);
    }
  }
  var _default = _exports.default = Ember._setComponentTemplate(__COLOCATED_TEMPLATE__, GalleryFilePreview);
});