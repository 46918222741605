define("plutof/templates/specimen/edit", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = Ember.HTMLBars.template({
    "id": "zblTHkYv",
    "block": "{\"symbols\":[],\"statements\":[[8,\"test/route-marker\",[],[[\"@route\"],[\"specimen.edit\"]],null],[2,\"\\n\\n\"],[8,\"navbar/edit\",[],[[\"@title\",\"@icon\",\"@infoContent\"],[[30,[36,0],[\"Specimen.editS\"],null],[30,[36,1],[\"specimen\"],null],\"information/content/general/map-controls\"]],null],[2,\"\\n\\n\"],[8,\"specimen/edit\",[],[[\"@data\",\"@closePanels\",\"@canModifyDeterminations\",\"@showAreaEditButton\",\"@canResetArea\",\"@preserveZoom\",\"@canModifyOwner\",\"@validationContext\"],[[32,0,[\"model\",\"specimenData\"]],[32,0,[\"closePanels\"]],false,true,false,false,true,[32,0,[\"validationContext\"]]]],null],[2,\"\\n\\n\"],[8,\"sticky-bottom-wrapper\",[],[[],[]],[[\"default\"],[{\"statements\":[[2,\"\\n    \"],[8,\"invalid-panels\",[],[[\"@validationContext\"],[[32,0,[\"validationContext\"]]]],null],[2,\"\\n\\n    \"],[10,\"div\"],[14,0,\"action-buttons\"],[12],[2,\"\\n        \"],[8,\"common/save-button\",[],[[\"@save\",\"@disabled\"],[[32,0,[\"editSpecimen\"]],[32,0,[\"validationContext\",\"isInvalid\"]]]],null],[2,\"\\n\\n        \"],[8,\"common/cancel-button\",[],[[\"@cancel\"],[[30,[36,2],[[32,0],\"cancel\"],null]]],null],[2,\"\\n    \"],[13],[2,\"\\n\"]],\"parameters\":[]}]]],[2,\"\\n\"]],\"hasEval\":false,\"upvars\":[\"i18n-t\",\"icon\",\"action\"]}",
    "moduleName": "plutof/templates/specimen/edit.hbs"
  });
});