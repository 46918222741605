define("plutof/components/information/content/analysis/general", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const __COLOCATED_TEMPLATE__ = Ember.HTMLBars.template(
  /*
    <Information::CollapsedSection @label="Programs">
      <Information::MarkdownReader @path="analysis/programs" />
  </Information::CollapsedSection>
  
  <Information::CollapsedSection @label="Matching sequences to Species Hypotheses">
      <Information::Content::Analysis::ShMatching />
  </Information::CollapsedSection>
  
  */
  {
    "id": "VTlEiWN/",
    "block": "{\"symbols\":[],\"statements\":[[8,\"information/collapsed-section\",[],[[\"@label\"],[\"Programs\"]],[[\"default\"],[{\"statements\":[[2,\"\\n    \"],[8,\"information/markdown-reader\",[],[[\"@path\"],[\"analysis/programs\"]],null],[2,\"\\n\"]],\"parameters\":[]}]]],[2,\"\\n\\n\"],[8,\"information/collapsed-section\",[],[[\"@label\"],[\"Matching sequences to Species Hypotheses\"]],[[\"default\"],[{\"statements\":[[2,\"\\n    \"],[8,\"information/content/analysis/sh-matching\",[],[[],[]],null],[2,\"\\n\"]],\"parameters\":[]}]]],[2,\"\\n\"]],\"hasEval\":false,\"upvars\":[]}",
    "moduleName": "plutof/components/information/content/analysis/general.hbs"
  });
  var _default = _exports.default = Ember._setComponentTemplate(__COLOCATED_TEMPLATE__, Ember._templateOnlyComponent());
});