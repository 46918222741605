define("plutof/components/transaction/view/objects/header", ["exports", "@glimmer/component"], function (_exports, _component) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _dec, _dec2, _class, _descriptor;
  function _initializerDefineProperty(e, i, r, l) { r && Object.defineProperty(e, i, { enumerable: r.enumerable, configurable: r.configurable, writable: r.writable, value: r.initializer ? r.initializer.call(l) : void 0 }); }
  function _defineProperty(e, r, t) { return (r = _toPropertyKey(r)) in e ? Object.defineProperty(e, r, { value: t, enumerable: !0, configurable: !0, writable: !0 }) : e[r] = t, e; }
  function _toPropertyKey(t) { var i = _toPrimitive(t, "string"); return "symbol" == typeof i ? i : i + ""; }
  function _toPrimitive(t, r) { if ("object" != typeof t || !t) return t; var e = t[Symbol.toPrimitive]; if (void 0 !== e) { var i = e.call(t, r || "default"); if ("object" != typeof i) return i; throw new TypeError("@@toPrimitive must return a primitive value."); } return ("string" === r ? String : Number)(t); }
  function _initializerWarningHelper(r, e) { throw Error("Decorating class property failed. Please ensure that transform-class-properties is enabled and runs after the decorators transform."); }
  function _applyDecoratedDescriptor(i, e, r, n, l) { var a = {}; return Object.keys(n).forEach(function (i) { a[i] = n[i]; }), a.enumerable = !!a.enumerable, a.configurable = !!a.configurable, ("value" in a || a.initializer) && (a.writable = !0), a = r.slice().reverse().reduce(function (r, n) { return n(i, e, r) || r; }, a), l && void 0 !== a.initializer && (a.value = a.initializer ? a.initializer.call(l) : void 0, a.initializer = void 0), void 0 === a.initializer ? (Object.defineProperty(i, e, a), null) : a; }
  const __COLOCATED_TEMPLATE__ = Ember.HTMLBars.template(
  /*
    <th
      {{on "click" this._toggleCollapse}}
      class="{{if @collapsed 'collapsed-header' 'clickable'}} {{if @tiny 'tiny-cell'}}"
  >
      {{#if @ordering}}
          <Transaction::View::Objects::OrderingButton
              @ordering={{@ordering}}
              @pagination={{@pagination}} />
      {{/if}}
  
      {{#if @tooltip}}
          <ToolTip
              @tooltip={{@tooltip}}
              class="plutof-tooltip-hover plutof-tooltip-hover--inline"
          >
              {{@label}}
          </ToolTip>
      {{else}}
          {{@label}}
      {{/if}}
  
      {{#if (and this.canCollapse @collapsed)}}
          …
      {{/if}}
  
      {{#unless @disableControls}}
          <@editButton />
      {{/unless}}
  </th>
  
  */
  {
    "id": "UmPTs9+c",
    "block": "{\"symbols\":[\"@editButton\",\"@label\",\"@tooltip\",\"@ordering\",\"@pagination\",\"@tiny\",\"@collapsed\",\"@disableControls\"],\"statements\":[[11,\"th\"],[16,0,[31,[[30,[36,0],[[32,7],\"collapsed-header\",\"clickable\"],null],\" \",[30,[36,0],[[32,6],\"tiny-cell\"],null]]]],[4,[38,1],[\"click\",[32,0,[\"_toggleCollapse\"]]],null],[12],[2,\"\\n\"],[6,[37,0],[[32,4]],null,[[\"default\"],[{\"statements\":[[2,\"        \"],[8,\"transaction/view/objects/ordering-button\",[],[[\"@ordering\",\"@pagination\"],[[32,4],[32,5]]],null],[2,\"\\n\"]],\"parameters\":[]}]]],[2,\"\\n\"],[6,[37,0],[[32,3]],null,[[\"default\",\"else\"],[{\"statements\":[[2,\"        \"],[8,\"tool-tip\",[[24,0,\"plutof-tooltip-hover plutof-tooltip-hover--inline\"]],[[\"@tooltip\"],[[32,3]]],[[\"default\"],[{\"statements\":[[2,\"\\n            \"],[1,[32,2]],[2,\"\\n        \"]],\"parameters\":[]}]]],[2,\"\\n\"]],\"parameters\":[]},{\"statements\":[[2,\"        \"],[1,[32,2]],[2,\"\\n\"]],\"parameters\":[]}]]],[2,\"\\n\"],[6,[37,0],[[30,[36,2],[[32,0,[\"canCollapse\"]],[32,7]],null]],null,[[\"default\"],[{\"statements\":[[2,\"        …\\n\"]],\"parameters\":[]}]]],[2,\"\\n\"],[6,[37,3],[[32,8]],null,[[\"default\"],[{\"statements\":[[2,\"        \"],[8,[32,1],[],[[],[]],null],[2,\"\\n\"]],\"parameters\":[]}]]],[13],[2,\"\\n\"]],\"hasEval\":false,\"upvars\":[\"if\",\"on\",\"and\",\"unless\"]}",
    "moduleName": "plutof/components/transaction/view/objects/header.hbs"
  });
  let TransactionViewObjectHeader = _exports.default = (_dec = Ember._action, _dec2 = Ember.computed.notEmpty('args.toggleCollapse'), _class = class TransactionViewObjectHeader extends _component.default {
    constructor(...args) {
      super(...args);
      _initializerDefineProperty(this, "canCollapse", _descriptor, this);
    }
    _toggleCollapse() {
      if (this.args.toggleCollapse) {
        this.args.toggleCollapse(this.args.name);
      }
    }
  }, _applyDecoratedDescriptor(_class.prototype, "_toggleCollapse", [_dec], Object.getOwnPropertyDescriptor(_class.prototype, "_toggleCollapse"), _class.prototype), _descriptor = _applyDecoratedDescriptor(_class.prototype, "canCollapse", [_dec2], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _class);
  Ember._setComponentTemplate(__COLOCATED_TEMPLATE__, TransactionViewObjectHeader);
});