define("plutof/components/sequence/view/chimeric-notification", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const __COLOCATED_TEMPLATE__ = Ember.HTMLBars.template(
  /*
    <span class="fas fa-exclamation-triangle"></span>
  
  {{i18n-t "Sequences.potentiallyChimeric"}}
  
  (
  <a href="https://drive5.com/usearch/manual/uchime_algo.html" target="_blank">
      UCHIME
  </a>
  
  {{i18n-t "Sequences.chimericScore"}} = {{@score.score}}
  ).
  
  {{i18n-t "Sequences.chimeraParents"}}
  {{#link-to "sequence.view" @score.parent_1.id}}{{@score.parent_1.representation}}{{/link-to}} &amp;
  {{#link-to "sequence.view" @score.parent_2.id}}{{@score.parent_2.representation}}{{/link-to}}.
  
  */
  {
    "id": "Xr4Zxa2g",
    "block": "{\"symbols\":[\"@score\"],\"statements\":[[10,\"span\"],[14,0,\"fas fa-exclamation-triangle\"],[12],[13],[2,\"\\n\\n\"],[1,[30,[36,0],[\"Sequences.potentiallyChimeric\"],null]],[2,\"\\n\\n(\\n\"],[10,\"a\"],[14,6,\"https://drive5.com/usearch/manual/uchime_algo.html\"],[14,\"target\",\"_blank\"],[12],[2,\"\\n    UCHIME\\n\"],[13],[2,\"\\n\\n\"],[1,[30,[36,0],[\"Sequences.chimericScore\"],null]],[2,\" = \"],[1,[32,1,[\"score\"]]],[2,\"\\n).\\n\\n\"],[1,[30,[36,0],[\"Sequences.chimeraParents\"],null]],[2,\"\\n\"],[6,[37,1],null,[[\"route\",\"model\"],[\"sequence.view\",[32,1,[\"parent_1\",\"id\"]]]],[[\"default\"],[{\"statements\":[[1,[32,1,[\"parent_1\",\"representation\"]]]],\"parameters\":[]}]]],[2,\" &\\n\"],[6,[37,1],null,[[\"route\",\"model\"],[\"sequence.view\",[32,1,[\"parent_2\",\"id\"]]]],[[\"default\"],[{\"statements\":[[1,[32,1,[\"parent_2\",\"representation\"]]]],\"parameters\":[]}]]],[2,\".\\n\"]],\"hasEval\":false,\"upvars\":[\"i18n-t\",\"link-to\"]}",
    "moduleName": "plutof/components/sequence/view/chimeric-notification.hbs"
  });
  var _default = _exports.default = Ember._setComponentTemplate(__COLOCATED_TEMPLATE__, Ember._templateOnlyComponent());
});