define("plutof/templates/components/import/import-progress", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = Ember.HTMLBars.template({
    "id": "MwVh+C+M",
    "block": "{\"symbols\":[],\"statements\":[[10,\"div\"],[15,0,[31,[\"import-progress__part import-progress__part--created \",[30,[36,2],[[35,1],\"import-progress__part--notempty\"],null]]]],[15,5,[34,3]],[12],[2,\"\\n\"],[6,[37,2],[[35,1]],null,[[\"default\"],[{\"statements\":[[2,\"        \"],[1,[30,[36,0],[\"Import.progress.created\"],null]],[2,\"\\n\"]],\"parameters\":[]}]]],[13],[2,\"\\n\\n\"],[10,\"div\"],[15,0,[31,[\"import-progress__part import-progress__part--ignored \",[30,[36,2],[[35,4],\"import-progress__part--notempty\"],null]]]],[15,5,[34,5]],[12],[2,\"\\n\"],[6,[37,2],[[35,4]],null,[[\"default\"],[{\"statements\":[[2,\"        \"],[1,[30,[36,0],[\"Import.progress.ignored\"],null]],[2,\"\\n\"]],\"parameters\":[]}]]],[13],[2,\"\\n\\n\"],[10,\"div\"],[14,0,\"import-progress__part import-progress__part--unprocessed\"],[15,5,[34,6]],[12],[2,\"\\n\"],[6,[37,2],[[35,7]],null,[[\"default\"],[{\"statements\":[[2,\"        \"],[1,[30,[36,0],[\"Import.progress.unprocessed\"],null]],[2,\"\\n\"]],\"parameters\":[]}]]],[13],[2,\"\\n\"]],\"hasEval\":false,\"upvars\":[\"i18n-t\",\"created\",\"if\",\"createdPercentage\",\"ignored\",\"ignoredPercentage\",\"unprocessedPercentage\",\"unprocessed\"]}",
    "moduleName": "plutof/templates/components/import/import-progress.hbs"
  });
});