define("plutof/components/taxonomy/common-names/view", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const __COLOCATED_TEMPLATE__ = Ember.HTMLBars.template(
  /*
    <PlutofPanel::ItemList as |panel|>
      <panel.header
          @title={{i18n-t "Taxonomy.vernacularNames"}}
          @count={{@commonNames.length}} />
  
      <panel.content>
          <Taxonomy::CommonNames::Table @commonNames={{@commonNames}} />
      </panel.content>
  </PlutofPanel::ItemList>
  
  */
  {
    "id": "NJTh67Jl",
    "block": "{\"symbols\":[\"panel\",\"@commonNames\"],\"statements\":[[8,\"plutof-panel/item-list\",[],[[],[]],[[\"default\"],[{\"statements\":[[2,\"\\n    \"],[8,[32,1,[\"header\"]],[],[[\"@title\",\"@count\"],[[30,[36,0],[\"Taxonomy.vernacularNames\"],null],[32,2,[\"length\"]]]],null],[2,\"\\n\\n    \"],[8,[32,1,[\"content\"]],[],[[],[]],[[\"default\"],[{\"statements\":[[2,\"\\n        \"],[8,\"taxonomy/common-names/table\",[],[[\"@commonNames\"],[[32,2]]],null],[2,\"\\n    \"]],\"parameters\":[]}]]],[2,\"\\n\"]],\"parameters\":[1]}]]],[2,\"\\n\"]],\"hasEval\":false,\"upvars\":[\"i18n-t\"]}",
    "moduleName": "plutof/components/taxonomy/common-names/view.hbs"
  });
  var _default = _exports.default = Ember._setComponentTemplate(__COLOCATED_TEMPLATE__, Ember._templateOnlyComponent());
});