define("plutof/components/profile/groups/managing-group/-view/row", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const __COLOCATED_TEMPLATE__ = Ember.HTMLBars.template(
  /*
    <tr>
      <td>{{@membership.user_full_name}}</td>
  
      {{! Unapproved status is not in choices}}
      {{#if @pending}}
          <td class="user-pending-approval">
              {{i18n-t "group.status.0"}}
          </td>
      {{else}}
          <td >
              {{get @translations.status.choices @membership.status}}
          </td>
      {{/if}}
  
      {{#if @includeModerationRights}}
          <td>
              {{get @translations.rights.choices @membership.rights}}
          </td>
      {{/if}}
  
      {{#if @includeAcceptedBy}}
          <td>{{@membership.accepted_by_name}}</td>
      {{/if}}
  
      <td>{{formatted-date @membership.created_at}}</td>
  </tr>
  
  */
  {
    "id": "FR+Zy7Fx",
    "block": "{\"symbols\":[\"@membership\",\"@translations\",\"@pending\",\"@includeModerationRights\",\"@includeAcceptedBy\"],\"statements\":[[10,\"tr\"],[12],[2,\"\\n    \"],[10,\"td\"],[12],[1,[32,1,[\"user_full_name\"]]],[13],[2,\"\\n\\n\"],[6,[37,2],[[32,3]],null,[[\"default\",\"else\"],[{\"statements\":[[2,\"        \"],[10,\"td\"],[14,0,\"user-pending-approval\"],[12],[2,\"\\n            \"],[1,[30,[36,1],[\"group.status.0\"],null]],[2,\"\\n        \"],[13],[2,\"\\n\"]],\"parameters\":[]},{\"statements\":[[2,\"        \"],[10,\"td\"],[12],[2,\"\\n            \"],[1,[30,[36,0],[[32,2,[\"status\",\"choices\"]],[32,1,[\"status\"]]],null]],[2,\"\\n        \"],[13],[2,\"\\n\"]],\"parameters\":[]}]]],[2,\"\\n\"],[6,[37,2],[[32,4]],null,[[\"default\"],[{\"statements\":[[2,\"        \"],[10,\"td\"],[12],[2,\"\\n            \"],[1,[30,[36,0],[[32,2,[\"rights\",\"choices\"]],[32,1,[\"rights\"]]],null]],[2,\"\\n        \"],[13],[2,\"\\n\"]],\"parameters\":[]}]]],[2,\"\\n\"],[6,[37,2],[[32,5]],null,[[\"default\"],[{\"statements\":[[2,\"        \"],[10,\"td\"],[12],[1,[32,1,[\"accepted_by_name\"]]],[13],[2,\"\\n\"]],\"parameters\":[]}]]],[2,\"\\n    \"],[10,\"td\"],[12],[1,[30,[36,3],[[32,1,[\"created_at\"]]],null]],[13],[2,\"\\n\"],[13],[2,\"\\n\"]],\"hasEval\":false,\"upvars\":[\"get\",\"i18n-t\",\"if\",\"formatted-date\"]}",
    "moduleName": "plutof/components/profile/groups/managing-group/-view/row.hbs"
  });
  var _default = _exports.default = Ember._setComponentTemplate(__COLOCATED_TEMPLATE__, Ember._templateOnlyComponent());
});