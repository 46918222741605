define("plutof/templates/taxon-description/add", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = Ember.HTMLBars.template({
    "id": "w/cWQe74",
    "block": "{\"symbols\":[],\"statements\":[[8,\"test/route-marker\",[],[[\"@route\"],[\"taxon-description.add\"]],null],[2,\"\\n\\n\"],[8,\"navbar/edit\",[],[[\"@title\",\"@icon\",\"@infoContent\",\"@reset\"],[[30,[36,0],[\"TaxonDescription.newTaxonDesc\"],null],[30,[36,1],[\"taxon-description\"],null],\"information/content/general/info-empty\",[30,[36,2],[\"triggerReset\"],null]]],null],[2,\"\\n\\n\"],[8,\"taxonomy-lab/taxon-description/edit\",[],[[\"@model\",\"@validationContext\"],[[32,0,[\"model\",\"taxonDescription\"]],[32,0,[\"validationContext\"]]]],null],[2,\"\\n\\n\"],[8,\"sticky-bottom-wrapper\",[],[[],[]],[[\"default\"],[{\"statements\":[[2,\"\\n    \"],[8,\"invalid-panels\",[],[[\"@validationContext\"],[[32,0,[\"validationContext\"]]]],null],[2,\"\\n\\n    \"],[10,\"div\"],[14,0,\"action-buttons\"],[12],[2,\"\\n        \"],[8,\"common/save-button\",[],[[\"@save\",\"@disabled\"],[[32,0,[\"save\"]],[32,0,[\"validationContext\",\"isInvalid\"]]]],null],[2,\"\\n\\n        \"],[8,\"common/cancel-button\",[],[[\"@cancel\"],[[30,[36,3],[[32,0],\"cancel\"],null]]],null],[2,\"\\n    \"],[13],[2,\"\\n\"]],\"parameters\":[]}]]],[2,\"\\n\"]],\"hasEval\":false,\"upvars\":[\"i18n-t\",\"icon\",\"route-action\",\"action\"]}",
    "moduleName": "plutof/templates/taxon-description/add.hbs"
  });
});