define("plutof/components/determination/view-parent-determination", ["exports", "@glimmer/component", "plutof/misc/content_types"], function (_exports, _component, _content_types) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _dec, _dec2, _class, _descriptor;
  function _initializerDefineProperty(e, i, r, l) { r && Object.defineProperty(e, i, { enumerable: r.enumerable, configurable: r.configurable, writable: r.writable, value: r.initializer ? r.initializer.call(l) : void 0 }); }
  function _defineProperty(e, r, t) { return (r = _toPropertyKey(r)) in e ? Object.defineProperty(e, r, { value: t, enumerable: !0, configurable: !0, writable: !0 }) : e[r] = t, e; }
  function _toPropertyKey(t) { var i = _toPrimitive(t, "string"); return "symbol" == typeof i ? i : i + ""; }
  function _toPrimitive(t, r) { if ("object" != typeof t || !t) return t; var e = t[Symbol.toPrimitive]; if (void 0 !== e) { var i = e.call(t, r || "default"); if ("object" != typeof i) return i; throw new TypeError("@@toPrimitive must return a primitive value."); } return ("string" === r ? String : Number)(t); }
  function _applyDecoratedDescriptor(i, e, r, n, l) { var a = {}; return Object.keys(n).forEach(function (i) { a[i] = n[i]; }), a.enumerable = !!a.enumerable, a.configurable = !!a.configurable, ("value" in a || a.initializer) && (a.writable = !0), a = r.slice().reverse().reduce(function (r, n) { return n(i, e, r) || r; }, a), l && void 0 !== a.initializer && (a.value = a.initializer ? a.initializer.call(l) : void 0, a.initializer = void 0), void 0 === a.initializer ? (Object.defineProperty(i, e, a), null) : a; }
  function _initializerWarningHelper(r, e) { throw Error("Decorating class property failed. Please ensure that transform-class-properties is enabled and runs after the decorators transform."); }
  const __COLOCATED_TEMPLATE__ = Ember.HTMLBars.template(
  /*
    {{#resolve-promise this.determination as |determination|}}
      {{#if determination}}
          <ViewSingleDetermination
              @determination={{determination}}
              @determiners={{determination.determiners}}
              @isParentDetermination={{true}}
              @enableBasis={{@enableBasis}}
              class="view-determinations__determination view-determinations__determination--parent" />
      {{/if}}
  {{/resolve-promise}}
  
  */
  {
    "id": "6CfRrec6",
    "block": "{\"symbols\":[\"determination\",\"@enableBasis\"],\"statements\":[[6,[37,1],[[32,0,[\"determination\"]]],null,[[\"default\"],[{\"statements\":[[6,[37,0],[[32,1]],null,[[\"default\"],[{\"statements\":[[2,\"        \"],[8,\"view-single-determination\",[[24,0,\"view-determinations__determination view-determinations__determination--parent\"]],[[\"@determination\",\"@determiners\",\"@isParentDetermination\",\"@enableBasis\"],[[32,1],[32,1,[\"determiners\"]],true,[32,2]]],null],[2,\"\\n\"]],\"parameters\":[]}]]]],\"parameters\":[1]}]]]],\"hasEval\":false,\"upvars\":[\"if\",\"resolve-promise\"]}",
    "moduleName": "plutof/components/determination/view-parent-determination.hbs"
  });
  function getDetermination(store, contentType, id) {
    if (Ember.isNone(contentType)) {
      return null;
    }
    return store.queryRecord('determination/determination', {
      content_type: contentType.id,
      object_id: id,
      ordering: '-is_current,-id'
    });
  }
  async function getParentDetermination(store, parentContentType, parentId, parentRecord) {
    const contentType = Ember.isNone(parentContentType) ? await (0, _content_types.get_object_ctype)(store, parentRecord) : await parentContentType;
    const id = Ember.isNone(parentId) ? parentRecord.id : parentId;
    const determination = await getDetermination(store, contentType, id);
    if (determination) {
      return determination;
    }
    if (!contentType) {
      return null;
    }
    if (contentType.full_name === 'sequence/sequence' || contentType.full_name === 'livingculture/strain') {
      const grandparent = await (0, _content_types.resolve_generic_link)(store, contentType, id);
      const grandparentContentType = await grandparent.content_type;
      const objectId = grandparent.object_id;
      const grandparentDetermination = await getDetermination(store, grandparentContentType, objectId);
      return grandparentDetermination;
    }
    return null;
  }
  let ViewParentDetermination = (_dec = Ember.inject.service, _dec2 = Ember.computed('args.parentContentType.id', 'args.parentId', 'args.parentRecord.id'), _class = class ViewParentDetermination extends _component.default {
    constructor(...args) {
      super(...args);
      _initializerDefineProperty(this, "store", _descriptor, this);
    }
    get determination() {
      return getParentDetermination(this.store, this.args.parentContentType, this.args.parentId, this.args.parentRecord);
    }
  }, _descriptor = _applyDecoratedDescriptor(_class.prototype, "store", [_dec], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _applyDecoratedDescriptor(_class.prototype, "determination", [_dec2], Object.getOwnPropertyDescriptor(_class.prototype, "determination"), _class.prototype), _class);
  var _default = _exports.default = Ember._setComponentTemplate(__COLOCATED_TEMPLATE__, ViewParentDetermination);
});