define("plutof/templates/glossary/add", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = Ember.HTMLBars.template({
    "id": "K9b3fD7k",
    "block": "{\"symbols\":[],\"statements\":[[8,\"test/route-marker\",[],[[\"@route\"],[\"glossary.add\"]],null],[2,\"\\n\\n\"],[8,\"navbar/edit\",[],[[\"@icon\",\"@title\",\"@record\",\"@reset\",\"@infoTitle\",\"@infoContent\"],[[30,[36,0],[\"glossary\"],null],[30,[36,1],[\"Glossary.newGlossary\"],null],[32,0,[\"model\",\"glossary\"]],[30,[36,2],[\"triggerReset\"],null],[30,[36,1],[\"Glossary.infoTitle\"],null],\"information/content/general/info-empty\"]],null],[2,\"\\n\\n\"],[8,\"glossary/edit\",[],[[\"@glossary\",\"@accessRights\",\"@validationContext\",\"@thesauri\",\"@relatedThesauri\",\"@objectsToDelete\",\"@save\"],[[32,0,[\"model\",\"glossary\"]],[32,0,[\"model\",\"accessRights\"]],[32,0,[\"validationContext\"]],[32,0,[\"model\",\"thesauri\"]],[32,0,[\"model\",\"relatedThesauri\"]],[32,0,[\"thesauriMarkedForDeletion\"]],[32,0,[\"save\"]]]],null],[2,\"\\n\"]],\"hasEval\":false,\"upvars\":[\"icon\",\"i18n-t\",\"route-action\"]}",
    "moduleName": "plutof/templates/glossary/add.hbs"
  });
});