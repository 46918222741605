define("plutof/components/auto-complete/bind-text", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _dec, _dec2, _class;
  function _applyDecoratedDescriptor(i, e, r, n, l) { var a = {}; return Object.keys(n).forEach(function (i) { a[i] = n[i]; }), a.enumerable = !!a.enumerable, a.configurable = !!a.configurable, ("value" in a || a.initializer) && (a.writable = !0), a = r.slice().reverse().reduce(function (r, n) { return n(i, e, r) || r; }, a), l && void 0 !== a.initializer && (a.value = a.initializer ? a.initializer.call(l) : void 0, a.initializer = void 0), void 0 === a.initializer ? (Object.defineProperty(i, e, a), null) : a; }
  const __COLOCATED_TEMPLATE__ = Ember.HTMLBars.template(
  /*
    <AutoComplete::Base
      @backend={{@backend}}
      @update={{this.complete}}
      @query={{@value}}
      @updateQuery={{this.update}}
      @params={{merge-params @params (hash allowIncompleteValue=true)}}
      ...attributes />
  
  */
  {
    "id": "iiUdr0D3",
    "block": "{\"symbols\":[\"@backend\",\"@value\",\"@params\",\"&attrs\"],\"statements\":[[8,\"auto-complete/base\",[[17,4]],[[\"@backend\",\"@update\",\"@query\",\"@updateQuery\",\"@params\"],[[32,1],[32,0,[\"complete\"]],[32,2],[32,0,[\"update\"]],[30,[36,1],[[32,3],[30,[36,0],null,[[\"allowIncompleteValue\"],[true]]]],null]]],null],[2,\"\\n\"]],\"hasEval\":false,\"upvars\":[\"hash\",\"merge-params\"]}",
    "moduleName": "plutof/components/auto-complete/bind-text.hbs"
  });
  // Just a container providing relatively clean interface for a giant hack
  // TODO: Think about how all this could be done better
  //
  // This was written for the previous version of AC, so parts of this might be
  // unnecessary now
  //
  // Since then another change has happen so it might not even be necessary :shrug:
  let BoundTextAutocomplete = _exports.default = (_dec = Ember._action, _dec2 = Ember._action, _class = class BoundTextAutocomplete extends Ember.Component {
    update(text) {
      this.set('value', text);
    }
    complete(record) {
      if (record) {
        this.update(record.representation);
        if (this.completed) {
          this.completed(record);
        }
      }
    }
  }, _applyDecoratedDescriptor(_class.prototype, "update", [_dec], Object.getOwnPropertyDescriptor(_class.prototype, "update"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "complete", [_dec2], Object.getOwnPropertyDescriptor(_class.prototype, "complete"), _class.prototype), _class);
  Ember._setComponentTemplate(__COLOCATED_TEMPLATE__, BoundTextAutocomplete);
});