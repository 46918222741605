define("plutof/components/annotation/bulk/multiple-field", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const __COLOCATED_TEMPLATE__ = Ember.HTMLBars.template(
  /*
    <Annotation::Bulk::Field
      @label={{@label}}
      @field={{@field}}
      as |wrapper|
  >
      {{yield (hash
          disabled=wrapper.disabled
          add=@field.add
      ) to="input"}}
  
      <Common::RecordList
          @records={{@field.added}}
          @remove={{@field.noLongerAdd}}
          as |item|
      >
          {{#if (has-block "item")}}
              {{yield item to="item"}}
          {{else}}
              {{item.representation}}
          {{/if}}
      </Common::RecordList>
  </Annotation::Bulk::Field>
  
  */
  {
    "id": "uf/qR59C",
    "block": "{\"symbols\":[\"wrapper\",\"item\",\"&default\",\"@label\",\"@field\",\"@namedBlocksInfo\"],\"statements\":[[8,\"annotation/bulk/field\",[],[[\"@label\",\"@field\"],[[32,4],[32,5]]],[[\"default\"],[{\"statements\":[[2,\"\\n    \"],[18,3,[[30,[36,0],[\"input\"],null],[30,[36,1],null,[[\"disabled\",\"add\"],[[32,1,[\"disabled\"]],[32,5,[\"add\"]]]]]]],[2,\"\\n\\n    \"],[8,\"common/record-list\",[],[[\"@records\",\"@remove\"],[[32,5,[\"added\"]],[32,5,[\"noLongerAdd\"]]]],[[\"default\"],[{\"statements\":[[2,\"\\n\"],[6,[37,3],[[30,[36,2],[[32,6],\"item\",false],null]],null,[[\"default\",\"else\"],[{\"statements\":[[2,\"            \"],[18,3,[[30,[36,0],[\"item\"],null],[32,2]]],[2,\"\\n\"]],\"parameters\":[]},{\"statements\":[[2,\"            \"],[1,[32,2,[\"representation\"]]],[2,\"\\n\"]],\"parameters\":[]}]]],[2,\"    \"]],\"parameters\":[2]}]]],[2,\"\\n\"]],\"parameters\":[1]}]]],[2,\"\\n\"]],\"hasEval\":false,\"upvars\":[\"-named-block-invocation\",\"hash\",\"-has-block\",\"if\"]}",
    "moduleName": "plutof/components/annotation/bulk/multiple-field.hbs"
  });
  var _default = _exports.default = Ember._setComponentTemplate(__COLOCATED_TEMPLATE__, Ember._templateOnlyComponent());
});