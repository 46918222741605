define("plutof/templates/taxon-description/view", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = Ember.HTMLBars.template({
    "id": "DGrNARZp",
    "block": "{\"symbols\":[],\"statements\":[[8,\"test/route-marker\",[],[[\"@route\"],[\"taxon-description.view\"]],null],[2,\"\\n\\n\"],[8,\"navbar/view\",[],[[\"@icon\",\"@title\",\"@record\",\"@editRoute\",\"@module\",\"@accessRights\",\"@deletable\"],[[30,[36,0],[\"taxon-description\"],null],[30,[36,1],[\"TaxonDescription.taxonDesc\"],null],[32,0,[\"model\",\"taxonDescription\"]],\"taxon-description.edit\",\"taxonomylab/taxondescription\",[32,0,[\"model\",\"accessRights\"]],true]],null],[2,\"\\n\\n\"],[8,\"taxonomy-lab/taxon-description/view\",[],[[\"@taxonDescription\"],[[32,0,[\"model\",\"taxonDescription\"]]]],null],[2,\"\\n\\n\"],[8,\"plutof-panel/simple\",[[24,\"data-test\",\"TaxonomyLab::TaxonDescription::View::ConservationInstructions\"]],[[\"@title\",\"@collapse\"],[[30,[36,1],[\"TaxonDescription.conservationInstructions\"],null],[30,[36,3],[[35,2,[\"taxonDescription\",\"conservation_instructions\"]]],null]]],[[\"default\"],[{\"statements\":[[2,\"\\n    \"],[8,\"flexible-textarea\",[[24,0,\"form-control route-taxon-description-view__conservation-instructions\"],[24,\"data-test\",\"conservation_instructions\"]],[[\"@value\",\"@disabled\"],[[32,0,[\"model\",\"taxonDescription\",\"conservation_instructions\"]],true]],null],[2,\"\\n\"]],\"parameters\":[]}]]],[2,\"\\n\\n\"],[8,\"linked-items/view\",[],[[\"@object\"],[[32,0,[\"model\",\"taxonDescription\"]]]],null],[2,\"\\n\\n\"],[8,\"access/view-rights\",[],[[\"@object\",\"@rights\"],[[32,0,[\"model\",\"taxonDescription\"]],[32,0,[\"model\",\"accessRights\"]]]],null],[2,\"\\n\"]],\"hasEval\":false,\"upvars\":[\"icon\",\"i18n-t\",\"model\",\"not\"]}",
    "moduleName": "plutof/templates/taxon-description/view.hbs"
  });
});