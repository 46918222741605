define("plutof/components/taxonomy/edit", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const __COLOCATED_TEMPLATE__ = Ember.HTMLBars.template(
  /*
    <PlutofPanel::Simple
      @title={{i18n-t "Taxonomy.attributes"}}
      data-test="Taxonomy::TaxonNode::GeneralData"
  >
      <Taxonomy::TaxonNode
          @taxon={{@taxon}}
          @disableRankField={{@disableRankField}}
          @validationContext={{@validationContext.taxon}} />
  </PlutofPanel::Simple>
  
  <Taxonomy::CommonNames::Edit
      @commonNames={{@commonNames}}
      @add={{@addVernacularName}}
      @remove={{@removeVernacularName}} />
  
  <LinkedItems::Edit
      @data={{@linkedItems}}
      @validationContext={{@validationContext.linkedItems}} />
  
  */
  {
    "id": "Ut8YLeaA",
    "block": "{\"symbols\":[\"@taxon\",\"@disableRankField\",\"@validationContext\",\"@commonNames\",\"@addVernacularName\",\"@removeVernacularName\",\"@linkedItems\"],\"statements\":[[8,\"plutof-panel/simple\",[[24,\"data-test\",\"Taxonomy::TaxonNode::GeneralData\"]],[[\"@title\"],[[30,[36,0],[\"Taxonomy.attributes\"],null]]],[[\"default\"],[{\"statements\":[[2,\"\\n    \"],[8,\"taxonomy/taxon-node\",[],[[\"@taxon\",\"@disableRankField\",\"@validationContext\"],[[32,1],[32,2],[32,3,[\"taxon\"]]]],null],[2,\"\\n\"]],\"parameters\":[]}]]],[2,\"\\n\\n\"],[8,\"taxonomy/common-names/edit\",[],[[\"@commonNames\",\"@add\",\"@remove\"],[[32,4],[32,5],[32,6]]],null],[2,\"\\n\\n\"],[8,\"linked-items/edit\",[],[[\"@data\",\"@validationContext\"],[[32,7],[32,3,[\"linkedItems\"]]]],null],[2,\"\\n\"]],\"hasEval\":false,\"upvars\":[\"i18n-t\"]}",
    "moduleName": "plutof/components/taxonomy/edit.hbs"
  });
  var _default = _exports.default = Ember._setComponentTemplate(__COLOCATED_TEMPLATE__, Ember._templateOnlyComponent());
});