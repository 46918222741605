define("plutof/templates/components/menu/language-buttons", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = Ember.HTMLBars.template({
    "id": "LrkrM01I",
    "block": "{\"symbols\":[\"btn\",\"@switch\"],\"statements\":[[6,[37,3],[[30,[36,2],[[30,[36,2],[[32,0,[\"visibleButtons\"]]],null]],null]],null,[[\"default\"],[{\"statements\":[[2,\"    \"],[11,\"button\"],[16,1,[31,[[34,0,[\"id\"]]]]],[24,0,\"main-menu-button-bar__btn\"],[24,4,\"button\"],[4,[38,1],[[32,0],[32,2],[32,1,[\"locale\"]]],null],[12],[2,\"\\n        \"],[1,[32,1,[\"label\"]]],[2,\"\\n    \"],[13],[2,\"\\n\"]],\"parameters\":[1]}]]]],\"hasEval\":false,\"upvars\":[\"button\",\"action\",\"-track-array\",\"each\"]}",
    "moduleName": "plutof/templates/components/menu/language-buttons.hbs"
  });
});