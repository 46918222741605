define("plutof/components/dmp/host-pid-systems", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _dec, _class;
  function _applyDecoratedDescriptor(i, e, r, n, l) { var a = {}; return Object.keys(n).forEach(function (i) { a[i] = n[i]; }), a.enumerable = !!a.enumerable, a.configurable = !!a.configurable, ("value" in a || a.initializer) && (a.writable = !0), a = r.slice().reverse().reduce(function (r, n) { return n(i, e, r) || r; }, a), l && void 0 !== a.initializer && (a.value = a.initializer ? a.initializer.call(l) : void 0, a.initializer = void 0), void 0 === a.initializer ? (Object.defineProperty(i, e, a), null) : a; }
  const __COLOCATED_TEMPLATE__ = Ember.HTMLBars.template(
  /*
    <MultipleSelectizer
      @content={{@choices}}
      @selected={{@host.pid_system}}
      @hideList={{true}}
      @prompt="---" />
  
  <Common::RecordList @records={{@host.pid_system}} @remove={{this.remove}} as |record|>
      {{record}}
  </Common::RecordList>
  
  */
  {
    "id": "VHVRMijh",
    "block": "{\"symbols\":[\"record\",\"@choices\",\"@host\"],\"statements\":[[8,\"multiple-selectizer\",[],[[\"@content\",\"@selected\",\"@hideList\",\"@prompt\"],[[32,2],[32,3,[\"pid_system\"]],true,\"---\"]],null],[2,\"\\n\\n\"],[8,\"common/record-list\",[],[[\"@records\",\"@remove\"],[[32,3,[\"pid_system\"]],[32,0,[\"remove\"]]]],[[\"default\"],[{\"statements\":[[2,\"\\n    \"],[1,[32,1]],[2,\"\\n\"]],\"parameters\":[1]}]]],[2,\"\\n\"]],\"hasEval\":false,\"upvars\":[]}",
    "moduleName": "plutof/components/dmp/host-pid-systems.hbs"
  });
  let DMPHostPidSystems = (_dec = Ember._action, _class = class DMPHostPidSystems extends Ember.Component {
    remove(pid) {
      this.host.pid_system.removeObject(pid);
    }
  }, _applyDecoratedDescriptor(_class.prototype, "remove", [_dec], Object.getOwnPropertyDescriptor(_class.prototype, "remove"), _class.prototype), _class);
  var _default = _exports.default = Ember._setComponentTemplate(__COLOCATED_TEMPLATE__, DMPHostPidSystems);
});