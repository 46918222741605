define("plutof/templates/components/search/habitat-input", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = Ember.HTMLBars.template({
    "id": "Y+2p2JpO",
    "block": "{\"symbols\":[\"add\",\"@filter\"],\"statements\":[[6,[37,1],[[32,2]],null,[[\"default\"],[{\"statements\":[[2,\"    \"],[10,\"div\"],[12],[2,\"\\n        \"],[10,\"div\"],[14,0,\"plutof-label\"],[12],[2,\" \"],[1,[30,[36,0],[\"Photobank.classifications\"],null]],[2,\" \"],[13],[2,\"\\n\\n\"],[6,[37,1],[[32,0,[\"classifications\"]]],null,[[\"default\"],[{\"statements\":[[2,\"            \"],[8,\"plutof-select/value\",[],[[\"@content\",\"@value\"],[[32,0,[\"classifications\"]],[32,0,[\"classificationId\"]]]],null],[2,\"\\n\"]],\"parameters\":[]}]]],[2,\"    \"],[13],[2,\"\\n\\n    \"],[10,\"div\"],[12],[2,\"\\n        \"],[10,\"div\"],[14,0,\"plutof-label\"],[12],[2,\" \"],[1,[35,2,[\"config\",\"label\"]]],[2,\" \"],[13],[2,\"\\n\\n        \"],[8,\"auto-complete/bind-multiple\",[],[[\"@value\"],[[32,2,[\"value\"]]]],[[\"default\"],[{\"statements\":[[2,\"\\n            \"],[8,\"auto-complete/model\",[],[[\"@model\",\"@update\",\"@filters\"],[\"habitat/community\",[32,1],[32,0,[\"autocompleteFilters\"]]]],null],[2,\"\\n        \"]],\"parameters\":[1]}]]],[2,\"\\n    \"],[13],[2,\"\\n\"]],\"parameters\":[]}]]]],\"hasEval\":false,\"upvars\":[\"i18n-t\",\"if\",\"filter\"]}",
    "moduleName": "plutof/templates/components/search/habitat-input.hbs"
  });
});