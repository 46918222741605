define("plutof/components/ui/modal/frame", ["exports", "@glimmer/component"], function (_exports, _component) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _dec, _dec2, _dec3, _class;
  function _defineProperty(e, r, t) { return (r = _toPropertyKey(r)) in e ? Object.defineProperty(e, r, { value: t, enumerable: !0, configurable: !0, writable: !0 }) : e[r] = t, e; }
  function _toPropertyKey(t) { var i = _toPrimitive(t, "string"); return "symbol" == typeof i ? i : i + ""; }
  function _toPrimitive(t, r) { if ("object" != typeof t || !t) return t; var e = t[Symbol.toPrimitive]; if (void 0 !== e) { var i = e.call(t, r || "default"); if ("object" != typeof i) return i; throw new TypeError("@@toPrimitive must return a primitive value."); } return ("string" === r ? String : Number)(t); }
  function _applyDecoratedDescriptor(i, e, r, n, l) { var a = {}; return Object.keys(n).forEach(function (i) { a[i] = n[i]; }), a.enumerable = !!a.enumerable, a.configurable = !!a.configurable, ("value" in a || a.initializer) && (a.writable = !0), a = r.slice().reverse().reduce(function (r, n) { return n(i, e, r) || r; }, a), l && void 0 !== a.initializer && (a.value = a.initializer ? a.initializer.call(l) : void 0, a.initializer = void 0), void 0 === a.initializer ? (Object.defineProperty(i, e, a), null) : a; }
  const __COLOCATED_TEMPLATE__ = Ember.HTMLBars.template(
  /*
    <dialog class="dialog-frame"
      {{get-reference this.register}}
      {{on "click" this.clickedOnFrame}}
      {{on "cancel" this.close}}
  >
      {{#if @open}}
          {{yield}}
      {{/if}}
  </dialog>
  
  */
  {
    "id": "078H2Reh",
    "block": "{\"symbols\":[\"&default\",\"@open\"],\"statements\":[[11,\"dialog\"],[24,0,\"dialog-frame\"],[4,[38,0],[[32,0,[\"register\"]]],null],[4,[38,1],[\"click\",[32,0,[\"clickedOnFrame\"]]],null],[4,[38,1],[\"cancel\",[32,0,[\"close\"]]],null],[12],[2,\"\\n\"],[6,[37,2],[[32,2]],null,[[\"default\"],[{\"statements\":[[2,\"        \"],[18,1,null],[2,\"\\n\"]],\"parameters\":[]}]]],[13],[2,\"\\n\"]],\"hasEval\":false,\"upvars\":[\"get-reference\",\"on\",\"if\"]}",
    "moduleName": "plutof/components/ui/modal/frame.hbs"
  });
  let DialogFrame = (_dec = Ember._action, _dec2 = Ember._action, _dec3 = Ember._action, _class = class DialogFrame extends _component.default {
    constructor(...args) {
      super(...args);
      _defineProperty(this, "dialog", null);
    }
    register(element) {
      this.dialog = element;
      if (this.args.open) {
        element.showModal();
      } else {
        element.close();
      }
    }
    clickedOnFrame(event) {
      if (event.target === this.dialog) {
        this.close();
      }
    }
    close() {
      this.dialog.close();
      if (this.args.close) {
        this.args.close();
      }
    }
  }, _applyDecoratedDescriptor(_class.prototype, "register", [_dec], Object.getOwnPropertyDescriptor(_class.prototype, "register"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "clickedOnFrame", [_dec2], Object.getOwnPropertyDescriptor(_class.prototype, "clickedOnFrame"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "close", [_dec3], Object.getOwnPropertyDescriptor(_class.prototype, "close"), _class.prototype), _class);
  var _default = _exports.default = Ember._setComponentTemplate(__COLOCATED_TEMPLATE__, DialogFrame);
});