define("plutof/templates/taxonomy/add", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = Ember.HTMLBars.template({
    "id": "JjVdZwfl",
    "block": "{\"symbols\":[],\"statements\":[[8,\"test/route-marker\",[],[[\"@route\"],[\"taxonomy.add\"]],null],[2,\"\\n\\n\"],[8,\"navbar/edit\",[],[[\"@title\",\"@icon\",\"@reset\",\"@infoContent\"],[[30,[36,0],[\"Taxonomy.newTaxon\"],null],[30,[36,1],[\"taxon-node\"],null],[30,[36,2],[\"triggerReset\"],null],\"information/content/taxon/general\"]],null],[2,\"\\n\\n\"],[8,\"taxonomy/edit\",[],[[\"@taxon\",\"@commonNames\",\"@linkedItems\",\"@addVernacularName\",\"@removeVernacularName\",\"@validationContext\"],[[32,0,[\"model\",\"taxon\"]],[32,0,[\"model\",\"commonNames\"]],[32,0,[\"model\",\"linkedItems\"]],[32,0,[\"addVernacularName\"]],[32,0,[\"removeVernacularName\"]],[32,0,[\"validationContext\"]]]],null],[2,\"\\n\\n\"],[8,\"taxonomy/edit-remarks-panel\",[],[[\"@taxon\",\"@title\"],[[32,0,[\"model\",\"taxon\"]],[30,[36,0],[\"Taxonomy.addPanelTitle\"],null]]],null],[2,\"\\n\\n\"],[8,\"invalid-panels\",[],[[\"@validationContext\"],[[32,0,[\"validationContext\"]]]],null],[2,\"\\n\\n\"],[10,\"div\"],[14,0,\"action-buttons\"],[12],[2,\"\\n    \"],[8,\"common/save-button\",[],[[\"@save\",\"@disabled\"],[[32,0,[\"save\"]],[32,0,[\"validationContext\",\"isInvalid\"]]]],null],[2,\"\\n\\n    \"],[8,\"common/cancel-button\",[],[[\"@cancel\"],[[32,0,[\"cancel\"]]]],null],[2,\"\\n\"],[13],[2,\"\\n\"]],\"hasEval\":false,\"upvars\":[\"i18n-t\",\"icon\",\"route-action\"]}",
    "moduleName": "plutof/templates/taxonomy/add.hbs"
  });
});