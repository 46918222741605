define("plutof/components/tables/-list-view/order-label", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const __COLOCATED_TEMPLATE__ = Ember.HTMLBars.template(
  /*
    <button
      {{on "click" @sortBy}}
      disabled={{@disabled}}
      class="btn-link {{if @active 'table-header-btn' 'table-header-btn table-header-btn--inactive'}}"
  >
      {{#if @active}}
          <span class="fas {{if @ascending 'fa-sort-up' 'fa-sort-down'}}"></span>
      {{else}}
          <span class="fas fa-sort"></span>
      {{/if}}
  </button>
  
  {{@label}}
  
  */
  {
    "id": "2biR8w2X",
    "block": "{\"symbols\":[\"@ascending\",\"@disabled\",\"@active\",\"@sortBy\",\"@label\"],\"statements\":[[11,\"button\"],[16,\"disabled\",[32,2]],[16,0,[31,[\"btn-link \",[30,[36,0],[[32,3],\"table-header-btn\",\"table-header-btn table-header-btn--inactive\"],null]]]],[4,[38,1],[\"click\",[32,4]],null],[12],[2,\"\\n\"],[6,[37,0],[[32,3]],null,[[\"default\",\"else\"],[{\"statements\":[[2,\"        \"],[10,\"span\"],[15,0,[31,[\"fas \",[30,[36,0],[[32,1],\"fa-sort-up\",\"fa-sort-down\"],null]]]],[12],[13],[2,\"\\n\"]],\"parameters\":[]},{\"statements\":[[2,\"        \"],[10,\"span\"],[14,0,\"fas fa-sort\"],[12],[13],[2,\"\\n\"]],\"parameters\":[]}]]],[13],[2,\"\\n\\n\"],[1,[32,5]],[2,\"\\n\"]],\"hasEval\":false,\"upvars\":[\"if\",\"on\"]}",
    "moduleName": "plutof/components/tables/-list-view/order-label.hbs"
  });
  var _default = _exports.default = Ember._setComponentTemplate(__COLOCATED_TEMPLATE__, Ember._templateOnlyComponent());
});