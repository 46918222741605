define("plutof/templates/publishing/ena/index", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = Ember.HTMLBars.template({
    "id": "1wduD7sx",
    "block": "{\"symbols\":[\"Field\",\"dataset\",\"common\"],\"statements\":[[8,\"navbar/list\",[],[[\"@icon\",\"@title\",\"@addRoute\",\"@goBack\",\"@pagination\",\"@infoTitle\",\"@infoContent\"],[[30,[36,0],[\"ena\"],null],[30,[36,1],[\"ena.datasets\"],null],\"publishing.ena.add\",[30,[36,2],[\"goBack\"],null],[32,0,[\"pagination\"]],[30,[36,1],[\"ena.title\"],null],\"information/content/ena/general\"]],null],[2,\"\\n\\n\"],[8,\"tables/list-route\",[],[[\"@records\"],[[32,0,[\"pagination\",\"objects\"]]]],[[\"default\"],[{\"statements\":[[2,\"\\n    \"],[8,[32,1],[],[[\"@label\"],[[30,[36,1],[\"PlutofListViewColumn.name\"],null]]],[[\"default\"],[{\"statements\":[[2,\"\\n\"],[6,[37,3],null,[[\"route\",\"model\"],[\"publishing.ena.view\",[32,2,[\"id\"]]]],[[\"default\"],[{\"statements\":[[2,\"            \"],[1,[32,2,[\"name\"]]],[2,\"\\n\"]],\"parameters\":[]}]]],[2,\"    \"]],\"parameters\":[]}]]],[2,\"\\n\\n    \"],[8,[32,3,[\"modified\"]],[],[[],[]],null],[2,\"\\n\"]],\"parameters\":[1,2,3]}]]],[2,\"\\n\"]],\"hasEval\":false,\"upvars\":[\"icon\",\"i18n-t\",\"route-action\",\"link-to\"]}",
    "moduleName": "plutof/templates/publishing/ena/index.hbs"
  });
});