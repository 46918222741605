define("plutof/components/plutof-panel/inner", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const __COLOCATED_TEMPLATE__ = Ember.HTMLBars.template(
  /*
    <div class="panel plutof-panel" ...attributes>
      <div class="panel-body plutof-panel-body {{if @highlight 'colored-panel-body'}} {{@bodyClass}}">
          {{yield}}
      </div>
  </div>
  
  */
  {
    "id": "9AhmGzPc",
    "block": "{\"symbols\":[\"&attrs\",\"@bodyClass\",\"@highlight\",\"&default\"],\"statements\":[[11,\"div\"],[24,0,\"panel plutof-panel\"],[17,1],[12],[2,\"\\n    \"],[10,\"div\"],[15,0,[31,[\"panel-body plutof-panel-body \",[30,[36,0],[[32,3],\"colored-panel-body\"],null],\" \",[32,2]]]],[12],[2,\"\\n        \"],[18,4,null],[2,\"\\n    \"],[13],[2,\"\\n\"],[13],[2,\"\\n\"]],\"hasEval\":false,\"upvars\":[\"if\"]}",
    "moduleName": "plutof/components/plutof-panel/inner.hbs"
  });
  var _default = _exports.default = Ember._setComponentTemplate(__COLOCATED_TEMPLATE__, Ember._templateOnlyComponent());
});