define("plutof/components/observation/general-data", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const __COLOCATED_TEMPLATE__ = Ember.HTMLBars.template(
  /*
    <PlutofPanel::Simple
      @title={{i18n-t "General.generalData"}}
      data-test="Specimen::GeneralData"
  >
      <Layout::RegularGrid @columns={{2}}>
          <div>
              <PlutofLabelc @path="observation.related_materialsample" />
  
              {{#async/bind-relation @materialSample as |value update|}}
                  <AutoComplete::Model
                      @model="taxonoccurrence/materialsample/materialsample"
                      @value={{value}}
                      @update={{update}}
                      @filters={{hash access="edit"}} />
              {{/async/bind-relation}}
          </div>
  
          <div>
              <PlutofLabelc @path="observation.living_specimen" />
  
              {{#async/bind-relation @livingSpecimen as |value update|}}
                  <AutoComplete::Model
                      @model="taxonoccurrence/livingculture/strain"
                      @value={{value}}
                      @update={{update}}
                      @filters={{hash access="edit"}} />
              {{/async/bind-relation}}
          </div>
      </Layout::RegularGrid>
  </PlutofPanel::Simple>
  
  */
  {
    "id": "06DB0IXZ",
    "block": "{\"symbols\":[\"value\",\"update\",\"value\",\"update\",\"@materialSample\",\"@livingSpecimen\"],\"statements\":[[8,\"plutof-panel/simple\",[[24,\"data-test\",\"Specimen::GeneralData\"]],[[\"@title\"],[[30,[36,1],[\"General.generalData\"],null]]],[[\"default\"],[{\"statements\":[[2,\"\\n    \"],[8,\"layout/regular-grid\",[],[[\"@columns\"],[2]],[[\"default\"],[{\"statements\":[[2,\"\\n        \"],[10,\"div\"],[12],[2,\"\\n            \"],[8,\"plutof-labelc\",[],[[\"@path\"],[\"observation.related_materialsample\"]],null],[2,\"\\n\\n\"],[6,[37,2],[[32,5]],null,[[\"default\"],[{\"statements\":[[2,\"                \"],[8,\"auto-complete/model\",[],[[\"@model\",\"@value\",\"@update\",\"@filters\"],[\"taxonoccurrence/materialsample/materialsample\",[32,3],[32,4],[30,[36,0],null,[[\"access\"],[\"edit\"]]]]],null],[2,\"\\n\"]],\"parameters\":[3,4]}]]],[2,\"        \"],[13],[2,\"\\n\\n        \"],[10,\"div\"],[12],[2,\"\\n            \"],[8,\"plutof-labelc\",[],[[\"@path\"],[\"observation.living_specimen\"]],null],[2,\"\\n\\n\"],[6,[37,2],[[32,6]],null,[[\"default\"],[{\"statements\":[[2,\"                \"],[8,\"auto-complete/model\",[],[[\"@model\",\"@value\",\"@update\",\"@filters\"],[\"taxonoccurrence/livingculture/strain\",[32,1],[32,2],[30,[36,0],null,[[\"access\"],[\"edit\"]]]]],null],[2,\"\\n\"]],\"parameters\":[1,2]}]]],[2,\"        \"],[13],[2,\"\\n    \"]],\"parameters\":[]}]]],[2,\"\\n\"]],\"parameters\":[]}]]],[2,\"\\n\"]],\"hasEval\":false,\"upvars\":[\"hash\",\"i18n-t\",\"async/bind-relation\"]}",
    "moduleName": "plutof/components/observation/general-data.hbs"
  });
  var _default = _exports.default = Ember._setComponentTemplate(__COLOCATED_TEMPLATE__, Ember._templateOnlyComponent());
});