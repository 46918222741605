define("plutof/components/tables/-list-view/clipboard", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const __COLOCATED_TEMPLATE__ = Ember.HTMLBars.template(
  /*
    <@Field @label={{i18n-t "PlutofListViewColumn.clipboard"}}>
      <Clipboard::ListViewClipboardButton
          @contentType={{@contentType}}
          @entry={{@record}} />
  </@Field>
  
  */
  {
    "id": "jFE+nivm",
    "block": "{\"symbols\":[\"@Field\",\"@contentType\",\"@record\"],\"statements\":[[8,[32,1],[],[[\"@label\"],[[30,[36,0],[\"PlutofListViewColumn.clipboard\"],null]]],[[\"default\"],[{\"statements\":[[2,\"\\n    \"],[8,\"clipboard/list-view-clipboard-button\",[],[[\"@contentType\",\"@entry\"],[[32,2],[32,3]]],null],[2,\"\\n\"]],\"parameters\":[]}]]],[2,\"\\n\"]],\"hasEval\":false,\"upvars\":[\"i18n-t\"]}",
    "moduleName": "plutof/components/tables/-list-view/clipboard.hbs"
  });
  var _default = _exports.default = Ember._setComponentTemplate(__COLOCATED_TEMPLATE__, Ember._templateOnlyComponent());
});