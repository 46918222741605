define("plutof/components/transaction/view/general-data", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const __COLOCATED_TEMPLATE__ = Ember.HTMLBars.template(
  /*
    <PlutofPanel::Simple
      @title={{i18n-t "General.generalData"}}
      data-test="Transaction::View::GeneralData"
  >
      <GenericViews::Record @record={{@transaction}} as |view|>
          <Layout::RegularGrid @columns={{4}}>
              {{view.field "type"}}
              {{view.attr "number"}}
              {{view.link "deposited_in" route="collection.view"}}
              {{view.field "purpose"}}
              {{view.attr "number_of_specimens"}}
              {{view.attr "content"}}
              {{view.attr "comment"}}
              {{view.attr "start_date"}}
              {{view.attr "end_date"}}
              {{view.attr "expected_end_date"}}
              {{view.attr "is_locked"}}
          </Layout::RegularGrid>
      </GenericViews::Record>
  </PlutofPanel::Simple>
  
  */
  {
    "id": "ELK5WHWs",
    "block": "{\"symbols\":[\"view\",\"@transaction\"],\"statements\":[[8,\"plutof-panel/simple\",[[24,\"data-test\",\"Transaction::View::GeneralData\"]],[[\"@title\"],[[30,[36,0],[\"General.generalData\"],null]]],[[\"default\"],[{\"statements\":[[2,\"\\n    \"],[8,\"generic-views/record\",[],[[\"@record\"],[[32,2]]],[[\"default\"],[{\"statements\":[[2,\"\\n        \"],[8,\"layout/regular-grid\",[],[[\"@columns\"],[4]],[[\"default\"],[{\"statements\":[[2,\"\\n            \"],[1,[30,[36,1],[[32,1,[\"field\"]],\"type\"],null]],[2,\"\\n            \"],[1,[30,[36,1],[[32,1,[\"attr\"]],\"number\"],null]],[2,\"\\n            \"],[1,[30,[36,1],[[32,1,[\"link\"]],\"deposited_in\"],[[\"route\"],[\"collection.view\"]]]],[2,\"\\n            \"],[1,[30,[36,1],[[32,1,[\"field\"]],\"purpose\"],null]],[2,\"\\n            \"],[1,[30,[36,1],[[32,1,[\"attr\"]],\"number_of_specimens\"],null]],[2,\"\\n            \"],[1,[30,[36,1],[[32,1,[\"attr\"]],\"content\"],null]],[2,\"\\n            \"],[1,[30,[36,1],[[32,1,[\"attr\"]],\"comment\"],null]],[2,\"\\n            \"],[1,[30,[36,1],[[32,1,[\"attr\"]],\"start_date\"],null]],[2,\"\\n            \"],[1,[30,[36,1],[[32,1,[\"attr\"]],\"end_date\"],null]],[2,\"\\n            \"],[1,[30,[36,1],[[32,1,[\"attr\"]],\"expected_end_date\"],null]],[2,\"\\n            \"],[1,[30,[36,1],[[32,1,[\"attr\"]],\"is_locked\"],null]],[2,\"\\n        \"]],\"parameters\":[]}]]],[2,\"\\n    \"]],\"parameters\":[1]}]]],[2,\"\\n\"]],\"parameters\":[]}]]],[2,\"\\n\"]],\"hasEval\":false,\"upvars\":[\"i18n-t\",\"component\"]}",
    "moduleName": "plutof/components/transaction/view/general-data.hbs"
  });
  var _default = _exports.default = Ember._setComponentTemplate(__COLOCATED_TEMPLATE__, Ember._templateOnlyComponent());
});