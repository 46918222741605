define("plutof/components/generic-views/-record/attr", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _dec, _dec2, _dec3, _dec4, _class, _descriptor, _GenericRecordViewAttribute;
  function _initializerDefineProperty(e, i, r, l) { r && Object.defineProperty(e, i, { enumerable: r.enumerable, configurable: r.configurable, writable: r.writable, value: r.initializer ? r.initializer.call(l) : void 0 }); }
  function _defineProperty(e, r, t) { return (r = _toPropertyKey(r)) in e ? Object.defineProperty(e, r, { value: t, enumerable: !0, configurable: !0, writable: !0 }) : e[r] = t, e; }
  function _toPropertyKey(t) { var i = _toPrimitive(t, "string"); return "symbol" == typeof i ? i : i + ""; }
  function _toPrimitive(t, r) { if ("object" != typeof t || !t) return t; var e = t[Symbol.toPrimitive]; if (void 0 !== e) { var i = e.call(t, r || "default"); if ("object" != typeof i) return i; throw new TypeError("@@toPrimitive must return a primitive value."); } return ("string" === r ? String : Number)(t); }
  function _initializerWarningHelper(r, e) { throw Error("Decorating class property failed. Please ensure that transform-class-properties is enabled and runs after the decorators transform."); }
  function _applyDecoratedDescriptor(i, e, r, n, l) { var a = {}; return Object.keys(n).forEach(function (i) { a[i] = n[i]; }), a.enumerable = !!a.enumerable, a.configurable = !!a.configurable, ("value" in a || a.initializer) && (a.writable = !0), a = r.slice().reverse().reduce(function (r, n) { return n(i, e, r) || r; }, a), l && void 0 !== a.initializer && (a.value = a.initializer ? a.initializer.call(l) : void 0, a.initializer = void 0), void 0 === a.initializer ? (Object.defineProperty(i, e, a), null) : a; }
  const __COLOCATED_TEMPLATE__ = Ember.HTMLBars.template(
  /*
    {{#if this.hasLabel}}
      {{#if @tooltip}}
          <ToolTip @tooltip={{@tooltip}}>
              <div class="plutof-label">
                  {{@label}}
              </div>
          </ToolTip>
      {{else}}
          <div class="plutof-label">
              {{@label}}
          </div>
      {{/if}}
  {{else}}
      <PlutofLabelc @path={{this.labelPath}} @tooltipText={{@tooltip}} />
  {{/if}}
  
  <p
      class="
          {{this.testClass}}
          generic-record-view__field
          {{if (or @annotated (get @context.record.fieldAnnotated this.field)) 'annotated'}}
      "
      data-test={{this.terminalMarker}}
  >
      {{#if (has-block)}}
          {{yield}}
      {{else}}
          {{show-field (get @context.record this.field)}}
      {{/if}}
  
      {{#unless @dontReserveSpace}}
          &nbsp;
      {{/unless}}
  </p>
  
  */
  {
    "id": "fYe/8aLj",
    "block": "{\"symbols\":[\"@context\",\"&default\",\"@tooltip\",\"@label\",\"@annotated\",\"@namedBlocksInfo\",\"@dontReserveSpace\"],\"statements\":[[6,[37,2],[[32,0,[\"hasLabel\"]]],null,[[\"default\",\"else\"],[{\"statements\":[[6,[37,2],[[32,3]],null,[[\"default\",\"else\"],[{\"statements\":[[2,\"        \"],[8,\"tool-tip\",[],[[\"@tooltip\"],[[32,3]]],[[\"default\"],[{\"statements\":[[2,\"\\n            \"],[10,\"div\"],[14,0,\"plutof-label\"],[12],[2,\"\\n                \"],[1,[32,4]],[2,\"\\n            \"],[13],[2,\"\\n        \"]],\"parameters\":[]}]]],[2,\"\\n\"]],\"parameters\":[]},{\"statements\":[[2,\"        \"],[10,\"div\"],[14,0,\"plutof-label\"],[12],[2,\"\\n            \"],[1,[32,4]],[2,\"\\n        \"],[13],[2,\"\\n\"]],\"parameters\":[]}]]]],\"parameters\":[]},{\"statements\":[[2,\"    \"],[8,\"plutof-labelc\",[],[[\"@path\",\"@tooltipText\"],[[32,0,[\"labelPath\"]],[32,3]]],null],[2,\"\\n\"]],\"parameters\":[]}]]],[2,\"\\n\"],[10,\"p\"],[15,0,[31,[\"\\n        \",[32,0,[\"testClass\"]],\"\\n        generic-record-view__field\\n        \",[30,[36,2],[[30,[36,3],[[32,5],[30,[36,0],[[32,1,[\"record\",\"fieldAnnotated\"]],[32,0,[\"field\"]]],null]],null],\"annotated\"],null],\"\\n    \"]]],[15,\"data-test\",[32,0,[\"terminalMarker\"]]],[12],[2,\"\\n\"],[6,[37,2],[[30,[36,4],[[32,6],\"default\",[27,[32,2]]],null]],null,[[\"default\",\"else\"],[{\"statements\":[[2,\"        \"],[18,2,null],[2,\"\\n\"]],\"parameters\":[]},{\"statements\":[[2,\"        \"],[1,[30,[36,1],[[30,[36,0],[[32,1,[\"record\"]],[32,0,[\"field\"]]],null]],null]],[2,\"\\n\"]],\"parameters\":[]}]]],[2,\"\\n\"],[6,[37,5],[[32,7]],null,[[\"default\"],[{\"statements\":[[2,\"         \\n\"]],\"parameters\":[]}]]],[13],[2,\"\\n\"]],\"hasEval\":false,\"upvars\":[\"get\",\"show-field\",\"if\",\"or\",\"-has-block\",\"unless\"]}",
    "moduleName": "plutof/components/generic-views/-record/attr.hbs"
  });
  let GenericRecordViewAttribute = _exports.default = (_dec = Ember.computed('context.modelPath', 'field'), _dec2 = Ember.computed.or('marker', 'field'), _dec3 = Ember.computed('label'), _dec4 = Ember.computed('context.testMarker', 'terminalMarker'), _class = (_GenericRecordViewAttribute = class GenericRecordViewAttribute extends Ember.Component {
    constructor(...args) {
      super(...args);
      _initializerDefineProperty(this, "terminalMarker", _descriptor, this);
    }
    get labelPath() {
      return `${this.context.modelPath}.${this.field}`;
    }
    get hasLabel() {
      // backend-translations/label will return falsy '' while loading
      return !Ember.isNone(this.label);
    }
    get testClass() {
      return this.context.testMarker ? `${this.context.testMarker}__${this.terminalMarker}` : '';
    }
  }, _defineProperty(_GenericRecordViewAttribute, "positionalParams", ['field']), _GenericRecordViewAttribute), _applyDecoratedDescriptor(_class.prototype, "labelPath", [_dec], Object.getOwnPropertyDescriptor(_class.prototype, "labelPath"), _class.prototype), _descriptor = _applyDecoratedDescriptor(_class.prototype, "terminalMarker", [_dec2], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _applyDecoratedDescriptor(_class.prototype, "hasLabel", [_dec3], Object.getOwnPropertyDescriptor(_class.prototype, "hasLabel"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "testClass", [_dec4], Object.getOwnPropertyDescriptor(_class.prototype, "testClass"), _class.prototype), _class);
  Ember._setComponentTemplate(__COLOCATED_TEMPLATE__, GenericRecordViewAttribute);
});