define("plutof/components/tables/-list-view/tristate-checkbox", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const __COLOCATED_TEMPLATE__ = Ember.HTMLBars.template(
  /*
    <@Field @label={{component "tables/-list-view/tristate-checkbox-label" label=@label changed=@changed}}>
      {{yield}}
  </@Field>
  
  */
  {
    "id": "PaDHLZ7h",
    "block": "{\"symbols\":[\"@Field\",\"@changed\",\"@label\",\"&default\"],\"statements\":[[8,[32,1],[],[[\"@label\"],[[30,[36,0],[\"tables/-list-view/tristate-checkbox-label\"],[[\"label\",\"changed\"],[[32,3],[32,2]]]]]],[[\"default\"],[{\"statements\":[[2,\"\\n    \"],[18,4,null],[2,\"\\n\"]],\"parameters\":[]}]]],[2,\"\\n\"]],\"hasEval\":false,\"upvars\":[\"component\"]}",
    "moduleName": "plutof/components/tables/-list-view/tristate-checkbox.hbs"
  });
  var _default = _exports.default = Ember._setComponentTemplate(__COLOCATED_TEMPLATE__, Ember._templateOnlyComponent());
});