define("plutof/components/annotation/annotation-link", ["exports", "@glimmer/component", "plutof/models/annotation/annotation"], function (_exports, _component, _annotation) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _dec, _class;
  function _applyDecoratedDescriptor(i, e, r, n, l) { var a = {}; return Object.keys(n).forEach(function (i) { a[i] = n[i]; }), a.enumerable = !!a.enumerable, a.configurable = !!a.configurable, ("value" in a || a.initializer) && (a.writable = !0), a = r.slice().reverse().reduce(function (r, n) { return n(i, e, r) || r; }, a), l && void 0 !== a.initializer && (a.value = a.initializer ? a.initializer.call(l) : void 0, a.initializer = void 0), void 0 === a.initializer ? (Object.defineProperty(i, e, a), null) : a; }
  const __COLOCATED_TEMPLATE__ = Ember.HTMLBars.template(
  /*
    <Annotation::-Link
      @annotation={{@annotation}}
      @highlight={{this.highlight}}
  >
      {{yield}}
  </Annotation::-Link>
  
  */
  {
    "id": "1vhJOdjs",
    "block": "{\"symbols\":[\"@annotation\",\"&default\"],\"statements\":[[8,\"annotation/-link\",[],[[\"@annotation\",\"@highlight\"],[[32,1],[32,0,[\"highlight\"]]]],[[\"default\"],[{\"statements\":[[2,\"\\n    \"],[18,2,null],[2,\"\\n\"]],\"parameters\":[]}]]],[2,\"\\n\"]],\"hasEval\":false,\"upvars\":[]}",
    "moduleName": "plutof/components/annotation/annotation-link.hbs"
  });
  let AnnotationLink = (_dec = Ember.computed('args.annotation.status', 'args.accessRights.canModify'), _class = class AnnotationLink extends _component.default {
    get highlight() {
      return this.args.accessRights.canModify && this.args.annotation.status === _annotation.AnnotationStatus.BeingReviewed;
    }
  }, _applyDecoratedDescriptor(_class.prototype, "highlight", [_dec], Object.getOwnPropertyDescriptor(_class.prototype, "highlight"), _class.prototype), _class);
  var _default = _exports.default = Ember._setComponentTemplate(__COLOCATED_TEMPLATE__, AnnotationLink);
});