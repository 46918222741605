define("plutof/templates/components/study/project-persons", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = Ember.HTMLBars.template({
    "id": "19dsx9p7",
    "block": "{\"symbols\":[\"value\",\"update\",\"@projectPersons\",\"@remove\"],\"statements\":[[10,\"div\"],[14,0,\"add-component\"],[12],[2,\"\\n    \"],[10,\"div\"],[14,0,\"component-part\"],[12],[2,\"\\n        \"],[1,[30,[36,1],null,[[\"path\"],[\"studyperson.person\"]]]],[2,\"\\n\\n\"],[6,[37,2],[[32,0,[\"person\"]]],null,[[\"default\"],[{\"statements\":[[2,\"            \"],[8,\"agent/person/auto-complete\",[],[[\"@value\",\"@update\",\"@params\"],[[32,1],[32,2],[30,[36,0],null,[[\"inputClasses\",\"sideActionClasses\"],[\"form-control control\",\"btn btn-default no-error-addon-button input-group-in-add-component--middle\"]]]]],null],[2,\"\\n\"]],\"parameters\":[1,2]}]]],[2,\"    \"],[13],[2,\"\\n\\n    \"],[10,\"div\"],[14,0,\"component-part\"],[12],[2,\"\\n        \"],[1,[30,[36,3],null,[[\"path\"],[\"studyperson.role\"]]]],[2,\"\\n\\n        \"],[8,\"input\",[],[[\"@type\",\"@value\",\"@class\"],[\"text\",[32,0,[\"role\"]],\"form-control control project-persons__role\"]],null],[2,\"\\n    \"],[13],[2,\"\\n\\n    \"],[10,\"div\"],[14,0,\"component-part component-part--button\"],[12],[2,\"\\n        \"],[11,\"button\"],[16,\"disabled\",[30,[36,4],[[32,0,[\"person\"]]],null]],[24,0,\"btn btn-default control project-persons__add-button\"],[4,[38,5],[\"click\",[32,0,[\"_add\"]]],null],[12],[2,\"\\n            \"],[10,\"span\"],[15,0,[30,[36,6],[\"ok\"],null]],[12],[13],[2,\"\\n            \"],[1,[30,[36,7],[\"General.Add\"],null]],[2,\"\\n        \"],[13],[2,\"\\n    \"],[13],[2,\"\\n\"],[13],[2,\"\\n\\n\"],[8,\"common/record-list\",[],[[\"@records\",\"@remove\"],[[32,3],[32,4]]],null],[2,\"\\n\"]],\"hasEval\":false,\"upvars\":[\"hash\",\"person-label-tooltip\",\"async/bind-relation\",\"plutof-labelc\",\"not\",\"on\",\"icon\",\"i18n-t\"]}",
    "moduleName": "plutof/templates/components/study/project-persons.hbs"
  });
});