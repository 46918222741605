define("plutof/templates/recover-password", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = Ember.HTMLBars.template({
    "id": "VaLSMPzO",
    "block": "{\"symbols\":[],\"statements\":[[8,\"test/route-marker\",[],[[\"@route\"],[\"recover-password\"]],null],[2,\"\\n\\n\"],[8,\"common/legend\",[],[[],[]],[[\"default\"],[{\"statements\":[[2,\"\\n    \"],[1,[30,[36,0],[\"Registration.recoverPassword\"],null]],[2,\"\\n\"]],\"parameters\":[]}]]],[2,\"\\n\\n\"],[8,\"login/form-input\",[],[[\"@label\",\"@value\"],[[30,[36,0],[\"Registration.credentials\"],null],[32,0,[\"identification\"]]]],[[\"default\"],[{\"statements\":[[2,\"\\n    \"],[8,\"input\",[[24,0,\"form-control\"]],[[\"@value\",\"@placeholder\"],[[32,0,[\"identification\"]],[30,[36,0],[\"Registration.credentials\"],null]]],null],[2,\"\\n\"]],\"parameters\":[]}]]],[2,\"\\n\\n\"],[11,\"button\"],[16,\"disabled\",[32,0,[\"validations\",\"isInvalid\"]]],[24,0,\"btn btn-default plutof-btn-green\"],[4,[38,1],[\"click\",[32,0,[\"reset\"]]],null],[12],[2,\"\\n    \"],[1,[30,[36,0],[\"Registration.recoverPassword\"],null]],[2,\"\\n\"],[13],[2,\"\\n\"]],\"hasEval\":false,\"upvars\":[\"i18n-t\",\"on\"]}",
    "moduleName": "plutof/templates/recover-password.hbs"
  });
});