define("plutof/components/identifiers/-list", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const __COLOCATED_TEMPLATE__ = Ember.HTMLBars.template(
  /*
    <div>
      <h5>{{@title}}</h5>
  
      <Tables::List
          @records={{@objects}}
          as |Field block|
      >
          <Field @label={{i18n-t "identifiers.list.range"}}>
              {{block.value.representation}}
          </Field>
  
          <Field @label={{i18n-t "identifiers.list.reservedFor"}}>
              {{block.value.reserved_for.representation}}
          </Field>
  
          <Field @label={{i18n-t "identifiers.list.used"}}>
              {{block.value.used}} / {{block.value.totalSize}}
          </Field>
  
          <Field>
              <Tables::RemoveButton @clicked={{fn @remove block}} />
          </Field>
      </Tables::List>
  </div>
  
  */
  {
    "id": "53Hqw76c",
    "block": "{\"symbols\":[\"Field\",\"block\",\"@title\",\"@objects\",\"@remove\"],\"statements\":[[10,\"div\"],[12],[2,\"\\n    \"],[10,\"h5\"],[12],[1,[32,3]],[13],[2,\"\\n\\n    \"],[8,\"tables/list\",[],[[\"@records\"],[[32,4]]],[[\"default\"],[{\"statements\":[[2,\"\\n        \"],[8,[32,1],[],[[\"@label\"],[[30,[36,0],[\"identifiers.list.range\"],null]]],[[\"default\"],[{\"statements\":[[2,\"\\n            \"],[1,[32,2,[\"value\",\"representation\"]]],[2,\"\\n        \"]],\"parameters\":[]}]]],[2,\"\\n\\n        \"],[8,[32,1],[],[[\"@label\"],[[30,[36,0],[\"identifiers.list.reservedFor\"],null]]],[[\"default\"],[{\"statements\":[[2,\"\\n            \"],[1,[32,2,[\"value\",\"reserved_for\",\"representation\"]]],[2,\"\\n        \"]],\"parameters\":[]}]]],[2,\"\\n\\n        \"],[8,[32,1],[],[[\"@label\"],[[30,[36,0],[\"identifiers.list.used\"],null]]],[[\"default\"],[{\"statements\":[[2,\"\\n            \"],[1,[32,2,[\"value\",\"used\"]]],[2,\" / \"],[1,[32,2,[\"value\",\"totalSize\"]]],[2,\"\\n        \"]],\"parameters\":[]}]]],[2,\"\\n\\n        \"],[8,[32,1],[],[[],[]],[[\"default\"],[{\"statements\":[[2,\"\\n            \"],[8,\"tables/remove-button\",[],[[\"@clicked\"],[[30,[36,1],[[32,5],[32,2]],null]]],null],[2,\"\\n        \"]],\"parameters\":[]}]]],[2,\"\\n    \"]],\"parameters\":[1,2]}]]],[2,\"\\n\"],[13],[2,\"\\n\"]],\"hasEval\":false,\"upvars\":[\"i18n-t\",\"fn\"]}",
    "moduleName": "plutof/components/identifiers/-list.hbs"
  });
  var _default = _exports.default = Ember._setComponentTemplate(__COLOCATED_TEMPLATE__, Ember._templateOnlyComponent());
});