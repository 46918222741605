define("plutof/components/transaction/view/objects/bulk-edit-button", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const __COLOCATED_TEMPLATE__ = Ember.HTMLBars.template(
  /*
    <button
     {{on "click" @edit}}
     disabled={{@disable}}
     class="btn-link btn-inline"
  >
      <span class={{icon (if @isLoading "loading" "ok")}}></span>
  </button>
  
  */
  {
    "id": "Mib12BC3",
    "block": "{\"symbols\":[\"@disable\",\"@edit\",\"@isLoading\"],\"statements\":[[11,\"button\"],[16,\"disabled\",[32,1]],[24,0,\"btn-link btn-inline\"],[4,[38,0],[\"click\",[32,2]],null],[12],[2,\"\\n    \"],[10,\"span\"],[15,0,[30,[36,2],[[30,[36,1],[[32,3],\"loading\",\"ok\"],null]],null]],[12],[13],[2,\"\\n\"],[13],[2,\"\\n\"]],\"hasEval\":false,\"upvars\":[\"on\",\"if\",\"icon\"]}",
    "moduleName": "plutof/components/transaction/view/objects/bulk-edit-button.hbs"
  });
  var _default = _exports.default = Ember._setComponentTemplate(__COLOCATED_TEMPLATE__, Ember._templateOnlyComponent());
});