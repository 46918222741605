define("plutof/templates/components/common/-button-group-button", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = Ember.HTMLBars.template({
    "id": "BkQYh6uf",
    "block": "{\"symbols\":[\"@disabled\",\"@invalid\",\"&attrs\",\"&default\"],\"statements\":[[11,\"button\"],[16,\"disabled\",[32,1]],[16,0,[31,[\"button-group__button\\n        \",[30,[36,0],[[32,0,[\"isActive\"]],\"button-group__button--active\"],null],\"\\n        \",[30,[36,0],[[32,2],\"plutof-group-btn--invalid\"],null]]]],[17,3],[4,[38,2],[\"click\",[30,[36,1],[[32,0,[\"click\"]],[32,0]],null]],null],[12],[2,\"\\n    \"],[18,4,null],[2,\"\\n\"],[13],[2,\"\\n\"]],\"hasEval\":false,\"upvars\":[\"if\",\"fn\",\"on\"]}",
    "moduleName": "plutof/templates/components/common/-button-group-button.hbs"
  });
});