define("plutof/components/search/filter-panels/common-properties/agents", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const __COLOCATED_TEMPLATE__ = Ember.HTMLBars.template(
  /*
    <Layout::RegularGrid @columns={{6}}>
      <Search::FilterInput
          @filter={{@filterInputs.owner}}
          @autocompletePreset="users/user"
          @additionalTooltip={{i18n-t "Agents.personTooltip"}} />
  
      <Search::FilterInput
          @filter={{@filterInputs.created_by}}
          @autocompletePreset="users/user"
          @additionalTooltip={{i18n-t "Agents.personTooltip"}} />
  
      {{#if @filterInputs.gathering_agents}}
          <Search::Filters::Person @filter={{@filterInputs.gathering_agents}} />
      {{/if}}
  
      {{#if @filterInputs.determiners}}
          <Search::Filters::Person @filter={{@filterInputs.determiners}} />
      {{/if}}
  
      <Search::FilterInput @filter={{@filterInputs.group}} @autocompletePreset="users/workgroup" />
  </Layout::RegularGrid>
  
  */
  {
    "id": "orieUFvK",
    "block": "{\"symbols\":[\"@filterInputs\"],\"statements\":[[8,\"layout/regular-grid\",[],[[\"@columns\"],[6]],[[\"default\"],[{\"statements\":[[2,\"\\n    \"],[8,\"search/filter-input\",[],[[\"@filter\",\"@autocompletePreset\",\"@additionalTooltip\"],[[32,1,[\"owner\"]],\"users/user\",[30,[36,0],[\"Agents.personTooltip\"],null]]],null],[2,\"\\n\\n    \"],[8,\"search/filter-input\",[],[[\"@filter\",\"@autocompletePreset\",\"@additionalTooltip\"],[[32,1,[\"created_by\"]],\"users/user\",[30,[36,0],[\"Agents.personTooltip\"],null]]],null],[2,\"\\n\\n\"],[6,[37,1],[[32,1,[\"gathering_agents\"]]],null,[[\"default\"],[{\"statements\":[[2,\"        \"],[8,\"search/filters/person\",[],[[\"@filter\"],[[32,1,[\"gathering_agents\"]]]],null],[2,\"\\n\"]],\"parameters\":[]}]]],[2,\"\\n\"],[6,[37,1],[[32,1,[\"determiners\"]]],null,[[\"default\"],[{\"statements\":[[2,\"        \"],[8,\"search/filters/person\",[],[[\"@filter\"],[[32,1,[\"determiners\"]]]],null],[2,\"\\n\"]],\"parameters\":[]}]]],[2,\"\\n    \"],[8,\"search/filter-input\",[],[[\"@filter\",\"@autocompletePreset\"],[[32,1,[\"group\"]],\"users/workgroup\"]],null],[2,\"\\n\"]],\"parameters\":[]}]]],[2,\"\\n\"]],\"hasEval\":false,\"upvars\":[\"i18n-t\",\"if\"]}",
    "moduleName": "plutof/components/search/filter-panels/common-properties/agents.hbs"
  });
  var _default = _exports.default = Ember._setComponentTemplate(__COLOCATED_TEMPLATE__, Ember._templateOnlyComponent());
});