define("plutof/templates/publishing/gbif/add", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = Ember.HTMLBars.template({
    "id": "jvzIPsMV",
    "block": "{\"symbols\":[],\"statements\":[[10,\"div\"],[14,0,\"route-marker-publishing-add\"],[12],[13],[2,\"\\n\\n\"],[8,\"navbar/edit\",[],[[\"@icon\",\"@title\",\"@record\",\"@reset\",\"@infoContent\"],[[30,[36,0],[\"gbif\"],null],[30,[36,1],[\"dataResource.general.newDataset\"],null],[32,0,[\"model\",\"dataset\"]],[30,[36,2],[\"triggerReset\"],null],\"information/content/publishing/gbif/general\"]],null],[2,\"\\n\\n\"],[8,\"publishing/gbif/edit-form\",[],[[\"@dataset\",\"@sequences\",\"@specimens\",\"@observations\",\"@referencebased\",\"@agents\",\"@rights\",\"@validationContext\",\"@cancel\",\"@save\",\"@setLogo\"],[[32,0,[\"model\",\"dataset\"]],[32,0,[\"model\",\"sequences\"]],[32,0,[\"model\",\"specimens\"]],[32,0,[\"model\",\"observations\"]],[32,0,[\"model\",\"referencebased\"]],[32,0,[\"model\",\"agents\"]],[32,0,[\"model\",\"rights\"]],[32,0,[\"validationContext\"]],[30,[36,3],[[32,0],\"cancel\"],null],[32,0,[\"saveDataset\"]],[32,0,[\"setLogo\"]]]],null],[2,\"\\n\"]],\"hasEval\":false,\"upvars\":[\"icon\",\"i18n-t\",\"route-action\",\"action\"]}",
    "moduleName": "plutof/templates/publishing/gbif/add.hbs"
  });
});