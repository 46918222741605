define("plutof/components/transaction/view/objects/row", ["exports", "@glimmer/component", "plutof/utils/notifications"], function (_exports, _component, _notifications) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _dec, _dec2, _dec3, _dec4, _dec5, _dec6, _dec7, _class, _descriptor, _descriptor2, _descriptor3;
  function _initializerDefineProperty(e, i, r, l) { r && Object.defineProperty(e, i, { enumerable: r.enumerable, configurable: r.configurable, writable: r.writable, value: r.initializer ? r.initializer.call(l) : void 0 }); }
  function _defineProperty(e, r, t) { return (r = _toPropertyKey(r)) in e ? Object.defineProperty(e, r, { value: t, enumerable: !0, configurable: !0, writable: !0 }) : e[r] = t, e; }
  function _toPropertyKey(t) { var i = _toPrimitive(t, "string"); return "symbol" == typeof i ? i : i + ""; }
  function _toPrimitive(t, r) { if ("object" != typeof t || !t) return t; var e = t[Symbol.toPrimitive]; if (void 0 !== e) { var i = e.call(t, r || "default"); if ("object" != typeof i) return i; throw new TypeError("@@toPrimitive must return a primitive value."); } return ("string" === r ? String : Number)(t); }
  function _applyDecoratedDescriptor(i, e, r, n, l) { var a = {}; return Object.keys(n).forEach(function (i) { a[i] = n[i]; }), a.enumerable = !!a.enumerable, a.configurable = !!a.configurable, ("value" in a || a.initializer) && (a.writable = !0), a = r.slice().reverse().reduce(function (r, n) { return n(i, e, r) || r; }, a), l && void 0 !== a.initializer && (a.value = a.initializer ? a.initializer.call(l) : void 0, a.initializer = void 0), void 0 === a.initializer ? (Object.defineProperty(i, e, a), null) : a; }
  function _initializerWarningHelper(r, e) { throw Error("Decorating class property failed. Please ensure that transform-class-properties is enabled and runs after the decorators transform."); }
  const __COLOCATED_TEMPLATE__ = Ember.HTMLBars.template(
  /*
    <tr>
      {{#if this.editMode}}
          <Transaction::View::Objects::Row::ObjectEdit
              @item={{@item}}
              @itemType={{@itemType}}
              @save={{this.save}}
              @cancel={{this.cancel}} />
      {{else if @item.transaction_item_id}}
          <Transaction::View::Objects::Row::ObjectView
              @item={{@item}}
              @itemType={{@itemType}}
              @disableEdit={{@disableEdit}}
              @disableControls={{@disableControls}}
              @edit={{this.edit}}
              @delete={{this.delete}}
              @collapsedColumns={{@collapsedColumns}} />
      {{else}}
          <Transaction::View::Objects::Row::ItemView
              @item={{@item}}
              @itemType={{@itemType}}
              @disableEdit={{@disableEdit}}
              @disableControls={{@disableControls}}
              @edit={{this.edit}}
              @delete={{this.delete}} />
      {{/if}}
  </tr>
  
  */
  {
    "id": "WtIr78BL",
    "block": "{\"symbols\":[\"@item\",\"@itemType\",\"@disableEdit\",\"@disableControls\",\"@collapsedColumns\"],\"statements\":[[10,\"tr\"],[12],[2,\"\\n\"],[6,[37,0],[[32,0,[\"editMode\"]]],null,[[\"default\",\"else\"],[{\"statements\":[[2,\"        \"],[8,\"transaction/view/objects/row/object-edit\",[],[[\"@item\",\"@itemType\",\"@save\",\"@cancel\"],[[32,1],[32,2],[32,0,[\"save\"]],[32,0,[\"cancel\"]]]],null],[2,\"\\n\"]],\"parameters\":[]},{\"statements\":[[6,[37,0],[[32,1,[\"transaction_item_id\"]]],null,[[\"default\",\"else\"],[{\"statements\":[[2,\"        \"],[8,\"transaction/view/objects/row/object-view\",[],[[\"@item\",\"@itemType\",\"@disableEdit\",\"@disableControls\",\"@edit\",\"@delete\",\"@collapsedColumns\"],[[32,1],[32,2],[32,3],[32,4],[32,0,[\"edit\"]],[32,0,[\"delete\"]],[32,5]]],null],[2,\"\\n\"]],\"parameters\":[]},{\"statements\":[[2,\"        \"],[8,\"transaction/view/objects/row/item-view\",[],[[\"@item\",\"@itemType\",\"@disableEdit\",\"@disableControls\",\"@edit\",\"@delete\"],[[32,1],[32,2],[32,3],[32,4],[32,0,[\"edit\"]],[32,0,[\"delete\"]]]],null],[2,\"\\n    \"]],\"parameters\":[]}]]]],\"parameters\":[]}]]],[13],[2,\"\\n\"]],\"hasEval\":false,\"upvars\":[\"if\"]}",
    "moduleName": "plutof/components/transaction/view/objects/row.hbs"
  });
  let TransactionViewObjectsRow = _exports.default = (_dec = Ember.inject.service, _dec2 = Ember.inject.service, _dec3 = Ember._tracked, _dec4 = Ember._action, _dec5 = Ember._action, _dec6 = Ember._action, _dec7 = Ember._action, _class = class TransactionViewObjectsRow extends _component.default {
    constructor(...args) {
      super(...args);
      _initializerDefineProperty(this, "confirmationDialog", _descriptor, this);
      _initializerDefineProperty(this, "store", _descriptor2, this);
      _initializerDefineProperty(this, "editMode", _descriptor3, this);
    }
    resetPagination() {
      this.args.pagination.switchPage(1);
      this.args.pagination.reload();
    }
    getObjectId(object) {
      return object.transaction_item_id || object.id;
    }
    edit() {
      this.editMode = true;
    }
    cancel() {
      this.editMode = false;
    }
    async save(record) {
      const target = record.target;
      record.object_id = Ember.isNone(target) ? null : target.get('id');
      try {
        await record.save();
        this.editMode = false;
        this.resetPagination();
      } catch (error) {
        (0, _notifications.reportError)(error);
      }
    }
    async delete() {
      const id = this.getObjectId(this.args.item);
      const confirmed = await this.confirmationDialog.confirm(this.confirmationDialog.DELETE_CONFIRMATION);
      if (!confirmed) {
        return;
      }
      try {
        const record = await this.store.findRecord('transaction/transactionitem', id);
        await record.destroyRecord();
        this.resetPagination();
      } catch (error) {
        (0, _notifications.reportError)(error);
      }
    }
  }, _descriptor = _applyDecoratedDescriptor(_class.prototype, "confirmationDialog", [_dec], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor2 = _applyDecoratedDescriptor(_class.prototype, "store", [_dec2], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor3 = _applyDecoratedDescriptor(_class.prototype, "editMode", [_dec3], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return false;
    }
  }), _applyDecoratedDescriptor(_class.prototype, "edit", [_dec4], Object.getOwnPropertyDescriptor(_class.prototype, "edit"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "cancel", [_dec5], Object.getOwnPropertyDescriptor(_class.prototype, "cancel"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "save", [_dec6], Object.getOwnPropertyDescriptor(_class.prototype, "save"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "delete", [_dec7], Object.getOwnPropertyDescriptor(_class.prototype, "delete"), _class.prototype), _class);
  Ember._setComponentTemplate(__COLOCATED_TEMPLATE__, TransactionViewObjectsRow);
});