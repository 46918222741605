define("plutof/components/information/content/general/area-event", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const __COLOCATED_TEMPLATE__ = Ember.HTMLBars.template(
  /*
    <h4>{{i18n-t "Sample.area.areasSubtitle"}}</h4>
  
  <Information::MarkdownReader @path="area/types" @locale={{@locale}} />
  <Information::Content::General::MapControls @locale={{@locale}} />
  
  */
  {
    "id": "6IlHdyYD",
    "block": "{\"symbols\":[\"@locale\"],\"statements\":[[10,\"h4\"],[12],[1,[30,[36,0],[\"Sample.area.areasSubtitle\"],null]],[13],[2,\"\\n\\n\"],[8,\"information/markdown-reader\",[],[[\"@path\",\"@locale\"],[\"area/types\",[32,1]]],null],[2,\"\\n\"],[8,\"information/content/general/map-controls\",[],[[\"@locale\"],[[32,1]]],null],[2,\"\\n\"]],\"hasEval\":false,\"upvars\":[\"i18n-t\"]}",
    "moduleName": "plutof/components/information/content/general/area-event.hbs"
  });
  var _default = _exports.default = Ember._setComponentTemplate(__COLOCATED_TEMPLATE__, Ember._templateOnlyComponent());
});