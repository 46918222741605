define("plutof/components/agent/organization-view/substructures", ["exports", "ember-concurrency", "plutof/utils/pagination"], function (_exports, _emberConcurrency, _pagination) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _dec, _dec2, _dec3, _dec4, _class, _descriptor, _descriptor2;
  function _initializerDefineProperty(e, i, r, l) { r && Object.defineProperty(e, i, { enumerable: r.enumerable, configurable: r.configurable, writable: r.writable, value: r.initializer ? r.initializer.call(l) : void 0 }); }
  function _defineProperty(e, r, t) { return (r = _toPropertyKey(r)) in e ? Object.defineProperty(e, r, { value: t, enumerable: !0, configurable: !0, writable: !0 }) : e[r] = t, e; }
  function _toPropertyKey(t) { var i = _toPrimitive(t, "string"); return "symbol" == typeof i ? i : i + ""; }
  function _toPrimitive(t, r) { if ("object" != typeof t || !t) return t; var e = t[Symbol.toPrimitive]; if (void 0 !== e) { var i = e.call(t, r || "default"); if ("object" != typeof i) return i; throw new TypeError("@@toPrimitive must return a primitive value."); } return ("string" === r ? String : Number)(t); }
  function _applyDecoratedDescriptor(i, e, r, n, l) { var a = {}; return Object.keys(n).forEach(function (i) { a[i] = n[i]; }), a.enumerable = !!a.enumerable, a.configurable = !!a.configurable, ("value" in a || a.initializer) && (a.writable = !0), a = r.slice().reverse().reduce(function (r, n) { return n(i, e, r) || r; }, a), l && void 0 !== a.initializer && (a.value = a.initializer ? a.initializer.call(l) : void 0, a.initializer = void 0), void 0 === a.initializer ? (Object.defineProperty(i, e, a), null) : a; }
  function _initializerWarningHelper(r, e) { throw Error("Decorating class property failed. Please ensure that transform-class-properties is enabled and runs after the decorators transform."); }
  const __COLOCATED_TEMPLATE__ = Ember.HTMLBars.template(
  /*
    <RelatedObjects::View
      @add={{this.add}}
      @canModify={{true}}
      @pagination={{this.pagination}}
      @title={{@title}}
  >
      <RelatedObjects::Content @pagination={{this.pagination}} as |Field organization|>
          <Field @label={{i18n-t "Agents.organization.fields.name"}}>
              {{#link-to @viewRoute organization.id}}
                  {{organization.name}}
              {{/link-to}}
          </Field>
  
          <Field @label={{i18n-t "General.occurrencesCount.label"}}>
              <Common::OccurrencesCount @queryParams={{hash dp=organization.id}} />
          </Field>
  
          <Field @label={{backend-translations/label "organization.updated_at"}}>
              {{formatted-date organization.updated_at}}
          </Field>
      </RelatedObjects::Content>
  </RelatedObjects::View>
  
  */
  {
    "id": "XDBSwDuw",
    "block": "{\"symbols\":[\"Field\",\"organization\",\"@title\",\"@viewRoute\"],\"statements\":[[8,\"related-objects/view\",[],[[\"@add\",\"@canModify\",\"@pagination\",\"@title\"],[[32,0,[\"add\"]],true,[32,0,[\"pagination\"]],[32,3]]],[[\"default\"],[{\"statements\":[[2,\"\\n    \"],[8,\"related-objects/content\",[],[[\"@pagination\"],[[32,0,[\"pagination\"]]]],[[\"default\"],[{\"statements\":[[2,\"\\n        \"],[8,[32,1],[],[[\"@label\"],[[30,[36,0],[\"Agents.organization.fields.name\"],null]]],[[\"default\"],[{\"statements\":[[2,\"\\n\"],[6,[37,1],null,[[\"route\",\"model\"],[[32,4],[32,2,[\"id\"]]]],[[\"default\"],[{\"statements\":[[2,\"                \"],[1,[32,2,[\"name\"]]],[2,\"\\n\"]],\"parameters\":[]}]]],[2,\"        \"]],\"parameters\":[]}]]],[2,\"\\n\\n        \"],[8,[32,1],[],[[\"@label\"],[[30,[36,0],[\"General.occurrencesCount.label\"],null]]],[[\"default\"],[{\"statements\":[[2,\"\\n            \"],[8,\"common/occurrences-count\",[],[[\"@queryParams\"],[[30,[36,2],null,[[\"dp\"],[[32,2,[\"id\"]]]]]]],null],[2,\"\\n        \"]],\"parameters\":[]}]]],[2,\"\\n\\n        \"],[8,[32,1],[],[[\"@label\"],[[30,[36,3],[\"organization.updated_at\"],null]]],[[\"default\"],[{\"statements\":[[2,\"\\n            \"],[1,[30,[36,4],[[32,2,[\"updated_at\"]]],null]],[2,\"\\n        \"]],\"parameters\":[]}]]],[2,\"\\n    \"]],\"parameters\":[1,2]}]]],[2,\"\\n\"]],\"parameters\":[]}]]],[2,\"\\n\"]],\"hasEval\":false,\"upvars\":[\"i18n-t\",\"link-to\",\"hash\",\"backend-translations/label\",\"formatted-date\"]}",
    "moduleName": "plutof/components/agent/organization-view/substructures.hbs"
  });
  let OrganizationViewSubstructures = _exports.default = (_dec = Ember.inject.service, _dec2 = Ember._action, _dec3 = Ember.computed('organizationId'), _dec4 = (0, _emberConcurrency.task)({
    restartable: true
  }), _class = class OrganizationViewSubstructures extends Ember.Component {
    constructor(...args) {
      super(...args);
      _initializerDefineProperty(this, "router", _descriptor, this);
      _initializerDefineProperty(this, "updatePagination", _descriptor2, this);
    }
    add() {
      return this.router.transitionTo(this.addRoute, {
        queryParams: {
          parent_organization: this.organizationId
        }
      });
    }
    get queryParams() {
      return {
        parent_organization: this.organizationId,
        ordering: '-updated_at'
      };
    }
    didReceiveAttrs() {
      super.didReceiveAttrs(...arguments);
      this.updatePagination.perform();
    }
  }, _descriptor = _applyDecoratedDescriptor(_class.prototype, "router", [_dec], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _applyDecoratedDescriptor(_class.prototype, "add", [_dec2], Object.getOwnPropertyDescriptor(_class.prototype, "add"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "queryParams", [_dec3], Object.getOwnPropertyDescriptor(_class.prototype, "queryParams"), _class.prototype), _descriptor2 = _applyDecoratedDescriptor(_class.prototype, "updatePagination", [_dec4], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return function* () {
        const pagination = yield (0, _pagination.paginateModel)(this.store, this.modelPath, this.queryParams);
        this.set('pagination', pagination);
      };
    }
  }), _class);
  Ember._setComponentTemplate(__COLOCATED_TEMPLATE__, OrganizationViewSubstructures);
});