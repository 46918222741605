define("plutof/templates/components/sample/navbar-buttons/cloning", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = Ember.HTMLBars.template({
    "id": "iZ3mv2oX",
    "block": "{\"symbols\":[\"@navbar\"],\"statements\":[[8,\"tool-tip\",[],[[\"@tooltip\"],[[30,[36,0],[\"cloning.cloneTooltip\"],null]]],[[\"default\"],[{\"statements\":[[2,\"\\n    \"],[8,[32,1,[\"button\"]],[],[[\"@icon\",\"@clicked\"],[[30,[36,1],[\"clone\"],null],[30,[36,2],[[32,0,[\"clone\"]],true,true],null]]],[[\"default\"],[{\"statements\":[[2,\"\\n        \"],[1,[30,[36,0],[\"cloning.clone\"],null]],[2,\"\\n    \"]],\"parameters\":[]}]]],[2,\"\\n\"]],\"parameters\":[]}]]],[2,\"\\n\\n\"],[8,\"tool-tip\",[],[[\"@tooltip\"],[[30,[36,0],[\"cloning.linkToAreaTooltip\"],null]]],[[\"default\"],[{\"statements\":[[2,\"\\n    \"],[8,[32,1,[\"button\"]],[],[[\"@icon\",\"@clicked\"],[[30,[36,1],[\"use-as-template\"],null],[30,[36,2],[[32,0,[\"clone\"]],false,true],null]]],[[\"default\"],[{\"statements\":[[2,\"\\n        \"],[1,[30,[36,0],[\"cloning.linkToArea\"],null]],[2,\"\\n    \"]],\"parameters\":[]}]]],[2,\"\\n\"]],\"parameters\":[]}]]],[2,\"\\n\\n\"],[8,\"tool-tip\",[],[[\"@tooltip\"],[[30,[36,0],[\"cloning.linkToEventTooltip\"],null]]],[[\"default\"],[{\"statements\":[[2,\"\\n    \"],[8,[32,1,[\"button\"]],[],[[\"@icon\",\"@clicked\"],[[30,[36,1],[\"use-as-template\"],null],[30,[36,2],[[32,0,[\"clone\"]],false,false],null]]],[[\"default\"],[{\"statements\":[[2,\"\\n        \"],[1,[30,[36,0],[\"cloning.linkToEvent\"],null]],[2,\"\\n    \"]],\"parameters\":[]}]]],[2,\"\\n\"]],\"parameters\":[]}]]],[2,\"\\n\"]],\"hasEval\":false,\"upvars\":[\"i18n-t\",\"icon\",\"fn\"]}",
    "moduleName": "plutof/templates/components/sample/navbar-buttons/cloning.hbs"
  });
});