define("plutof/components/specimen/autocomplete-result", ["exports", "@glimmer/component"], function (_exports, _component) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const __COLOCATED_TEMPLATE__ = Ember.HTMLBars.template(
  /*
    <p>
      {{hl-subs this.name @query}}
  
      {{#if @result.owner}}
          <span class="plutof-ac-suggestion-description">
              {{@result.owner}}
          </span>
      {{/if}}
  </p>
  
  {{#if @result.tooltip}}
      <div>
          <span class="plutof-tooltip">{{@result.tooltip}}</span>
      </div>
  {{/if}}
  
  */
  {
    "id": "aGiEJ2j3",
    "block": "{\"symbols\":[\"@result\",\"@query\"],\"statements\":[[10,\"p\"],[12],[2,\"\\n    \"],[1,[30,[36,0],[[32,0,[\"name\"]],[32,2]],null]],[2,\"\\n\\n\"],[6,[37,1],[[32,1,[\"owner\"]]],null,[[\"default\"],[{\"statements\":[[2,\"        \"],[10,\"span\"],[14,0,\"plutof-ac-suggestion-description\"],[12],[2,\"\\n            \"],[1,[32,1,[\"owner\"]]],[2,\"\\n        \"],[13],[2,\"\\n\"]],\"parameters\":[]}]]],[13],[2,\"\\n\\n\"],[6,[37,1],[[32,1,[\"tooltip\"]]],null,[[\"default\"],[{\"statements\":[[2,\"    \"],[10,\"div\"],[12],[2,\"\\n        \"],[10,\"span\"],[14,0,\"plutof-tooltip\"],[12],[1,[32,1,[\"tooltip\"]]],[13],[2,\"\\n    \"],[13],[2,\"\\n\"]],\"parameters\":[]}]]]],\"hasEval\":false,\"upvars\":[\"hl-subs\",\"if\"]}",
    "moduleName": "plutof/components/specimen/autocomplete-result.hbs"
  });
  class SpecimenAutocompleteResult extends _component.default {
    get name() {
      const {
        name,
        additional_identifiers
      } = this.args.result.object;
      return [name].concat(additional_identifiers).join(' | ');
    }
  }
  _exports.default = SpecimenAutocompleteResult;
  Ember._setComponentTemplate(__COLOCATED_TEMPLATE__, SpecimenAutocompleteResult);
});