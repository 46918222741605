define("plutof/templates/components/multiple-selection", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = Ember.HTMLBars.template({
    "id": "7tyYK2ze",
    "block": "{\"symbols\":[\"item\",\"@disabled\",\"@optionLabelPath\",\"@optionValuePath\"],\"statements\":[[1,[30,[36,5],null,[[\"content\",\"optionValuePath\",\"optionLabelPath\",\"selection\",\"prompt\",\"selectClass\",\"disabled\"],[[35,4],[30,[36,3],[[32,4],\"id\"],null],[30,[36,3],[[32,3],\"representation\"],null],[35,2],[35,1],[35,0],[32,2]]]]],[2,\"\\n\\n\"],[8,\"common/record-list\",[],[[\"@records\",\"@remove\"],[[32,0,[\"selections\"]],[32,0,[\"removeRecord\"]]]],[[\"default\"],[{\"statements\":[[2,\"\\n    \"],[1,[30,[36,6],[[32,1],[32,3]],null]],[2,\"\\n\"]],\"parameters\":[1]}]]],[2,\"\\n\"]],\"hasEval\":false,\"upvars\":[\"selectClass\",\"prompt\",\"selectedValue\",\"default-to\",\"content\",\"plutof-select/object\",\"get\"]}",
    "moduleName": "plutof/templates/components/multiple-selection.hbs"
  });
});