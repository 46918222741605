define("plutof/templates/components/block-label", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = Ember.HTMLBars.template({
    "id": "xM0RnZ5T",
    "block": "{\"symbols\":[\"info\",\"&default\"],\"statements\":[[10,\"label\"],[15,0,[31,[[34,5]]]],[12],[2,\"\\n    \"],[18,2,null],[2,\"\\n\\n    \"],[1,[35,1,[\"labelText\"]]],[2,\"\\n\"],[13],[2,\"\\n\\n\"],[6,[37,7],[[35,6]],null,[[\"default\"],[{\"statements\":[[2,\"    \"],[10,\"span\"],[14,0,\"plutof-tooltip\"],[12],[2,\"\\n        \"],[1,[30,[36,2],[[35,1,[\"tooltipText\"]]],null]],[2,\"\\n\\n\"],[6,[37,4],[[30,[36,3],[[30,[36,3],[[35,1,[\"infoLinks\"]]],null]],null]],null,[[\"default\"],[{\"statements\":[[2,\"            \"],[1,[30,[36,0],null,[[\"infoLink\"],[[32,1,[\"url\"]]]]]],[2,\"\\n\"]],\"parameters\":[1]}]]],[2,\"    \"],[13],[2,\"\\n\"]],\"parameters\":[]}]]]],\"hasEval\":false,\"upvars\":[\"info-link\",\"data\",\"break-line\",\"-track-array\",\"each\",\"labelClasses\",\"enableTooltip\",\"if\"]}",
    "moduleName": "plutof/templates/components/block-label.hbs"
  });
});