define("plutof/components/annotation/-fields/status", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const __COLOCATED_TEMPLATE__ = Ember.HTMLBars.template(
  /*
    <@field @label={{i18n-t "annotation.fields.status"}}>
      {{! Not resolving translation here because ModelTexts will unnecessarily instantiate for every row }}
      {{get @translations.status.choices @annotation.status}}
  </@field>
  
  */
  {
    "id": "hMdCNnWH",
    "block": "{\"symbols\":[\"@field\",\"@annotation\",\"@translations\"],\"statements\":[[8,[32,1],[],[[\"@label\"],[[30,[36,0],[\"annotation.fields.status\"],null]]],[[\"default\"],[{\"statements\":[[2,\"\\n\"],[2,\"    \"],[1,[30,[36,1],[[32,3,[\"status\",\"choices\"]],[32,2,[\"status\"]]],null]],[2,\"\\n\"]],\"parameters\":[]}]]],[2,\"\\n\"]],\"hasEval\":false,\"upvars\":[\"i18n-t\",\"get\"]}",
    "moduleName": "plutof/components/annotation/-fields/status.hbs"
  });
  var _default = _exports.default = Ember._setComponentTemplate(__COLOCATED_TEMPLATE__, Ember._templateOnlyComponent());
});