define("plutof/templates/person/view", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = Ember.HTMLBars.template({
    "id": "3Z52m8MA",
    "block": "{\"symbols\":[],\"statements\":[[10,\"div\"],[14,0,\"route-marker-person-view\"],[14,\"data-test\",\"route-person.view\"],[12],[13],[2,\"\\n\\n\"],[8,\"navbar/view\",[],[[\"@icon\",\"@title\",\"@record\",\"@module\",\"@editRoute\",\"@accessRights\",\"@deletable\",\"@neverDeleteRelatedFiles\",\"@infoContent\"],[[30,[36,0],[\"person\"],null],[30,[36,1],[\"Agents.person.person\"],null],[32,0,[\"model\",\"person\"]],\"agent/person\",\"person.edit\",[32,0,[\"model\",\"accessRights\"]],[32,0,[\"canDelete\"]],true,\"information/content/person/general\"]],null],[2,\"\\n\\n\"],[8,\"person/person-view-general\",[],[[\"@person\",\"@synonyms\"],[[32,0,[\"model\",\"person\"]],[32,0,[\"model\",\"synonyms\"]]]],null],[2,\"\\n\\n\"],[1,[30,[36,3],null,[[\"agentassociations\"],[[35,2,[\"agentassociations\"]]]]]],[2,\"\\n\\n\"],[1,[30,[36,4],null,[[\"person\",\"statistics\",\"statisticsOptions\"],[[35,2,[\"person\"]],[35,2,[\"statistics\"]],[35,2,[\"statisticsOptions\"]]]]]],[2,\"\\n\\n\"],[8,\"access/view-rights\",[],[[\"@object\",\"@rights\"],[[32,0,[\"model\",\"person\"]],[32,0,[\"model\",\"accessRights\"]]]],null],[2,\"\\n\"]],\"hasEval\":false,\"upvars\":[\"icon\",\"i18n-t\",\"model\",\"person/person-view-associations\",\"person/person-view-statistics\"]}",
    "moduleName": "plutof/templates/person/view.hbs"
  });
});