define("plutof/components/search/filter-panels/common-properties/checkboxes", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const __COLOCATED_TEMPLATE__ = Ember.HTMLBars.template(
  /*
    <Search::FilterInput @filter={{@filterInputs.sub_studies}} />
  <Search::FilterInput @filter={{@filterInputs.is_verified}} />
  <Search::FilterInput @filter={{@filterInputs.is_public}} />
  
  <Search::FilterInput @filter={{@filterInputs.has_files}} />
  <Search::FilterInput @filter={{@filterInputs.has_sound_files}} />
  <Search::FilterInput @filter={{@filterInputs.has_video_files}} />
  <Search::FilterInput @filter={{@filterInputs.has_images}} />
  <Search::FilterInput @filter={{@filterInputs.has_3d_model}} />
  
  <Search::FilterInput @filter={{@filterInputs.has_externallinks}} />
  <Search::FilterInput @filter={{@filterInputs.has_sequences}} />
  <Search::FilterInput @filter={{@filterInputs.has_dna}} />
  <Search::FilterInput @filter={{@filterInputs.has_cultures}} />
  
  <Search::FilterInput @filter={{@filterInputs.has_accepted_annotations}} />
  
  */
  {
    "id": "7F9gWUbh",
    "block": "{\"symbols\":[\"@filterInputs\"],\"statements\":[[8,\"search/filter-input\",[],[[\"@filter\"],[[32,1,[\"sub_studies\"]]]],null],[2,\"\\n\"],[8,\"search/filter-input\",[],[[\"@filter\"],[[32,1,[\"is_verified\"]]]],null],[2,\"\\n\"],[8,\"search/filter-input\",[],[[\"@filter\"],[[32,1,[\"is_public\"]]]],null],[2,\"\\n\\n\"],[8,\"search/filter-input\",[],[[\"@filter\"],[[32,1,[\"has_files\"]]]],null],[2,\"\\n\"],[8,\"search/filter-input\",[],[[\"@filter\"],[[32,1,[\"has_sound_files\"]]]],null],[2,\"\\n\"],[8,\"search/filter-input\",[],[[\"@filter\"],[[32,1,[\"has_video_files\"]]]],null],[2,\"\\n\"],[8,\"search/filter-input\",[],[[\"@filter\"],[[32,1,[\"has_images\"]]]],null],[2,\"\\n\"],[8,\"search/filter-input\",[],[[\"@filter\"],[[32,1,[\"has_3d_model\"]]]],null],[2,\"\\n\\n\"],[8,\"search/filter-input\",[],[[\"@filter\"],[[32,1,[\"has_externallinks\"]]]],null],[2,\"\\n\"],[8,\"search/filter-input\",[],[[\"@filter\"],[[32,1,[\"has_sequences\"]]]],null],[2,\"\\n\"],[8,\"search/filter-input\",[],[[\"@filter\"],[[32,1,[\"has_dna\"]]]],null],[2,\"\\n\"],[8,\"search/filter-input\",[],[[\"@filter\"],[[32,1,[\"has_cultures\"]]]],null],[2,\"\\n\\n\"],[8,\"search/filter-input\",[],[[\"@filter\"],[[32,1,[\"has_accepted_annotations\"]]]],null],[2,\"\\n\"]],\"hasEval\":false,\"upvars\":[]}",
    "moduleName": "plutof/components/search/filter-panels/common-properties/checkboxes.hbs"
  });
  var _default = _exports.default = Ember._setComponentTemplate(__COLOCATED_TEMPLATE__, Ember._templateOnlyComponent());
});