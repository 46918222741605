define("plutof/templates/publishing/ena/add", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = Ember.HTMLBars.template({
    "id": "UAh/fcGo",
    "block": "{\"symbols\":[],\"statements\":[[8,\"test/route-marker\",[],[[\"@route\"],[\"ena.add\"]],null],[2,\"\\n\\n\"],[8,\"navbar/edit\",[],[[\"@icon\",\"@title\",\"@record\",\"@reset\",\"@infoTitle\",\"@infoContent\"],[[30,[36,0],[\"ena\"],null],[30,[36,1],[\"ena.newDataset\"],null],[32,0,[\"model\",\"ena\"]],[30,[36,2],[\"triggerReset\"],null],[30,[36,1],[\"ena.title\"],null],\"information/content/ena/general\"]],null],[2,\"\\n\\n\"],[8,\"publishing/ena/edit\",[],[[\"@ena\",\"@save\",\"@validationContext\"],[[32,0,[\"model\",\"ena\"]],[32,0,[\"save\"]],[32,0,[\"validationContext\"]]]],null],[2,\"\\n\"]],\"hasEval\":false,\"upvars\":[\"icon\",\"i18n-t\",\"route-action\"]}",
    "moduleName": "plutof/templates/publishing/ena/add.hbs"
  });
});