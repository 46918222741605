define("plutof/components/access/-view-rights/agent-list", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const __COLOCATED_TEMPLATE__ = Ember.HTMLBars.template(
  /*
    <Common::RecordListView
      @records={{@groups}}
      @terminator={{if @usernames.length "," ""}}
      as |group|
  >
      {{profile/groups/group-link group=group}}
  </Common::RecordListView>
  
  <Common::RecordListView @records={{@usernames}} as |username|>
      {{username}}
  </Common::RecordListView>
  
  */
  {
    "id": "i7dVlmqD",
    "block": "{\"symbols\":[\"username\",\"group\",\"@groups\",\"@usernames\"],\"statements\":[[8,\"common/record-list-view\",[],[[\"@records\",\"@terminator\"],[[32,3],[30,[36,0],[[32,4,[\"length\"]],\",\",\"\"],null]]],[[\"default\"],[{\"statements\":[[2,\"\\n    \"],[1,[30,[36,1],null,[[\"group\"],[[32,2]]]]],[2,\"\\n\"]],\"parameters\":[2]}]]],[2,\"\\n\\n\"],[8,\"common/record-list-view\",[],[[\"@records\"],[[32,4]]],[[\"default\"],[{\"statements\":[[2,\"\\n    \"],[1,[32,1]],[2,\"\\n\"]],\"parameters\":[1]}]]],[2,\"\\n\"]],\"hasEval\":false,\"upvars\":[\"if\",\"profile/groups/group-link\"]}",
    "moduleName": "plutof/components/access/-view-rights/agent-list.hbs"
  });
  var _default = _exports.default = Ember._setComponentTemplate(__COLOCATED_TEMPLATE__, Ember._templateOnlyComponent());
});