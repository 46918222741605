define("plutof/templates/experiment/pcr/view", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = Ember.HTMLBars.template({
    "id": "H4Fiq2nm",
    "block": "{\"symbols\":[\"materialContainer\"],\"statements\":[[10,\"div\"],[14,0,\"route-marker-pcr-view\"],[12],[13],[2,\"\\n\\n\"],[8,\"navbar/view\",[],[[\"@icon\",\"@title\",\"@record\",\"@permissions\",\"@editRoute\",\"@module\",\"@deletable\",\"@infoTitle\",\"@infoContent\"],[[30,[36,0],[\"experiment\"],null],[30,[36,1],[\"experiment.pcr.routeTitles.view\"],null],[32,0,[\"model\",\"experiment\"]],[32,0,[\"model\",\"permissions\"]],\"experiment.pcr.edit\",\"dnalab/pcr\",true,[30,[36,1],[\"experiment.general.experiments\"],null],\"information/content/dna-lab/general\"]],null],[2,\"\\n\\n\"],[8,\"experiment/pcr/view/general-data\",[],[[\"@experiment\"],[[32,0,[\"model\",\"experiment\"]]]],null],[2,\"\\n\\n\"],[8,\"experiment/common/material-container\",[],[[\"@labID\",\"@materials\",\"@materialType\"],[[32,0,[\"model\",\"experiment\",\"laboratory\",\"id\"]],[32,0,[\"model\",\"materials\"]],\"pcr\"]],[[\"default\"],[{\"statements\":[[2,\"\\n    \"],[8,\"experiment/pcr/view/experiment-material-view\",[],[[\"@products\",\"@enableTubeSelection\",\"@materialContainer\"],[[32,0,[\"model\",\"materials\"]],true,[32,1]]],null],[2,\"\\n\\n    \"],[8,\"experiment/common/labware-visualization\",[],[[\"@experiment\",\"@materials\",\"@materialType\",\"@enableTubeSelection\",\"@materialContainer\"],[[32,0,[\"model\",\"experiment\"]],[32,0,[\"model\",\"materials\"]],\"pcr\",true,[32,1]]],null],[2,\"\\n\"]],\"parameters\":[1]}]]],[2,\"\\n\\n\"],[8,\"linked-items/view\",[],[[\"@object\"],[[32,0,[\"model\",\"experiment\"]]]],null],[2,\"\\n\\n\"],[8,\"experiment/common/record-metadata\",[],[[\"@record\"],[[32,0,[\"model\",\"experiment\"]]]],null],[2,\"\\n\"]],\"hasEval\":false,\"upvars\":[\"icon\",\"i18n-t\"]}",
    "moduleName": "plutof/templates/experiment/pcr/view.hbs"
  });
});