define("plutof/templates/components/transaction/edit-correspondences", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = Ember.HTMLBars.template({
    "id": "I3/Zo92J",
    "block": "{\"symbols\":[\"panel\",\"correspondence\",\"context\",\"@data\",\"@validationContext\"],\"statements\":[[8,\"plutof-panel/item-list\",[[24,\"data-test\",\"Transaction::Correspondences\"]],[[\"@collapse\"],[[30,[36,0],[[32,4,[\"count\"]]],null]]],[[\"default\"],[{\"statements\":[[2,\"\\n    \"],[8,[32,1,[\"header\"]],[],[[\"@title\",\"@count\"],[[30,[36,1],[\"transactions.correspondence\"],null],[32,4,[\"count\"]]]],[[\"default\"],[{\"statements\":[[2,\"\\n        \"],[8,[32,1,[\"button\"]],[],[[\"@clicked\",\"@class\"],[[30,[36,2],[[32,0],[32,0,[\"add\"]]],null],\"test-marker-btn-add\"]],[[\"default\"],[{\"statements\":[[2,\"\\n            \"],[1,[30,[36,1],[\"General.Add\"],null]],[2,\"\\n        \"]],\"parameters\":[]}]]],[2,\"\\n    \"]],\"parameters\":[]}]]],[2,\"\\n\\n    \"],[8,[32,1,[\"innerPanels\"]],[],[[],[]],[[\"default\"],[{\"statements\":[[2,\"\\n        \"],[8,\"validation/split-context\",[],[[\"@context\",\"@items\"],[[32,5],[32,4,[\"correspondences\"]]]],[[\"default\"],[{\"statements\":[[2,\"\\n\"],[2,\"            \"],[8,\"transaction/transaction-correspondence\",[[24,\"data-test\",\"Transaction::Correspondence\"]],[[\"@correspondence\",\"@validationContext\",\"@remove\"],[[32,2],[32,3],[30,[36,3],[[32,0,[\"remove\"]],[32,2]],null]]],null],[2,\"\\n        \"]],\"parameters\":[2,3]}]]],[2,\"\\n    \"]],\"parameters\":[]}]]],[2,\"\\n\"]],\"parameters\":[1]}]]],[2,\"\\n\"]],\"hasEval\":false,\"upvars\":[\"not\",\"i18n-t\",\"action\",\"fn\"]}",
    "moduleName": "plutof/templates/components/transaction/edit-correspondences.hbs"
  });
});