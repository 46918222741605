define("plutof/components/add-multiple-form/-add-button", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const __COLOCATED_TEMPLATE__ = Ember.HTMLBars.template(
  /*
    <div class="component-part component-part--button">
      <button
          {{on "click" @add}}
          disabled={{@disable}}
          class="btn btn-default control"
      >
          <span class="fas fa-check"></span>
          {{i18n-t "General.Add"}}
      </button>
  </div>
  
  */
  {
    "id": "2+U+n3ks",
    "block": "{\"symbols\":[\"@disable\",\"@add\"],\"statements\":[[10,\"div\"],[14,0,\"component-part component-part--button\"],[12],[2,\"\\n    \"],[11,\"button\"],[16,\"disabled\",[32,1]],[24,0,\"btn btn-default control\"],[4,[38,0],[\"click\",[32,2]],null],[12],[2,\"\\n        \"],[10,\"span\"],[14,0,\"fas fa-check\"],[12],[13],[2,\"\\n        \"],[1,[30,[36,1],[\"General.Add\"],null]],[2,\"\\n    \"],[13],[2,\"\\n\"],[13],[2,\"\\n\"]],\"hasEval\":false,\"upvars\":[\"on\",\"i18n-t\"]}",
    "moduleName": "plutof/components/add-multiple-form/-add-button.hbs"
  });
  var _default = _exports.default = Ember._setComponentTemplate(__COLOCATED_TEMPLATE__, Ember._templateOnlyComponent());
});