define("plutof/templates/compound/view", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = Ember.HTMLBars.template({
    "id": "6Cn6hMo3",
    "block": "{\"symbols\":[\"navbar\",\"slots\"],\"statements\":[[10,\"div\"],[14,0,\"route-marker-compound-view\"],[12],[13],[2,\"\\n\\n\"],[10,\"div\"],[14,0,\"fullscreen-route\"],[12],[2,\"\\n    \"],[8,\"navbar/view\",[],[[\"@icon\",\"@title\",\"@permissions\"],[[30,[36,0],[\"unite-compound-cluster\"],null],[34,1],[32,0,[\"permissions\"]]]],[[\"default\"],[{\"statements\":[[2,\"\\n        \"],[8,[32,2,[\"secondaryControls\"]],[],[[],[]],[[\"default\"],[{\"statements\":[[2,\"\\n            \"],[8,[32,1,[\"button\"]],[],[[\"@clicked\",\"@icon\"],[[32,0,[\"downloadFASTA\"]],[30,[36,0],[\"download\"],null]]],[[\"default\"],[{\"statements\":[[2,\"\\n                \"],[1,[30,[36,2],[\"UniteSH.downloadFASTA\"],null]],[2,\"\\n            \"]],\"parameters\":[]}]]],[2,\"\\n        \"]],\"parameters\":[]}]]],[2,\"\\n    \"]],\"parameters\":[1,2]}]]],[2,\"\\n\\n    \"],[1,[30,[36,4],null,[[\"totalCount\",\"clusterSequences\",\"clusterID\",\"thresholdOrdering\"],[[35,3,[\"totalCount\"]],[35,3,[\"clusterSequences\"]],[35,3,[\"cluster\",\"id\"]],[32,0,[\"model\",\"thresholdOrdering\"]]]]]],[2,\"\\n\"],[13],[2,\"\\n\"]],\"hasEval\":false,\"upvars\":[\"icon\",\"title\",\"i18n-t\",\"model\",\"compound-table\"]}",
    "moduleName": "plutof/templates/compound/view.hbs"
  });
});