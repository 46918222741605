define("plutof/components/information/content/filerepository/general", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const __COLOCATED_TEMPLATE__ = Ember.HTMLBars.template(
  /*
    <Information::MarkdownReader @path="filerepository/general" @locale={{@locale}} />
  
  <h4> {{i18n-t 'Profile.CreativeCommons'}}</h4>
  
  <Information::Content::General::DefaultLicence />
  
  */
  {
    "id": "ocLA2Y8J",
    "block": "{\"symbols\":[\"@locale\"],\"statements\":[[8,\"information/markdown-reader\",[],[[\"@path\",\"@locale\"],[\"filerepository/general\",[32,1]]],null],[2,\"\\n\\n\"],[10,\"h4\"],[12],[2,\" \"],[1,[30,[36,0],[\"Profile.CreativeCommons\"],null]],[13],[2,\"\\n\\n\"],[8,\"information/content/general/default-licence\",[],[[],[]],null],[2,\"\\n\"]],\"hasEval\":false,\"upvars\":[\"i18n-t\"]}",
    "moduleName": "plutof/components/information/content/filerepository/general.hbs"
  });
  var _default = _exports.default = Ember._setComponentTemplate(__COLOCATED_TEMPLATE__, Ember._templateOnlyComponent());
});