define("plutof/components/conservation/red-list/view/fields", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const __COLOCATED_TEMPLATE__ = Ember.HTMLBars.template(
  /*
    <PlutofPanel::Simple @title={{i18n-t "General.generalData"}}>
      <GenericViews::Record @record={{@redlist}} as |view|>
          {{view.attr "name"}}
      </GenericViews::Record>
  </PlutofPanel::Simple>
  
  <Trait::List::View @form={{@redlist.form}} />
  
  <PlutofPanel::Simple @title={{i18n-t "conservation.redList.fields.area"}}>
      {{! TODO: ViewMap needs a better API }}
      <Map::ViewMap
          @geodata={{@redlist.area_polygon}}
          @singular={{true}}
          @geomProperty=""
          @defaultControl={{null}} />
  </PlutofPanel::Simple>
  
  */
  {
    "id": "aNs887VK",
    "block": "{\"symbols\":[\"view\",\"@redlist\"],\"statements\":[[8,\"plutof-panel/simple\",[],[[\"@title\"],[[30,[36,0],[\"General.generalData\"],null]]],[[\"default\"],[{\"statements\":[[2,\"\\n    \"],[8,\"generic-views/record\",[],[[\"@record\"],[[32,2]]],[[\"default\"],[{\"statements\":[[2,\"\\n        \"],[1,[30,[36,1],[[32,1,[\"attr\"]],\"name\"],null]],[2,\"\\n    \"]],\"parameters\":[1]}]]],[2,\"\\n\"]],\"parameters\":[]}]]],[2,\"\\n\\n\"],[8,\"trait/list/view\",[],[[\"@form\"],[[32,2,[\"form\"]]]],null],[2,\"\\n\\n\"],[8,\"plutof-panel/simple\",[],[[\"@title\"],[[30,[36,0],[\"conservation.redList.fields.area\"],null]]],[[\"default\"],[{\"statements\":[[2,\"\\n\"],[2,\"    \"],[8,\"map/view-map\",[],[[\"@geodata\",\"@singular\",\"@geomProperty\",\"@defaultControl\"],[[32,2,[\"area_polygon\"]],true,\"\",null]],null],[2,\"\\n\"]],\"parameters\":[]}]]],[2,\"\\n\"]],\"hasEval\":false,\"upvars\":[\"i18n-t\",\"component\"]}",
    "moduleName": "plutof/components/conservation/red-list/view/fields.hbs"
  });
  var _default = _exports.default = Ember._setComponentTemplate(__COLOCATED_TEMPLATE__, Ember._templateOnlyComponent());
});