define("plutof/components/annotation/-fields/icon", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const __COLOCATED_TEMPLATE__ = Ember.HTMLBars.template(
  /*
    <@field>
      <Annotation::List::Icon @annotation={{@annotation}} />
  </@field>
  
  */
  {
    "id": "RoNEpVGB",
    "block": "{\"symbols\":[\"@field\",\"@annotation\"],\"statements\":[[8,[32,1],[],[[],[]],[[\"default\"],[{\"statements\":[[2,\"\\n    \"],[8,\"annotation/list/icon\",[],[[\"@annotation\"],[[32,2]]],null],[2,\"\\n\"]],\"parameters\":[]}]]],[2,\"\\n\"]],\"hasEval\":false,\"upvars\":[]}",
    "moduleName": "plutof/components/annotation/-fields/icon.hbs"
  });
  var _default = _exports.default = Ember._setComponentTemplate(__COLOCATED_TEMPLATE__, Ember._templateOnlyComponent());
});