define("plutof/components/search/filter-panels/annotation/taxon", ["exports", "@glimmer/component"], function (_exports, _component) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _dec, _dec2, _dec3, _dec4, _dec5, _dec6, _class, _descriptor;
  function _initializerDefineProperty(e, i, r, l) { r && Object.defineProperty(e, i, { enumerable: r.enumerable, configurable: r.configurable, writable: r.writable, value: r.initializer ? r.initializer.call(l) : void 0 }); }
  function _defineProperty(e, r, t) { return (r = _toPropertyKey(r)) in e ? Object.defineProperty(e, r, { value: t, enumerable: !0, configurable: !0, writable: !0 }) : e[r] = t, e; }
  function _toPropertyKey(t) { var i = _toPrimitive(t, "string"); return "symbol" == typeof i ? i : i + ""; }
  function _toPrimitive(t, r) { if ("object" != typeof t || !t) return t; var e = t[Symbol.toPrimitive]; if (void 0 !== e) { var i = e.call(t, r || "default"); if ("object" != typeof i) return i; throw new TypeError("@@toPrimitive must return a primitive value."); } return ("string" === r ? String : Number)(t); }
  function _applyDecoratedDescriptor(i, e, r, n, l) { var a = {}; return Object.keys(n).forEach(function (i) { a[i] = n[i]; }), a.enumerable = !!a.enumerable, a.configurable = !!a.configurable, ("value" in a || a.initializer) && (a.writable = !0), a = r.slice().reverse().reduce(function (r, n) { return n(i, e, r) || r; }, a), l && void 0 !== a.initializer && (a.value = a.initializer ? a.initializer.call(l) : void 0, a.initializer = void 0), void 0 === a.initializer ? (Object.defineProperty(i, e, a), null) : a; }
  function _initializerWarningHelper(r, e) { throw Error("Decorating class property failed. Please ensure that transform-class-properties is enabled and runs after the decorators transform."); }
  const __COLOCATED_TEMPLATE__ = Ember.HTMLBars.template(
  /*
    <PlutofPanel::Simple
      @title={{i18n-t "Search.panelTaxon"}}
      @collapse={{true}}
  >
      {{#if this.taxonFilter}}
          <div class="out-of-form-field">
              <Common::Ui::ControlLabel>
                  <:label>
                      {{i18n-t "General.taxon"}}
                  </:label>
  
                  <:controls as |controls|>
                      <controls.toggle
                          @checked={{this.includeSubtree}}
                          @toggle={{this.toggleSubtree}}
                      >
                          {{i18n-t "annotation.routes.search.includeTaxonSubtree"}}
                      </controls.toggle>
                  </:controls>
              </Common::Ui::ControlLabel>
  
              <Taxonomy::Taxon::AutoComplete @update={{this.addTaxon}} />
  
              <Common::RecordList
                  @records={{this.taxonFilter.value}}
                  @remove={{this.removeTaxon}} />
          </div>
      {{/if}}
  </PlutofPanel::Simple>
  
  */
  {
    "id": "bXnNz0et",
    "block": "{\"symbols\":[\"__arg0\",\"__arg1\",\"controls\"],\"statements\":[[8,\"plutof-panel/simple\",[],[[\"@title\",\"@collapse\"],[[30,[36,0],[\"Search.panelTaxon\"],null],true]],[[\"default\"],[{\"statements\":[[2,\"\\n\"],[6,[37,3],[[32,0,[\"taxonFilter\"]]],null,[[\"default\"],[{\"statements\":[[2,\"        \"],[10,\"div\"],[14,0,\"out-of-form-field\"],[12],[2,\"\\n            \"],[8,\"common/ui/control-label\",[],[[\"@namedBlocksInfo\"],[[30,[36,4],null,[[\"label\",\"controls\"],[0,1]]]]],[[\"default\"],[{\"statements\":[[6,[37,3],[[30,[36,2],[[32,1],\"label\"],null]],null,[[\"default\",\"else\"],[{\"statements\":[[2,\"\\n                    \"],[1,[30,[36,0],[\"General.taxon\"],null]],[2,\"\\n                \"]],\"parameters\":[]},{\"statements\":[[6,[37,3],[[30,[36,2],[[32,1],\"controls\"],null]],null,[[\"default\"],[{\"statements\":[[6,[37,1],[[32,2]],null,[[\"default\"],[{\"statements\":[[2,\"\\n                    \"],[8,[32,3,[\"toggle\"]],[],[[\"@checked\",\"@toggle\"],[[32,0,[\"includeSubtree\"]],[32,0,[\"toggleSubtree\"]]]],[[\"default\"],[{\"statements\":[[2,\"\\n                        \"],[1,[30,[36,0],[\"annotation.routes.search.includeTaxonSubtree\"],null]],[2,\"\\n                    \"]],\"parameters\":[]}]]],[2,\"\\n                \"]],\"parameters\":[3]}]]]],\"parameters\":[]}]]]],\"parameters\":[]}]]]],\"parameters\":[1,2]}]]],[2,\"\\n\\n            \"],[8,\"taxonomy/taxon/auto-complete\",[],[[\"@update\"],[[32,0,[\"addTaxon\"]]]],null],[2,\"\\n\\n            \"],[8,\"common/record-list\",[],[[\"@records\",\"@remove\"],[[32,0,[\"taxonFilter\",\"value\"]],[32,0,[\"removeTaxon\"]]]],null],[2,\"\\n        \"],[13],[2,\"\\n\"]],\"parameters\":[]}]]]],\"parameters\":[]}]]],[2,\"\\n\"]],\"hasEval\":false,\"upvars\":[\"i18n-t\",\"let\",\"-is-named-block-invocation\",\"if\",\"hash\"]}",
    "moduleName": "plutof/components/search/filter-panels/annotation/taxon.hbs"
  });
  let AnnotationTaxonFilters = (_dec = Ember._tracked, _dec2 = Ember.computed('args.filterInputs.{taxon.value.[],taxon_tree.value.[]}', 'preferInclude'), _dec3 = Ember.computed('includeSubtree'), _dec4 = Ember._action, _dec5 = Ember._action, _dec6 = Ember._action, _class = class AnnotationTaxonFilters extends _component.default {
    constructor(...args) {
      super(...args);
      _initializerDefineProperty(this, "preferInclude", _descriptor, this);
    }
    get includeSubtree() {
      const filters = this.args.filterInputs;
      if (!filters) {
        return this.preferInclude;
      }
      const {
        taxon,
        taxon_tree
      } = filters;
      if (!taxon || !taxon_tree) {
        return this.preferInclude;
      }
      if (taxon.value.length > 0) {
        return false;
      } else if (taxon_tree.value.length > 0) {
        return true;
      } else {
        return this.preferInclude;
      }
    }
    get taxonFilter() {
      const filters = this.args.filterInputs;
      if (!filters) {
        return null;
      }
      return this.includeSubtree ? filters.taxon_tree : filters.taxon;
    }
    toggleSubtree() {
      if (this.taxonFilter.value.length > 0) {
        const value = this.taxonFilter.value;
        const other = this.includeSubtree ? this.args.filterInputs.taxon : this.args.filterInputs.taxon_tree;
        this.taxonFilter.clear();
        // TODO: Add this to clear
        this.taxonFilter.value = [];
        other.value = value.slice();
      }
      this.preferInclude = !this.preferInclude;
    }
    addTaxon(taxon) {
      this.taxonFilter.value.pushObject(taxon);
    }
    removeTaxon(taxon) {
      this.taxonFilter.value.removeObject(taxon);
    }
  }, _descriptor = _applyDecoratedDescriptor(_class.prototype, "preferInclude", [_dec], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return true;
    }
  }), _applyDecoratedDescriptor(_class.prototype, "includeSubtree", [_dec2], Object.getOwnPropertyDescriptor(_class.prototype, "includeSubtree"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "taxonFilter", [_dec3], Object.getOwnPropertyDescriptor(_class.prototype, "taxonFilter"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "toggleSubtree", [_dec4], Object.getOwnPropertyDescriptor(_class.prototype, "toggleSubtree"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "addTaxon", [_dec5], Object.getOwnPropertyDescriptor(_class.prototype, "addTaxon"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "removeTaxon", [_dec6], Object.getOwnPropertyDescriptor(_class.prototype, "removeTaxon"), _class.prototype), _class);
  var _default = _exports.default = Ember._setComponentTemplate(__COLOCATED_TEMPLATE__, AnnotationTaxonFilters);
});