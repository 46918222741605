define("plutof/templates/sequence/add", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = Ember.HTMLBars.template({
    "id": "CMQ7yHc1",
    "block": "{\"symbols\":[],\"statements\":[[8,\"test/route-marker\",[],[[\"@route\"],[\"sequence.add\"]],null],[2,\"\\n\\n\"],[8,\"navbar/edit\",[],[[\"@icon\",\"@title\",\"@record\",\"@reset\",\"@infoContent\"],[[30,[36,0],[\"sequence\"],null],[30,[36,1],[\"Sequences.newSequence\"],null],[32,0,[\"model\",\"sequenceData\"]],[30,[36,2],[\"triggerReset\"],null],\"information/content/sequence/add\"]],null],[2,\"\\n\\n\"],[8,\"sequence/edit\",[],[[\"@data\",\"@validationContext\",\"@showSourceSelector\",\"@showFormSelector\",\"@quickFormChoices\",\"@requireProject\",\"@allowAreaReset\",\"@preserveMapZoom\"],[[32,0,[\"model\",\"sequenceData\"]],[32,0,[\"validationContext\"]],true,true,[32,0,[\"plutofMainforms\"]],[32,0,[\"parentIsProject\"]],true,[32,0,[\"preserveZoom\"]]]],null],[2,\"\\n\\n\"],[8,\"invalid-panels\",[],[[\"@validationContext\"],[[32,0,[\"validationContext\"]]]],null],[2,\"\\n\\n\"],[10,\"div\"],[14,0,\"action-buttons\"],[12],[2,\"\\n    \"],[8,\"common/save-button\",[],[[\"@save\",\"@disabled\"],[[32,0,[\"save\"]],[32,0,[\"validationContext\",\"isInvalid\"]]]],null],[2,\"\\n\\n    \"],[8,\"common/cancel-button\",[],[[\"@cancel\"],[[32,0,[\"cancel\"]]]],null],[2,\"\\n\\n    \"],[8,\"save-and-reset\",[],[[\"@targetObj\",\"@disabled\"],[[32,0],[32,0,[\"validationContext\",\"isInvalid\"]]]],null],[2,\"\\n\"],[13],[2,\"\\n\"]],\"hasEval\":false,\"upvars\":[\"icon\",\"i18n-t\",\"route-action\"]}",
    "moduleName": "plutof/templates/sequence/add.hbs"
  });
});