define("plutof/components/gallery/footer/-left-item", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const __COLOCATED_TEMPLATE__ = Ember.HTMLBars.template(
  /*
    <div>
      <strong>{{@label}}:</strong>
      {{yield}}
  </div>
  
  */
  {
    "id": "YkeYLK86",
    "block": "{\"symbols\":[\"@label\",\"&default\"],\"statements\":[[10,\"div\"],[12],[2,\"\\n    \"],[10,\"strong\"],[12],[1,[32,1]],[2,\":\"],[13],[2,\"\\n    \"],[18,2,null],[2,\"\\n\"],[13],[2,\"\\n\"]],\"hasEval\":false,\"upvars\":[]}",
    "moduleName": "plutof/components/gallery/footer/-left-item.hbs"
  });
  var _default = _exports.default = Ember._setComponentTemplate(__COLOCATED_TEMPLATE__, Ember._templateOnlyComponent());
});