define("plutof/components/clipboard/external-actions", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const __COLOCATED_TEMPLATE__ = Ember.HTMLBars.template(
  /*
    {{#each @externalActions as |externalAction|}}
      <button
          {{action externalAction.action}}
          disabled={{@disabled}}
          class="btn btn-default plutof-btn-green"
      >
          <span class="{{externalAction.icon}}"></span>
  
          <span class="hidden-sm hidden-xs">
              {{i18n-t externalAction.label count=(if @selectedCount (locale-string @selectedCount) (i18n-t 'Clipboard.all'))}}
          </span>
      </button>
  {{/each}}
  
  */
  {
    "id": "umNsT/Pf",
    "block": "{\"symbols\":[\"externalAction\",\"@disabled\",\"@selectedCount\",\"@externalActions\"],\"statements\":[[6,[37,5],[[30,[36,4],[[30,[36,4],[[32,4]],null]],null]],null,[[\"default\"],[{\"statements\":[[2,\"    \"],[11,\"button\"],[16,\"disabled\",[32,2]],[24,0,\"btn btn-default plutof-btn-green\"],[4,[38,0],[[32,0],[32,1,[\"action\"]]],null],[12],[2,\"\\n        \"],[10,\"span\"],[15,0,[31,[[32,1,[\"icon\"]]]]],[12],[13],[2,\"\\n\\n        \"],[10,\"span\"],[14,0,\"hidden-sm hidden-xs\"],[12],[2,\"\\n            \"],[1,[30,[36,1],[[32,1,[\"label\"]]],[[\"count\"],[[30,[36,3],[[32,3],[30,[36,2],[[32,3]],null],[30,[36,1],[\"Clipboard.all\"],null]],null]]]]],[2,\"\\n        \"],[13],[2,\"\\n    \"],[13],[2,\"\\n\"]],\"parameters\":[1]}]]]],\"hasEval\":false,\"upvars\":[\"action\",\"i18n-t\",\"locale-string\",\"if\",\"-track-array\",\"each\"]}",
    "moduleName": "plutof/components/clipboard/external-actions.hbs"
  });
  var _default = _exports.default = Ember._setComponentTemplate(__COLOCATED_TEMPLATE__, Ember._templateOnlyComponent());
});