define("plutof/components/profile/-bookmark-list-row", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const __COLOCATED_TEMPLATE__ = Ember.HTMLBars.template(
  /*
    <tr>
      <td class="bookmark-row__bookmark-title">
          {{@bookmark.personal_link_title}}
      </td>
  
      <td>
          <ToolTip @tooltip={{@bookmark.fullURL}}>
              <a href={{@bookmark.fullURL}} target="_blank">
                  {{short-string @bookmark.fullURL 70}}
              </a>
          </ToolTip>
      </td>
  
      <td class="align-right">
          <Tables::RemoveButton @clicked={{@remove}} />
      </td>
  </tr>
  
  */
  {
    "id": "X1gCUtvx",
    "block": "{\"symbols\":[\"@bookmark\",\"@remove\"],\"statements\":[[10,\"tr\"],[12],[2,\"\\n    \"],[10,\"td\"],[14,0,\"bookmark-row__bookmark-title\"],[12],[2,\"\\n        \"],[1,[32,1,[\"personal_link_title\"]]],[2,\"\\n    \"],[13],[2,\"\\n\\n    \"],[10,\"td\"],[12],[2,\"\\n        \"],[8,\"tool-tip\",[],[[\"@tooltip\"],[[32,1,[\"fullURL\"]]]],[[\"default\"],[{\"statements\":[[2,\"\\n            \"],[10,\"a\"],[15,6,[32,1,[\"fullURL\"]]],[14,\"target\",\"_blank\"],[12],[2,\"\\n                \"],[1,[30,[36,0],[[32,1,[\"fullURL\"]],70],null]],[2,\"\\n            \"],[13],[2,\"\\n        \"]],\"parameters\":[]}]]],[2,\"\\n    \"],[13],[2,\"\\n\\n    \"],[10,\"td\"],[14,0,\"align-right\"],[12],[2,\"\\n        \"],[8,\"tables/remove-button\",[],[[\"@clicked\"],[[32,2]]],null],[2,\"\\n    \"],[13],[2,\"\\n\"],[13],[2,\"\\n\"]],\"hasEval\":false,\"upvars\":[\"short-string\"]}",
    "moduleName": "plutof/components/profile/-bookmark-list-row.hbs"
  });
  var _default = _exports.default = Ember._setComponentTemplate(__COLOCATED_TEMPLATE__, Ember._templateOnlyComponent());
});