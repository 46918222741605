define("plutof/templates/profile/groups/add", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = Ember.HTMLBars.template({
    "id": "0PPGPPB/",
    "block": "{\"symbols\":[],\"statements\":[[8,\"common/legend\",[],[[],[]],[[\"default\"],[{\"statements\":[[2,\"\\n    \"],[1,[30,[36,0],[\"Settings.groups.create\"],null]],[2,\"\\n\"]],\"parameters\":[]}]]],[2,\"\\n\\n\"],[10,\"div\"],[14,0,\"spacer-large-below\"],[12],[13],[2,\"\\n\\n\"],[10,\"div\"],[12],[2,\"\\n    \"],[1,[30,[36,1],null,[[\"path\"],[\"workgroup.name\"]]]],[2,\"\\n\\n    \"],[10,\"div\"],[15,0,[31,[\"control-group \",[30,[36,3],[[35,2,[\"attrs\",\"model\",\"group\",\"name\",\"isInvalid\"]],\"has-error\"],null]]]],[12],[2,\"\\n        \"],[1,[30,[36,5],null,[[\"type\",\"value\",\"class\"],[\"text\",[35,4,[\"group\",\"name\"]],\"form-control\"]]]],[2,\"\\n    \"],[13],[2,\"\\n\"],[13],[2,\"\\n\\n\"],[10,\"div\"],[12],[2,\"\\n    \"],[1,[30,[36,1],null,[[\"path\"],[\"workgroup.description\"]]]],[2,\"\\n\\n    \"],[10,\"div\"],[15,0,[31,[\"control-group \",[30,[36,3],[[35,2,[\"attrs\",\"model\",\"group\",\"description\",\"isInvalid\"]],\"has-error\"],null]]]],[12],[2,\"\\n        \"],[8,\"flexible-textarea\",[[24,0,\"form-control\"]],[[\"@value\"],[[34,4,[\"group\",\"description\"]]]],null],[2,\"\\n    \"],[13],[2,\"\\n\"],[13],[2,\"\\n\\n\"],[10,\"div\"],[14,0,\"spacer-large-below\"],[12],[13],[2,\"\\n\\n\"],[10,\"div\"],[14,0,\"action-buttons\"],[12],[2,\"\\n    \"],[1,[30,[36,8],null,[[\"save\",\"disabled\"],[[30,[36,7],[[32,0],\"save\"],null],[35,6,[\"isInvalid\"]]]]]],[2,\"\\n\\n    \"],[1,[30,[36,10],null,[[\"cancel\"],[[30,[36,9],[\"goBack\"],null]]]]],[2,\"\\n\"],[13],[2,\"\\n\"]],\"hasEval\":false,\"upvars\":[\"i18n-t\",\"plutof-labelc\",\"validations\",\"if\",\"model\",\"input\",\"validationContext\",\"action\",\"common/save-button\",\"route-action\",\"common/cancel-button\"]}",
    "moduleName": "plutof/templates/profile/groups/add.hbs"
  });
});