define("plutof/templates/components/clipboard/clipboard-button", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = Ember.HTMLBars.template({
    "id": "1PKebLtL",
    "block": "{\"symbols\":[],\"statements\":[[10,\"a\"],[12],[2,\"\\n    \"],[11,\"span\"],[16,0,[31,[[34,0]]]],[4,[38,1],[[32,0],\"toggleInclusion\"],[[\"bubbles\"],[false]]],[12],[13],[2,\"\\n\"],[13],[2,\"\\n\"]],\"hasEval\":false,\"upvars\":[\"iconClass\",\"action\"]}",
    "moduleName": "plutof/templates/components/clipboard/clipboard-button.hbs"
  });
});