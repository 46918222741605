define("plutof/templates/components/occurrences-table/-responsive-row/actions", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = Ember.HTMLBars.template({
    "id": "uHbSxM1L",
    "block": "{\"symbols\":[\"&default\"],\"statements\":[[1,[30,[36,0],[\"General.controls\"],null]],[2,\"\\n\\n\"],[10,\"div\"],[14,0,\"occ-table-responsive-row__actions\"],[12],[2,\"\\n    \"],[18,1,[[30,[36,2],null,[[\"button\"],[[30,[36,1],[\"occurrences-table/-responsive-row/action-button\"],null]]]]]],[2,\"\\n\"],[13],[2,\"\\n\"]],\"hasEval\":false,\"upvars\":[\"common/local-label\",\"component\",\"hash\"]}",
    "moduleName": "plutof/templates/components/occurrences-table/-responsive-row/actions.hbs"
  });
});