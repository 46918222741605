define("plutof/components/clipboard/-export/darwin-core", ["exports", "@glimmer/component", "plutof/utils/reflection", "plutof/misc/options-getter", "plutof/components/clipboard/-export/utils"], function (_exports, _component, _reflection, _optionsGetter, _utils) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = _exports.DarwinCoreExportModel = void 0;
  var _dec, _dec2, _dec3, _dec4, _dec5, _dec6, _dec7, _dec8, _dec9, _class, _descriptor, _descriptor2, _descriptor3, _descriptor4, _descriptor5, _descriptor6, _descriptor7, _descriptor8;
  function _initializerDefineProperty(e, i, r, l) { r && Object.defineProperty(e, i, { enumerable: r.enumerable, configurable: r.configurable, writable: r.writable, value: r.initializer ? r.initializer.call(l) : void 0 }); }
  function _defineProperty(e, r, t) { return (r = _toPropertyKey(r)) in e ? Object.defineProperty(e, r, { value: t, enumerable: !0, configurable: !0, writable: !0 }) : e[r] = t, e; }
  function _toPropertyKey(t) { var i = _toPrimitive(t, "string"); return "symbol" == typeof i ? i : i + ""; }
  function _toPrimitive(t, r) { if ("object" != typeof t || !t) return t; var e = t[Symbol.toPrimitive]; if (void 0 !== e) { var i = e.call(t, r || "default"); if ("object" != typeof i) return i; throw new TypeError("@@toPrimitive must return a primitive value."); } return ("string" === r ? String : Number)(t); }
  function _applyDecoratedDescriptor(i, e, r, n, l) { var a = {}; return Object.keys(n).forEach(function (i) { a[i] = n[i]; }), a.enumerable = !!a.enumerable, a.configurable = !!a.configurable, ("value" in a || a.initializer) && (a.writable = !0), a = r.slice().reverse().reduce(function (r, n) { return n(i, e, r) || r; }, a), l && void 0 !== a.initializer && (a.value = a.initializer ? a.initializer.call(l) : void 0, a.initializer = void 0), void 0 === a.initializer ? (Object.defineProperty(i, e, a), null) : a; }
  function _initializerWarningHelper(r, e) { throw Error("Decorating class property failed. Please ensure that transform-class-properties is enabled and runs after the decorators transform."); }
  const __COLOCATED_TEMPLATE__ = Ember.HTMLBars.template(
  /*
    {{!--
      Was wrapped like this before and I try to not go too deep into
      the refacto atm. Ideally, this should be inlined and instead
      of validated action, just have a model property
  --}}
  <Clipboard::DarwinCoreExportForm
      @data={{@model}}
      @options={{@model.options}}
      @validated={{@model.updateValidity}} />
  
  */
  {
    "id": "oAF1CFkh",
    "block": "{\"symbols\":[\"@model\"],\"statements\":[[8,\"clipboard/darwin-core-export-form\",[],[[\"@data\",\"@options\",\"@validated\"],[[32,1],[32,1,[\"options\"]],[32,1,[\"updateValidity\"]]]],null],[2,\"\\n\"]],\"hasEval\":false,\"upvars\":[]}",
    "moduleName": "plutof/components/clipboard/-export/darwin-core.hbs"
  });
  let DarwinCoreExportModel = _exports.DarwinCoreExportModel = (_dec = Ember._tracked, _dec2 = Ember._tracked, _dec3 = Ember._tracked, _dec4 = Ember._tracked, _dec5 = Ember._tracked, _dec6 = Ember._tracked, _dec7 = Ember._tracked, _dec8 = Ember._tracked, _dec9 = Ember._action, _class = class DarwinCoreExportModel extends _utils.ExportFormatModel {
    constructor({
      endpoint,
      options
    }) {
      super({
        endpoint
      });
      _initializerDefineProperty(this, "title", _descriptor, this);
      _initializerDefineProperty(this, "creators", _descriptor2, this);
      _initializerDefineProperty(this, "contacts", _descriptor3, this);
      _initializerDefineProperty(this, "metadata_providers", _descriptor4, this);
      _initializerDefineProperty(this, "abstract", _descriptor5, this);
      _initializerDefineProperty(this, "pub_date", _descriptor6, this);
      _initializerDefineProperty(this, "language", _descriptor7, this);
      _initializerDefineProperty(this, "valid", _descriptor8, this);
      this.options = options;
    }
    static async create(store, endpoint) {
      const ajax = (0, _reflection.getService)(store, 'ajax');
      const options = await (0, _optionsGetter.getPromise)(ajax, endpoint);
      return new DarwinCoreExportModel({
        endpoint,
        options: options.actions.POST
      });
    }
    prepareSaveData(objectList) {
      return {
        object_list: objectList,
        title: this.title,
        creators: this.creators.map(creator => this._formatPerson(creator)),
        contacts: this.contacts.map(contact => this._formatPerson(contact)),
        metadata_providers: this.metadata_providers.map(provider => this._formatPerson(provider)),
        abstract: this.abstract,
        pub_date: this.pub_date,
        language: this.language.url
      };
    }
    _formatPerson(person) {
      return {
        person: person.person.url,
        email: person.email
      };
    }
    // TODO: This is temporary, so that I don't have to change
    // DarwinCoreExportForm
    updateValidity(valid) {
      this.valid = valid;
    }
    get canReset() {
      return true;
    }
    reset() {
      this.title = '';
      this.creators = [];
      this.contacts = [];
      this.metadata_providers = [];
      this.abstract = '';
      this.pub_date = '';
      this.language = null;
    }
  }, _descriptor = _applyDecoratedDescriptor(_class.prototype, "title", [_dec], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return '';
    }
  }), _descriptor2 = _applyDecoratedDescriptor(_class.prototype, "creators", [_dec2], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return [];
    }
  }), _descriptor3 = _applyDecoratedDescriptor(_class.prototype, "contacts", [_dec3], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return [];
    }
  }), _descriptor4 = _applyDecoratedDescriptor(_class.prototype, "metadata_providers", [_dec4], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return [];
    }
  }), _descriptor5 = _applyDecoratedDescriptor(_class.prototype, "abstract", [_dec5], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return '';
    }
  }), _descriptor6 = _applyDecoratedDescriptor(_class.prototype, "pub_date", [_dec6], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return '';
    }
  }), _descriptor7 = _applyDecoratedDescriptor(_class.prototype, "language", [_dec7], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor8 = _applyDecoratedDescriptor(_class.prototype, "valid", [_dec8], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return false;
    }
  }), _applyDecoratedDescriptor(_class.prototype, "updateValidity", [_dec9], Object.getOwnPropertyDescriptor(_class.prototype, "updateValidity"), _class.prototype), _class);
  class DarwinCoreExport extends _component.default {}
  _exports.default = DarwinCoreExport;
  Ember._setComponentTemplate(__COLOCATED_TEMPLATE__, DarwinCoreExport);
});