define("plutof/components/clipboard/living-specimen/label-printing", ["exports", "@glimmer/component", "ember-concurrency", "plutof/config/environment", "plutof/utils/notifications"], function (_exports, _component, _emberConcurrency, _environment, _notifications) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _dec, _dec2, _dec3, _dec4, _dec5, _dec6, _dec7, _class, _descriptor, _descriptor2, _descriptor3, _descriptor4;
  function _initializerDefineProperty(e, i, r, l) { r && Object.defineProperty(e, i, { enumerable: r.enumerable, configurable: r.configurable, writable: r.writable, value: r.initializer ? r.initializer.call(l) : void 0 }); }
  function _defineProperty(e, r, t) { return (r = _toPropertyKey(r)) in e ? Object.defineProperty(e, r, { value: t, enumerable: !0, configurable: !0, writable: !0 }) : e[r] = t, e; }
  function _toPropertyKey(t) { var i = _toPrimitive(t, "string"); return "symbol" == typeof i ? i : i + ""; }
  function _toPrimitive(t, r) { if ("object" != typeof t || !t) return t; var e = t[Symbol.toPrimitive]; if (void 0 !== e) { var i = e.call(t, r || "default"); if ("object" != typeof i) return i; throw new TypeError("@@toPrimitive must return a primitive value."); } return ("string" === r ? String : Number)(t); }
  function _applyDecoratedDescriptor(i, e, r, n, l) { var a = {}; return Object.keys(n).forEach(function (i) { a[i] = n[i]; }), a.enumerable = !!a.enumerable, a.configurable = !!a.configurable, ("value" in a || a.initializer) && (a.writable = !0), a = r.slice().reverse().reduce(function (r, n) { return n(i, e, r) || r; }, a), l && void 0 !== a.initializer && (a.value = a.initializer ? a.initializer.call(l) : void 0, a.initializer = void 0), void 0 === a.initializer ? (Object.defineProperty(i, e, a), null) : a; }
  function _initializerWarningHelper(r, e) { throw Error("Decorating class property failed. Please ensure that transform-class-properties is enabled and runs after the decorators transform."); }
  const __COLOCATED_TEMPLATE__ = Ember.HTMLBars.template(
  /*
    {{#@tabGroup.panel}}
      <div class="form-group">
          <Common::LocalLabel @label="Clipboard.labelFormat" />
  
          <PlutofSelect::Value
              @content={{this.labelFormats}}
              @value={{this.labelFormat}} />
      </div>
  {{/@tabGroup.panel}}
  
  <div class="action-buttons spacer-large-above">
      <PromiseButton
          @clicked={{this.printLabels}}
          @disabled={{not this.entriesCount}}
          @restIcon={{icon "download"}}
          class="btn btn-default plutof-btn-green"
      >
          {{i18n-t "Clipboard.actions.printLabels.label"
              count=(if @selectedEntries.length (locale-string @selectedEntries.length) (i18n-t 'Clipboard.all'))}}
      </PromiseButton>
  </div>
  
  */
  {
    "id": "4SVg0/Wt",
    "block": "{\"symbols\":[\"@tabGroup\",\"@selectedEntries\"],\"statements\":[[6,[37,0],[[32,1,[\"panel\"]]],null,[[\"default\"],[{\"statements\":[[2,\"    \"],[10,\"div\"],[14,0,\"form-group\"],[12],[2,\"\\n        \"],[8,\"common/local-label\",[],[[\"@label\"],[\"Clipboard.labelFormat\"]],null],[2,\"\\n\\n        \"],[8,\"plutof-select/value\",[],[[\"@content\",\"@value\"],[[32,0,[\"labelFormats\"]],[32,0,[\"labelFormat\"]]]],null],[2,\"\\n    \"],[13],[2,\"\\n\"]],\"parameters\":[]}]]],[2,\"\\n\"],[10,\"div\"],[14,0,\"action-buttons spacer-large-above\"],[12],[2,\"\\n    \"],[8,\"promise-button\",[[24,0,\"btn btn-default plutof-btn-green\"]],[[\"@clicked\",\"@disabled\",\"@restIcon\"],[[32,0,[\"printLabels\"]],[30,[36,1],[[32,0,[\"entriesCount\"]]],null],[30,[36,2],[\"download\"],null]]],[[\"default\"],[{\"statements\":[[2,\"\\n        \"],[1,[30,[36,3],[\"Clipboard.actions.printLabels.label\"],[[\"count\"],[[30,[36,5],[[32,2,[\"length\"]],[30,[36,4],[[32,2,[\"length\"]]],null],[30,[36,3],[\"Clipboard.all\"],null]],null]]]]],[2,\"\\n    \"]],\"parameters\":[]}]]],[2,\"\\n\"],[13],[2,\"\\n\"]],\"hasEval\":false,\"upvars\":[\"component\",\"not\",\"icon\",\"i18n-t\",\"locale-string\",\"if\"]}",
    "moduleName": "plutof/components/clipboard/living-specimen/label-printing.hbs"
  });
  function downloadLabels(content) {
    const a = document.createElement('a');
    const blob = new Blob([content], {
      type: 'text/html'
    });
    a.href = URL.createObjectURL(blob);
    a.download = 'labels.html';
    a.click();
    URL.revokeObjectURL(a.href);
    a.remove();
  }
  let LivingSpecimenLabelPrinting = (_dec = Ember.inject.service, _dec2 = Ember.inject.service, _dec3 = Ember.inject.service, _dec4 = Ember.computed('args.selectedEntries.length', 'args.totalCount'), _dec5 = Ember.computed('session.isAuthenticated', 'session.data.authenticated.access_token'), _dec6 = (0, _emberConcurrency.task)({
    restartable: true
  }), _dec7 = Ember._action, _class = class LivingSpecimenLabelPrinting extends _component.default {
    constructor(...args) {
      super(...args);
      _initializerDefineProperty(this, "i18n", _descriptor, this);
      _initializerDefineProperty(this, "locale", _descriptor2, this);
      _initializerDefineProperty(this, "session", _descriptor3, this);
      _defineProperty(this, "labelFormats", [{
        display_name: 'TU Botanical Garden',
        value: null
      }]);
      _initializerDefineProperty(this, "fetchLabels", _descriptor4, this);
    }
    get entriesCount() {
      return Ember.get(this.args, 'selectedEntries.length') || this.args.totalCount;
    }

    // TODO: Fetch service.
    get headers() {
      let headers = {
        'Content-Type': 'application/json',
        'Accept-Language': this.locale.locale
      };
      if (this.session.isAuthenticated) {
        headers['Authorization'] = `Bearer ${this.session.data.authenticated.access_token}`;
      }
      return headers;
    }
    printLabels() {
      return this.fetchLabels.perform();
    }
  }, _descriptor = _applyDecoratedDescriptor(_class.prototype, "i18n", [_dec], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor2 = _applyDecoratedDescriptor(_class.prototype, "locale", [_dec2], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor3 = _applyDecoratedDescriptor(_class.prototype, "session", [_dec3], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _applyDecoratedDescriptor(_class.prototype, "entriesCount", [_dec4], Object.getOwnPropertyDescriptor(_class.prototype, "entriesCount"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "headers", [_dec5], Object.getOwnPropertyDescriptor(_class.prototype, "headers"), _class.prototype), _descriptor4 = _applyDecoratedDescriptor(_class.prototype, "fetchLabels", [_dec6], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return function* () {
        const endpoint = 'export/livingcultures/labels/tu-botanical-garden';
        const objects = this.args.selectedEntries.mapBy('id');
        try {
          const response = yield fetch(`${_environment.default.API_HOST}/${endpoint}/`, {
            method: 'POST-d',
            headers: this.headers,
            body: JSON.stringify({
              object_list: objects
            })
          });
          if (response.ok) {
            downloadLabels(yield response.text());
            (0, _notifications.displayNotification)('success', this.i18n.translate('Clipboard.labelPrinting.downloadStarted'));
          } else {
            (0, _notifications.displayNotification)('error', response.statusText);
          }
        } catch (err) {
          (0, _notifications.reportError)(err);
        }
      };
    }
  }), _applyDecoratedDescriptor(_class.prototype, "printLabels", [_dec7], Object.getOwnPropertyDescriptor(_class.prototype, "printLabels"), _class.prototype), _class);
  var _default = _exports.default = Ember._setComponentTemplate(__COLOCATED_TEMPLATE__, LivingSpecimenLabelPrinting);
});