define("plutof/components/search/filter-panels/occurrence-taxon-checkboxes", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const __COLOCATED_TEMPLATE__ = Ember.HTMLBars.template(
  /*
    <Search::FilterInput @filter={{@filterInputs.interacting_lineage}} />
  <Search::FilterInput @filter={{@filterInputs.has_interactions}} />
  <Search::FilterInput @filter={{@filterInputs.is_synonym}} />
  <Search::FilterInput @filter={{@filterInputs.phenological}} />
  <Search::FilterInput @filter={{@filterInputs.is_restricted}} />
  <Search::FilterInput @filter={{@filterInputs.sequence_parent_taxon}} />
  <Search::FilterInput @filter={{@filterInputs.has_conflict}} />
  <Search::FilterInput @filter={{@filterInputs.has_cluster}} />
  <Search::FilterInput @filter={{@filterInputs.has_typification}} />
  <Search::FilterInput @filter={{@filterInputs.new_name__exists}} />
  
  */
  {
    "id": "kSwYR0jg",
    "block": "{\"symbols\":[\"@filterInputs\"],\"statements\":[[8,\"search/filter-input\",[],[[\"@filter\"],[[32,1,[\"interacting_lineage\"]]]],null],[2,\"\\n\"],[8,\"search/filter-input\",[],[[\"@filter\"],[[32,1,[\"has_interactions\"]]]],null],[2,\"\\n\"],[8,\"search/filter-input\",[],[[\"@filter\"],[[32,1,[\"is_synonym\"]]]],null],[2,\"\\n\"],[8,\"search/filter-input\",[],[[\"@filter\"],[[32,1,[\"phenological\"]]]],null],[2,\"\\n\"],[8,\"search/filter-input\",[],[[\"@filter\"],[[32,1,[\"is_restricted\"]]]],null],[2,\"\\n\"],[8,\"search/filter-input\",[],[[\"@filter\"],[[32,1,[\"sequence_parent_taxon\"]]]],null],[2,\"\\n\"],[8,\"search/filter-input\",[],[[\"@filter\"],[[32,1,[\"has_conflict\"]]]],null],[2,\"\\n\"],[8,\"search/filter-input\",[],[[\"@filter\"],[[32,1,[\"has_cluster\"]]]],null],[2,\"\\n\"],[8,\"search/filter-input\",[],[[\"@filter\"],[[32,1,[\"has_typification\"]]]],null],[2,\"\\n\"],[8,\"search/filter-input\",[],[[\"@filter\"],[[32,1,[\"new_name__exists\"]]]],null],[2,\"\\n\"]],\"hasEval\":false,\"upvars\":[]}",
    "moduleName": "plutof/components/search/filter-panels/occurrence-taxon-checkboxes.hbs"
  });
  var _default = _exports.default = Ember._setComponentTemplate(__COLOCATED_TEMPLATE__, Ember._templateOnlyComponent());
});