define("plutof/templates/components/array-view", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = Ember.HTMLBars.template({
    "id": "ZPDMAqN1",
    "block": "{\"symbols\":[],\"statements\":[[10,\"div\"],[14,0,\"plutof-tooltip-hover\"],[12],[2,\"\\n    \"],[1,[34,1]],[2,\"\\n\"],[6,[37,2],[[35,0]],null,[[\"default\"],[{\"statements\":[[2,\"        ...\\n        \"],[10,\"span\"],[14,0,\"plutof-tooltip\"],[12],[1,[34,0]],[13],[2,\"\\n\"]],\"parameters\":[]}]]],[13],[2,\"\\n\"]],\"hasEval\":false,\"upvars\":[\"tooltip\",\"textArray\",\"if\"]}",
    "moduleName": "plutof/templates/components/array-view.hbs"
  });
});