define("plutof/components/tables/-list/field-header", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const __COLOCATED_TEMPLATE__ = Ember.HTMLBars.template(
  /*
    <th>{{@label}}</th>
  
  */
  {
    "id": "5TAKgFNb",
    "block": "{\"symbols\":[\"@label\"],\"statements\":[[10,\"th\"],[12],[1,[32,1]],[13],[2,\"\\n\"]],\"hasEval\":false,\"upvars\":[]}",
    "moduleName": "plutof/components/tables/-list/field-header.hbs"
  });
  var _default = _exports.default = Ember._setComponentTemplate(__COLOCATED_TEMPLATE__, Ember._templateOnlyComponent());
});