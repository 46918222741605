define("plutof/components/dmp/cost", ["exports", "@ember-decorators/component", "ember-cp-validations", "plutof/mixins/component-validations", "plutof/utils/formatters"], function (_exports, _component, _emberCpValidations, _componentValidations, _formatters) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _dec, _class;
  const __COLOCATED_TEMPLATE__ = Ember.HTMLBars.template(
  /*
    <td class={{has-error this "cost.title"}}>
      <Input @type="text" @value={{@cost.title}} @class="mini-input" />
  </td>
  
  <td>
      <FlexibleTextarea @value={{@cost.description}} @class="mini-input" />
  </td>
  
  <td class={{has-error this "cost.value"}}>
      <Input @type="number" @value={{@cost.value}} @class="mini-input" />
  </td>
  
  <td class={{has-error this "cost.currency"}}>
      <Input
          @type="text"
          @value={{@cost.currency}}
          @placeholder={{i18n-t "dmp.cost.currency.placeholder"}}
          @class="mini-input" />
  </td>
  
  <td class="align-right">
      <button
          {{on "click" (fn @remove @cost)}}
          class="btn btn-link data-table-btn"
      >
          <span class={{icon "remove"}}></span>
      </button>
  </td>
  
  */
  {
    "id": "dF9GD8gr",
    "block": "{\"symbols\":[\"@cost\",\"@remove\"],\"statements\":[[10,\"td\"],[15,0,[30,[36,0],[[32,0],\"cost.title\"],null]],[12],[2,\"\\n    \"],[8,\"input\",[],[[\"@type\",\"@value\",\"@class\"],[\"text\",[32,1,[\"title\"]],\"mini-input\"]],null],[2,\"\\n\"],[13],[2,\"\\n\\n\"],[10,\"td\"],[12],[2,\"\\n    \"],[8,\"flexible-textarea\",[],[[\"@value\",\"@class\"],[[32,1,[\"description\"]],\"mini-input\"]],null],[2,\"\\n\"],[13],[2,\"\\n\\n\"],[10,\"td\"],[15,0,[30,[36,0],[[32,0],\"cost.value\"],null]],[12],[2,\"\\n    \"],[8,\"input\",[],[[\"@type\",\"@value\",\"@class\"],[\"number\",[32,1,[\"value\"]],\"mini-input\"]],null],[2,\"\\n\"],[13],[2,\"\\n\\n\"],[10,\"td\"],[15,0,[30,[36,0],[[32,0],\"cost.currency\"],null]],[12],[2,\"\\n    \"],[8,\"input\",[],[[\"@type\",\"@value\",\"@placeholder\",\"@class\"],[\"text\",[32,1,[\"currency\"]],[30,[36,1],[\"dmp.cost.currency.placeholder\"],null],\"mini-input\"]],null],[2,\"\\n\"],[13],[2,\"\\n\\n\"],[10,\"td\"],[14,0,\"align-right\"],[12],[2,\"\\n    \"],[11,\"button\"],[24,0,\"btn btn-link data-table-btn\"],[4,[38,3],[\"click\",[30,[36,2],[[32,2],[32,1]],null]],null],[12],[2,\"\\n        \"],[10,\"span\"],[15,0,[30,[36,4],[\"remove\"],null]],[12],[13],[2,\"\\n    \"],[13],[2,\"\\n\"],[13],[2,\"\\n\"]],\"hasEval\":false,\"upvars\":[\"has-error\",\"i18n-t\",\"fn\",\"on\",\"icon\"]}",
    "moduleName": "plutof/components/dmp/cost.hbs"
  });
  const Validations = (0, _emberCpValidations.buildValidations)({
    'cost.title': (0, _emberCpValidations.validator)('presence', true),
    'cost.value': (0, _emberCpValidations.validator)('format', {
      regex: _formatters.DECIMAL_REGEX
    }),
    'cost.currency': (0, _emberCpValidations.validator)('presence', true)
  });
  let DMPCost = (_dec = (0, _component.tagName)('tr'), _dec(_class = class DMPCost extends Ember.Component.extend(Validations, _componentValidations.default) {}) || _class);
  var _default = _exports.default = Ember._setComponentTemplate(__COLOCATED_TEMPLATE__, DMPCost);
});