define("plutof/components/common/private-relation", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const __COLOCATED_TEMPLATE__ = Ember.HTMLBars.template(
  /*
    <span class="private-relation">
      {{i18n-t "Access.forbidden"}}
  </span>
  
  */
  {
    "id": "dBbLHBkR",
    "block": "{\"symbols\":[],\"statements\":[[10,\"span\"],[14,0,\"private-relation\"],[12],[2,\"\\n    \"],[1,[30,[36,0],[\"Access.forbidden\"],null]],[2,\"\\n\"],[13],[2,\"\\n\"]],\"hasEval\":false,\"upvars\":[\"i18n-t\"]}",
    "moduleName": "plutof/components/common/private-relation.hbs"
  });
  var _default = _exports.default = Ember._setComponentTemplate(__COLOCATED_TEMPLATE__, Ember._templateOnlyComponent());
});