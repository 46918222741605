define("plutof/components/search/top-results/info", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const __COLOCATED_TEMPLATE__ = Ember.HTMLBars.template(
  /*
    {{#unless @canLoad}}
      <p class="top-results-container__info">
          <span class={{icon "info"}}></span>
          {{i18n-t "Search.topResults.phenoFilterWarning"}}
      </p>
  {{/unless}}
  
  {{#if @isLoading}}
      <p class="top-results-container__info">
          <span class="loading-icon"></span>
      </p>
  {{/if}}
  
  */
  {
    "id": "uw3V+ZUq",
    "block": "{\"symbols\":[\"@canLoad\",\"@isLoading\"],\"statements\":[[6,[37,2],[[32,1]],null,[[\"default\"],[{\"statements\":[[2,\"    \"],[10,\"p\"],[14,0,\"top-results-container__info\"],[12],[2,\"\\n        \"],[10,\"span\"],[15,0,[30,[36,0],[\"info\"],null]],[12],[13],[2,\"\\n        \"],[1,[30,[36,1],[\"Search.topResults.phenoFilterWarning\"],null]],[2,\"\\n    \"],[13],[2,\"\\n\"]],\"parameters\":[]}]]],[2,\"\\n\"],[6,[37,3],[[32,2]],null,[[\"default\"],[{\"statements\":[[2,\"    \"],[10,\"p\"],[14,0,\"top-results-container__info\"],[12],[2,\"\\n        \"],[10,\"span\"],[14,0,\"loading-icon\"],[12],[13],[2,\"\\n    \"],[13],[2,\"\\n\"]],\"parameters\":[]}]]]],\"hasEval\":false,\"upvars\":[\"icon\",\"i18n-t\",\"unless\",\"if\"]}",
    "moduleName": "plutof/components/search/top-results/info.hbs"
  });
  var _default = _exports.default = Ember._setComponentTemplate(__COLOCATED_TEMPLATE__, Ember._templateOnlyComponent());
});