define("plutof/components/progress-bar", ["exports", "@glimmer/component"], function (_exports, _component) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const __COLOCATED_TEMPLATE__ = Ember.HTMLBars.template(
  /*
    <div
      class="progress-bar"
      role="progressbar"
      aria-valuemin="0"
      aria-valuemax="100"
      style={{this.progressStyle}}
      aria-valuenow={{this.percentage}}
  >
      <span>
          {{this.percentage}}%
      </span>
  </div>
  
  */
  {
    "id": "xkIPEOox",
    "block": "{\"symbols\":[],\"statements\":[[10,\"div\"],[14,0,\"progress-bar\"],[14,\"role\",\"progressbar\"],[14,\"aria-valuemin\",\"0\"],[14,\"aria-valuemax\",\"100\"],[15,5,[32,0,[\"progressStyle\"]]],[15,\"aria-valuenow\",[32,0,[\"percentage\"]]],[12],[2,\"\\n    \"],[10,\"span\"],[12],[2,\"\\n        \"],[1,[32,0,[\"percentage\"]]],[2,\"%\\n    \"],[13],[2,\"\\n\"],[13],[2,\"\\n\"]],\"hasEval\":false,\"upvars\":[]}",
    "moduleName": "plutof/components/progress-bar.hbs"
  });
  class ProgressBar extends _component.default {
    get percentage() {
      return Math.round(this.args.progress * 100);
    }
    get progressStyle() {
      const percentage = this.args.progress * 100;
      return Ember.String.htmlSafe(`width: ${percentage}%;`);
    }
  }
  var _default = _exports.default = Ember._setComponentTemplate(__COLOCATED_TEMPLATE__, ProgressBar);
});