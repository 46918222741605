define("plutof/components/substrate/edit", ["exports", "@ember-decorators/component", "ember-cp-validations", "plutof/misc/abstract", "plutof/mixins/component-validations"], function (_exports, _component, _emberCpValidations, _abstract, _componentValidations) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.createSubstrateData = createSubstrateData;
  _exports.default = void 0;
  var _dec, _class, _dec2, _class2;
  function _applyDecoratedDescriptor(i, e, r, n, l) { var a = {}; return Object.keys(n).forEach(function (i) { a[i] = n[i]; }), a.enumerable = !!a.enumerable, a.configurable = !!a.configurable, ("value" in a || a.initializer) && (a.writable = !0), a = r.slice().reverse().reduce(function (r, n) { return n(i, e, r) || r; }, a), l && void 0 !== a.initializer && (a.value = a.initializer ? a.initializer.call(l) : void 0, a.initializer = void 0), void 0 === a.initializer ? (Object.defineProperty(i, e, a), null) : a; }
  const __COLOCATED_TEMPLATE__ = Ember.HTMLBars.template(
  /*
    <PlutofPanel::Simple
      @title={{i18n-t "General.substrate"}}
      @collapse={{not @data.substrate.substrate}}
      data-test="Substrate::Edit"
  >
      <GenericForms::Record @record={{@data.substrate}} as |form|>
          <Layout::RegularGrid @columns={{4}}>
              {{form.bind "substrate" filters=(hash access="view")}}
              {{form.bind "substrate_new_name" valid=this.validations.attrs.substrate.substrate_new_name.isValid}}
              {{form.bind "type"}}
              {{form.bind "text"}}
          </Layout::RegularGrid>
      </GenericForms::Record>
  </PlutofPanel::Simple>
  
  */
  {
    "id": "cCKTi2Wh",
    "block": "{\"symbols\":[\"form\",\"@data\"],\"statements\":[[8,\"plutof-panel/simple\",[[24,\"data-test\",\"Substrate::Edit\"]],[[\"@title\",\"@collapse\"],[[30,[36,0],[\"General.substrate\"],null],[30,[36,1],[[32,2,[\"substrate\",\"substrate\"]]],null]]],[[\"default\"],[{\"statements\":[[2,\"\\n    \"],[8,\"generic-forms/record\",[],[[\"@record\"],[[32,2,[\"substrate\"]]]],[[\"default\"],[{\"statements\":[[2,\"\\n        \"],[8,\"layout/regular-grid\",[],[[\"@columns\"],[4]],[[\"default\"],[{\"statements\":[[2,\"\\n            \"],[1,[30,[36,3],[[32,1,[\"bind\"]],\"substrate\"],[[\"filters\"],[[30,[36,2],null,[[\"access\"],[\"view\"]]]]]]],[2,\"\\n            \"],[1,[30,[36,3],[[32,1,[\"bind\"]],\"substrate_new_name\"],[[\"valid\"],[[32,0,[\"validations\",\"attrs\",\"substrate\",\"substrate_new_name\",\"isValid\"]]]]]],[2,\"\\n            \"],[1,[30,[36,3],[[32,1,[\"bind\"]],\"type\"],null]],[2,\"\\n            \"],[1,[30,[36,3],[[32,1,[\"bind\"]],\"text\"],null]],[2,\"\\n        \"]],\"parameters\":[]}]]],[2,\"\\n    \"]],\"parameters\":[1]}]]],[2,\"\\n\"]],\"parameters\":[]}]]],[2,\"\\n\"]],\"hasEval\":false,\"upvars\":[\"i18n-t\",\"not\",\"hash\",\"component\"]}",
    "moduleName": "plutof/components/substrate/edit.hbs"
  });
  const DataValidations = (0, _emberCpValidations.buildValidations)({
    'substrate.substrate_new_name': (0, _emberCpValidations.validator)('length', {
      max: 255
    })
  });
  let SubstrateData = (_dec = Ember.computed('substrate.{substrate_new_name,text,substrate.id,type.id}'), _class = class SubstrateData extends Ember.Object.extend(DataValidations) {
    save() {
      if (this.isSubstrateEmpty && this.get('substrate.isNew')) {
        return _abstract.EMPTY_PROMISE;
      }
      this.occurrence.set('substrate', this.substrate);
      return this.substrate.save();
    }
    get isSubstrateEmpty() {
      const fields = [this.get('substrate.substrate_new_name'), this.get('substrate.text'), this.get('substrate.substrate.id'), this.get('substrate.type')];
      return fields.every(Ember.isEmpty);
    }
  }, _applyDecoratedDescriptor(_class.prototype, "isSubstrateEmpty", [_dec], Object.getOwnPropertyDescriptor(_class.prototype, "isSubstrateEmpty"), _class.prototype), _class);
  function createSubstrateData(store, occurrence) {
    return occurrence.get('substrate').then(existingSubstrate => {
      let substrate = existingSubstrate;
      if (Ember.isNone(existingSubstrate)) {
        substrate = store.createRecord('substrate/substrate');
      }
      const ownerInjection = Ember.getOwner(store).ownerInjection();
      return SubstrateData.create(ownerInjection, {
        substrate,
        occurrence
      });
    });
  }
  const Validations = (0, _emberCpValidations.buildValidations)({
    'data.validations.isValid': (0, _emberCpValidations.validator)('is-truthy')
  });
  let SubstrateComponent = (_dec2 = (0, _component.classNames)('substrate-edit'), _dec2(_class2 = class SubstrateComponent extends Ember.Component.extend(Validations, _componentValidations.default) {}) || _class2);
  var _default = _exports.default = Ember._setComponentTemplate(__COLOCATED_TEMPLATE__, SubstrateComponent);
});