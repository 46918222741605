define("plutof/components/tables/column-control/-toggle", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const __COLOCATED_TEMPLATE__ = Ember.HTMLBars.template(
  /*
    <button
      {{on "click" @toggle}}
      class="column-control-toggle"
      ...attributes
  >
      <span class="fas {{if @visible 'fa-toggle-on' 'fa-toggle-off'}}"></span>
  
      {{yield}}
  </button>
  
  */
  {
    "id": "XPb38aHY",
    "block": "{\"symbols\":[\"&attrs\",\"@toggle\",\"@visible\",\"&default\"],\"statements\":[[11,\"button\"],[24,0,\"column-control-toggle\"],[17,1],[4,[38,0],[\"click\",[32,2]],null],[12],[2,\"\\n    \"],[10,\"span\"],[15,0,[31,[\"fas \",[30,[36,1],[[32,3],\"fa-toggle-on\",\"fa-toggle-off\"],null]]]],[12],[13],[2,\"\\n\\n    \"],[18,4,null],[2,\"\\n\"],[13],[2,\"\\n\"]],\"hasEval\":false,\"upvars\":[\"on\",\"if\"]}",
    "moduleName": "plutof/components/tables/column-control/-toggle.hbs"
  });
  var _default = _exports.default = Ember._setComponentTemplate(__COLOCATED_TEMPLATE__, Ember._templateOnlyComponent());
});