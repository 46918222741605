define("plutof/templates/components/navbar/-navbar-base/info", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = Ember.HTMLBars.template({
    "id": "2UoMa2Iw",
    "block": "{\"symbols\":[],\"statements\":[[6,[37,3],null,[[\"clicked\"],[[30,[36,2],[[32,0],[32,0,[\"show\"]]],null]]],[[\"default\"],[{\"statements\":[[2,\"    \"],[10,\"span\"],[15,0,[30,[36,0],[\"info\"],null]],[12],[13],[2,\"\\n    \"],[1,[30,[36,1],[\"General.Info\"],null]],[2,\"\\n\"]],\"parameters\":[]}]]]],\"hasEval\":false,\"upvars\":[\"icon\",\"i18n-t\",\"action\",\"navbar/-navbar-base/button\"]}",
    "moduleName": "plutof/templates/components/navbar/-navbar-base/info.hbs"
  });
});