define("plutof/components/tables/-list/field", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const __COLOCATED_TEMPLATE__ = Ember.HTMLBars.template(
  /*
    <td ...attributes>{{yield}}</td>
  
  */
  {
    "id": "MDbk7ajJ",
    "block": "{\"symbols\":[\"&attrs\",\"&default\"],\"statements\":[[11,\"td\"],[17,1],[12],[18,2,null],[13],[2,\"\\n\"]],\"hasEval\":false,\"upvars\":[]}",
    "moduleName": "plutof/components/tables/-list/field.hbs"
  });
  var _default = _exports.default = Ember._setComponentTemplate(__COLOCATED_TEMPLATE__, Ember._templateOnlyComponent());
});