define("plutof/components/tables/column-control/-remove", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const __COLOCATED_TEMPLATE__ = Ember.HTMLBars.template(
  /*
    <button
      {{on "click" @toggle}}
      disabled={{@disabled}}
      class="column-control-btn"
      ...attributes
  >
      <span class="icon-remove icon"></span>
  </button>
  
  */
  {
    "id": "YsrqH36I",
    "block": "{\"symbols\":[\"@disabled\",\"&attrs\",\"@toggle\"],\"statements\":[[11,\"button\"],[16,\"disabled\",[32,1]],[24,0,\"column-control-btn\"],[17,2],[4,[38,0],[\"click\",[32,3]],null],[12],[2,\"\\n    \"],[10,\"span\"],[14,0,\"icon-remove icon\"],[12],[13],[2,\"\\n\"],[13],[2,\"\\n\"]],\"hasEval\":false,\"upvars\":[\"on\"]}",
    "moduleName": "plutof/components/tables/column-control/-remove.hbs"
  });
  var _default = _exports.default = Ember._setComponentTemplate(__COLOCATED_TEMPLATE__, Ember._templateOnlyComponent());
});