define("plutof/templates/components/common/button-group", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = Ember.HTMLBars.template({
    "id": "C6dh9YX0",
    "block": "{\"symbols\":[\"&attrs\",\"@activate\",\"@active\",\"&default\"],\"statements\":[[11,\"div\"],[24,0,\"button-group\"],[17,1],[12],[2,\"\\n    \"],[18,4,[[30,[36,1],null,[[\"button\"],[[30,[36,0],[\"common/-button-group-button\"],[[\"active\",\"activate\"],[[32,3],[32,2]]]]]]]]],[2,\"\\n\"],[13],[2,\"\\n\"]],\"hasEval\":false,\"upvars\":[\"component\",\"hash\"]}",
    "moduleName": "plutof/templates/components/common/button-group.hbs"
  });
});