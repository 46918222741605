define("plutof/templates/trait/index", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = Ember.HTMLBars.template({
    "id": "z6vLKrmt",
    "block": "{\"symbols\":[\"translations\",\"Field\",\"trait\"],\"statements\":[[8,\"navbar/list\",[],[[\"@icon\",\"@title\",\"@addRoute\",\"@pagination\",\"@goBack\"],[[30,[36,1],[\"trait\"],null],[30,[36,2],[\"Forms.traitsLabel\"],null],[30,[36,3],[[32,0,[\"model\",\"canEdit\"]],\"trait.add\"],null],[32,0,[\"model\",\"pagination\"]],[30,[36,4],[\"goBack\"],null]]],null],[2,\"\\n\\n\"],[8,\"i18n/model-strings\",[],[[\"@model\"],[\"measurement.measurement\"]],[[\"default\"],[{\"statements\":[[2,\"\\n    \"],[8,\"tables/list-route\",[],[[\"@records\"],[[32,0,[\"model\",\"pagination\",\"objects\"]]]],[[\"default\"],[{\"statements\":[[2,\"\\n        \"],[8,[32,2],[],[[\"@label\"],[[32,1,[\"name\",\"label\"]]]],[[\"default\"],[{\"statements\":[[2,\"\\n\"],[6,[37,5],null,[[\"route\",\"model\"],[\"trait.view\",[32,3,[\"id\"]]]],[[\"default\"],[{\"statements\":[[2,\"                \"],[1,[30,[36,0],[[32,3,[\"name\"]],[32,3,[\"name_translated\"]]],null]],[2,\"\\n\"]],\"parameters\":[]}]]],[2,\"        \"]],\"parameters\":[]}]]],[2,\"\\n\\n        \"],[8,[32,2],[],[[\"@label\"],[[32,1,[\"description\",\"label\"]]]],[[\"default\"],[{\"statements\":[[2,\"\\n            \"],[1,[30,[36,6],[[30,[36,0],[[32,3,[\"description\"]],[32,3,[\"description_translated\"]]],null],120],null]],[2,\"\\n        \"]],\"parameters\":[]}]]],[2,\"\\n\\n        \"],[8,[32,2],[],[[\"@label\"],[[32,1,[\"type\",\"label\"]]]],[[\"default\"],[{\"statements\":[[2,\"\\n            \"],[1,[30,[36,7],[[32,1,[\"type\",\"choices\"]],[32,3,[\"type\"]]],null]],[2,\"\\n        \"]],\"parameters\":[]}]]],[2,\"\\n    \"]],\"parameters\":[2,3]}]]],[2,\"\\n\"]],\"parameters\":[1]}]]],[2,\"\\n\"]],\"hasEval\":false,\"upvars\":[\"localized-field\",\"icon\",\"i18n-t\",\"if\",\"route-action\",\"link-to\",\"short-string\",\"get\"]}",
    "moduleName": "plutof/templates/trait/index.hbs"
  });
});