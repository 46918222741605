define("plutof/components/sample/measurement-fields", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const __COLOCATED_TEMPLATE__ = Ember.HTMLBars.template(
  /*
    <Measurements::MeasurementView::Wrapper
      @mainform={{@mainform}}
      @object={{@object}}
      as |measurements empty expand|
  >
      {{#each measurements as |item|}}
          <Measurements::MeasurementView::Single
              @measurement={{item}}
              @expand={{unless item.valueIsEmpty (fn expand item)}} />
      {{/each}}
  </Measurements::MeasurementView::Wrapper>
  
  */
  {
    "id": "/bL34uhF",
    "block": "{\"symbols\":[\"measurements\",\"empty\",\"expand\",\"item\",\"@mainform\",\"@object\"],\"statements\":[[8,\"measurements/measurement-view/wrapper\",[],[[\"@mainform\",\"@object\"],[[32,5],[32,6]]],[[\"default\"],[{\"statements\":[[2,\"\\n\"],[6,[37,3],[[30,[36,2],[[30,[36,2],[[32,1]],null]],null]],null,[[\"default\"],[{\"statements\":[[2,\"        \"],[8,\"measurements/measurement-view/single\",[],[[\"@measurement\",\"@expand\"],[[32,4],[30,[36,1],[[32,4,[\"valueIsEmpty\"]],[30,[36,0],[[32,3],[32,4]],null]],null]]],null],[2,\"\\n\"]],\"parameters\":[4]}]]]],\"parameters\":[1,2,3]}]]],[2,\"\\n\"]],\"hasEval\":false,\"upvars\":[\"fn\",\"unless\",\"-track-array\",\"each\"]}",
    "moduleName": "plutof/components/sample/measurement-fields.hbs"
  });
  var _default = _exports.default = Ember._setComponentTemplate(__COLOCATED_TEMPLATE__, Ember._templateOnlyComponent());
});