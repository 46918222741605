define("plutof/components/plutof-panel/item-list/button", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const __COLOCATED_TEMPLATE__ = Ember.HTMLBars.template(
  /*
    <PromiseButton
      @clicked={{@clicked}}
      @disabled={{@disabled}}
      @restIcon={{@icon}}
      class="plutof-btn__controls plutof-btn__controls--xs {{@class}}"
      ...attributes
  >
      {{yield}}
  </PromiseButton>
  
  */
  {
    "id": "AKUIy8VL",
    "block": "{\"symbols\":[\"@clicked\",\"@disabled\",\"@icon\",\"@class\",\"&attrs\",\"&default\"],\"statements\":[[8,\"promise-button\",[[16,0,[31,[\"plutof-btn__controls plutof-btn__controls--xs \",[32,4]]]],[17,5]],[[\"@clicked\",\"@disabled\",\"@restIcon\"],[[32,1],[32,2],[32,3]]],[[\"default\"],[{\"statements\":[[2,\"\\n    \"],[18,6,null],[2,\"\\n\"]],\"parameters\":[]}]]],[2,\"\\n\"]],\"hasEval\":false,\"upvars\":[]}",
    "moduleName": "plutof/components/plutof-panel/item-list/button.hbs"
  });
  var _default = _exports.default = Ember._setComponentTemplate(__COLOCATED_TEMPLATE__, Ember._templateOnlyComponent());
});